import React ,{useState} from "react";
import Privacy from "../../../Includes/Layouts/TMSV_SQL_V1/Privacy";
import Complaint from "../../../Includes/Layouts/TMSV_SQL_V1/Complaint";
const AdvFooter = () => {
    const [showprivacy, setshowprivacy] = useState(false);
    const [showcomplaint, setshowcomplaint] = useState(false);
    const handleprivacyShow = () => {
        setshowprivacy(true);
      };
      const handleprivacyClose = () => {
        setshowprivacy(false);
      };
      const handlecomplaintShow = () => {
        setshowcomplaint(true);
      };
      const handlecomplaintHide = () => {
        setshowcomplaint(false);
      };

  return (
    <>
         <footer>
      <div className="container  ">
        <div className="inner pt-3">
          <div className="row">
            <div className="col-lg-8">
              <p>TMS Legal Ltd is a limited company registered in England and Wales under company no: 10315962 Its registered office is 1 Poole Road, Bournemouth , Dorset, BH2 5QQ It is authorised and regulated by the Solicitors Regulation Authority, Number: 645041</p>
              <div className="ft_menu  mb-4 ">
                <ul>
                  <li>
                    <a className="text-primary" data-toggle="modal" onClick={handleprivacyShow}  id="privacy"> Privacy and Cookie Policy</a>
                  </li>
                  <li>
                    <a className="text-primary"   href="https://articles.bankrefundsnow.co.uk/static/media/TOBA_New.760d315a.pdf" target="_blank"> TMS Legal Terms and Conditions</a>
                  </li>
                  <li>
                    <a className="text-primary"  data-toggle="modal" onClick={handlecomplaintShow} id="complaints"> Complaints Procedure</a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-4"></div>
          </div>
        </div>
      </div>
    </footer>
    <Privacy showstatus={showprivacy} hidepopup={handleprivacyClose} />
    <Complaint showstatus={showcomplaint} hidepopup={handlecomplaintHide} /> 
    </>
  );
};
export default AdvFooter;
