import React from "react";
const SupportingDoc = ({
    clsName,
    backClick,
    onSelectAnswer
}) => {
    const labelclicked = async (e) => {
        // e.preventDefault();
        onSelectAnswer(e); 
    };
    return (
        <>
        <div className="question-content mt-0 scale-down-ver-center px-3 py-lg-3 py-md-3 py-sm-2 py-2 pb-3">
    <div className="row">
      <div className="col-lg-12 col-md-12 col-sm-12">
        <div className=" mb-2"> 
            <button
          className="btn-comm2 py-3 w-100  nextBtn"
          type="button"
          onClick={labelclicked}
          value="124"
          name="question_42"
          id="agreeone"
          data-quest-id={42}
          data-ans-id={124}
          data-ans-label="Your Credit Agreement Number"
        >Your Credit Agreement Number
        </button>
  </div>
      </div>
      <div className="col-lg-12 col-md-12 col-sm-12">
        <div className=" mb-2">
            <button
          className="btn-comm2 py-lg-3 py-md-3 py-sm-2 py-2 w-100 nextBtn"
          onClick={labelclicked}
          type="button"
          value="125"
          name="question_42"
          id="agreetwo"
          data-quest-id={42}
          data-ans-id={125}
          data-ans-label="Any Documents Relating to Your Credit Agreement"
        >Any Documents Relating to Your Credit Agreement
        </button>
        </div>
      </div>
      <div className="col-lg-12 col-md-12 col-sm-12">
        <div className=" mb-2">
          <button
           className=" btn-comm2 py-lg-3 py-md-3 py-sm-2 py-2 w-100 nextBtn"
           onClick={labelclicked}
          type="button"
          value="120"
          name="question_42"
          id="agreethree"
          data-quest-id={42}
          data-ans-id={120}
          data-ans-label="A Statement of Account"
           >
              A Statement of Account
            </button>
        </div>
      </div>
      <div className="col-lg-12 col-md-12 col-sm-12">
        <div className=" mb-2">
          <button 
          className=" btn-comm2 py-lg-3 py-md-3 py-sm-2 py-2 w-100 nextBtn"
          onClick={labelclicked}
          type="button"
          value="126"
          name="question_42"
          id="agreefour"
          data-quest-id={42}
          data-ans-id={126}
          data-ans-label="BANK STATEMENTS (3 months before and after the start of your credit(s)"
          >
              BANK STATEMENTS (3 months before and after the start of your credit(s)
           </button>
        </div>
      </div>
      <div className="col-lg-12 col-md-12 col-sm-12">
        <div className=" mb-2">
          <button 
          className=" btn-comm2 py-lg-3 py-md-3 py-sm-2 py-2 w-100 nextBtn"
          onClick={labelclicked}
          type="button"
          value="127"
          name="question_42"
          id="agreefive"
          data-quest-id={42}
          data-ans-id={127}
          data-ans-label="Arrears Letters"
          >
              Arrears Letters
         </button>
        </div>
      </div>
      <div className="col-lg-12 col-md-12 col-sm-12">
        <div className=" mb-2">
          <button 
          className=" btn-comm2 py-lg-3 py-md-3 py-sm-2 py-2 w-100 nextBtn"
          onClick={labelclicked}
          type="button"
          value="123"
          name="question_42"
          id="agreesix"
          data-quest-id={42}
          data-ans-id={123}
          data-ans-label="None of the Above"
          >
              None of the Above
            </button>
        </div>
      </div>
    </div>
    <p className="text-center bck-marwid">
                    <button
                      name="btn_qest_48"
                      className="btn btn-secondary backbtn1a"
                      id=""
                      value=""
                      type="button"
                      onClick={backClick}
              
                    >Go Back</button>
                    </p>
  </div>
        </>
    )
}
export default SupportingDoc;