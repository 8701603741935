import React, {
    useState,
    useEffect,
  } from "react";
  import Header from "../Includes/Layouts/FLPClicktoCall/Header";
  import { Api } from "../../api/Api";
  import "../../assets/FLPClicktoCall/css/style.scss";
  import { queryString } from "../../Utility/QueryString";
  import { AppDomain } from "../../Constants/EnvConstants";
  import { useOpenBanking } from "../../Hooks/useOpenBanking";
  import { useHistory } from "react-router-dom";
  import CallButton from "../Includes/Layouts/Common/CallButton";

const FLPClicktoCall = () => {
  const [userDetails, setUserDetails] = useState({});
  const [loader, setLoader] = useState(1);
  const [payPerLoader, setPayPerLoader] = useState(0);
  const history = useHistory();
  const { storeClickResponse } = useOpenBanking();
  const query = new URLSearchParams(window.location.search);
  const query_source = query.get("atp_sub6");
  var source = query_source ? query_source : "live";
  let click_to_call_stat = '';
  let category = '';
  let payload;

  const startTimer = (duration, display) => 
  {
    var timer = duration, minutes, seconds;
    setInterval(function () {
        minutes = parseInt(timer / 60, 10);
        seconds = parseInt(timer % 60, 10);
  
        minutes = minutes < 10 ? "0" + minutes : minutes;
        seconds = seconds < 10 ? "0" + seconds : seconds;
  
        display.textContent = minutes + ":" + seconds;
  
        if (--timer < 0) {
            timer = duration;
        }
    }, 1000);
  }

  
  useEffect(() => {
    var twoMinutes = 60 * 2,
    display = document.querySelector('#time');
    startTimer(twoMinutes, display);

    (async () => { 
        const response = await Api.get(
            "/api/v1/fetch-clicktocall-details?query_string=" + queryString,
            {}
          );
          if (response.data.status === "Success") {
            let flg_id = response.data.response.flg_id;
            let lender = response.data.response.lender;
            let fullname = response.data.response.full_name;
            let source = response.data.response.source;
            let type_id = response.data.response.type_id;
            category = response.data.response.category;
            click_to_call_stat = response.data.response.click_to_call_stat;
            localStorage.setItem('click_to_call_stat', click_to_call_stat);
            
            setUserDetails({ 
              flg_id,
              lender,
              fullname,
              click_to_call_stat,
              type_id
            })
            
          
          
          if (click_to_call_stat == 1) {
            payload = { "page": 'CLICK_TO_CALL',type:'visit', source};
          } else {
            payload = { "page": 'CLICK_TO_CALL_VIA',type:'visit', source};
          }
          await storeClickResponse({uuid:type_id},"ob_clicktocall_history",type_id,payload);
          
        }

          setTimeout(() => {
            
            if (click_to_call_stat == 0 ) {
               
               if (category == 'Internal') {
                window.location.href = AppDomain + "/proceed-obV2?"+queryString;
                // history.push("/proceed-obV2?"+ queryString);

               } else if(category == 'External') {
                window.location.href = AppDomain + "/followup-fos?"+queryString;

                // history.push("/followup-fos?"+ queryString);

               } else {
                history.push("/thankyou?"+ queryString);
               }          


            } else {
              setPayPerLoader(1);  
            }
           
            setLoader(0);
          
          
          }, 1700);

    })();

  },[]);


  const clickToCall = async (e) => {
    
    // setBtnDisable(true);
    // setLoader(1);
    // setCredLoader(1);
    
    let btn_label = e.target.getAttribute('data-btn-label');
    let page = e.target.getAttribute('data-page-label');

    let payload = { page,type:'btn_click', btn_label, source,'click_btn':'1' };
    await storeClickResponse({uuid:userDetails.type_id},"ob_clicktocall_history",userDetails.type_id,payload);

    // setTimeout(() => {
    //   setLoader(0);
    // }, 1500);
  };

  return (
    <>
    <Header />
    <div className="FLPClicktoCall">
    <div className="bg-main-pr">
        <div className={`loader-wrap  ${
            loader == 1 ? "showload" : "hide"
          }`}>
      <div className="loader">
        <img src="assets/FLPClicktoCall/img/review.gif" alt="" />
      </div>
    </div>
    <div className={`pay-per-call  main-div ${payPerLoader == 0 ? "hide" : "showload"}`}>
      <div className="brand-logo">
        <img className="mt-lg-4  mt-4" height="65px" src="assets/FLPClicktoCall/img/a00187b31dd5a4ece0c8f809b69962eb.png" alt="" />  
      </div>
      <div className="container">
        <div className="item-cent-flx ">
          <div className="   mt-4 mt-md-5 text-center">
            <h1 className="mt-2">{userDetails?.fullname}, we need to speak to you about your {userDetails?.lender} complaint. </h1>
            <img className="img_2"  src="assets/FLPClicktoCall/img/customer-service-agent.png" alt="" />

            <div className="box_1"> 
              Your Case Reference ID is  &nbsp; <span>{userDetails?.flg_id}</span>
            </div>
            <p className="mt-3 text_2a sub_head_til">Act Fast! One of our agent is available online. </p>
            <p className=" text2b mb-3">Simply tap the Green button below to connect with one of our agents.</p>

            <div className="row mt-lg-4 mt-md-2 mt-sm-3 mt-0">
              <div className="col-lg-6 px-4 m-auto mb-2">
                <div>
                  <CallButton 
                  clickToCall = {clickToCall}
                  />
                </div>
              </div>

              <p className="counter_p">Your spot will be reserved for: <span id="time">2:00</span> </p>
            </div>
          
          </div>
        </div>
      </div>
    </div>
  </div>
  </div>
  </>
  )
}

export default FLPClicktoCall