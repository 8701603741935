import React, { useState } from "react";
import SelectBox from "../../../../UI/SelectBox";
import { Amount } from "../../../../../Constants/Constants";

const OutstandingBalance = ({
  saveInputAns,
  reff,
  display,
  validation,
  validationMsg,
  trigger,
  lender
}) => {
  const handleClick = async (e) => {
    let outstandingBalance = await trigger("outstanding_balance");
    let errorFlag = 0;
    if (!outstandingBalance) {
      errorFlag = 1;
    }
    if (errorFlag === 0) {
      saveInputAns(e);
    } else {
      return false;
    }
  };

  return (
    <>
      <div style={{ display: display }}>
        <div className="radio-box question animated fadeInUp" ref={reff}>
          <h3>
            <b>{`Please select from the list below approximately how much is your outstanding balance with Vanquis.`}</b>
          </h3>
          <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 p-0">
            <SelectBox
              className="form-select form-control"
              OptionValue={Amount}
              name="outstanding_balance"
              id="outstanding_balance"
              myRef={validation({
                required: "Please Select Amount",
              })}
              validationMsg={validationMsg.outstanding_balance && validationMsg.outstanding_balance.message}
            />
          </div>
          <div className="col-xl-12 col-lg-12 text-center p-0">
            <button
              type="button"
              className="btn-next"
              onClick={handleClick}
              data-quest-id={90}
              data-ans-id={null}
              data-ans-label={null}
              name="question_90"
            >{`NEXT`}</button>
          </div>
        </div>
      </div>
    </>
  );
};

export default OutstandingBalance;
