import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import "../../../../assets/OpenBanking/css/styleV1.scss";
import { queryString } from "../../../../Utility/QueryString";
import { useOpenBanking } from "../../../../Hooks/useOpenBanking";

const CreditCheckV2 = () => {
  const [loader, setLoader] = useState(1);
  const [credLoader, setCredLoader] = useState(0);
  const [proceedLoader, sePproceedLoader] = useState(0);
  const [iframeUrl, setIframeUrl] = useState(null);
  const [btndisable, setBtnDisable] = useState(false);

  const urlPortion = localStorage.getItem("url_portion");
  const fname = localStorage.getItem("firstname");
  const credcheckStat = localStorage.getItem("credStat");
  const history = useHistory();
  const { OpenBankVisit,storeClickResponse,milestoneChase } = useOpenBanking();
  const query = new URLSearchParams(window.location.search);
  const query_uuid = query.get("uuid");
  const local_storage_uuid = localStorage.getItem("uuid");
  var uuid = query_uuid ? query_uuid : local_storage_uuid;
  const query_source = localStorage.getItem("source");
  var source = query_source ? query_source : "FLP";
  const user_data = { uuid: uuid, source: source };
  let btn_label = '';
  let page = '';
  const userId = localStorage.getItem("user_id");
   

  useEffect(() => {
    (async () => {
     
    if(uuid) {
    let payload = { "page": 'CREDIT_CHECK',type:'visit', source};
    await storeClickResponse({uuid:uuid},"ob_creditcheck_history",uuid,payload);

    setTimeout(() => {
      setLoader(0);
    }, 1500);

    let iframUrl = "https://uk.rspcdn.com/xprr/red/PID/3916?" + urlPortion;
    setIframeUrl(iframUrl);

    if (credcheckStat == "0" || credcheckStat == null || credcheckStat == "") {
     // history.push("/thankyou?uuid=" + local_storage_uuid);
      return false;
    }
  } else {
  //  history.push("/thankyou");
  }

  })();

  }, []);

  const redirectCreditCheck = async (e) => {
    
    setBtnDisable(true);
    setLoader(1);
    setCredLoader(1);
     
    btn_label = e.target.getAttribute('data-btn-label');
    page = e.target.getAttribute('data-page-label');
    
    let payload = { page,type:'btn_click', btn_label, source,'click_btn':'3' };
    await storeClickResponse({uuid:uuid},"ob_creditcheck_history",uuid,payload);
    await milestoneChase({ uuid: uuid },userId,"credit_check_milestone",1)

    const flpResponse = await OpenBankVisit(
      "creditcheck_stats",
      uuid,
      user_data,
      "proceed-creditcheck",
      queryString,
      "v1"
    );
    setTimeout(() => {
      setLoader(0);
    }, 1500);
  };

  return (
    <>
      <div className="OpenBankingV1">
        <div
          className={`text-center loader-wrap ${
            loader == 1 ? "showload" : "hide"
          }`}
        >
          <div className="loader">
            <img src="assets/img/review.gif" alt="" />
          </div>
        </div>
        <div className={`${credLoader == 0 ? "showload" : "hide"}`}>
          <div
            className={`main text-center main-div  ${
              loader == 0 ? "showload" : "hide"
            }`}
          >
            <div className="brand-logo mt-lg-0 mt-md-2 mt-sm-2 mt-2">
              <img
                height="65"
                src="assets/img/a00187b31dd5a4ece0c8f809b69962eb.png"
                alt=""
              />
            </div>
            <div className="container">
              <div className="item-cent-flx ">
                <div className="pt-lg-5 pt-md-3 pt-sm-3  pt-3 text-center">
                  <h3 className="mt-lg-5 mt-md-2 mt-sm-2 mt-0 step_head">
                    FINAL STEP
                  </h3>
                  <h1 className="mt-2">
                    {fname}, we require your credit report to complete our
                    comprehensive investigation and finalise your complaint.
                  </h1>
                  <p className="my-3 sub_head_til">
                    {" "}
                    We have partnered with UK Credit Ratings to offer a FREE,
                    comprehensive, and simple-to-use service.
                  </p>
                  <div className="row mt-lg-4 mt-md-2 mt-sm-3 mt-0">
                    <div className="col-lg-6 px-4 m-auto mb-2">
                      <div>
                        <button
                          onClick={redirectCreditCheck}
                          disabled={btndisable}
                          data-btn-label = "access_free_credit_report"
                          data-page-label = "CREDIT_CHECK"
                          className="  btn-comm2 mt-lg-3 mt-md-3 mt-sm-3 mt-0 second anim_green disablebtn"
                        >
                          {" "}
                          Access Free Credit Report <span> ›</span>{" "}
                        </button>
                        <p className="mt-3 text2b mb-0">
                          <i className="fa fa-lock"></i> &nbsp; 24/7 Monitoring.
                          Individual Credit Review. Correct Credit Errors.
                        </p>
                        <p className="mt-0 text3b">
                          <i className="fa fa-clock-o"> </i> Estimated
                          Processing: 1 minute{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="mb-lg-5 mb-md-5 mb-sm-3 mb-3 mt-5 mt-lg-3 mt-md-5 mt-sm-5 ">
                    <img
                      height="30"
                      src="assets/img/LGO_UKC_Black.svg"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className={`${
            credLoader == 1 ? "showload" : "hide"
          } iframe-container`}
        >
          <iframe src={iframeUrl} frameborder="0"></iframe>
        </div>
      </div>
    </>
  );
};
export default CreditCheckV2;
