import React, { useState } from "react";
import RadioButton from "./RadioButton";
import { useFormContext } from "react-hook-form";
import GtmDataLayer from "../../../../Utility/GtmDataLayer";


const Claim = (e) => {
  const { register, errors, clearErrors } = useFormContext();
  const [checkedOption, setCheckedOption] = useState("");
  const handleRadioButtonClick =(e,id,value) => {
    GtmDataLayer({
      question: "select product",
    });
    clearErrors(["productclaim"])
    setCheckedOption(id);
    const targetInput = document.getElementById("sign-div");
    if (targetInput) {
      targetInput.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };
  return (
    <div>
      <div className="mt-3">
        <h5 className="title mb-3">
          Which product would you like to register a claim against?
        </h5>
        <div className="row">
          <div className="col-lg-4 col-md-4 col-sm-6 col-6 mb-2">
            <div className="card-wrap ">
              <RadioButton
                id="card1"
                name="productclaim"
                value="164"
                labelName=" Classic Card "
                labelClassName="ms-2"
                className="d-flex card-item p-2 align-items-center"
                iclassName="bi bi-credit-card-2-back-fill"
                checked={checkedOption === "card1"}
                onClick={handleRadioButtonClick}
              />
            </div>
          </div>
          <div className="col-lg-4 col-md-4 col-sm-6 col-6 ps-lg-0 v">
            <div className="card-wrap">
              <RadioButton
                id="card2"
                name="productclaim"
                value="165"
                labelName="Chrome Card"
                labelClassName="ms-2"
                className="d-flex card-item  card2 p-2 align-items-center"
                iclassName="bi bi-credit-card-2-back-fill"
                checked={checkedOption === "card2"}
                onClick={handleRadioButtonClick}
              />
            </div>
          </div>
          <div className="col-lg-4 col-md-4 col-sm-6 col-6 ps-lg-0 mb-2">
            <div className="card-wrap">
              <RadioButton
                id="card3"
                name="productclaim"
                value="166"
                labelName="Origin Card"
                labelClassName="ms-2"
                className="d-flex card-item p-2 align-items-center"
                iclassName="bi bi-credit-card-2-back-fill"
                checked={checkedOption === "card3"}
                onClick={handleRadioButtonClick}

              />
            </div>
          </div>
          {/* <div className="col-lg-3 col-md-3 col-sm-6 col-6 ps-lg-0 mb-2">
            <div className="card-wrap">
              <RadioButton
                id="card4"
                name="productclaim"
                value="167"
                labelName="Personal Loan"
                labelClassName="ms-2"
                className="d-flex card-item p-2 align-items-center"
                iclassName="bi bi-credit-card-2-back-fill"
                checked={checkedOption === "card4"}
                onClick={handleRadioButtonClick}
                
              />
            </div>
          </div> */}
        </div>
        {errors.productclaim && (
        <>
          <span className="error_msg">{errors.productclaim.message}</span>
        </>
      )}
      </div>
    </div>
  );
};

export default Claim;
