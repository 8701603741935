import React, { useState } from "react";
import OtpInputBox from "../../../UI/OtpInputBox";
import Accordion from "react-bootstrap/Accordion";
// import "../../../../assets/Quest_NDY_UB_4_4/scss/modules/popupnew.scss"

const ShareInfo = ({
  onSelectAnswer,
  backClick,
  SkippedLeads,
  setClickedSlide,
  setAccNum,
  lenderName,
  accNumPopUp,
  setAccNumPopUp,
  setSortCodePopUp,
  shareInfoPopUp,
  setShareInfoPopUp,
  closeModal,
}) => {
  
  const handleNext = async (e) => {
    if (e.target.value == 294) {
      setShareInfoPopUp("hide");
      setAccNumPopUp("show");
      onSelectAnswer(e);
    } else {
      onSelectAnswer(e);
      setShareInfoPopUp("hide");
      setAccNumPopUp("hide");
      setSortCodePopUp("hide");
      setClickedSlide({ slide: "banks" });
    }
  };

  return (
    <>
      <div
        className={`modal fade ${shareInfoPopUp} shareInfo`}
        style={{ backgroundColor: "#000000e3" }}
        id="CredCardAgreement"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-lg">
          <div className="modal-content">
            <div className="modal-header border border-0 popmodalbg">
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={closeModal}
              />
            </div>

            <div className="modal-body">
              <ul id="demopopslide1" className="p-0 m-0 questionistmod mb-3">
                <h2 className="px-3 p-0 m-0 fs-4 text-center">
                  <b>
                    Sharing your Account Number and Sort Code can help us
                    resolve your issue more quickly and accurately.{" "}
                  </b>
                </h2>
                <li className="mt-0">
                  <div className=" q161" id="slide161">
                    <h1 className="que-title pb-0 p-3 text-style  text-center" style={{fontSize : '18px'}}>
                      Would you like to provide this information to enhance your
                      complaint resolution?
                    </h1>
                    <div className="question-content mt-0 scale-down-ver-center px-3 py-lg-3 py-md-3 py-sm-2 py-2 pb-3 ">
                      
                      <div className="row">
                        <div className="col-lg-12 mb-3 text-center">
                        <span>
                          <button
                            type="button"
                            className="yespo btn-comm2  m-1 fs-6 py-lg-3 py-md-3 py-sm-2 py-2  mb-3"
                            style={{minWidth : '300px'}}
                            id="shareInfo1"
                            name="shareInfo"
                            value={294}
                            onClick={handleNext}
                            data-quest-id={107}
                            data-ans-id={294}
                          >
                            Yes
                            </button>
                          </span>
                          <span>
                          <button
                            type="button"
                            className="btn-comm2 fs-6 py-lg-3 m-1 py-md-3 py-sm-2 py-2" 
                            style={{minWidth : '300px'}}
                            id="shareInfo2"
                            name="shareInfo"
                            value={295}
                            onClick={handleNext}
                            data-quest-id={107}
                            data-ans-id={295}
                          >
                            No
                            </button>
                            </span>
                          
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ShareInfo;
