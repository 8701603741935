import React, { useState, useEffect, useContext } from "react";
import "../../assets/FollowupS3Car/css/followupS3Car.scss";
import VisitorParamsContext from "../../Contexts/VisitorParams";
import { Api } from "../../api/Api";
import { queryString, updateQueryString } from "../../Utility/QueryString";
import { useHistory } from "react-router-dom";
import { useFollowupLoad } from "../../Hooks/useFollowupLoad";
import Loader from "../Includes/Layouts/FLPS3CAR/Loader";
import IdFollowup from "../Includes/Layouts/FollowupS2/IdFollowup";
import MoneyBarnflpCar from "../Includes/Layouts/FLPS3CAR/MoneyBarnFlpCar";
import { MonthNameArray } from "../../Constants/Constants";


const FollowupcarS3 = () => {
  const { visitorParameters } = useContext(VisitorParamsContext);
  const history = useHistory();
  const [verifyData, setVerifyData] = useState({});
  const { loadFollowup } = useFollowupLoad();
  const [showLoad, setshowLoad] = useState("show");
  const [allFollowupData, setFollowupData] = useState(false);
  const [user_uuid, setUser_uuid] = useState(false);
  const [idUploadFlp, setIdUploadFlp] = useState(false);
  const [moneyBarn, setMoneyBarn] = useState(false);
  const [pendingSlide, setPendingSlide] = useState([]);
  const [keeperDates, setKeeperDates] = useState([]);
  const [userEmail, setUserEmail] = useState();
  const [t2aStatus, setT2aStatus] = useState("");
  const [splitName, setSplitName] = useState(false);
  const [lenderList, setLenderList] = useState([]);
  const [userDetails, setUserDetails] = useState({});
  const [agreementDate, setAgreementDate] = useState('');
  const [divStyle, setDivStyle] = useState('show');
  const [loaderContent, setLoaderContent] = useState("");
  const [pendingSlideR, setPendingSlideR] = useState([]);
  const [isBankStmtProvided,setIsBankStmtProvided] = useState("");

  
  useEffect(() => {
    (async () => {
      const response = await Api.get(
        "/api/v1/followup/get-details-s3-m2?query_string=" + queryString,
        {}
      );
      if (response.data.status === "Success") {
        let followupDetails = response.data.response.followup_data;
        localStorage.setItem("followupData", JSON.stringify(followupDetails));
        let uuid = response.data.response.uuid;
        localStorage.setItem("uuid", uuid);
        let completed = response.data.response.completed;
        let signStatus = response.data.response.signed_status;
        let slidePending = response.data.response.pending_slides;
        // let pendingLength = slidePending?.length;
        let qstnStatus = response.data.response.question_status;
        let split_name = response.data.response.split_name;
        let fname = response.data.response.first_name;
        let lname = response.data.response.last_name;
        let postcode = response.data.response.postcode;
        let address_line1 = response.data.response.address_line1;
        let t2a_result = response.data.response.t2a_status;
        let keeper_dates = response.data.response.keeper_date;
        let vehicle = response.data.response.vehicle_name;
        let dob_day = response.data.response.dob_day;
        let dob_month = response.data.response.dob_month;
        let dob_yr = response.data.response.dob_yr;
        let page = "complete";
        let loan_yr = response.data.response.loan_year;
        let email = response.data.response.email;
        let phone = response.data.response.phone;
        let lender = response.data.response.lender;
        let address_line2 = response.data.response.address_line2;
        let town = response.data.response.town;
        let country = response.data.response.country;
        let title = response.data.response.title;
        let addressLine3 = response.data.response.address_line3;
        let addressLine4 = response.data.response.address_line4;
        let county = response.data.response.county;
        localStorage.setItem("t2a_result", t2a_result);
        let productId = response.data.response.product_id;
        localStorage.setItem("product_id", productId);
        let lenders = response.data.response.providers;
        let user_id = response.data.response.user_id;
        let is_test = response.data.response.is_test;
        let dob = response.data.response.dob;
        let loanDate = response.data.response.loanDate;
        let match_status = response.data.response.match_status;
        let envelop_status = response.data.response.envelop_status;
        let BankStmt = response.data.response.BankStmt;
        let district = response.data.response.district;
        let preview_visit = response.data.response.preview_visit;
        localStorage.setItem("previewVisit", preview_visit);
        let slidePendingR = response.data.response.reverse_slides;
        let provide_statement = response.data.response.provide_statement;

        let dobMonth = dob_month ? MonthNameArray[dob_month] : '';

        localStorage.setItem("fName", fname);
        localStorage.setItem("lName", lname);
        localStorage.setItem("pCode", postcode);
        localStorage.setItem("addressLine1", address_line1);
        localStorage.setItem("dobYr", dob_yr);
        localStorage.setItem("dobMon", dobMonth);
        localStorage.setItem("dobDy", dob_day);
        localStorage.setItem("addressLine2", address_line2);
        localStorage.setItem("postTown", town);
        localStorage.setItem("country", country);
        localStorage.setItem("title", title);
        localStorage.setItem("addressLine3", addressLine3);
        localStorage.setItem("addressLine4", addressLine4);
        localStorage.setItem("county", county);
        localStorage.setItem("district", district);

        let UcFname = fname.charAt(0).toUpperCase() + fname.slice(1);

        localStorage.setItem("UcFname", UcFname);
        localStorage.setItem("queryString", queryString);

        visitorParameters.email = email || false;


        setVerifyData({
          uuid: uuid,
          fName: fname,
          lName: lname,
          pCode: postcode,
          addressLine1: address_line1,
          day: dob_day,
          month: dob_month,
          year: dob_yr,
          addressLine2: address_line2,
          postTown: town,
          country: country,
          title: title,
          addressLine3: addressLine3,
          addressLine4: addressLine4,
          county: county
        });

        setUserDetails({
          uuid: uuid,
          lender: lender,
          source: followupDetails.atp_sub6,
          lstSalutation: title,
          txtFName: fname,
          txtLName: lname,
          txtEmail: email,
          txtPhone: phone,
          is_test: is_test,
          token: followupDetails.atp_sub2,
          pixel: followupDetails.pixel,
          txtPostcode: postcode,
          txtAddressline1: address_line1,
          txtAddressline2: address_line2,
          txtAddressline3: addressLine3,
          town: town,
          dob: dob,
          user_id: user_id,
        });

        localStorage.setItem("source", followupDetails.atp_sub6);

        setFollowupData(followupDetails);
        setUser_uuid(uuid);
        setPendingSlide(slidePending);
        setKeeperDates(keeper_dates);
        setUserEmail(email);
        setT2aStatus(t2a_result);
        setSplitName(split_name);
        setLenderList(lenders);
        setAgreementDate(loanDate);
        setPendingSlideR(slidePendingR);
        setIsBankStmtProvided(provide_statement);

        setLoaderContent(UcFname + ', Analysing your Results');

        if (productId == 1) {
          page = "moneybarn";
        } else if (t2a_result == 0) {
          page = "idUpload";
        } else {
          console.log("no data");
        }

        setTimeout(() => {
          setDivShow(page, uuid);
          setshowLoad("hide");
        }, 3000);

        const flpResponse = await loadFollowup(
          "followup_load",
          followupDetails,
          "followup-s3",
          queryString,
          "s3"
        );
      } else {
        history.push("/thankyou?uuid=" + response.data.response.uuid);
        return false;
      }
    })();
  }, []);

  const setDivShow = (page, uuid) => {
    // if (page == "complete") {
    //   history.push("/thankyou?uuid=" + uuid);
    // }

    if (page == "moneybarn") {
      setMoneyBarn(true);
    } else if (page == "statementUpload") {
      history.push("bank_statement_S3?" + queryString);
    } else {
      history.push("/thankyou?uuid=" + uuid);
    }
  };

  const redirectBnkStmnt = (match_status, envelop_status, BankStmt) => {
    let statemnt = (localStorage.getItem('BankStmt')) ? localStorage.getItem('BankStmt') : BankStmt;

    if ((match_status == 0 || envelop_status == 1) && (statemnt == 0)) {
      setTimeout(() => {
        history.push("/bank_statement_S3?" + queryString);
      }, 3000);
    } else {
      console.log('no redirect required')
    }
  }

  return (
    <>
      <div className="followupS3CarSection">
        <Loader content={loaderContent} showLoad={showLoad}></Loader>
        <div className={divStyle}>
          {moneyBarn ? (
            <MoneyBarnflpCar
              allFollowupData={allFollowupData}
              pendingSlide={pendingSlide}
              setshowLoad={setshowLoad}
              currentUuid={user_uuid}
              keeperDates={keeperDates}
              userEmail={userEmail}
              setPendingSlide={setPendingSlide}
              setDivShow={setDivShow}
              verifyData={verifyData}
              t2aStatus={t2aStatus}
              splitName={splitName}
              lenderList={lenderList}
              userDetails={userDetails}
              setAgreementDate={setAgreementDate}
              agreementDate={agreementDate}
              setDivStyle={setDivStyle}
              setLoaderContent={setLoaderContent}
              reverseSlide={pendingSlideR}
              isBankStmtProvided={isBankStmtProvided}
            />
          ) : null}
        </div>
      </div>
    </>
  );
};

export default FollowupcarS3;
