import React, {useContext} from "react";
import { Api, T2aValidationApi } from "../api/Api";
import * as EnvConstants from "../Constants/EnvConstants";
import {useErrorWebhook} from "./useErrorWebhook";
import VisitorParamsContext from "../Contexts/VisitorParams";

// export const useT2aValidation = () => {
//   const getT2aValidation = async (
//     uuid,
//     firstname,
//     lastname,
//     postcode,
//     address1,
//     atpSub6
//   ) => {
//     const response = await Api.post("api/v1/t2a-validation", {
//       uuid,
//       firstname,
//       lastname,
//       postcode,
//       address1,
//       atpSub6
//     });
//     return response;
//   };
//   return { getT2aValidation };
// };

export const useT2aValidation = () => {
  const { visitorParameters } = useContext(VisitorParamsContext);
  const uuid=visitorParameters.visitor_parameters.uuid;
  const { ErrorWebhookTrigger } = useErrorWebhook();
  const getT2aValidation = async (
    firstname,
    lastname,
    postcode,
    address1,
    dobYr,
    dobMon,
    dobDy
  ) => {
    const response = await T2aValidationApi.post(
      "api/personsearch?ProjectCode=" +
        EnvConstants.AppAdtopiaUPID +
        "&environment=" +
        EnvConstants.AppEnv +
        "&firstname=" +
        firstname +
        "&lastname=" +
        lastname +
        "&postcode=" +
        postcode +
        "&address_line_1=" +
        address1 +
        "&dob_y=" +
        dobYr +
        "&dob_m=" +
        dobMon +
        "&dob_d=" +
        dobDy
    ).catch(function (error) {
      const erroMessage = {
        serviceName : 'T2A VALIDATION',
        errorReason:error.message,
        errorCode:error.code,
        uuid:uuid
      }
      ErrorWebhookTrigger(erroMessage);
    });
    return response;
  };

  const saveT2aResponse = async (visitor_parameters,t2a_data,message_type,data,query_string,t2a_req_data) => {
    const response = await Api.post("api/v1/data-ingestion-pipeline", {
        visitor_parameters,
        t2a_data,
        message_type,
        data,
        query_string,
        t2a_req_data
    }).catch(function (error) {
      const erroMessage = {
        serviceName : 'T2A RESPONSE',
        errorReason:error.message,
        errorCode:error.code,
        uuid:visitor_parameters.uuid
      }
      ErrorWebhookTrigger(erroMessage);
    });
    return response;
  }

  const saveFollowupT2aResponse = async (followup_data,t2a_data,message_type,query_string,t2a_req_data) => {
    const response = await Api.post("api/v1/data-ingestion-pipeline", {
        followup_data,
        t2a_data,
        message_type,
        query_string,
        t2a_req_data
    }).catch(function (error) {
      const erroMessage = {
        serviceName : 'T2A FOLLOWUP RESPONSE',
        errorReason:error.message,
        errorCode:error.code,
      }
      ErrorWebhookTrigger(erroMessage);
    });
    return response;
  }

  return { getT2aValidation,saveT2aResponse,saveFollowupT2aResponse };
};
