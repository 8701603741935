import react from "react";
import { Employmentsts } from "../../../../Constants/Constants";
import SelectBox from "../../../UI/SelectBox";
const EmploymentStatus = ({
  clsName,
  onSelectAnswer,
  validationMsg,
  clearErrors,
  validation,
  trigger,
  getValues,
  backClick,
  outBalace
}) => {

  let toggle;
  if(outBalace == "245"){
    toggle = "outBalAmount";
  }else{
    toggle = "OutstandBalance";
  }

  const optionSelected = async (e) => {
    let errorFlag = 0;
    let result = await trigger("employment");

    if (!result) {
      errorFlag = 1;
      return false;
    } else {
      onSelectAnswer(e);
    }
  };
  return (
    <>
      <div className="question-content mt-0 scale-down-ver-center px-3 py-lg-3 py-md-3 py-sm-2 py-2 " >
        <div className="row">
          <div className="col-lg-8 mb-2">
            <SelectBox
              className="form-select"
              OptionValue={Employmentsts}
              name="employment"
              id="employment"
              onChange={() => clearErrors("employment")}
              clearErrors={clearErrors}
              myRef={validation({ required: "Please select your employement status" })}
              validationMsg={
                validationMsg.employment && validationMsg.employment.message
              }
            ></SelectBox>
          </div>
          <div className="col-lg-4 mb-2 ">
            <button
              type="button"
              className=" btn-comm2 py-lg-3 py-md-3 py-sm-2 py-2 w-100 nextBtn "
              id="nextscroll"
              name="employmentsts"
              onClick={optionSelected}
              data-quest-id={2}
            >
              Next &#8594; 
            </button>
          </div>
        </div>
        <p className="text-center bck-marwid">
                    <button
                      name={toggle}
                      className="btn btn-secondary backbtn1a"
                      id=""
                      value=""
                      type="button"
                      onClick={backClick}
              
                    >Go Back</button>
                    </p>
      </div>
    </>
  )
}
export default EmploymentStatus; 