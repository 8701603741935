import React, { useState } from "react";
import TextField from "../../../../UI/TextField";
import AnalyzeModal from "../AnalayzeModal";
import { useCarRegisterValidation } from "../../../../../Hooks/useCarRegisterValidation";
import { useSkippedLeads } from "../../../../../Hooks/useSkippedLeads";

const QuestionnaireFlpVehicle = ({
  reff,
  display,
  validation,
  validationMsg,
  trigger,
  getValues,
  setError,
  allFollowupData,
  checkCarRegistration,
  saveVehicleDetails,
  splitName,
  currentUuid,
  slideSkip,
  className
}) => {
  const { registrationvalidation } = useCarRegisterValidation();
  const [loader, showLoader] = useState(false);
  const { skippedLeads } = useSkippedLeads();
  const { skippedLeadsFLP } = useSkippedLeads();

  const handleClick = async (e) => {
    let registration_no = await trigger("question_10");
    if (registration_no) {
      const fieldResult = getValues();
      let carReg = fieldResult["question_10"];
      let carRegNo = carReg.replace(/\s+/g, "");
      CarRegisterValidationApi(e, carRegNo);
    } else {
      return false;
    }
  };

  const CarRegisterValidationApi = async (e, carRegNo) => {
    showLoader(true);
    const getRegisterValidation = await registrationvalidation(
      carRegNo,
      currentUuid,
      splitName
    );
    if (getRegisterValidation.data.validity == "1") {
      //slideChange(e);
      let vehicle = getRegisterValidation.data.vehicleData;
      vehicle.reg_no = carRegNo;
      saveVehicleDetails(vehicle);
      setTimeout(() => {
        showLoader(false);
      }, 1000);
      let keeperData = JSON.parse(vehicle.keeper_dates);
      let keeperArray = keeperData.keeper_dates;
      let keeperLength = keeperArray.length;

      checkCarRegistration(carRegNo);
    } else {
      setError("question_10", {
        type: "manual",
        message: "Your Vehicle Registration is Invalid. Please Recheck!",
      });
      //showLoader('hide');
      showLoader(false);
      return false;
    }
  };
  const SkipRegistration = async (e) => {
    const skippedLeadsResponse = await skippedLeadsFLP(
      e.target.name,
      allFollowupData,
      splitName
    );
    if (skippedLeadsResponse.data.status == "Success") {
      slideSkip();
    }
  };
  return (
    <>
      {/* <div className={className}>
        <div className="row">
          <div className="col-lg-6 offset-lg-3">
            <h1 className="title">
              Enter your Vehicle Registration to begin your refund check?
            </h1>
            <div className="row mt-4">
              <div className="col-lg-12">
                <div className="mb-3">
                  <TextField
                    type="text"
                    className="form-control car-number"
                    placeholder="Enter Vehicle Registration Number"
                    name="question_10"
                    validation={validation({
                      required: "Please Enter Vehicle Registration",
                      minLength: {
                        value: 3,
                        message: "Please Enter Valid Vehicle Registration",
                      },
                      pattern: {
                        value: /^(?=.*[a-zA-Z])(?=.*[0-9])[a-zA-Z0-9 ]+$/,
                        message:
                          "Your Vehicle Registration is Invalid. Please Recheck",
                      },
                    })}
                    validationMsg={
                      validationMsg.question_10 &&
                      validationMsg.question_10.message
                    }
                  ></TextField>
                </div>
                <div>
                  <button
                    type="button"
                    className="com-btn btn-next w-100 mb-3"
                    onClick={handleClick}
                    data-quest-id={10}
                    data-ans-id={null}
                    data-ans-label={null}
                    name="btn_qest_10"
                  >{`Next`}</button>
                </div>
                <p className="text-center">
                  <a
                    href="#"
                    className="skip-btn skip1"
                    name="skipRegistration"
                    onClick={SkipRegistration}
                  >
                    Skip
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <AnalyzeModal modalClass={loader} /> */}


      {/* {>>NEw} */}
      <div className={className}>
        <div className="item qs-item2 px-3 mt-4" >
          <h2 className="fw-bold title mt-4 mb-4">
            Enter your Vehicle Registration to begin your refund check?
          </h2>
          <div className="form-section">
            <div className="mb-3">
              <div className="input-section mb-4">
                <div className="input-group post">
                  <TextField
                    type="text"
                    className="form-control mob-wd car-regnumber"
                    placeholder="Enter Vehicle Registration Number"
                    name="question_10"
                    validation={validation({
                      required: "Please Enter Vehicle Registration",
                      minLength: {
                        value: 3,
                        message: "Please Enter Valid Vehicle Registration",
                      },
                      pattern: {
                        value: /^(?=.*[a-zA-Z])(?=.*[0-9])[a-zA-Z0-9 ]+$/,
                        message:
                          "Your Vehicle Registration is Invalid. Please Recheck",
                      },
                    })}
                    validationMsg={
                      validationMsg.question_10 &&
                      validationMsg.question_10.message
                    }
                  ></TextField>
                </div>
              </div>
              <div className="mb-4 col-lg-12 mb-pd text-center ">
                <div>
                  <button
                    type="button"
                    className=" btn-question nextvehl btn-comm2 w-100"
                    onClick={handleClick}
                    data-quest-id={10}
                    data-ans-id={null}
                    data-ans-label={null}
                    name="btn_qest_10"
                  >{`Next`}</button>
                </div>
              </div>
              {/* <p class="text-center"><a href="#" class="skip-btn" id="skip1">
            Skip</a>
        </p> */}
              <p className="text-center">
                <a
                  href="#"
                  className="skip-btn skip1"
                  name="skipRegistration"
                  onClick={SkipRegistration}
                >
                  Skip
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
      <AnalyzeModal modalClass={loader} />

    </>
  );
};

export default QuestionnaireFlpVehicle;
