import React, { useState } from 'react';
import TextField from '../../../UI/TextField';
import { useCarRegisterValidation } from '../../../../Hooks/useCarRegisterValidation';
import { useSkippedLeads } from '../../../../Hooks/useSkippedLeads';
import { useTotalCarCheck } from '../../../../Hooks/useTotalCarCheck';
import AnalyzeModal from './AnalayzeModal';


const Questionnaire1 = ({
	clsName,
	reff,
	checkCarRegistration,
	validation,
	validationMsg,
	trigger,
	getValues,
	setError,
	page_name,
	uuid,
	saveVehicleDetails,
	slideSkip,
	split_name
}) => {
	const { registrationvalidation } = useCarRegisterValidation();
	const { skippedLeads } = useSkippedLeads();
  const { totalCarCheckApi } = useTotalCarCheck();
	const [loader, showLoader] = useState(false);

  const handleClick = async (e) => {
    let registration_no = await trigger("question_10");
    if (registration_no) {
      const fieldResult = getValues();
      var carReg = fieldResult["question_10"];
      var carRegNo = carReg.replace(/\s+/g, "");
      CarRegisterValidationApi(e, carRegNo)
    } else {
      return false;
    }
  };

  const SkipRegistration = async (e) => {
    const skippedLeadsResponse = await skippedLeads(e.target.name, uuid, split_name);
		if (skippedLeadsResponse.data.status == "Success") {
      slideSkip();
    }
  }

	const CarRegisterValidationApi = async (e,carRegNo) => {
		showLoader(true);
    const getRegisterValidation = await registrationvalidation(carRegNo, uuid, page_name);

		
		if (getRegisterValidation.data.validity == "1") {
			//slideChange(e);
      let vehicle = getRegisterValidation.data.vehicleData;
      vehicle.reg_no = carRegNo;
      saveVehicleDetails(vehicle);
			setTimeout(() => {
				showLoader(false);
        }, 1000);   
      let keeperData = JSON.parse(vehicle.keeper_dates);
			let keeperArray = keeperData.keeper_dates;
			let keeperLength= keeperArray.length;


			checkCarRegistration(e);
    } else {
      setError("question_10", {
        type: "manual",
        message: "Your Vehicle Registration is Invalid. Please Recheck!",
      });
      //showLoader('hide');
			showLoader(false);
      return false;
    }
  };

	return (
		<>
			<div className={`radio-box animated fadeInUp ${clsName}`} ref={reff} >
				<h3><b>If you have your Vehicle Registration to hand, please enter now:</b></h3>
				<div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 p-0">
					<span className="gb" >
						<img src="/assets/img/gb.png" alt="" width="16px" />
						<p>GB</p>
					</span>
					<TextField 
						type="text"
						className="car-reg"
						placeholder="(e.g.ME12DAR)"
						name="question_10"
						validation={validation({
							required: "Please Enter Vehicle Registration",
							minLength: {
								value: 3,
								message: "Please Enter Valid Vehicle Registration",
							},
							pattern: {
								value: /^(?=.*[a-zA-Z])(?=.*[0-9])[a-zA-Z0-9 ]+$/,
								message:
									"Your Vehicle Registration is Invalid. Please Recheck",
							},
						})}
						validationMsg={
							validationMsg.question_10 &&
							validationMsg.question_10.message
						}
					></TextField>
				</div>

				<div className="col-xl-12 col-lg-12 text-center p-0" >
					{/* <input type="button" className="btn-next" id="next-1" value="Start My Free Check>>" onClick={handleClick}/> */}
					
					<button 
						type="button" 
						className="btn-next" 
						onClick={handleClick} 
						data-quest-id={10} 
						data-ans-id={null}
						data-ans-label={null}
						name="btn_qest_10"
						>{`Lookup >>`}</button>
				</div>
				<div className="w-100 text-center pb-3">
					<a href="#" name="skipRegistration" onClick={SkipRegistration}>SKIP {">>"} I don't have it to hand</a>
				</div>
			</div>

			<AnalyzeModal modalClass={loader}/>
		</>
	)
}

export default Questionnaire1;