import React, { useState } from "react";
import { ClosedYears2 } from "../../../../Constants/Constants";
import SelectBox from "../../../UI/SelectBox";
import InputButton from "../../../UI/InputButton";
import { Modal } from "react-bootstrap";
import { TopSelectedLenders } from "../../../../Constants/Constants";
import Select from "react-select";
import RadioButtonQuest from "../../../UI/RadioButtonQuest";
import BankAccNo from "./BankAccNo";
import SortCode from "./SortCode";
const Banks = ({
  clsName,
  saveInputAns,
  validationMsg,
  clearErrors,
  validation,
  trigger,
  getValues,
  backClick,
  SelectBank,
  setSelectBank,
  lenderList,
  oldCard,
  latePay,
  SkippedLeads,
  setLenderName,
  setClickedSlide,
  setAccNum,
  lenderName,
  setSortCode,
}) => {
  const [BankValidationMsg, setBankValidationMsg] = useState("");
  const [accNumPopUp, setAccNumPopUp] = useState("hide");
  const [sortCodePopUp, setSortCodePopUp] = useState(false);
  const customStyles = {
    control: (base) => ({
      ...base,
      //height: 50,
      minHeight: 30,
    }),
  };

  const nextSlide = async (e) => {
    let LengthSelectedBank = SelectBank.length;
    let ValueSelectedBank = SelectBank.value;

    if (e.target.name == "bankOptions") {

      const selectedLender = TopSelectedLenders.find(
        lender => lender.value === e.target.value
      );
      if (selectedLender) {
        localStorage.setItem("bud_lender",  e.target.value);
        setLenderName(selectedLender.label);
        setAccNumPopUp("show");
        saveInputAns(e);
      }

      // // setSelectBank(e.target.value)
      // localStorage.setItem("bud_lender", e.target.value);
      // // localStorage.setItem("lenderArray", JSON.stringify([TopSelectedLenders]));
      // setLenderName(e.target.labelName);
      // saveInputAns(e);

    } else {
      if (LengthSelectedBank < 1 || ValueSelectedBank == "") {
        setBankValidationMsg("Please select your Bank");
      } else {
        localStorage.setItem("bud_lender", ValueSelectedBank);
        localStorage.setItem("lenderArray", JSON.stringify([SelectBank]));
        setLenderName(ValueSelectedBank);
        setAccNumPopUp("show");
        saveInputAns(e);
      }
    }
  };

  function handleSelect(opt) {
    setSelectBank(opt);
    setBankValidationMsg("");
  }
  let BankList = [];

  if (lenderList.length > 0) {
    BankList = BankList.concat(
      lenderList
        .filter(function (val) {
          return (
            !val.display_name.endsWith("Card") &&
            !val.display_name.endsWith("Business") &&
            !val.display_name.endsWith("card") &&
            !val.display_name.endsWith("Cards") &&
            !val.display_name.includes("Barclays") &&
            !val.display_name.includes("NatWest") &&
            !val.display_name.includes("Halifax") &&
            !val.display_name.includes("Lloyds") &&
            !val.display_name.includes("Santander") &&
            !val.display_name.includes("Nationwide") &&
            !val.display_name.includes("HSBC")
          );
        })
        .map(function (val) {
          return {
            value: val.provider,
            label: val.display_name,
          };
        })
    );
  }
  return (
    <>
      <div className="question-content mt-0 scale-down-ver-center  px-3 py-lg-3 py-md-3 py-sm-2 py-2">
      <div className="row mb-3">
        <div className="mb-2">
          <ul className="p-0 m-0 option-grid">
            {TopSelectedLenders.map((lender, index) => (
              <div className="optionbtn">
              <li key={lender.label}>
                <RadioButtonQuest
                  type="button"
                  //labelClassName={`${checkedOne}`}
                  id={`bankOption${index + 1}`}
                  name="bankOptions"
                  value={lender.value}
                  labelName={lender.label}
                  onClick={nextSlide}
                  dataQuestId={64}
                >
                  {lender.label}
                </RadioButtonQuest>
              </li>
              </div>
            ))}
          </ul>
        </div>
      </div>
        <div className="row">
          <div className="col-lg-8 mb-2 z-3">
            <Select
              placeholder="Select Bank"
              options={BankList}
              aria-label="Username"
              name="bankName"
              id="bankName"
              value={SelectBank}
              onChange={handleSelect}
              isSearchable={true}
              styles={customStyles}
            //isMulti

            />
            <span id="bank_err" className="error_msg">{BankValidationMsg}</span>
          </div>
          <div className="col-lg-4 mb-2">
            <button
              type="button"
              className=" btn-comm2 py-lg-3 py-md-3 py-sm-2 py-2 w-100 nextBtn "
              id="nextscroll"
              name="banks"
              onClick={nextSlide}
              data-quest-id={64}
            >
              Next &#8594;
            </button>
          </div>
        </div>
        <p className="text-center bck-marwid">
          <button
            name='bankruptcy'
            className="btn btn-secondary backbtn1a"
            id=""
            value=""
            type="button"
            onClick={backClick}

          >Go Back</button>
        </p>
      </div>
      {accNumPopUp && (
        <>
          <BankAccNo
            backClick={backClick}
            saveInputAns={saveInputAns}
            SkippedLeads={SkippedLeads}
            setClickedSlide={setClickedSlide}
            setAccNum={setAccNum}
            lenderName={lenderName}
            accNumPopUp={accNumPopUp}
            setAccNumPopUp={setAccNumPopUp}
            setSortCodePopUp={setSortCodePopUp}
          />
        </>
      )}

      {sortCodePopUp && (
        <SortCode
          backClick={backClick}
          saveInputAns={saveInputAns}
          SkippedLeads={SkippedLeads}
          setClickedSlide={setClickedSlide}
          setSortCode={setSortCode}
          lenderName={lenderName}
          sortCodePopUp={sortCodePopUp}
          setSortCodePopUp={setSortCodePopUp}
        />
      )}
    </>
  )
}
export default Banks;
