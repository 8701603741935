import React, { useState } from "react";
import TextField from "../../../UI/TextField";
import { useCarRegisterValidation } from '../../../../Hooks/useCarRegisterValidation';
import tickgif from "../../../../assets/QuestionaireMBGN_4/img/loader-review2.gif"
import Modal from "react-bootstrap/Modal";
import { useSkippedLeads } from '../../../../Hooks/useSkippedLeads';


const RegistrationNo = ({ saveInputAns, backClick,
    checkCarRegistration,
    validation,
    validationMsg,
    trigger,
    getValues,
    setError,
    page_name,
    uuid,
    saveVehicleDetails,
    split_name,
    slideSkip,
    setSkip,
    setPrevStill
}) => {
    const { skippedLeads } = useSkippedLeads();
    const selectedClick = async (e) => {
        saveInputAns(e);
    };

    const { registrationvalidation } = useCarRegisterValidation();
    const [loader, showLoader] = useState(false);

    const handleClick = async (e) => {
        let registration_no = await trigger("question_10");
        if (registration_no) {
            const fieldResult = getValues();
            var carReg = fieldResult["question_10"];
            var carRegNo = carReg.replace(/\s+/g, "");
            CarRegisterValidationApi(e, carRegNo);
            setPrevStill("");
            localStorage.setItem("Vehicle_RegNo",carRegNo);
        } else {
            return false;
        }
    };

    const SkipRegistration = async (e) => {
        // setSkip(true);
        const skippedLeadsResponse = await skippedLeads(e.target.name, uuid, split_name);
        if (skippedLeadsResponse.data.status == "Success") {
            localStorage.setItem('ValidKeeper', 0);
            slideSkip();
        }
    }


    const CarRegisterValidationApi = async (e, carRegNo) => {
        showLoader(true);
        const getRegisterValidation = await registrationvalidation(carRegNo, uuid, page_name);


        if (getRegisterValidation.data.validity == "1") {
            //slideChange(e);
            let vehicle = getRegisterValidation.data.vehicleData;

            vehicle.reg_no = carRegNo;
            saveVehicleDetails(vehicle);
            setTimeout(() => {
                showLoader(false);
            }, 1000);
            let keeperData = JSON.parse(vehicle.keeper_dates);
            let keeperArray = keeperData.keeper_dates;
            let keeperLength = keeperArray.length;

           
            checkCarRegistration(e);
        } else {
            setError("question_10", {
                type: "manual",
                message: "Your Vehicle Registration is Invalid. Please Recheck!",
            });
            //showLoader('hide');
            showLoader(false);
            return false;
        }
    };

    return (
        <>
            <div
                className={`question-content mt-0 scale-down-ver-center px-3  py-lg-3 py-md-3 py-sm-2 py-2 `}
            >
                <div className="row">
                    <div className="col-lg-7 col-md-7 col-sm-12  ">
                        <TextField
                            type="text"
                            className="car-number  w-100"
                            placeholder="(e.g.ME12DAR)"
                            name="question_10"
                            validation={validation({
                                required: "Please Enter Vehicle Registration",
                                minLength: {
                                    value: 3,
                                    message: "Please Enter Valid Vehicle Registration",
                                },
                                pattern: {
                                    value: /^(?=.*[a-zA-Z])(?=.*[0-9])[a-zA-Z0-9 ]+$/,
                                    message:
                                        "Your Vehicle Registration is Invalid. Please Recheck",
                                },
                            })}
                            validationMsg={
                                validationMsg.question_10 &&
                                validationMsg.question_10.message
                            }
                        ></TextField>
                    </div>
                    <div className="col-lg-5 col-md-5 col-sm-12 ps-lg-1 ps-md-1 mt-lg-0 mt-md-0 mt-sm-2 mt-3">
                        <button className=" btn-comm2 py-3 w-100 nextBtn" id="regid" type="button" name="CarRegno"
                            onClick={handleClick}
                            data-quest-id={10}                         >
                            {/* <span> */}
                            {/* Next &nbsp; <i className="bi bi-arrow-right" /> */}
                            Next &#8594;
                            {/* </span> */}
                        </button>
                    </div>
                </div>
                <div className="mb-2 mt-3 text-center">
                    <a className="backbtn skip-btn" name="skipRegistration" onClick={SkipRegistration}>{"Skip"}</a>
                </div>

                {/* <div className="text-center mb-3" >
                    <a className="backbtn" name="bankstatement" onClick={backClick} >
                        {" "}
                        <i className="bi bi-arrow-left" /> Back
                    </a>
                </div> */}


            </div>

            <Modal
                show={loader}
                onHide={loader}
                backdrop="static"
                keyboard={false}
                centered
                contentClassName="modalcontentcar carmodal modal-dialog-centered"
            >
                <Modal.Body className="modelxp d-flex justify-content-center align-items-center">
                    <div className="form-group text-center" id="analize1">
                        <img height="70" src={tickgif} alt="" className="align-center" />
                    </div>
                </Modal.Body>
            </Modal>

            {/* <Modal
                show={loader}
                onHide={loader}
                backdrop="static"
                keyboard={false}
                className={`modal-body creditpopup`}
            >
                <div className="pb-3">
                    <div className="row align-items-center">
                        <div className="col-lg-12 col-md-12 col-sm-3 col-2 text-center">
                            <div className="mb-lg-3 mb-md-3 mb-sm-0 mb-0">
                                <img className="alertimg" src={tickgif} height="50" alt="" />
                            </div>
                        </div>
                        <div className="col-lg-12 col-md-12 col-sm-9 col-10">
                            <h6 className="mb-0 text-lg-center text-md-center text-sm-start text-start px-lg-3 px-md-3 px-sm-0 px-0">
                            </h6>
                        </div>
                    </div>
                </div>
            </Modal> */}



        </>

    );
}


export default RegistrationNo;