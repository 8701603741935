import React, { useState } from "react";
import TextField from "../../../UI/TextField";
import RadioButtonQuest from "../../../UI/RadioButtonQuest";
import InputButton from "../../../UI/InputButton";
const Questionnaire4 = ({
  clsName,
  reff,
  questArr,
  saveSlide4,
  name,
  btnName,
  validation,
  validationMsg,
  getValues,
  trigger,
  setError,
  splitForm,
  monthlyExpShow,
  setMonthlyExpShow,
  otherExpShow,
  setOtherExpShow,
  setClickedModel,
  question57Option,
  setQuestion57Option,
  question58Option,
  setQuestion58Option,

  checkedQuestionOne,
  setCheckedQuestionOne,
  checkedQuestionTwo,
  setCheckedQuestionTwo,
  checkedQuestionThree,
  setCheckedQuestionThree,
  checkedQuestionFour,
  setCheckedQuestionFour
}) => {
  const [question57Result,setQuestion57Result] = useState(false);
  const [question58Result,setQuestion58Result] = useState(false);

  // const [checkedQuestionOne, setCheckedQuestionOne] = useState("unchecked");
  // const [checkedQuestionTwo, setCheckedQuestionTwo] = useState("unchecked");
  // const [checkedQuestionThree, setCheckedQuestionThree] = useState("unchecked");
  // const [checkedQuestionFour, setCheckedQuestionFour] = useState("unchecked");

  const questionId = questArr.questionId ? questArr.questionId : 0;

  const selectClick = async (e) => {
    e.preventDefault();
    let errorFlag = 0;
    const values = getValues();
    
    let question31Result = await trigger("question_31");
    if (!question31Result) {
      errorFlag = 1;
      return false;
    }

    let question32Result = await trigger("question_32");
    if (!question32Result) {
      errorFlag = 1;
      return false;
    }

    // let question33Result = await trigger("question_33");
    // if (!question33Result) {
    //   errorFlag = 1;
    //   return false;
    // }

    let question33Result = await trigger("question_33");
    if (!question33Result) {
      errorFlag = 1;
      return false;
    }

    // let question35Result = await trigger("question_35");
    // if (!question35Result) {
    //   errorFlag = 1;
    //   return false;
    // }

    
    let question57Result = splitForm.current["question_57"].value;
    if(question57Result=='') {
      errorFlag = 1;
      setQuestion57Result(true);
      // setMonthlyExpShow('hide');
      return false;
    }

    let question58Result = splitForm.current["question_58"].value;
    if(question58Result=='') {
      errorFlag = 1;
      setQuestion58Result(true);
      return false;
    }

    if (errorFlag === 0) {
  
      saveSlide4(e);
    } 
  };

  const handleChange = (e) => {
    // const re = /^[0-9\b]+$/;
    const re = /^[1-9]\d*$/;
    if (e.target.value !== "" && !re.test(e.target.value)) {
      e.target.value = e.target.value.replace(/\D/g, "") * 1;
    }
    if (e.target.value !== "") {
      e.target.value =
        "£" + e.target.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");        
    }
  };

  const labelclickedLoan = (e) => {
    if (e.target.value === "151") {
      setCheckedQuestionOne("checked");
      setCheckedQuestionTwo("unchecked");
      setClickedModel({ model: "monthly", status: "show" });
    }
    if (e.target.value === "152") {
      setCheckedQuestionOne("unchecked");
      setCheckedQuestionTwo("checked");
      setQuestion57Option(152);
    } 
    setQuestion57Result(false);    
  }

  const labelclicked = (e) => {
    if (e.target.value === "153") {
      setCheckedQuestionThree("checked");
      setCheckedQuestionFour("unchecked");
      setClickedModel({ model: "other", status: "show" });
    }
    if (e.target.value === "154") {
      setCheckedQuestionThree("unchecked");
      setCheckedQuestionFour("checked");
      setQuestion58Option(154);
    } 
    setQuestion58Result(false);
    //setQuestion58Option(e.target.value);
  }

  // const totalDebitValidation = async (e) => {
  //   e.preventDefault();
  //   let errorFlag = 0;
  //   const values = getValues();
    
  //   let question31Result = await trigger("question_59");
  //   if (!question31Result) {
  //     errorFlag = 1;
  //     return false;
  //   }
  //   if(errorFlag == 0) {
  //     let question58Result = splitForm.current["question_58"].value;
  //     if (question58Result=='153') {
  //       setMonthlyExpShow('hide');
  //       setOtherExpShow('show');
  //     } else {
  //       setMonthlyExpShow('hide');
  //       saveSlide4(e);
  //     }
  //   }
  // }

  // const totalOtherDebitValidation = async (e) => {
  //   e.preventDefault();
  //   let errorFlag = 0;

  //   let question60Result = await trigger("question_60");
  //   if (!question60Result) {
  //     errorFlag = 1;
  //     return false;
  //   }
  //   if(errorFlag == 0) {
  //     setOtherExpShow('hide');
  //     setMonthlyExpShow('hide');
  //     saveSlide4(e);
  //   }
  // }

  return (
    <>
      <div
        className={`radio-box question animated fadeInUp ${clsName}`}
        ref={reff}
      >
        <h3><b>{questArr.question}</b></h3>
        {/* <p className="text-center text-success">{questArr.question_sub}</p> */}
        <p className="text-center text-success"> Please confirm the following MONTHLY / ANNUAL income and expenditure details as <b><u>at the time of the Loan finance</u></b>&nbsp;as accurately as possible:-</p>
       

        <div className="input-group mb-3">
          <span className="input-group-text input-mobile" id="basic-addon1">
            My monthly / annual income, (after tax), was
          </span>
          <TextField
            type="tel"
            className="form-control"
            placeholder="My monthly / annual income, (after tax), was"
            name="question_31"
            onChange={handleChange}
            validation={validation({
              required: "Please Enter Monthly / Annual Income",
              pattern: {
                value:
                  /^[ A-Za-z0-9_@./#&+-£]*$/,
                message: "Monthly / Annual Income is Invalid. Please Recheck",
              },
            })}
            validationMsg={
              validationMsg.question_31 && validationMsg.question_31.message
            }
          ></TextField>
        </div>

        <div className="input-group mb-3">
          <span className="input-group-text input-mobile" id="basic-addon1">
            My monthly mortgage / rent was
          </span>
          <TextField
            type="tel"
            className="form-control"
            placeholder="My monthly mortgage / rent was"
            name="question_32"
            onChange={handleChange}
            validation={validation({
              required: "Please Enter MortgageRent Expense",
              pattern: {
                value:
                /^[ A-Za-z0-9_@./#&+-£]*$/,
                message: "MortgageRent Expense is Invalid. Please Recheck",
              },
            })}
            validationMsg={
              validationMsg.question_32 && validationMsg.question_32.message
            }
          ></TextField>
        </div>

        {/* <div className="input-group mb-3">
          <span className="input-group-text input-mobile" id="basic-addon1">
          Loan Expense
          </span>
          <TextField
            type="tel"
            className="form-control"
            placeholder="Loan Expense"
            name="question_33"
            onChange={handleChange}
            validation={validation({
              required: "Please Enter Loan Expense",
              pattern: {
                value:
                /^[ A-Za-z0-9_@./#&+-£]*$/,
                message: "Loan Expense is Invalid. Please Recheck",
              },
            })}
            validationMsg={
              validationMsg.question_33 && validationMsg.question_33.message
            }
          ></TextField>
        </div> */}

        <div className="input-group mb-3">
          <span className="input-group-text input-mobile" id="basic-addon1">
            My monthly car finance was
          </span>
          <TextField
            type="tel"
            className="form-control"
            placeholder="My monthly car finance was"
            name="question_33"
            onChange={handleChange}
            validation={validation({
              required: "Please Enter Car finance",
              pattern: {
                value:
                /^[ A-Za-z0-9_@./#&+-£]*$/,
                message: "Car finance is Invalid. Please Recheck",
              },
            })}
            validationMsg={
              validationMsg.question_33 && validationMsg.question_33.message
            }
          ></TextField>
        </div>

        {/* <div className="input-group mb-3">
          <span className="input-group-text input-mobile" id="basic-addon1">
           Other Monthly Credit Commitments
          </span>
          <TextField
            type="tel"
            className="form-control"
            placeholder="eg: Typical Amount Paid to Credit Cards & Loans"
            name="question_35"
            onChange={handleChange}
            validation={validation({
              required: "Please Enter Other Monthly Credit Commitments",
              pattern: {
                value:
                /^[ A-Za-z0-9_@./#&+-£]*$/,
                message:
                  "Other Monthly Credit Commitments is Invalid. Please Recheck",
              },
            })}
            validationMsg={
              validationMsg.question_35 && validationMsg.question_35.message
            }
          ></TextField>
        </div> */}

        <div className="input-group mb-3">
          <fieldset className="form-box2 second_box">
            <legend className="title"> Did you have any monthly Loan / Credit Card Expenses?</legend>
            <div className="mx-2">
              <div className="row">
                <div className="col-sm-6">
                  {/* <RadioButtonQuest
                    key="YES"
                    className="radio-button"
                    labelName="Yes"
                    labelClassName={`option_but_2 next-2 ${checkedQuestionOne}`}
                    // labelCountString="a"
                    onClick={labelclickedLoan}
                    value="151"
                    name="question_57"
                    id="Yes57"
                    dataQuestId={57}
                    dataAnsId={151}
                    dataAnsLabel="Yes"
                    checked={question57Option === '151'}
                  /> */}
                  <input type="radio" 
                    className="radio-button" 
                    id="Yes57" 
                    name="question_57"
                    data-ans-id="151" 
                    data-quest-id='57' 
                    data-ans-label="Yes"
                    value="151" 
                    onClick={labelclickedLoan} 
                    checked={question57Option === 151}
                    ref={validation()}
                  />
                  <label htmlFor="Yes57" 
                    data-ans-id="151" 
                    data-quest-id="57"
                    className={`option_but_2 next-2 ${checkedQuestionOne}`}
                    >
                    Yes
                  </label>
                </div>
                <div className="col-sm-6">
                  {/* <RadioButtonQuest
                    key="NO"
                    className="radio-button"
                    labelName="No"
                    labelClassName={`option_but_2 next-2 ms-4 ${checkedQuestionTwo}`}
                    // labelCountString="a"
                    onClick={labelclickedLoan}
                    value="152"
                    name="question_57"
                    id="No57"
                    dataQuestId={57}
                    dataAnsId={152}
                    dataAnsLabel="No"
                    checked={question57Option === '152'}
                  /> */}
                  <input type="radio" 
                    className="radio-button" 
                    id="No57" 
                    name="question_57"
                    data-ans-id="152" 
                    data-quest-id='57' 
                    data-ans-label="No"
                    value="152" 
                    onClick={labelclickedLoan} 
                    checked={question57Option === 152}
                    ref={validation()}
                  />
                  <label htmlFor="No57" 
                    data-ans-id="152" 
                    data-quest-id="57"
                    className={`option_but_2 next-2 ${checkedQuestionTwo}`}
                    >
                    No
                  </label>
                </div>
              </div>
            </div>
            {question57Result && (
              <span className="error_msg">Please Select an Item</span>
            )}
          </fieldset>
        </div>
        
        <div className="input-group mb-3">
          <fieldset className="form-box2 second_box">
            <legend className="title first">  Did you have any other monthly expenses not   mentioned above (betting, groceries,  takeaways etc)</legend>
            <div className="mx-2"> 
              <div className="row"> 
                <div className="col-sm-6">
                  {/* <RadioButtonQuest
                    key="YES"
                    className="radio-button"
                    labelName="Yes"
                    labelClassName={`option_but_2 next-2 ${checkedQuestionThree}`}
                    // labelCountString="a"
                    onClick={labelclicked}
                    value="153"
                    name="question_58"
                    id="yes58"
                    dataQuestId={58}
                    dataAnsId={153}
                    dataAnsLabel="Yes"
                  /> */}

                  <input type="radio" 
                    className="radio-button" 
                    id="Yes58" 
                    name="question_58"
                    data-ans-id="153" 
                    data-quest-id='58' 
                    data-ans-label="Yes"
                    value="153" 
                    onClick={labelclicked} 
                    checked={question58Option === 153}
                    ref={validation()}
                  />
                  <label htmlFor="Yes58" 
                    data-ans-id="153" 
                    data-quest-id="58"
                    className={`option_but_2 next-2 ${checkedQuestionThree}`}
                    >
                    Yes
                  </label>
                </div>
                <div className="col-sm-6">
                  {/* <RadioButtonQuest
                    key="NO"
                    className="radio-button"
                    labelName="No"
                    labelClassName={`option_but_2 next-2 ms-4 ${checkedQuestionFour}`}
                    // labelCountString="a"
                    onClick={labelclicked}
                    value="154"
                    name="question_58"
                    id="No58"
                    dataQuestId={58}
                    dataAnsId={154}
                    dataAnsLabel="No"
                  /> */}
                  <input type="radio" 
                    className="radio-button" 
                    id="No58" 
                    name="question_58"
                    data-ans-id="154" 
                    data-quest-id='58' 
                    data-ans-label="No"
                    value="154" 
                    onClick={labelclicked} 
                    checked={question58Option === 154}
                    ref={validation()}
                  />
                  <label htmlFor="No58" 
                    data-ans-id="154" 
                    data-quest-id="58"
                    className={`option_but_2 next-2 ${checkedQuestionFour}`}
                    >
                    No
                  </label>
                </div>
              </div>
            </div>
          </fieldset>
          {question58Result && (
            <span className="error_msg">Please Select an Item</span>
          )}
        </div>

        {/* <SelectBox
					className="form-select"
					OptionValue={questArr.answers}
					name="question_13"
					id="question_13"
					myRef={validation({ required: "Please select an amount range" })}
					validationMsg={
						validationMsg.question_13 && validationMsg.question_13.message
					}
				/>	 */}
        <div className="col-xl-12 col-lg-12 text-center p-0">
          <button
            type="button"
            className="btn-next btn-next-green"
            onClick={selectClick}
            data-quest-id={questionId}
            data-ans-id={null}
            data-ans-label={null}
            name={btnName}
          >
            Next
          </button>
        </div>
        
      </div>
      
      {/*         
      <div role="dialog" className={`fade modal blackover ${monthlyExpShow}`}>
        <div className="modal-dialog modal-md modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body">
              <h3 className="text-center fonts15"><u>Provide your total Loan and Credit card expenses you have with your Lenders</u></h3>
              <p><i>(Many of our customers have expenses with multiple lenders. So you can provide the total sum of expenses you pay with all Lenders)</i></p>
              <div className="input-group mb-3 justify-content-center">
                <div className="mb-3 mt-3 col-12 p-0">
                  <div className="input-group mb-3">
                    <TextField
                      type="tel"
                      className="form-control"
                      placeholder="Provide your total Loan and Credit card expenses"
                      // name="question_51"
                      name="question_59"
                      validation={validation({
                        required: "Please Enter Total Loan and Credit card expenses",
                        pattern: {
                          value: /^[ A-Za-z0-9_@./#&+-£]*$/,
                          message:
                            "Total Loan and Credit card expenses is Invalid. Please Recheck",
                        },
                      })}
                      validationMsg={
                        validationMsg.question_59 &&
                        validationMsg.question_59.message
                      }
                    ></TextField>
                  </div>

                  <InputButton
                    name="eeeee"
                    className="btn-next"
                    id=""
                    value="Next"
                    btnType="button"
                    onClick={totalDebitValidation}
                    style={{ float: "none" }}
                    data-quest-id={questionId}
                    data-ans-id={null}
                    data-ans-label={null}
                  />
                  
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>

      <div role="dialog" className={`fade modal blackover ${otherExpShow}`}>
        <div className="modal-dialog modal-md modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body">
              <h3 className="text-center fonts15"><u>Provide your total other monthly expenses you have</u></h3>
              <p><i>(This can be a total of Fuel, Clothing, Betting, Bills, Coffee/Tea etc)</i></p>
              <div className="input-group mb-3 justify-content-center">
                <div className="mb-3 mt-3 col-12 p-0">
                  <div className="input-group mb-3">
                    <TextField
                      type="tel"
                      className="form-control"
                      placeholder="Provide your total Loan and Credit card expenses"
                      // name="question_51"
                      name="question_60"
                      validation={validation({
                        required: "Please Enter Total Loan and Credit card expenses",
                        pattern: {
                          value: /^[ A-Za-z0-9_@./#&+-£]*$/,
                          message:
                            "Total Loan and Credit card expenses is Invalid. Please Recheck",
                        },
                      })}
                      validationMsg={
                        validationMsg.question_60 &&
                        validationMsg.question_60.message
                      }
                    ></TextField>
                  </div>

                  <InputButton
                    name="eeeee"
                    className="btn-next"
                    id=""
                    value="Next"
                    btnType="button"
                    onClick={totalOtherDebitValidation}
                    style={{ float: "none" }}

                    data-quest-id={questionId}
                    data-ans-id={null}
                    data-ans-label={null}
                  />
                
                </div>

              </div>
            </div>
          </div>
        </div>
      </div> 
      */}

    </>
  );
};

export default Questionnaire4;
