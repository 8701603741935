import React from 'react';
import ProgressBar from './ProgressBar';
import Telephone from '../../../UI/Telephone';
import Email from '../../../UI/Email';
import { useEmailValidation } from '../../../../Hooks/useEmailValidation';
import { usePhoneValidation } from '../../../../Hooks/usePhoneValidation';
import InputButton from '../../../UI/InputButton';

const Slide15 = ({
  validation,
  validationMsg,
  setError,
  className,
  backClick,
  trigger,
  disabled,
  getValues,
  formSubmit
}) => {
  const { emailValidation } = useEmailValidation();
  const { phoneValidation } = usePhoneValidation();

  const emailValidCheck = async () => {
    const values = getValues();
    const email = values['txtEmail'];
    const phone = values['txtPhone'];
    if (email !== "") {
      const getEmailValidation = await emailValidation(email)
      if (getEmailValidation.data.status_code === 1) {
        return 1;
      } else {
        setError("txtEmail", {
          type: "manual",
          message: "Invalid Email",
        });
      }
    }
  };

  const phoneValidCheck = async () => {
    const values = getValues();
    const phone = values['txtPhone'];
    if (phone !== "") {
      const getPhoneValidation = await phoneValidation(phone)
      if (getPhoneValidation.data.status_code === 0) {
        setError("txtPhone", {
          type: "manual",
          message: "Please Enter Valid Working Phone Number",
        });
        return 0;
      } else if (getPhoneValidation.data.status_code === 2) {
        setError("txtPhone", {
          type: "manual",
          message: "Phone Number Already Exist",
        });
        return 0;
      } else if (getPhoneValidation.data.status_code === 3) {
        setError("txtPhone", {
          type: "manual",
          message: "Please Enter Valid Phone Number",
        });
        return 0;
      }
      else {

        return 1;
      }
    }
  };

  const validateSlide14 = async (e) => {

    e.preventDefault();
    const values = getValues();
    var emailVal = await trigger("txtEmail");
    var phoneVal;
    if (emailVal) {
      phoneVal = await trigger("txtPhone");
    } else {
      document.getElementById("txtEmail").focus();
      return false;
    }


    if (emailVal && phoneVal) {
      if ((await emailValidCheck()) === 1 && (await phoneValidCheck()) === 1) {
        formSubmit();
      }
    }
  }

  return (
    <>
      <div id="slide15" className={`col-12 top_minus ${className}`}>
        <ProgressBar value={95} />

        <h3>What's the best way to communicate?</h3>
        <div className=" mb-3">
          <div className="input-group ">
            <Email
              name="txtEmail"
              className="form-control"
              id="txtEmail"
              placeholder="Email Address"
              onBlur={emailValidCheck}
              validation={validation({
                required: "Please Enter Valid Email Address",
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                  message: "Invalid Email Address",
                },
              })}

            ></Email>
            <div className="input-group-append"> <span className="input-group-text">
              <img src="/assets/MB_UBRF_2/img/privacy.png" alt="" />
            </span>
            </div>
          </div>
          {validationMsg.txtEmail && (
            <span
              className="error_msg "
              id="email_err"
            >
              {validationMsg.txtEmail.message}
            </span>
          )}
        </div>
        <div className=" mb-3">
          <div className="input-group phon">
            <Telephone
              name="txtPhone"
              id="txtphone"
              className="form-control nme_inpu"
              placeholder="Phone Number"
              onBlur={phoneValidCheck}
              onKeyPress={e => (!/[0-9]+$/.test(e.key)) && e.preventDefault()}
              maxlength="11"
              validation={validation({
                pattern: {
                  value: /^[0-9]*$/i,
                  message: "Please Enter Valid Phone Number",
                },
                required: "Please Enter Phone Number",
              })}
            ></Telephone>
            <div className="input-group-append"> <span className="input-group-text">
              <img src="/assets/MB_UBRF_2/img/privacy.png" alt="" />
            </span>
            </div>
          </div>
          {validationMsg.txtPhone && (
            <span
              className="error_msg "
              id="phone_err"
            >
              {validationMsg.txtPhone && "Please Enter Valid Phone Number"}
            </span>
          )}
        </div>

        <div className="col-lg-12 mb-pd text-center ">
          <InputButton
            name="formSubmitBtn"
            className="btn regNextBtn next10"
            id="formSubmitBtn"
            value="SUBMIT"
            btnType="submit"
            style={{ float: "none" }}
            disabled={disabled}
            onClick={validateSlide14}
          />

        </div>
        <p className="text-center">
          <a href="#" className="back13" id="back13" name="back13" onClick={backClick}>
            {'<< Previous'}
          </a>
        </p>

      </div>
    </>
  );
};

export default Slide15;