import React, { useState } from "react";
import RadioButtonQuest from "../../../../UI/RadioButtonQuest";
import InputButton from "../../../../UI/InputButton";
import Modal from "react-bootstrap/Modal";

const DidReceiveIncome = ({ onselectAnswer, reff, display, trigger, isLoan }) => {
  const labelclicked = async (e) => {
      onselectAnswer(e);
  };



  return (
    <>
      <div style={{ display: display }}>
        <div className="radio-box question animated fadeInUp" ref={reff}>
          <h3>
          {isLoan ? 'In the year when you took the loan – did you receive any income?' : 'In the year when you took the card – did you receive any income?'}
          </h3>
          <RadioButtonQuest
            key="Yes"
            className="radio-button"
            labelName="Yes"
            labelClassName="option_but next-2"
            onClick={labelclicked}
            value="226"
            name="question_77"
            id="inyes77"
            dataQuestId={77}
            dataAnsId={226}
            dataAnsLabel="Yes"
          />
          <RadioButtonQuest
            key="No"
            className="radio-button"
            labelName="No"
            labelClassName="option_but next-2"
            onClick={labelclicked}
            value="227"
            name="question_77"
            id="inno77"
            dataQuestId={77}
            dataAnsId={227}
            dataAnsLabel="No"
          />
        </div>
      </div>
    </>
  );
};

export default DidReceiveIncome;

