import React, { useState, useContext } from 'react';
import ProgressBar from '../Common/ProgressBar';
import TextField from '../../../UI/TextField';
import RadioButton from '../../../UI/RadioButton';
import { useCarRegisterValidation } from '../../../../Hooks/useCarRegisterValidation';
import { useSkippedLeads } from '../../../../Hooks/useSkippedLeads';
import { useTotalCarCheck } from '../../../../Hooks/useTotalCarCheck';
import AnalyzeModal from './AnalyzeModal';
import { useOpenBanking } from "../../../../Hooks/useOpenBanking";
import VisitorParamsContext from "../../../../Contexts/VisitorParams";
import * as EnvConstants from "../../../../Constants/EnvConstants";
import {obLender} from "../../../../Hooks/useOblender";
const VehicleReg = ({
  className,
  slideChange,
  reff,
  checkCarRegistration,
  page_name,
  uuid,
  saveVehicleDetails,
  split_name,
  validation,
  validationMsg,
  trigger,
  clearErrors,
  getValues,
  setError,
  slideSkip,
  backClick,
  setSkip,
  textHeading,
  setLenderList
}) => {
  const { registrationvalidation } = useCarRegisterValidation();
  const { totalCarCheckApi } = useTotalCarCheck();
  const [loader, showLoader] = useState(false);
  const { getVisitorID, getAccessToken, getProviders } = useOpenBanking();
  const { visitorParameters } = useContext(VisitorParamsContext);
  const { skippedLeads } = useSkippedLeads();
  const environment = EnvConstants.AppEnv;
  const isTest = (environment == "prod") ? false : true;
  const { getLenderlist } = obLender();

  const nextSlide = async (e) => {
    setSkip(false);
    let registration_no = await trigger("carRegNo");
    if (registration_no) {
      const fieldResult = getValues();
      var carReg = fieldResult["carRegNo"];
      var carRegNo = carReg.replace(/\s+/g, "");
      CarRegisterValidationApi(e, carRegNo)
    } else {
      return false;
    }
  };

  const SkipRegistration = async (e) => {
    setSkip(true);
    const skippedLeadsResponse = await skippedLeads(e.target.name, uuid, split_name);
    if (skippedLeadsResponse.data.status == "Success") {
      localStorage.setItem('ValidKeeper',0);
      // getproviderList();
      getLender();
      slideSkip();
    }
  }

  const CarRegisterValidationApi = async (e, carRegNo) => {
    showLoader(true);
    const getRegisterValidation = await registrationvalidation(carRegNo, uuid, "MB_UFGN_1");


    if (getRegisterValidation.data.validity == "1") {
      //slideChange(e);
      let vehicle = getRegisterValidation.data.vehicleData;

      vehicle.reg_no = carRegNo;
      saveVehicleDetails(vehicle);
      let keeperData = JSON.parse(vehicle.keeper_dates);
      let keeperArray = keeperData.keeper_dates;
      let keeperLength = keeperArray.length;
      checkCarRegistration(e);
      // getproviderList();
      getLender();

    } else {
      setError("carRegNo", {
        type: "manual",
        message: "Your Vehicle Registration is Invalid. Please Recheck!",
      });
      //showLoader('hide');
      showLoader(false);
      return false;
    }
  };

  const getproviderList = async () => {
    let user_data = { 'atp_sub6': 'live', 'atp_sub2': null, 'pixel': null }
    const visitor = await getVisitorID(
      visitorParameters.visitor_parameters.uuid,
      user_data,
      'new'
    )
    let visitor_id = visitor.data.visitorId;
    localStorage.setItem('visitorId', visitor_id);
    const response1 = await getAccessToken(
      visitorParameters.visitor_parameters.uuid,
      'live',
      isTest,
      visitor_id);
    const providersList = await getProviders(
      visitorParameters.visitor_parameters.uuid,
      'live',
      isTest,
      visitor_id
    );
    let listLength = providersList.data.providers.length;
    if (listLength > 0) {
      setLenderList(providersList.data.providers);
      setTimeout(() => {
        showLoader(false);
      }, 1000);
    }
  }

  const getLender = async () => {
    try {
      const response = await getLenderlist();
      const dataLength = response.data.providers.length;
      
      if (dataLength > 0) {
        setLenderList(response.data.providers);
        setTimeout(() => {
          showLoader(false);
        }, 1000);
      }
    } catch (error) {
      console.error("Error fetching lender list:", error);
    }
  }
 
  return (
    <>
      <div id="slide2" className={`${className}`} ref={reff}>
        <ProgressBar value="20" />
        <h3>{textHeading} </h3>


        <div className="input-section mb-3">
          <TextField
            type="text"
            className="form-control mob-wd car-regnumber"
            placeholder="Enter Vehicle Registration Number"
            aria-label="Username"
            name="carRegNo"
            id="carRegNo"
            validation={validation({
              required: "Please enter a valid car registration number",
              minLength: {
                value: 3,
                message: "Please Enter A Valid Registration",
              },
              pattern: {
                value: /^(?=.*[a-zA-Z])(?=.*[0-9])[a-zA-Z0-9 ]+$/,
                message:
                  "Your Vehicle Registration is Invalid. Please Recheck",
              },
            })}
            validationMsg={validationMsg.carRegNo &&
              validationMsg.carRegNo.message}
          />
        </div>
        <div className="btn-comm text-center">
          {/* <input
            type="button"
            className="option_but next2a"
            onClick={nextSlide}
            name="VehRegNo"
            value="Next"
          /> */}
          <RadioButton
            name="VehRegNo"
            id={"yearNext1"}
            className="radio-button"
            labelName="Next"
            labelClassName={`regNextBtn next2a`}
            data-quest-id={10}
            data-ans-id={null}
            data-ans-label={null}
            onClick={nextSlide}
            validation={validation()}
          />
        </div>
        <div className="w-100 text-center pb-3">
          <p className="text-center"><a className="back01 skip-btn" name="skipRegistration" onClick={SkipRegistration}>{"Skip"}</a></p>
        </div>
        <p className="text-center"><a className="back01" name="prevHardship" onClick={backClick}> {'<< Previous'} </a></p>
      </div>

      <AnalyzeModal modalClass={loader} />
    </>
  );
};

export default VehicleReg;