import React, { useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import "../../assets/VAQ_ID_UPLOAD/css/Vanquis_id_upload.scss";
import VisitorParamsContext from "../../Contexts/VisitorParams";
import GtmDataLayer from "../../Utility/GtmDataLayer";
import { useUserVerify } from "../../Hooks/useUserVerify";
import { userInfo } from "../../Hooks/userInfo";
import GetVisitorsParams from "../../Utility/GetVisitorsParams";
import { useLogRecord } from "../../Hooks/UseLogRecord";
import { Api } from "../../api/Api";
import { useUpdateMilestone } from "../../Hooks/useUpdateMilestone";
import { queryString } from "../../Utility/QueryString";
import loader_review2 from "../../assets/FollowupVaqV2/img/loader-review2.svg";

const VerifyData_FLP_VAQ_V2 = ({
  removeFromPending,
  t2aApiReqData,
  currentUuid,
  allFollowupData
}) => {

  const history = useHistory();
  const { updateLogRecord } = useLogRecord();
  const { getUserVerfyValidation, saveFollowupUserVerify } = useUserVerify();
  const { UserDataInfo, VaQPdfGeneration } = userInfo();
  const { updateMilestone } = useUpdateMilestone();
  const flp_source = allFollowupData.atp_sub6;

  useEffect(() => {
    sendT2aApiReqDataAPI();
  }, []);

  const sendT2aApiReqDataAPI = () => {
    const useruuid = t2aApiReqData.uuid;
    const fName = t2aApiReqData.fName;
    const lName = t2aApiReqData.lName;
    const pCode = t2aApiReqData.pCode;
    const addressLine1 = t2aApiReqData.addressLine1;
    const dobDay = t2aApiReqData.day;
    const dobMonth = t2aApiReqData.month;
    const dobYear = t2aApiReqData.year;
    const addressLine2 = t2aApiReqData.addressLine2;
    const postTown = t2aApiReqData.postTown;
    const country = t2aApiReqData.country;
    const title = t2aApiReqData.title;
    const addressLine3 = t2aApiReqData.addressLine3;
    const addressLine4 = t2aApiReqData.addressLine4;
    const county = t2aApiReqData.county;
    if (
      useruuid &&
      fName &&
      lName &&
      pCode &&
      addressLine1
    ) {
      const startTime = performance.now();
      let startDateTime = getCurrentDateTime();
      (async () => {
        const response = await getUserVerfyValidation(
          useruuid,
          fName,
          lName,
          pCode,
          addressLine1,
          dobYear,
          dobMonth,
          dobDay,
          addressLine2,
          postTown,
          country,
          title,
          addressLine3,
          addressLine4,
          county
        );
        let endDateTime = getCurrentDateTime();

        const endTime = performance.now();

        const Timems = endTime - startTime;

        const timeTaken = (Timems / 1000).toFixed(2); // Convert milliseconds to seconds

        storeT2aResponse(response.data);

        const LogRecord = await updateLogRecord(
          useruuid,
          "Person_Search_FLP_VAQ_V2",
          timeTaken,
          startDateTime,
          endDateTime
        );
       
        if (response.data.status == "success") {
          updateMilestone(currentUuid, "kyc_check", flp_source, 1);
          updateMilestone(currentUuid, "questions", flp_source);
          localStorage.setItem("t2a_result", 1);
          localStorage.setItem("t2aValidation", 1);
        } else {
          updateMilestone(currentUuid, "kyc_check", flp_source, 2);
          updateMilestone(currentUuid, "questions", flp_source);

          localStorage.setItem("t2a_result", 0);
          localStorage.setItem("t2aValidation", 0);
        }
      })();
    } else {
    //
    }
  };

  const storeT2aResponse = async (t2a_data) => {
    const response = await saveFollowupUserVerify(
      allFollowupData,
      t2a_data,
      "person_verify_store_flp",
      queryString,
      t2aApiReqData
    );
    removeFromPending('verify');
  };

  const getCurrentDateTime = () => {
    const currentDate = new Date();

    const year = currentDate.getFullYear();
    const month = (currentDate.getMonth() + 1).toString().padStart(2, "0");
    const day = currentDate.getDate().toString().padStart(2, "0");
    const hours = currentDate.getHours().toString().padStart(2, "0");
    const minutes = currentDate.getMinutes().toString().padStart(2, "0");
    const seconds = currentDate.getSeconds().toString().padStart(2, "0");

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  };

  return (
    <>
      <div className="container d-flex align-items-center justify-content-center vh-100">
        <div className={`loader-wrap text-center text-light`}>
          <div>
            <img style={{ height: 80 }} src={loader_review2} alt />

            <h6 className="mt-3">
              Analysing your answers
            </h6>
            <p>(Don't worry, it will only take a minute.)</p>
          </div>
        </div>
      </div>
    </>
  );
};
export default VerifyData_FLP_VAQ_V2;
