import React, { useEffect, useState } from "react";


const MatchCheckLoader = ({ matchCheckLoader, setMatchCheckLoader, setAffordabilityStat }) => {



  return (
    <>
      <div className="OpenBankingV1" >
        <div className={`${matchCheckLoader}`}>
          <div className="brand-logotp text-center mt-lg-4 mt-md-2 mt-sm-2 mt-2">
            <img
              height="65"
              src="assets/img/a00187b31dd5a4ece0c8f809b69962eb.png"
              alt=""
            />
          </div>
          <div
            className={`loader_1 text-center loader-wrap  flex-column `}
          >
            <div className="loader">
              <img src="assets/img/review.gif" alt="" />
            </div>
            <h5>Analysing your application....</h5>
          </div>
        </div>




        {/* <div className={`loader text-center loader-wrap show`}>
        
        <img src="/assets/Followup/img/review.gif" alt="" 
        // style={{ width: '100px', marginTop: '30px' }} 
        />
        <h5>Analysing your application....</h5>
      </div> */}
      </div>
    </>
  );
};

export default MatchCheckLoader;

