import React, { useEffect, useState, useContext } from 'react';
import Modal from 'react-bootstrap/Modal';
import "../../assets/Thankyou/css/Thankyou.scss";
import Footer from '../Includes/Layouts/Common/Footer';
import { useUpdateMilestone } from "../../Hooks/useUpdateMilestone";
// import VisitorParamsContext from "../../Contexts/VisitorParams";
import GetVisitorsParams from '../../Utility/GetVisitorsParams';

const Thankyou = () => {

  // const { visitorParameters } = useContext(VisitorParamsContext);
  const urlSearchParams = new URLSearchParams(window.location.search);
  const uuid = urlSearchParams.get('uuid');
  const {updateMilestone} = useUpdateMilestone();

  useEffect(() => { 
    const allFollowupData = JSON.parse(localStorage.getItem('allFollowupData'));
    if (allFollowupData) {
        let flp_source = allFollowupData.atp_sub6;
      updateMilestone(uuid,"completed",flp_source);
    }
    else{
      updateMilestone(uuid,"completed","live");
    }
    localStorage.clear();
  }, []);

  

  return (
    <>
      <div className="thankyou">
        <header>
          <div className="container">
            <div className="col-lg-12 col-12 text-center">
              <img src="/assets/img/logo.png" alt="" />
            </div>
          </div>
        </header>
          <div className="container inner-height">
            <div className="row ">
              <div className="col-lg-10 offset-lg-1 col-sm-02 col-12 mp0  text-center animated bounceInDown">
                <img src="thumb.png" alt="" />
                <h1 className="animated zoomInUp">Thank you</h1>
                <p className="thankuparag">You have successfully submitted your claim</p>
              </div>
            </div>
          </div>
        <div className='footerclass'>
          <Footer />
        </div>
      </div>
    </>
  )
}

export default Thankyou;
