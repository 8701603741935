import React , {
  useContext
} from "react";
import { useHistory } from "react-router-dom";
import "../../assets/viewdocs/css/docs.scss";

const ViewdocsFollowup = () => {
  const history = useHistory();
  const getUuid = () => {
    const query = new URLSearchParams(window.location.search);
    const query_uuid = query.get("uuid");
    const local_storage_uuid = localStorage.getItem("uuid");
    var uuid = query_uuid ? query_uuid : local_storage_uuid;
    return uuid;
  };
  const uuid = getUuid();

  
  
  const split_name = localStorage.getItem("split_name");
  const product_id = localStorage.getItem("product_id");
  const redirectPreview = () => {

    if(product_id == 1){
      history.push("/preview_flp?uuid=" + uuid);
    }else if(product_id == 2){
      history.push("/preview_flp_v3?uuid=" + uuid);
    }else if(product_id == 3){
      history.push("/preview_flp_v4?uuid=" + uuid);
    }else if(product_id == 4){
      if (split_name == 'VAQ_UB_4_3_1') {
        history.push("/PreviewVAQ_UB_4_3_1?uuid=" + uuid);
      } else {
        history.push("/PreviewVAQ4?uuid=" + uuid);
      }
    }else if(product_id == 5){
      // history.push("/preview_flp_v7?uuid=" + uuid);
      history.push("/preview-newday-flp?uuid=" + uuid);
    }else{
      history.push("/thankyou?uuid=" + uuid);
    }
    // if(split_name=='MB_UBRF_1' || split_name=='MB_UBRF_3'||split_name=='MB_OBV1'){
    //   history.push("/preview_flp?uuid=" + uuid);
    // } else if (split_name=='LS_UB_1') {
    //   history.push("/preview_flp_v3?uuid=" + uuid);
    // } else if ((split_name=='LS_UB_1_FLP')) {
    //   history.push("/preview_flp_v3?uuid=" + uuid);
    // }  else if ((split_name=='VCC_UB_1')) {
    //   history.push("/preview_flp_v4?uuid=" + uuid);
    // } else if ((split_name=='VCC_UB_2' || split_name=='VCC_UB_3')) {
    //   history.push("/preview_flp_v4?uuid=" + uuid);
    // } else if((split_name=='NDY_UB_1'|| split_name=='NDY_UB_2' || split_name=='NDY_OB_UB_2_1' )){
    //   history.push("/preview_flp_v7?uuid=" + uuid);
    // } else if((split_name=='VAQ_UB_1' || split_name=='VAQ_UB_2' || split_name=='VAQ_UB_3' || split_name=='VAQ_OB_UB_3_1')){
    //   history.push("/preview_flp_v5?uuid=" + uuid);
    // } else if((split_name=='VAQ_UB_4' || split_name=='VAQ_UB_4_2'|| split_name == "VAQ_UB_4_3")){
    //   history.push("/preview-vaq-flp?uuid=" + uuid);
    // }else {
    //   history.push("/thankyou?uuid=" + uuid);
    // }
  } 
  return(
    <>
      <div className ="view_doc">
        <div className ="suc_page">
          <section>
            <div className="container text-center" style={{margin:"auto"}}>
              <div className="row">
                <div className="col-lg-12">
                  <div className="mt-3">
                    <a href className="tms_logo">
                      <img src="/assets/viewdocs/img/logo.png"/>
                    </a>
                  </div>
                  <div className="suc_midle">
                    <div className="loader">
                      <img src="/assets/viewdocs/img/loader-review2.gif" className="image" />
                    </div>
                    <p className="text py-5">
                      Thank you for submitting the documents to allow TMS Legal to represent you in your responsible lending claim.&nbsp;
                      <span>
                        We will be in touch shortly.</span>
                    </p>
                    <a href="javascript:void(0)" onClick={redirectPreview} className="btn-view bnt-style"> View Your Documents &gt; </a>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  )
}
export default ViewdocsFollowup;