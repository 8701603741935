import React, { useContext, useEffect, useRef, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Email from "../../../UI/Email";
import Telephone from "../../../UI/Telephone";
import InputButton from "../../../UI/InputButton";
import { useHistory } from "react-router-dom";
import VisitorParamsContext from "../../../../Contexts/VisitorParams";
import { useEmailValidation } from "../../../../Hooks/useEmailValidation";
import { usePhoneValidation } from "../../../../Hooks/usePhoneValidation";
import privacy from "../../../../assets/MB_UFGN_1/img/privacy.png";
import ProgressBar from "../../Layouts/Common/ProgressBar";
import Verify2 from "../MB_UFGN_1/Verify2";
import Bankruptcy from "../MB_UFGN_1/Bankruptcy";
import ssl from "../../../../assets/MB_UFGN_1/img/ssl.svg";

const ContactDetails = ({
  validation,
  validationMsg,
  setError,
  className,
  backClick,
  trigger,
  getValues,
  formSubmit,
  // setQuest,
}) => {
  const { emailValidation } = useEmailValidation();
  const { phoneValidation } = usePhoneValidation();
  const ques = useRef();
  const [disabled, setDisabled] = useState(false);
  const [showModal, setShowModal] = useState(true);
  const [claimModal, setshowClaimModal] = useState(false);
  const handleCloseclaimModal = () => setshowClaimModal(false);
  const [bankrupcheck, setBankrupcheck] = useState("no");
  const history = useHistory();
  const { visitorParameters } = useContext(VisitorParamsContext);

  const validateSlide4 = async (e) => {
    e.preventDefault();

    if (!showModal) {
      setshowClaimModal(true);
    } else {
      setDisabled(true);
      const values = getValues();
      var emailVal = await trigger("txtEmail");
      var phoneVal;
      if (emailVal) {
        phoneVal = await trigger("txtPhone");
      } else {
        setDisabled(false);
        document.getElementById("txtEmail").focus();
        return false;
      }

      if (emailVal && phoneVal) {
        setDisabled(true);
        if (
          (await emailValidCheck()) === 1 &&
          (await phoneValidCheck()) === 1
        ) {
          formSubmit();
        } else {
          setDisabled(false);
        }
      } else {
        setDisabled(false);
      }
    }
  };
  const phoneValidCheck = async () => {
    const values = getValues();
    const phone = values["txtPhone"];
    const email = values["txtEmail"];

    if (phone !== "" && email !== "") {
      document.getElementById("formSubmitBtn").classList.add("anim_ylw");
    } else {
      document.getElementById("formSubmitBtn").classList.remove("anim_ylw");
    }
    if (phone !== "") {
      const getPhoneValidation = await phoneValidation(phone);
      if (getPhoneValidation.data.status_code === 0) {
        setError("txtPhone", {
          type: "manual",
          message: "Please Enter Valid Working Phone Number",
        });
        return 0;
      } else if (getPhoneValidation.data.status_code === 2) {
        setError("txtPhone", {
          type: "manual",
          message: "Phone Number Already Exist",
        });
        return 0;
      } else if (getPhoneValidation.data.status_code === 3) {
        setError("txtPhone", {
          type: "manual",
          message: "Please Enter Valid Phone Number",
        });
        return 0;
      } else {
        return 1;
      }
    }
  };
  useEffect(() => {}, [validationMsg]);
  const emailValidCheck = async () => {
    const values = getValues();
    const email = values["txtEmail"];
    const phone = values["txtPhone"];
    if (phone !== "" && email !== "") {
      document.getElementById("formSubmitBtn").classList.add("anim_ylw");
    } else {
      document.getElementById("formSubmitBtn").classList.remove("anim_ylw");
    }
    if (email !== "") {
      const getEmailValidation = await emailValidation(email);
      if (getEmailValidation.data.status_code === 1) {
        return 1;
      } else {
        setError("txtEmail", {
          type: "manual",
          message: getEmailValidation.data.message,
        });
      }
    }
  };

  return (
    <>
      <div id="slide14" className={`slidetop_adjust ${className} flex`}>
        <ProgressBar value="95" />
        <h3 className="text-center">What's the best way to communicate?</h3>
        <div className="input-section mb-3">
          <div className="input-group">
            <Email
              name="txtEmail"
              className="form-control"
              id="txtEmail"
              placeholder="Email Address"
              onBlur={emailValidCheck}
              validation={validation({
                required: "Please enter a valid Email Address",
                // pattern: {
                //   value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                //   message: "Invalid Email Address",
                // },
              })}
            />
            <span className="input-group-text privacyico">
              <img src={privacy} alt="" />
            </span>
          </div>
          {validationMsg.txtEmail && (
            <span className="error_msg " id="email_err">
              {validationMsg.txtEmail.message}
            </span>
          )}
        </div>
        <div className="input-group mb-3">
          <Telephone
            name="txtPhone"
            id="txtphone"
            className="form-control"
            placeholder="Phone Number"
            onBlur={phoneValidCheck}
            onKeyPress={(e) => !/[0-9]+$/.test(e.key) && e.preventDefault()}
            maxlength="11"
            validation={validation({
              pattern: {
                value: /^[0-9]*$/i,
                message: "Please enter a valid Phone Number",
              },
              required: "Please enter a valid Phone Number",
            })}
          />
          <span className="input-group-text privacyico">
            <img src={privacy} alt="" />
          </span>
          {validationMsg.txtPhone && (
            <span className="error_msg " id="phone_err">
              {validationMsg.txtPhone && "Please enter a valid Phone Number"}
            </span>
          )}
        </div>
        <div className="col-lg-12 col-12">
          <InputButton
            name="formSubmitBtn"
            className="btn option_but next10"
            id="formSubmitBtn"
            value="SUBMIT"
            btnType="submit"
            style={{ float: "none" }}
            disabled={disabled}
            onClick={validateSlide4}
          />
          <div className="d-flex align-items-start check-field">
            <input
              type="checkbox"
              ref={ques}
              className="me-1 mt-1"
              value="Yes"
              name="bankruptcy"
              defaultChecked={true}
              onChange={(e) => {
                e.target.checked ? setShowModal(true) : setShowModal(false);
              }}
              id="bankruptcy"
            />
            <label htmlFor="bankruptcy">
              {" "}
              I confirm that I am not entitled for bankruptcy and have not filed
              any claims in the past.
            </label>
          </div>

          {/* **** cannot accept your claim popup start **** */}
          <Modal
            size="md"
            show={claimModal}
            onHide={handleCloseclaimModal}
            centered
            backdrop="static"
            className="claimdeinial"
          >
            <Modal.Body>
              <h3 className="text-center">
                Sorry, we cannot accept your claim
              </h3>
              <div className="input-group mb-3 prevname-Btnpop justify-content-center">
                <InputButton
                  className="btn next08  regNextBtnNew text-center"
                  name="modalBack"
                  value="Back"
                  btnType="button"
                  onClick={handleCloseclaimModal}
                />
              </div>
            </Modal.Body>
          </Modal>
        </div>
        <p className="text-center">
          <a className="back01" name="back12" onClick={backClick}>
            {" "}
            {"<< Previous"}{" "}
          </a>
        </p>
      </div>
      <div className="col-lg-12 text-center slide-logo pt-3">
        <img src={ssl} alt="" />
      </div>
      
    </>
  );
};
export default ContactDetails;
