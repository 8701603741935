import React, {
  useState,
  useReducer,
  useRef,
  useContext,
  useEffect,
} from "react";
import PersonalDetails from "../Includes/Layouts/VAQ_UB_4_3_1_C/PersonalDetails";
import { FormProvider, useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { useDataIngestion } from "../../Hooks/useDataIngestion";
import QueryStringContext from "../../Contexts/QueryString";
import { blacklist } from "../../Hooks/blacklist";
import GtmDataLayer from "../../Utility/GtmDataLayer";
import VisitorParamsContext from "../../Contexts/VisitorParams";
import * as EnvConstants from "../../Constants/EnvConstants";
import { useSignature } from "../../Hooks/useSignature";
import { useClaimReg } from "../../Hooks/useClaimReg";

import secureSSL from "../../assets/VAQ_UB_4_3_1_C/img/mobtick.png";
import { useUpdateMilestone } from "../../Hooks/useUpdateMilestone";
// import reviewImg from '../../assets/VAQ_UB_4_3/img/review-star.png';


const FormVAQ_UB_4_3_1_C = () => {
  // const { register, errors, trigger, watch, setError, clearErrors, getValues, handleSubmit} =
  //   useForm({
  //     mode: "onBlur",
  //     reValidateMode: "onChange",
  //     defaultValues: {},
  //     resolver: undefined,
  //     context: undefined,
  //     criteriaMode: "firstError",
  //     shouldFocusError: true,
  //     shouldUnregister: true,
  //   });
  const formMethods = useForm({
    mode: "onBlur",
    reValidateMode: "onChange",
    defaultValues: {},
    resolver: undefined,
    context: undefined,
    criteriaMode: "firstError",
    shouldFocusError: true,
    shouldUnregister: true,
  });
  const {
    register,
    errors,
    trigger,
    setError,
    clearErrors,
    getValues,
    watch,
    handleSubmit,
  } = formMethods;

  const {updateMilestone} = useUpdateMilestone();
  const { visitorParameters } = useContext(VisitorParamsContext);
  const formdata = JSON.parse(localStorage.getItem("formData"));
  const [previousAddressData, setPreviousAddressData] = useState({});
  const formParameters = JSON.parse(localStorage.getItem("formData"));
  const { saveSignature } = useSignature();
  const [signatureDataURL, setSignatureDataURL] = useState('');
  const history = useHistory();
  const { updateClaimReg } = useClaimReg();
  const splitForm = useRef(null);
  const signPadRef = useRef(null);
  const [divShow, setDivShow] = useState('');
  const [blacklisted, setBlacklisted] = useState([]);
  const field = "pid";
  const { getBlacklist } = blacklist();
  const { saveDataIngestion, formDataIngestion, isLoading } =
    useDataIngestion();
  const { queryString } = useContext(QueryStringContext);
  useEffect(() => {
    let responsesss = [];
    (async () => {
      const response = await getBlacklist();
      let dataLength = response.data.response.length;
      responsesss = response.data.response;
      if (dataLength > 0) {
        setBlacklisted(response.data.response);
      }
    })();
  }, []);


  useEffect(() => {
    if (signatureDataURL !== '') {
      setSignatureDataURL(signatureDataURL);
    }
  }, [signatureDataURL]);
  const form = splitForm.current;
  const values = getValues();
  const formData = values;

  const formSubmit = async () => {

    if (formData.address1 !== "") {
      const txtUdprn = form["txtUdprn"].value;
      const txtDeliveryPointSuffix = form["txtDeliveryPointSuffix"].value;
      const txtPz_mailsort = form["txtPz_mailsort"].value;
      const txtStreet = form["txtStreet"].value;
      const txtAddress3 = form["txtAddress3"].value;
      const txtDistrict = form["txtDistrict"].value;

      formData.txtDistrict = txtDistrict;
      formData.txtAddress3 = txtAddress3;
      formData.txtUdprn = txtUdprn;
      formData.txtDeliveryPointSuffix = txtDeliveryPointSuffix;
      formData.txtPz_mailsort = txtPz_mailsort;
      formData.txtStreet = txtStreet;
    }

    // //***previous_address ****/
    // formData.previous_postcode = previousAddressData.postcode;
    // formData.previous_address_line1 = previousAddressData.line_1;
    // formData.previous_address_line2 = previousAddressData.line_2;
    // formData.previous_address_line3 = previousAddressData.line_3;
    // formData.previous_address_county = previousAddressData.county;
    // formData.previous_address_town = previousAddressData.town;
    // formData.previous_address_country = previousAddressData.country;
    // formData.previous_district = previousAddressData.district;
    // formData.previous_address_id = previousAddressData.prevAddressId;

    if (form["addressprev1"].value) {
      formData.previous_postcode = form["txtPrevPostCode"].value.toUpperCase();
      formData.previous_address_line1 = form["txtHouseNumberPrev"].value;
      formData.previous_address_line2 = form["txtAddress3prev"].value;
      formData.previous_address_line3 = form["txtAddress3Previous"]?.value;
      formData.previous_address_county = form["txtCountyprev"].value;
      formData.previous_address_town = form["txtTownprev"].value;
      formData.previous_address_country = form["txtCountryprev"].value;
      formData.previous_district = form["txtDistrictPrev"].value;
      formData.previous_address_id = form["addressprev1"].value;
    }

    formData.user_sign = signatureDataURL;
    formData.Previousname = form["txtPreName"].value;
    formData.question_62 = form["productclaim"].value;
    localStorage.setItem("ProductType", formData.question_62);
    formData.page_name = "VAQ_UB_4_3_1_C";
    formData.pCode_manual = form["pCode_EntryType"].value;
    if (
      visitorParameters.data !== null ||
      visitorParameters.data !== undefined
    ) {
      // let dobMonth = formData.DobMonth ? MonthNameArray[formData.DobMonth] : '';
      localStorage.setItem("fName", formData.txtFName);
      localStorage.setItem("lName", formData.txtLName);
      localStorage.setItem("pCode", formData.txtPostCode);
      localStorage.setItem("addressLine1", formData.txtHouseNumber);
      localStorage.setItem("dobYr", formData.DobYear);
      localStorage.setItem("dobMon", formData.DobMonth);
      localStorage.setItem("dobDy", formData.DobDay);
      localStorage.setItem("addressLine2", formData.txtAddress2);
      localStorage.setItem("postTown", formData.txtTown);
      localStorage.setItem("country", formData.txtCounty);
      localStorage.setItem("title", formData.lstSalutation);
      localStorage.setItem("addressLine3", formData.txtAddress3);
      localStorage.setItem("county", formData.txtCounty);
      localStorage.setItem(
        "district",
        formData.txtDistrict ? formData.txtDistrict : ""
      );
      let UcFname =
        formData.txtFName.charAt(0).toUpperCase() + formData.txtFName.slice(1);

      localStorage.setItem("UcFname", UcFname);

      localStorage.setItem("formData", JSON.stringify(formData));
      localStorage.setItem("queryString", queryString);

      if (signatureDataURL) {
        const formSUbmitResult = formDataIngestion(
          visitorParameters.visitor_parameters,
          formData,
          "split_form_submit",
          "VAQ_UB_4_3_1_C",
          queryString,
          visitorParameters.data
        );
        const message_type = "signature_store";
        const response = await saveSignature(
          signatureDataURL,
          visitorParameters.visitor_parameters,
          formData,
          visitorParameters.data,
          queryString,
          message_type
        );
        if (response.data.status === "Success") {
          updateMilestone(visitorParameters.visitor_parameters.uuid,"lead","live");
          updateMilestone(visitorParameters.visitor_parameters.uuid,"signature","live");
          pageChange();
        }
      }
    } else {
      return "Fail";
    }
  };
  const pageChange = () => {

    let blacklistFlag = false;
    blacklisted.map((value, index) => {
      if ("email" == value.type && value.value == formData.txtEmail) {
        blacklistFlag = true;
      }

      if ("phone" == value.type && value.value == formData.txtPhone) {
        blacklistFlag = true;
      }
    });

    if (blacklistFlag) {
      window.scrollTo(0, 0);
      history.push(
        "/thankyou-bl?uuid=" + visitorParameters.visitor_parameters.uuid
      );
    } else {
      if (queryString != null) {
        if (queryString.indexOf("&" + field + "=") !== -1) {
          if (EnvConstants.AppConversionPixel === "true") {
            history.push("/fbpixel?split_name=VAQ_UB_4_3_1_C");
          }
          else {
            window.scrollTo(0, 0);

            history.push(
              "/questionnaireV30?uuid=" + visitorParameters.visitor_parameters.uuid
            );
          }
        }
        else {
          window.scrollTo(0, 0);
          history.push(
            "/questionnaireV30?uuid=" + visitorParameters.visitor_parameters.uuid
          );
        }
      } else {
        window.scrollTo(0, 0);
        history.push(
          "/questionnaireV30?uuid=" + visitorParameters.visitor_parameters.uuid
        );
      }
    }
  }

  const getUuid = () => {
    const visitorData = visitorParameters.visitor_parameters;
    if (visitorData != "") {
      var uuid = visitorData.uuid;
    } else {
      const query = new URLSearchParams(window.location.search);
      const query_uuid = query.get("uuid");
      const local_storage_uuid = localStorage.getItem("uuid");
      var uuid = query_uuid ? query_uuid : local_storage_uuid;
    }
    return uuid;
  };
  const uuid = getUuid();
  const slideChange = async () => {
    const claimReg = await updateClaimReg(
      uuid,
      "VAQ_UB_4_3_1_C",
      "register_claim"
    );
    if (claimReg.data.status == "Success") {
      GtmDataLayer({
        'question': 'register claim'
      });
      setDivShow('is-open')
    }
  };


  return (
    <>
      <FormProvider {...formMethods}>
        <form
          ref={splitForm}
          name="split_form"
          id="user_form"
          method="POST"
          autoComplete="off"
        >
          <section className="banner">
            <div className="container">
              <div>
                <div className="brand">
                  <div className="logo pt-3">
                    <a href="index.html">
                      {" "}
                      <img
                        src="../../assets/VAQ_UB_4_3/img/logo-white.png"
                        height={60}
                      />
                    </a>
                  </div>
                </div>
              </div>
              <div className="container-xxl">
                <div className="banner-inner">
                  <div className="row">
                    <div className="col-lg-7 col-md-7">
                      <div className="banner-caption">
                        <div className="overflow-hidden">
                          <h1 className="fw-bold  drop-in tex-lg-start text-md-start text-sm-center text-center">
                            Taken Out Repeated High-Cost Credit from Vanquis?
                          </h1>
                        </div>

                        <div className="hilighttext-homepage drop-in">

                          <p className="drop-in">Our Average Refund for Vanquis as of 20/08/2024 is <b>£2,200</b></p>
                        </div>

                        <div className="overflow-hidden">
                          <p className="py-lg-3 py-md-3 py-sm-3 py-1 drop-in-2 subtext tex-lg-start text-md-start text-sm-center text-center">
                          If you found the credit to be unaffordable and struggled to make your repayments, you could make a claim to see if you are owed a refund of any interest and charges.
                          </p>
                        </div>
                        
                        <div className="overflow-hidden tex-lg-start text-md-start text-sm-center text-center mt-lg-0 mt-md-0 mt-sm-0 mt-3">
                          <button
                            type="button"
                            className="btn-comm2  drop-in-3 widmob zoom"
                            id="claimNow"
                            onClick={slideChange}
                          >
                            <span>
                              Yes, Register My Claim{" "}
                              <i className="bi bi-caret-right-fill" />
                            </span>
                          </button>
                          <img className="drop-in-3 m-3 mt-2" src={secureSSL}
                            height={40} 
                            // width={120}
                            />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-5 col-md-5">
                      <div className="bannerimage-wraper tex-lg-start text-md-start text-sm-center text-center mt-lg-0 mt-md-0 mt-sm-0 mt-5">
                        <img
                          className="bannerimg fade-in"
                          src="../../assets/VAQ_UB_4_3/img/banner-img.png"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="welcome-highlights py-5">
            <div className="container">
              <div className="row">
                <div className="col-lg-4 col-md-4 col-sm-12 text-center mb-3">
                  <div className="px-lg-3 px-md-0 px-sm-0 px-0 items">
                    <img src="../../assets/VAQ_UB_4_3/img/icon01.svg" />
                    <h2 className="mt-3">
                      Get your Mis-sold credit card Fees and Charges back
                    </h2>
                    <p>
                      Many credit cards were not sold properly. You could claim back
                      the fees and charges plus 8% interest.
                    </p>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-12 text-center mb-3">
                  <div className="px-lg-3 px-md-0 px-sm-0 px-0 items">
                    <img src="../../assets/VAQ_UB_4_3/img/icon02.svg" />
                    <h2 className="mt-3">
                      Your Claim Fought by Financial &amp; Legal Experts
                    </h2>
                    <p>
                    We're ahead of the field, drawing on years of financial services and legal expertise for a strong claim.
                    </p>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-12 text-center mb-3">
                  <div className="px-lg-3 px-md-0 px-sm-0 px-0 items">
                    <img src="../../assets/VAQ_UB_4_3/img/icon03.svg" />
                    <h2 className="mt-3">
                      Respecting Your Information Security
                    </h2>
                    <p>
                    We take security very seriously and follow the rules of the Data Protection Act.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* <div
            className="modal fade"
            id="CredModal"
            data-bs-backdrop="static"
            data-bs-keyboard="false"
            tabIndex={-1}
            aria-labelledby="staticBackdropLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-dialog-centered credimodal">
              <div className="modal-content">
                <div className="modal-header py-3">
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  />
                </div>
                <div className="modal-body  creditpopup">
                  <div className="pb-3">
                    <div className="row align-items-center">
                      <div className="col-lg-12 col-md-12 col-sm-3 col-2 text-center">
                        <div className="mb-lg-3 mb-md-3 mb-sm-0 mb-0">
                          {" "}
                          <img
                            className="alertimg"
                            src="../../assets/VAQ_UB_4_3/img/alert.png"
                            height={50}
                          />
                        </div>
                      </div>
                      <div className="col-lg-12 col-md-12 col-sm-9 col-10">
                        <h6 className="  mb-0 text-lg-center text-md-center text-sm-start text-start px-lg-3 px-md-3 px-sm-0 px-0">
                          Credit check will only be accessible as the final
                          step.
                        </h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
          <PersonalDetails
            formSubmit={formSubmit}
            pageChange={pageChange}
            divShow={divShow}
            setPreviousAddressData={setPreviousAddressData}
            setSignatureDataURL={setSignatureDataURL}

          />
        </form>

      </FormProvider>
    </>
  );
};

export default FormVAQ_UB_4_3_1_C;
