import React, {
	useRef,
	useState,
	useEffect,
	useContext,
	useReducer,
} from "react";
import { useForm } from "react-hook-form";
import {
	questinnare1,
	questinnaire36VanquisV2,
	questinnaire76VanquisV2,
	answerArray,
	supportDocTypeArray
} from "../../Constants/Questions";
import {
	VeryLenderArray, MonthNumberArray
}
	from "../../Constants/Constants";
import { useQuestionnaire } from "../../Hooks/useQuestionnaire";
import { userInfo } from "../../Hooks/userInfo";
import VisitorParamsContext from "../../Contexts/VisitorParams";
import { ConsoleView } from "react-device-detect";
import { CheckUUID } from "../../Utility/CheckUUID";
import { useHistory } from "react-router-dom";
import { FormData } from "../../Utility/FormData";
import GetVisitorsParams from "../../Utility/GetVisitorsParams";
import "../../assets/css/QuestionnaireV11.scss";
import QuestionnaireLoder from "../Includes/Layouts/QuestionaireV11/QuestionnaireLoder";
import QuestionnaireFooter from "../Includes/Layouts/QuestionaireV11/QuestionnaireFooter";
import Questionnaire1 from "../Includes/Layouts/QuestionaireV11/Questionnaire1";
import Questionnaire2 from "../Includes/Layouts/QuestionaireV11/Questionnaire2";
import Questionnaire3 from "../Includes/Layouts/QuestionaireV11/Questionnaire3";
import Questionnaire4 from "../Includes/Layouts/QuestionaireV11/Questionnaire4";
import Questionnaire5 from "../Includes/Layouts/QuestionaireV11/Questionnaire5";
import GtmDataLayer from "../../Utility/GtmDataLayer";
import { useUserVerify } from "../../Hooks/useUserVerify";
import { useTotalCarCheck } from "../../Hooks/useTotalCarCheck";
import Questionnaire31 from "../Includes/Layouts/QuestionaireV11/Questionnaire31";
import Questionnaire32 from "../Includes/Layouts/QuestionaireV11/Questionnaire32";
import Questionnaire45 from "../Includes/Layouts/QuestionaireV11/Questionnaire45";
import Questionnaire46 from "../Includes/Layouts/QuestionaireV11/Questionnaire46";
import QuestionnaireEmail from "../Includes/Layouts/QuestionaireV11/QuestionnaireEmail";
import { supportingdoc } from "../../Hooks/supportingdoc";
import Questionnaire4Model from "../Includes/Layouts/QuestionaireV11/Questionnaire4Modal";
import Questionnaire51 from "../Includes/Layouts/QuestionaireV11/Questionnaire51";
import QuestionnaireSupportDoc from "../Includes/Layouts/QuestionaireV11/QuestionnaireSupportDoc";
import QuestionnaireAgreementNoModal from "../Includes/Layouts/QuestionaireV11/QuestionnaireAgreementNoModal";
import { useSkippedLeads } from "../../Hooks/useSkippedLeads";
import { userMilestoneUpdateV2 } from "../../Hooks/userMilestoneUpdateV2";
import Questionnaire76 from "../Includes/Layouts/QuestionaireV11/Questionnaire76";
import Questionnaire75 from "../Includes/Layouts/QuestionaireV11/Questionnaire75";

const initialState = {
	showSlide1: "show",
	showSlide2: "hide",
	showSlide3: "hide",
	showSlide4: "hide",
	showSlide4a: "hide",
	showSlide4b: "hide",
	showSlide5: "hide",
	showSlide51: "hide",
	showSlide5a: "hide",
	showSlide6: "hide",
	showSlide7: "hide",
	showSlide8: "hide",
	showModel1: "hide",
	showModel2: "hide",
	showSlide9: "hide",
};

const FormReducer = (state, action) => {
	switch (action.type) {
		case "showSlide": {
			if (action.payload.clickedSlide.slide == "btn_qest_10a") {
				// GtmDataLayer({
				//   'question': 'Closed year'
				// });
				return {
					...state,
					showSlide1: "hide",
					showSlide2: "show",
				};
			}
			else if (action.payload.clickedSlide.slide == "btn_qest_10") {
				GtmDataLayer({
					'question': 'Closed year'
				});
				return {
					...state,
					showSlide1: "hide",
					showSlide2: "show",
				};
			} else if (action.payload.clickedSlide.slide == "question_53") {
				GtmDataLayer({
					'question': 'highest balance'
				});
				return {
					...state,
					showSlide2: "hide",
					showSlide3: "show",
				};
			} else if (action.payload.clickedSlide.slide == "question_54") {
				GtmDataLayer({
					'question': 'how many times increased'
				});
				return {
					...state,
					showSlide3: "hide",
					showSlide4: "show",
				};
			}
			// else if (action.payload.clickedSlide.slide == "question_43a") {
			//   GtmDataLayer({
			//     'question': 'your vehicle?'
			//   });
			//   return {
			//     ...state,
			//     showSlide4: "hide",
			//     showSlide4a: "show",
			//   };
			// }

			else if (action.payload.clickedSlide.slide == "question_55") {
				GtmDataLayer({
					'question': 'close or exceed'
				});
				return {
					...state,
					// showSlide1: "hide",
					showSlide4: "hide",
					showSlide4a: "show",
				};
			}
			else if (action.payload.clickedSlide.slide == "question_56") {
				GtmDataLayer({
					'question': 'minimum payment due'
				});
				return {
					...state,
					// showSlide1: "hide",
					showSlide4a: "hide",
					showSlide4b: "show",
				};
			}
			else if (action.payload.clickedSlide.slide == "question_32") {
				// GtmDataLayer({
				//   'question':
				// });
				return {
					...state,
					showSlide4a: "hide",
					showSlide4b: "show",
				};
			}
			else if (action.payload.clickedSlide.slide == "question_75") {
				// GtmDataLayer({
				//   'question':
				// });
				return {
					...state,
					showSlide4b: "hide",
					showSlide5: "show",
				};
			}
			else if (action.payload.clickedSlide.slide == "question_75a") {
				// GtmDataLayer({
				//   'question':
				// });
				return {
					...state,
					showSlide4b: "hide",
					showSlide51: "show",
				};
			}
			else if (action.payload.clickedSlide.slide == "slide_4") {
				GtmDataLayer({
					'question': 'Income details'
				});
				return {
					...state,
					showSlide1: "hide",
					showSlide5: "hide",
					showSlide51: "show",
				};
			}
			else if (action.payload.clickedSlide.slide == "question_51a") {
				GtmDataLayer({
					'question': 'reg. email'
				});
				return {
					...state,
					showSlide51: "hide",
					showSlide5a: "show",
				};
			}
			else if (action.payload.clickedSlide.slide == "question_61") {
				GtmDataLayer({
					'question': 'reg. email'
				});
				return {
					...state,
					showSlide51: "hide",
					showSlide7: "show",
				};
			}
			else if (action.payload.clickedSlide.slide == "btn_qest_48") {
				GtmDataLayer({
					'question': 'further details'
				});
				return {
					...state,
					showSlide5a: "hide",
					showSlide7: "show",
				};
			}

			//   //Further details to be considered
			//   else if (action.payload.clickedSlide.slide == "btn_qest_36") {
			// 	GtmDataLayer({
			// 	  'question': 'support doc'
			// 	});
			// 	return {
			// 	  ...state,
			// 	  showSlide5a: "hide",
			// 	  showSlide6: "hide",
			// 	  showSlide9:"show",
			// 	};
			//   }   
			else if (action.payload.clickedSlide.slide == "question_45" || action.payload.clickedSlide.slide == "question_42_none") {
				// GtmDataLayer({
				//   'question': 'further details'
				// });
				return {
					...state,
					showSlide7: "hide",
					showSlide9: "show",
				};
			}
			else if (action.payload.clickedSlide.slide == "question_42") {
				// GtmDataLayer({
				//   'question': 'further details'
				// });
				return {
					...state,
					showSlide9: "hide",
					showSlide8: "show",
				};
			}
			else if (action.payload.clickedSlide.slide == "support_doc") {
				// GtmDataLayer({
				//   'question': 'further details'
				// });
				return {
					...state,
					showSlide8: "hide",
					showSlide5a: "show",
				};
			}
			//Further details to be considered
			else if (action.payload.clickedSlide.slide == "btn_qest_36") {
				GtmDataLayer({
					'question': 'minimum payment due'
				});
				return {
					...state,
					showSlide6: "hide",
					showSlide9: "show",
				};
			}
			else if (action.payload.clickedSlide.slide == "btn_qest_76") {
				return {
					...state,
					showSlide7: "hide",
					showSlide6: "show",
				};
			}


			else {
				return {
					...state,
				};
			}
		}
		case "showModel": {


			if (action.payload.clickedModel.model == "monthly" && action.payload.clickedModel.status == "hide") {
				return {
					...state,
					showModel1: "hide",
				};
			} else if (action.payload.clickedModel.model == "monthly" && action.payload.clickedModel.status == "show") {
				return {
					...state,
					showModel1: "show",
				};
			} else if (action.payload.clickedModel.model == "other" && action.payload.clickedModel.status == "hide") {
				return {
					...state,
					showModel1: "hide",
					showModel2: "hide",
				};
			} else if (action.payload.clickedModel.model == "other" && action.payload.clickedModel.status == "show") {
				return {
					...state,
					showModel2: "show",
				};
			}
		}
		default:
			return state;
	}
};

const QuestionnaireV11 = () => {
	const { isCheck } = CheckUUID();
	const { getUserVerfyValidation, saveUserVerify } = useUserVerify();
	isCheck();
	const history = useHistory();
	const [completedQuestArr, setCompletedQuestArr] = useState([]);
	const { isLoading, SaveQuestionnaire } = useQuestionnaire();
	const { visitorParameters } = useContext(VisitorParamsContext);
	const [checkboxValues, SetCheckboxValues] = useState([]);
	const [displayError, SetDisplayError] = useState("d-none");
	const [modalSub1Ans, setModalSub1Ans] = useState(null);
	const [modalSub2Ans, setModalSub2Ans] = useState(null);
	const query_string = localStorage.getItem("querystring");
	const formdata = JSON.parse(localStorage.getItem("formData"));
	const [checkQuestion, setCheckQuestion] = useState(0);
	const [percentage, SetPercentage] = useState(0);
	const [lastClickQuest, setLastClickQuest] = useState(null);

	const scrollRef = useRef([]);
	const splitForm = useRef(null);
	const [state, dispatch] = useReducer(FormReducer, initialState);

	const [splitName, setSplitName] = useState();
	const [splitNameForSkip, setSplitNameForSkip] = useState();
	const { getUserInfo } = userInfo();
	const { setFormData } = FormData();
	const [showLoad, setShowLoad] = useState("show");
	const [showUserName, setUserName] = useState();
	const [vehicle, setVehicle] = useState("");
	const [totalCarCheck, settotalCarCheck] = useState("");
	const [clickedSlide, setClickedSlide] = useState([]);
	const [validKeeperDate, setValidKeeperDate] = useState("");
	const [optionSelected] = useState([]);
	const [t2aApiReqData, setT2aApiReqData] = useState({});
	const { totalCarCheckApi } = useTotalCarCheck();
	let [totalQuestArr, setTotalQuestArr] = useState([10,11,12,75,36,45,46,76]);
	const { getSupportingDoc } = supportingdoc();
	const [supportDocType, setSupportDocType] = useState("");
	const [supportDoc, setSupportDoc] = useState("");
	const [docType, setDocType] = useState("");
	const [accClsYear, setAccClsYear] = useState([]);
	const [veryLenderN, setveryLenderN] = useState("");
	const { skippedLeads } = useSkippedLeads();
	const [optionSelected76] = useState([]);

	const [monthlyExpShow, setMonthlyExpShow] = useState("hide");
	const [otherExpShow, setOtherExpShow] = useState("hide");
	const [clickedModel, setClickedModel] = useState([]);
	const [userEmail, setUserEmail] = useState();
	const [agNoShow, setAgNoShow] = useState("hide");
	const [t2a, setT2a] = useState();
	const { updateMilestoneV2 } = userMilestoneUpdateV2();


	const { register, errors, trigger, setError, clearErrors, getValues } =
		useForm({
			mode: "onBlur",
			reValidateMode: "onChange",
			defaultValues: {},
			resolver: undefined,
			context: undefined,
			criteriaMode: "firstError",
			shouldFocusError: true,
			shouldUnregister: true,
		});
	const getUuid = () => {
		const visitorData = visitorParameters.visitor_parameters;
		if (visitorData != "") {
			var uuid = visitorData.uuid;
		} else {
			const query = new URLSearchParams(window.location.search);
			const query_uuid = query.get("uuid");
			const local_storage_uuid = localStorage.getItem("uuid");
			var uuid = query_uuid ? query_uuid : local_storage_uuid;
		}
		return uuid;
	};
	const uuid = getUuid();
	const saveVehicleDetails = (vehicleData) => {
		setVehicle(vehicleData);
	};
	const savetotalCarCheck = (totalCarCheck) => {
		settotalCarCheck(totalCarCheck);
	};

	useEffect(() => {
		if (formdata === undefined || formdata === null || formdata == "") {
			(async () => {
				const response = await getUserInfo(uuid);
				const set_formdata = setFormData(response);
				let dataLength = response.data.response.length;
				if (dataLength > 0) {
					const pageName = response.data.response[0].page_name
						? response.data.response[0].page_name
						: " ";
					setSplitName(pageName);
					if (localStorage.getItem("split_name") != undefined && localStorage.getItem("split_name") != undefined) {
						setSplitNameForSkip(localStorage.getItem("split_name"));
					} else {
						setSplitNameForSkip(pageName);
					}
					const userName = response.data.response[0].first_name
						? response.data.response[0].first_name +
						" " +
						response.data.response[0].last_name
						: " ";
					var userDobN = response.data.response[0].user_dob.split('/');
					var DobDayF = userDobN[0];
					var DobMonthF = userDobN[1];
					var DobYearF = userDobN[2];

					setT2aApiReqData({
						uuid: uuid,
						fName: response.data.response[0].first_name,
						lName: response.data.response[0].last_name,
						pCode: response.data.response[0].postcode,
						addressLine1: response.data.response[0].address_line1,
						dob_y: DobYearF,
						dob_d: DobDayF,
						dob_m: DobMonthF,
						addressLine2: response.data.response[0].address_line2,
						postTown: response.data.response[0].town,
						country: response.data.response[0].county,
						title: response.data.response[0].title,
						addressLine3: response.data.response[0].address_line3,
						addressLine4: response.data.response[0].address_line4,
						county: response.data.response[0].county
					});

					setSupportDocType(response.data.response[0]?.questionnaire[42]['answerId']);

					setUserName(userName);
					setUserEmail(response.data.response[0].email);
					const timer = setTimeout(() => {
						setShowLoad("hide");
					}, 1000);
				}
			})();
		} else {
			const pageName = formdata.page_name;
			setSplitName(pageName);
			if (localStorage.getItem("split_name") != undefined && localStorage.getItem("split_name") != undefined) {
				setSplitNameForSkip(localStorage.getItem("split_name"));
			} else {
				setSplitNameForSkip(pageName);
			}
			const userName = formdata.txtFName + " " + formdata.txtLName;
			setUserName(userName);
			const dobMonth = formdata.DobMonth ? MonthNumberArray[formdata.DobMonth] : '';
			setT2aApiReqData({
				uuid: uuid,
				fName: formdata.txtFName,
				lName: formdata.txtLName,
				pCode: formdata.txtPostCode,
				addressLine1: formdata.txtHouseNumber,
				dob_y: formdata.DobYear,
				dob_m: dobMonth,
				dob_d: formdata.DobDay,
				addressLine2: formdata.txtAddress2,
				postTown: formdata.txtTown,
				country: formdata.txtCountry,
				title: formdata.lstSalutation,
				addressLine3: formdata.txtAddress3,
				addressLine4: formdata.txtAddress4 ? formdata.txtAddress4 : '',
				county: formdata.txtCounty
			});
			const timer = setTimeout(() => {
				setShowLoad("hide");
			}, 1000);
			setSupportDocType(formdata.question_42);
			setSupportDoc(answerArray[formdata.question_42]);
			setDocType(supportDocTypeArray[formdata.question_42]);
			setUserEmail(formdata.txtEmail);
		}

	}, [splitName]);

	useEffect(() => {
		const accOpenyr = formdata ? formdata.YearOfPossession : '2006';
		const accCloseYear = [{ label: "Select Year", value: "" }];
		const startAccYear = 2023;
		const endAccYear = accOpenyr;
		let aa = [];
		for (var k = startAccYear; k >= endAccYear; k--) {
			let objAccYear = {};
			objAccYear["label"] = k;
			objAccYear["value"] = k;
			//setAccClsYear([...accClsYear,objAccYear]);
			aa.push(objAccYear);
			// accCloseYear.push(objAccYear);
		}
		setAccClsYear([...accCloseYear, ...aa]);


		let very_lender_id = formdata.brandregister;
		let very_lender = very_lender_id ? VeryLenderArray[very_lender_id] : '';
		setveryLenderN(very_lender);

		let still_active = formdata.stillactive;
		if (still_active == '95') {
			setClickedSlide({ slide: 'btn_qest_10a' });
		}

		// setClickedSlide({ slide: 'question_43' });

	}, []);

	const onselectAnswer = async (e) => {
		let selectedAns = parseInt(e.target.getAttribute("data-ans-id"));
		let selectedQuest = parseInt(e.target.getAttribute("data-quest-id"));
		let anslabel = e.target.getAttribute("data-ans-label");
		const valuesreg = getValues();
		let validEmail = valuesreg["question_48"];

		if (e.target.name == 'question_43' && selectedAns == '121') {
			setTotalQuestArr([...totalQuestArr, 44]);
		}

		if (e.target.name == 'question_61' && selectedAns == '163') {
			setTotalQuestArr([...totalQuestArr, 48]);
		}
		if (e.target.name == 'question_75' && selectedAns == '213') {
			setTotalQuestArr([...totalQuestArr, 31]);
		}

		setCompletedQuestArr([...completedQuestArr, selectedQuest]);

		if (e.target.name == 'question_43' && selectedAns == '121') {
			setClickedSlide({ slide: "question_43a" });
			totalQuestArr.push(44);
		}
		else if (e.target.name == 'question_61' && selectedAns == '163') {
			setClickedSlide({ slide: "question_51a" });
			totalQuestArr.push(48);
		}
		else if (e.target.name == 'question_75' && selectedAns == '214') {
			setClickedSlide({ slide: "question_75a" });
		}
		else if (e.target.name == 'question_42') {
			if (selectedAns == '124') {
				setAgNoShow('show');
			}
			else if (selectedAns == '123') {
				redirectToPreview();
			}
			else {
				setClickedSlide({ slide: "question_42" });
			}
		}

		else {
			setClickedSlide({ slide: e.target.name });
		}


		let flage = false;
		let question_data = {};
		if (e.target.name == "btn_qest_36") {
			GtmDataLayer({
				'question': 'further details'
			});
			if (selectedQuest) {
				flage = true;
				question_data = {
					question_id: 36,
					option_id: null,
					answer_text: anslabel,
					input_answer: optionSelected,
				};
			}
			if (supportDocType && supportDocType == '123' || supportDocType == '124') {
				sett2aValidation();
			} else {
				setClickedSlide({ slide: 'btn_qest_36' });
				sett2aValidation();
			}

		} else if (e.target.name == "btn_qest_76") {
				
				if (selectedQuest) {
					flage = true;
					question_data = {
						question_id: 76,
						option_id: null,
						answer_text: anslabel,
						input_answer: optionSelected76,
					};
				
				setClickedSlide({ slide: 'btn_qest_76' });
				}
	
		} else if (e.target.name == "question_11") {
			if (selectedAns && selectedQuest) {
				flage = true;
				question_data = {
					question_id: selectedQuest,
					option_id: null,
					answer_text: "",
					input_answer: e.target.value,
					valid_keeper_date: e.target.id,
				};
			}
		} else if (e.target.name == "btn_qest_48") {
			if (selectedQuest) {
				flage = true;
				question_data = {
					question_id: 48,
					option_id: null,
					answer_text: null,
					input_answer: validEmail,
				};
			}
		} else if (e.target.name == "question_75") {
			if (selectedQuest) {
			   flage = true;
			   question_data = {
				   question_id: 75,
				   option_id: selectedAns,
				   answer_text: null,
				   input_answer: null,
			    };
		    }
	    }
		else {
			if (selectedAns && selectedQuest) {
				flage = true;
				question_data = {
					question_id: selectedQuest,
					option_id: selectedAns,
					answer_text: anslabel,
					input_answer: "",
				};
			}
		}

		if (flage) {
			storeQuestionnire(question_data);
			var existingEntries = JSON.parse(localStorage.getItem("questionData"));
			if (existingEntries == null) existingEntries = [];
			localStorage.setItem(
				"currentquestionData",
				JSON.stringify(question_data)
			);
			existingEntries.push(question_data);
			localStorage.setItem("questionData", JSON.stringify(existingEntries));
		}
	};

	const saveInputAns = async (e) => {
		let selectedQuest = parseInt(e.target.getAttribute("data-quest-id"));
		setCompletedQuestArr([...completedQuestArr, selectedQuest]);
		const valuesreg = getValues();
		let regPlate = valuesreg["question_44"];
		let financeAgremmentNo = valuesreg["question_45"];
		let accCloseyear = valuesreg["question_52"];
		let highestBalance = valuesreg["highest_balance"];
		let flage = false;
		let question_data = {};

		if (selectedQuest == '44' && regPlate) {
			setClickedSlide({ slide: "question_32" });
			flage = true;
			question_data = {
				question_id: selectedQuest,
				option_id: null,
				answer_text: "",
				input_answer: regPlate,
			};

		}
		else if (selectedQuest == '45' && financeAgremmentNo) {
			setAgNoShow('hide');
			// setClickedSlide({ slide: "question_45" });
			flage = true;
			question_data = {
				question_id: selectedQuest,
				option_id: null,
				answer_text: "",
				input_answer: financeAgremmentNo,
			};
		}
		else if (selectedQuest == '52' && accCloseyear) {
			setClickedSlide({ slide: "btn_qest_10" });
			flage = true;
			question_data = {
				question_id: selectedQuest,
				option_id: null,
				answer_text: "",
				input_answer: accCloseyear,
			};
		}
		else if (selectedQuest == '53' && highestBalance) {
			setClickedSlide({ slide: "question_53" });
			flage = true;
			question_data = {
				question_id: selectedQuest,
				option_id: null,
				answer_text: "",
				input_answer: highestBalance,
			};
		}

		if (flage) {
			storeQuestionnire(question_data);
			var existingEntries = JSON.parse(localStorage.getItem("questionData"));
			if (existingEntries == null) existingEntries = [];
			localStorage.setItem(
				"currentquestionData",
				JSON.stringify(question_data)
			);
			existingEntries.push(question_data);
			localStorage.setItem("questionData", JSON.stringify(existingEntries));
			if (selectedQuest == '45') {
				redirectToPreview();
			}
		}


	}

	const redirectToPreview = async () => {
		if (t2a == 1) {
			(async () => {
				const response = await updateMilestoneV2(
					visitorParameters.visitor_parameters.uuid,
					t2a
				);
			})();
		}
		history.push("/view_docs?uuid=" + visitorParameters.visitor_parameters.uuid);
	}

	const SkippedLeads = async (type) => {
		// type : SkipAgreementNo,SkipDoc
		const skippedLeadsResponse = await skippedLeads(type, uuid, splitName);
		if (skippedLeadsResponse.data.status == "Success") {
			redirectToPreview();
		}
	}



	const skipAgNo = async (e) => {
		setAgNoShow('hide');
		SkippedLeads("SkipAgreementNo");
		// redirectToPreview();
	}



	const saveSlide4 = async (e) => {
		let selectedAns = parseInt(e.target.getAttribute("data-ans-id"));
		let selectedQuest = parseInt(e.target.getAttribute("data-quest-id"));
		let anslabel = e.target.getAttribute("data-ans-label");

		setCompletedQuestArr([...completedQuestArr, selectedQuest]);

		var answer31 = splitForm.current["question_31"].value;
		var answer32 = splitForm.current["question_32"].value;
		var answer33 = splitForm.current["question_33"].value;
		// var answer34 = splitForm.current["question_34"].value;
		//   var answer35 = splitForm.current["question_35"].value;

		// let answer57 = splitForm.current["question_57"].value;
		// let answer58 = splitForm.current["question_58"].value;
		// let answer59 = splitForm.current["question_59"].value;
		// let answer60 = splitForm.current["question_60"].value;

		var slide_array = [
			["31", answer31],
			["32", answer32],
			["33", answer33],
			// ["34", answer34],
			// ["35", answer35],
			// ["57", answer57],
			// ["58", answer58]
		];
	
		var question_data = {
			slide_4: slide_array,
		};
		setClickedSlide({ slide: "slide_4" });
		storeQuestionnire(question_data);

		let _existingEntries = JSON.parse(localStorage.getItem("questionData"));
		if (_existingEntries == null) _existingEntries = [];
		_existingEntries.push(question_data);
		localStorage.setItem("questionData", JSON.stringify(_existingEntries));
	};

	useEffect(() => {

		var questIntersection = totalQuestArr.filter(
			(x) => !completedQuestArr.includes(x)
		);

		SetPercentage(
			Math.round((completedQuestArr.length * 100) / totalQuestArr.length)
		);

		let completeQuestCount = questIntersection.length;
		if (completeQuestCount == 1) {
			pushPageChange();
		}
	}, [completedQuestArr]);

	const handleCheckBoxChange = (e) => {
		SetDisplayError("d-none");
		let checkVal = e.target.value;
		if (e.target.checked == true) {
			SetCheckboxValues([...checkboxValues, checkVal]);
		} else {
			SetCheckboxValues(checkboxValues.filter((e) => e !== checkVal));
		}
	};
	const onModalClick = (e) => {
		let selectedAns = e.target.getAttribute("data-ans-id");
		let selectedQuest = e.target.getAttribute("data-quest-id");
		let selectedAnsTxt = e.target.getAttribute("data-ans-label");
		if (selectedQuest === "sub1") {
			setModalSub1Ans(selectedAns);
		} else if (selectedQuest === "sub2") {
			setModalSub2Ans(selectedAns);
		}
	};
	const storeQuestionnire = async (question_data) => {
		const response = await SaveQuestionnaire(
			visitorParameters.visitor_parameters,
			question_data,
			"question_store",
			visitorParameters.data,
			query_string,
			formdata
		);
	};

	const storeT2aResponse = async (t2a_data) => {
		const response = await saveUserVerify(
			visitorParameters.visitor_parameters,
			t2a_data,
			"person_verify_store",
			visitorParameters.data,
			query_string,
			t2aApiReqData
		);
	};
	const pushPageChange = () => {

		//   if (validKeeperDate) {
		// 	(async () => {
		// 	  const totalCarCheckApiData = await totalCarCheckApi(
		// 		vehicle.reg_no,
		// 		visitorParameters.visitor_parameters.uuid,
		// 		splitName
		// 	  );
		// 	  savetotalCarCheck(totalCarCheckApiData.data.vehicleData);
		// 	  var lender = totalCarCheckApiData.data.vehicleData.finance_company;
		// 	  localStorage.setItem("lenderName", lender);
		// 	  if(lender != "Moneybarn" && lender != undefined && lender != null && lender != ""){
		// 		window.scrollTo(0, 0);
		// 		history.push("/unqualified?uuid=" + visitorParameters.visitor_parameters.uuid);
		// 	  }else{
		// 		sett2aValidation();
		// 	  }
		// 	})();
		//   }else{
		// 	sett2aValidation();
		//   }
	};
	const pushPageChangeV2 = () => {
		window.scrollTo(0, 0);
		history.push("/view_docs?uuid=" + visitorParameters.visitor_parameters.uuid);
	};

	const sett2aValidation = () => {
		const useruuid = t2aApiReqData.uuid;
		const fName = t2aApiReqData.fName;
		const lName = t2aApiReqData.lName;
		const pCode = t2aApiReqData.pCode;
		const addressLine1 = t2aApiReqData.addressLine1;
		const dobYr = t2aApiReqData.dob_y;
		const dobDy = t2aApiReqData.dob_d;
		const addressLine2 = t2aApiReqData.addressLine2;
		const postTown = t2aApiReqData.postTown;
		const country = t2aApiReqData.country;
		const title = t2aApiReqData.title;
		const addressLine3 = t2aApiReqData.addressLine3;
		const addressLine4 = t2aApiReqData.addressLine4;
		const county = t2aApiReqData.county;
		let dobMon;
		if (t2aApiReqData.dob_m === undefined) {
			dobMon = formdata.DobMonth;
		}
		else {
			dobMon = t2aApiReqData.dob_m;
		}

		if (useruuid && fName && lName && pCode && addressLine1 && dobYr && dobMon && dobDy) {
			(async () => {
				const response = await getUserVerfyValidation(
					useruuid,
					fName,
					lName,
					pCode,
					addressLine1,
					dobYr,
					dobMon,
					dobDy,
					addressLine2,
					postTown,
					country,
					title,
					addressLine3,
					addressLine4,
					county
				);

				if (response.data.status == "success") {
					localStorage.setItem("t2aValidation", 1);
					// window.scrollTo(0, 0);
					//   // history.push("/preview?uuid=" + visitorParameters.visitor_parameters.uuid);
					if (supportDocType && supportDocType == '124' || supportDocType == '123') {
						history.push("/view_docs?uuid=" + visitorParameters.visitor_parameters.uuid);
					}

				} else {
					localStorage.setItem("t2aValidation", 0);
					// window.scrollTo(0, 0);
					if (supportDocType && supportDocType == '124' || supportDocType == '123') {
						window.scrollTo(0, 0);
						history.push("/view_docs?uuid=" + visitorParameters.visitor_parameters.uuid);
					}
				}



				storeT2aResponse(response.data);
			})();


		}

	}


	const handleDownClick = () => {
		scrollRef.current[lastClickQuest + 2]?.scrollIntoView({
			behavior: "smooth",
			block: "center",
			inline: "center",
		});
		setLastClickQuest(lastClickQuest + 1);
	};
	const handleUpClick = () => {
		scrollRef.current[lastClickQuest]?.scrollIntoView({
			behavior: "smooth",
			block: "center",
			inline: "center",
		});
		setLastClickQuest(lastClickQuest - 1);
	};

	// const checkCarRegistration = (e) => {
	//   let selectedQuest = parseInt(e.target.getAttribute("data-quest-id"));
	//   // let selectedAns = parseInt(e.target.getAttribute("data-ans-id"));
	//   // let anslabel = e.target.getAttribute("data-ans-label");
	//   let selectedAns = null;
	//   let anslabel = splitForm.current["question_10"].value;
	//   setCompletedQuestArr([...completedQuestArr, selectedQuest]);

	//   if (selectedQuest) {
	//     let question_data = {
	//       question_id: selectedQuest,
	//       option_id: selectedAns,
	//       answer_text: anslabel,
	//       input_answer: anslabel,
	//     };
	//     storeQuestionnire(question_data);
	//     var existingEntries = JSON.parse(localStorage.getItem("questionData"));
	//     if (existingEntries == null) existingEntries = [];
	//     localStorage.setItem(
	//       "currentquestionData",
	//       JSON.stringify(question_data)
	//     );
	//     existingEntries.push(question_data);
	//     localStorage.setItem("questionData", JSON.stringify(existingEntries));
	//   }
	//   setClickedSlide({ slide: e.target.name });
	// };

	const pushUnqualified = () => {
		window.scrollTo(0, 0);
		history.push(
			"/unqualified?uuid=" + visitorParameters.visitor_parameters.uuid
		);
	};

	const slideSkip = () => {
		setClickedSlide({ slide: 'question_43' });
	};

	const handleChange = (e) => {
		// const re = /^[0-9\b]+$/;
		const re = /^[1-9]\d*$/;
		if (e.target.value !== "" && !re.test(e.target.value)) {
			e.target.value = e.target.value.replace(/\D/g, "") * 1;
		}
		if (e.target.value !== "") {
			e.target.value =
				"£" + e.target.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
		}
	};
	const totalDebitValidation = async (e) => {
		e.preventDefault();
		let errorFlag = 0;
		const values = getValues();

		let question31Result = await trigger("question_59");
		if (!question31Result) {
			errorFlag = 1;
			return false;
		}
		if (errorFlag == 0) {
			let question58Result = splitForm.current["question_58"].value;
			if (question58Result == '153') {
				// setMonthlyExpShow('hide');
				// setOtherExpShow('show');
				setClickedModel({ model: "monthly", status: "hide" });
				setClickedModel({ model: "other", status: "show" });
			} else {
				// setMonthlyExpShow('hide');
				setClickedModel({ model: "monthly", status: "hide" });
				saveSlide4(e);
			}
		}
	}

	const totalOtherDebitValidation = async (e) => {
		e.preventDefault();
		let errorFlag = 0;

		let question60Result = await trigger("question_60");
		if (!question60Result) {
			errorFlag = 1;
			return false;
		}
		if (errorFlag == 0) {
			// setOtherExpShow('hide');
			// setMonthlyExpShow('hide');
			setClickedModel({ model: "monthly", status: "hide" });
			setClickedModel({ model: "other", status: "hide" });
			saveSlide4(e);
		}
	}

	useEffect(() => {
		if (clickedModel) {
			dispatch({ type: "showModel", payload: { clickedModel } });
		}
	}, [clickedModel]);

	useEffect(() => {
		if (clickedSlide) {
			dispatch({ type: "showSlide", payload: { clickedSlide } });
		}
	}, [clickedSlide]);

	useEffect(() => {
		localStorage.removeItem('questionData');
	}, []);

	// useEffect(() => {
	//   (async () => {
	//     const response = await getSupportingDoc(uuid);
	//     let reponseData = response.data.response.data.support_doc_type ? response.data.response.data.support_doc_type: '';
	//     setSupportDocType(reponseData); 
	//   })();
	// }, []);

	return (
		<>
			<GetVisitorsParams />
			<div className="QuestionaireV11">
				<QuestionnaireLoder showUserName={showUserName} showLoad={showLoad} />

				<section
					className="formsection"
					style={
						showLoad === "hide" ? { display: "block" } : { display: "none " }
					}
				>
					<div className="container">
						<div className="row">
							<form
								ref={splitForm}
								name="split_form"
								id="user_form"
								method="POST"
								autoComplete="off"
							//onSubmit={handleSubmit}
							>
								<div className="offset-xl-3 col-xl-6 offset-lg-3 col-lg-6 offset-md-2 col-md-8 col-12">
									<div className="radio-box animated fadeInUp">
										<Questionnaire1
											clsName={`space-top ${state.showSlide1}`}
											//reff={(el) => (scrollRef.current[10] = el)}
											uuid={uuid}
											validation={register}
											validationMsg={errors}
											getValues={getValues}
											trigger={trigger}
											setError={setError}
											page_name={splitName}
											split_name={splitNameForSkip}
											slideSkip={slideSkip}
											saveInputAns={saveInputAns}
											accClsYear={accClsYear}
										/>

										<Questionnaire2
											clsName={`space-top ${state.showSlide2}`}
											reff={(el) => (scrollRef.current[11] = el)}
											page_name={splitName}
											uuid={visitorParameters.visitor_parameters.uuid}
											validation={register}
											validationMsg={errors}
											getValues={getValues}
											trigger={trigger}
											setError={setError}
											saveInputAns={saveInputAns}
											lender={veryLenderN}
										/>

										<Questionnaire3
											clsName={`space-top space-top2 ${state.showSlide3}`}
											reff={(el) => (scrollRef.current[12] = el)}
											onselectAnswer={onselectAnswer}
											vehicle={vehicle}
											validKeeperDate={validKeeperDate}
											pushUnqualified={pushUnqualified}
											savetotalCarCheck={savetotalCarCheck}
											uuid={uuid}
											page_name={splitName}
										/>

										<Questionnaire31
											clsName={`space-top space-top2 ${state.showSlide4}`}
											reff={(el) => (scrollRef.current[43] = el)}
											onselectAnswer={onselectAnswer}
										/>

										<Questionnaire32
											clsName={`space-top space-top2 ${state.showSlide4a}`}
											reff={(el) => (scrollRef.current[44] = el)}
											onselectAnswer={onselectAnswer}
										/>

  										<Questionnaire75
											clsName={`space-top2 ${state.showSlide4b}`}
											reff={(el) => (scrollRef.current[75] = el)}
											onselectAnswer={onselectAnswer}
											validation={register}
											validationMsg={errors}
											getValues={getValues}
											trigger={trigger}
											setError={setError}
										/>

										<Questionnaire4
											clsName={`space-top2 ${state.showSlide5}`}
											reff={(el) => (scrollRef.current[4] = el)}
											questArr={questinnare1}
											saveSlide4={saveSlide4}
											name="question_31_35"
											btnName="slide_4"
											validation={register}
											validationMsg={errors}
											getValues={getValues}
											trigger={trigger}
											setError={setError}
											splitForm={splitForm}
											setClickedModel={setClickedModel}
										/>

										<Questionnaire51
											clsName={`space-top2 ${state.showSlide51}`}
											reff={(el) => (scrollRef.current[41] = el)}
											onselectAnswer={onselectAnswer}
											userEmail={userEmail}
											validation={register}
											validationMsg={errors}
											getValues={getValues}
											trigger={trigger}
											setError={setError}
										/>

										<QuestionnaireEmail
											clsName={`space-top2 ${state.showSlide5a}`}
											reff={(el) => (scrollRef.current[45] = el)}
											validation={register}
											validationMsg={errors}
											getValues={getValues}
											trigger={trigger}
											setError={setError}
											onselectAnswer={onselectAnswer}
											lender={veryLenderN}
										/>

										<Questionnaire76
											clsName={`space-top ${state.showSlide7}`}
											reff={(el) => (scrollRef.current[76] = el)}
											questArr={questinnaire76VanquisV2}
											onselectAnswer={onselectAnswer}
											validation={register}
											validationMsg={errors}
											getValues={getValues}
											trigger={trigger}
											setError={setError}
											optionSelected76={optionSelected76}
										/>

										<Questionnaire5
											clsName={`space-top ${state.showSlide6}`}
											reff={(el) => (scrollRef.current[36] = el)}
											questArr={questinnaire36VanquisV2}
											onselectAnswer={onselectAnswer}
											validation={register}
											validationMsg={errors}
											getValues={getValues}
											trigger={trigger}
											setError={setError}
											optionSelected={optionSelected}
										/>


										{/* /support doc */}

										<Questionnaire46
											clsName={`space-top ${state.showSlide8}`}
											reff={(el) => (scrollRef.current[46] = el)}
											saveInputAns={saveInputAns}
											validation={register}
											validationMsg={errors}
											getValues={getValues}
											trigger={trigger}
											setError={setError}
											pushPageChange={pushPageChangeV2}
											docType={docType}
											split_name={splitNameForSkip}
											uuid={uuid}
										/>
										{/* supporting doc */}
										<QuestionnaireSupportDoc
											clsName={`space-top ${state.showSlide9}`}
											reff={(el) => (scrollRef.current[49] = el)}
											onselectAnswer={onselectAnswer}
											lender={veryLenderN}
										/>
										{/* supporting doc */}

										{/* Agrement No */}

										{/* Agrement No */}

										{/* <Questionnaire45
						clsName={`space-top ${state.showSlide7}`}
						reff={(el) => (scrollRef.current[47] = el)}
						saveInputAns={saveInputAns}
						validation={register}
						validationMsg={errors}
						getValues={getValues}
						trigger={trigger}
						setError={setError}
						pushPageChange={pushPageChange}
					  /> */}



									</div>
								</div>

								<QuestionnaireAgreementNoModal
									validation={register}
									validationMsg={errors}
									trigger={trigger}
									saveInputAns={saveInputAns}
									agNoShow={agNoShow}
									skipAgNo={skipAgNo}
								/>
								<Questionnaire4Model
									questArr={questinnare1}
									validation={register}
									validationMsg={errors}
									monthlyExpShow={monthlyExpShow}
									otherExpShow={otherExpShow}
									handleChange={handleChange}
									totalDebitValidation={totalDebitValidation}
									totalOtherDebitValidation={totalOtherDebitValidation}

									showModel1={state.showModel1}
									showModel2={state.showModel2}

								/>

							</form>
						</div>

						<QuestionnaireFooter
							percentage={percentage}
							onDownClick={handleDownClick}
							onUpClick={handleUpClick}
						/>
					</div>
				</section>
			</div>
		</>
	);
};

export default QuestionnaireV11;
