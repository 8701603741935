import "../../../../assets/ReviewSubmit/scss/style.scss";
import { useHistory } from "react-router-dom";
import { useClaimReg } from "../../../../Hooks/useClaimReg";
import { useUpdateMilestone } from "../../../../Hooks/useUpdateMilestone";
import { Api } from "../../../../api/Api";

const ReviewSubmit = ({
  setReviewSubmitPopup,
  reviewSubmitPopup,
  customUuid,
  source
}) => {

  const cfirstName = localStorage.getItem("UcFname") ? localStorage.getItem("UcFname") : '';
  const { clickPreviewVisit } = useClaimReg();
  const { updateMilestone } = useUpdateMilestone();
  const history = useHistory();

  const pushPageChange = async () => {
    try {
      const previewresponse = await clickPreviewVisit(
        customUuid, 'ReviewSubmit', "preview_submit", source
      );
      if (previewresponse.data.status == "Success") {
        updateMilestone(customUuid, "preview_submit", source);
        window.scrollTo(0, 0);
        pdfGeneration('thankyou');
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const showPreview = async () => {
    try {
      const previewresponse = await clickPreviewVisit(
        customUuid, 'ReviewSubmit', "show_preview", source
      );
      pdfGeneration('preview');
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const pdfGeneration = async (page) => {
    const responsepdf = await Api.get("/api/v1/vaq-pdf-generate?uuid=" + customUuid, {});
    if (responsepdf.data.status === "Success") {
      if (page == "thankyou") {
        history.push("/Vanquisthankyou?uuid=" + customUuid);
      } else {
        setReviewSubmitPopup(false);
      }

    }
  }

  return (
    <div className="ReviewSubmit">
      <div className={`modal blackover fade ${reviewSubmitPopup ? "show" : "hide"}`} id="prev-popup" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
        aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-lg">
          <div class="modal-content  goodnews_popup">
            <div class="modal-body">
              <div class="text-center max-400 m-auto thakyoumsg">
                <div class="pb-lg-2 mt-5 "><img height="50" src="assets/MeritPage/img/logo.png" alt="" /></div>
                <div class="questionnaire19">
                  <div class="container">
                    <div id="quetWraper" class="questionnair-section form-style mb-5 ps-0">
                      <ul class="p-0 m-0 questionist mb-5">
                        <li>
                          <div class="questitem_v2 mt-2" id="slide9">
                            <h4 class="que-title p-3 "><b>Your claim document is ready for submission to Vanquis with all your information and signature.</b></h4>
                            <div class="question-content mt-0 scale-down-ver-center px-3 py-lg-3 py-md-3 py-sm-2 py-2 pb-3">
                              <div class="row">
                                <div class="col-lg-6 col-md-12">
                                  <div class=" mb-2">
                                    <button
                                      className="mt-2 btn-comm2 py-lg-3 py-md-3 py-sm-2 py-2 w-100  noissue"
                                      id="review"
                                      onClick={showPreview}
                                    >
                                      <i className="bi bi-file-earmark-break me-2"></i> Review My Claim Documents Online
                                    </button>
                                  </div>
                                </div>
                                <div class="col-lg-6 col-md-12">
                                  <div class=" mb-2">
                                    <button className="mt-2 btn-comm2 py-lg-3 py-md-3 py-sm-2 py-2 w-100  noissue"
                                      onClick={pushPageChange}
                                    >
                                      <i className="bi bi-envelope me-2"></i> Email My Claim Documents
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  );
};

export default ReviewSubmit;
