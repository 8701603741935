import React, { useEffect, useState, useContext } from 'react';
import Modal from 'react-bootstrap/Modal';
import "../../assets/Thankyou/css/ThankyouNDY.scss";
import Footer from '../Includes/Layouts/Common/Footer';
import { useUpdateMilestone } from "../../Hooks/useUpdateMilestone";
// import VisitorParamsContext from "../../Contexts/VisitorParams";
import GetVisitorsParams from '../../Utility/GetVisitorsParams';

const ThankyouNDY = () => {

  // const { visitorParameters } = useContext(VisitorParamsContext);
  const urlSearchParams = new URLSearchParams(window.location.search);
  const uuid = urlSearchParams.get('uuid');
  const { updateMilestone } = useUpdateMilestone();
  const [kycPopup, setKycPopup] = useState('hide');

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 500);

    let t2aStatus = localStorage.getItem("t2aValidation");

    if (t2aStatus == 1 || t2aStatus == "1") {
      setKycPopup('show');
    }

    const allFollowupData = JSON.parse(localStorage.getItem('allFollowupData'));
    if (allFollowupData) {
      let flp_source = allFollowupData.atp_sub6;
      updateMilestone(uuid, "completed", flp_source);
    }
    else {
      updateMilestone(uuid, "completed", "live");
    }

    clearLocal();
  }, []);

  const hideKyc = () => {
    localStorage.clear();
    setKycPopup('hide');
    window.scrollTo(0, 0);
  }
  const clearLocal = () => {
    localStorage.clear();
  }




  return (
    <>
      <div className="thankyouNDY">
        <header>
          <div className="container">
            <div className="col-lg-12 col-12 text-center">
              <img src="/assets/img/logo.png" alt="" />
            </div>
          </div>
        </header>
        <div className="thankyouPopup">
          <div className={`modal fade modal-backdrop ${kycPopup}`} id="myModal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
            <div className="modal-dialog  modal-dialog-centered" role="document">
              <div className="modal-content">

                <div className="modal-body text-center">

                  Thank you for submitting your claim to TMS Legal. We have completed your digital ID check and it was successful. We will now email you copies of all signed documents and we will call you shortly to verify your details and answer any questions.
                </div>
                <div className="modal-footer">
                  <button type="button" className="btn" data-bs-dismiss="modal"
                    onClick={() => hideKyc()}
                  >Close</button>

                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container inner-height">
          <div className="row ">
            <div className="col-lg-10 offset-lg-1 col-sm-02 col-12 mp0  text-center animated bounceInDown">
              <img src="thumb.png" alt="" />
              <h1 className="animated zoomInUp">Thank you</h1>
              <p className="thankuparag">You have successfully submitted your claim</p>
            </div>
          </div>
        </div>
        <div className='footerclass'>
          <Footer />
        </div>
      </div>
    </>
  )
}

export default ThankyouNDY;
