import React, {
  Fragment,
  useState,
  useEffect,
  useReducer,
  useContext,
  useRef,
} from "react";
import GetVisitorsParams from "../../Utility/GetVisitorsParams";
import { useHistory } from "react-router-dom";
import RadioButton from "../UI/RadioButton";
import Button from "../UI/Button";
import { queryString } from "../../Utility/QueryString";
import { useIdUpload } from "../../Hooks/useIdUpload";
import Accordion from "react-bootstrap/Accordion";

import axios from "axios";
import GtmDataLayer from "../../Utility/GtmDataLayer";
require("dotenv").config();
const base_api_url = process.env.REACT_APP_API_URL;
const api_auth_token = process.env.REACT_APP_API_AUTH_TOKEN;

const initialState = {
  showSlide1: "show",
  showSlide2: "hide",
};

const userfile = {
  preview: undefined,
  title: undefined,
};
const FormReducer = (state, action) => {
  switch (action.type) {
    case "showSlide": {
      if (action.payload.clickedSlide.slide == "slide1") {
        return {
          ...state,
          showSlide1: "hide",
          showSlide2: "show",
        };
      } else if (action.payload.clickedSlide.slide == "retake") {
        return {
          ...state,
          showSlide1: "show",
          showSlide2: "hide",
        };
      } else {
        return {
          ...state,
        };
      }
    }
  }
};

const IdUploadS2 = ({ className }) => {
  const history = useHistory();
  const formParameters = JSON.parse(localStorage.getItem("formData"));
  const split_name = localStorage.getItem("split_name");
  const { saveFLPUserIdUpload, isLoading } = useIdUpload();
  const [clickedSlide, setClickedSlide] = useState([]);
  const [state, dispatch] = useReducer(FormReducer, initialState);
  const [selectedDocType, setSelectedDocType] = useState();
  const [selectedFile, setSelectedFile] = useState();
  const [selectedFileType, setSelectedFileType] = useState();
  const [isFilePicked, setIsFilePicked] = useState(false);
  const [isInValidfFile, setIsInValidfFile] = useState(false);
  const [documentModalShow, setDocumentModalShow] = useState(false);
  const [nationalIDCard, setNationalIDCard] = useState(false);
  const [driversLicense, setDriversLicense] = useState(false);
  const [passport, setPassport] = useState(false);
  const [base64Image, setBase64Image] = useState();
  const [errorMessage, setErrorMessage] = useState();
  const scrollRef = useRef(null);

  const followup_data = JSON.parse(localStorage.getItem("followup_data"));
  const user_token = localStorage.getItem("user_token");
  const [currentUuid, setCurrentUuid] = useState();

  const slideChange = (e, slide) => {
    setClickedSlide({ slide: "slide1" });
  };

  useEffect(() => {
    if (clickedSlide) {
      dispatch({ type: "showSlide", payload: { clickedSlide } });
    }
  }, [clickedSlide]);

  const selectDocId = (e) => {
    retakeClick();
    // setSelectedDocType(e.target.value);
  };

  const changeCameraHandler = (event) => {
    setSelectedFile(event.target.files[0]);
    let imageFile = event.target.files[0];
    if (imageFile) {
      setIsFilePicked(true);
      let extension = event.target.files[0].type;
      let imgsize = event.target.files[0].size;
      let allowedExtensions = ["image/jpeg", "image/png"];
      if (allowedExtensions.indexOf(extension) === -1) {
        setIsInValidfFile(true);
        setErrorMessage({
          txt: "The uploaded ID type should be an image in jpeg,png or jpg",
        });
      } else if (imgsize > 1024 * 1024 * 20) {
        setIsInValidfFile(true);
        setErrorMessage({ txt: "Please choose a file less than 20mb" });
      } else {
        setSelectedFileType(event.target.name);
        setIsInValidfFile(false);
      }

      setNationalIDCard(false);
      setDriversLicense(false);
      setPassport(false);

      if ("nationalIDCard_doc_cam" == event.target.name) {
        setNationalIDCard(true);
      } else if ("driversLicense_doc_cam" == event.target.name) {
        setDriversLicense(true);
      } else if ("passport_doc_cam" == event.target.name) {
        setPassport(true);
      }

      userfile.preview = URL.createObjectURL(imageFile);
      userfile.title = imageFile.name;

      if (imageFile) {
        let reader = new FileReader();
        reader.onload = _handleReaderLoaded.bind(this);
        //reader.readAsBinaryString(imageFile);
        reader.readAsDataURL(imageFile);
      }
    } else {
      setIsInValidfFile(true);
      setIsFilePicked(false);
      userfile.preview = undefined;
      userfile.title = undefined;
    }
  };

  const changeFilesHandler = (event) => {
    setSelectedFile(event.target.files[0]);
    let imageFile = event.target.files[0];
    if (imageFile) {
      setIsFilePicked(true);
      let extension = event.target.files[0].type;
      let imgsize = event.target.files[0].size;

      let allowedExtensions = ["image/jpeg", "image/png"];
      if (allowedExtensions.indexOf(extension) === -1) {
        setIsInValidfFile(true);
        setErrorMessage({
          txt: "The uploaded ID type should be an image in jpeg,png or jpg",
        });
      } else if (imgsize > 1024 * 1024 * 20) {
        setIsInValidfFile(true);
        setErrorMessage({ txt: "Please choose a file less than 20mb" });
      } else {
        setSelectedFileType(event.target.name);
        setIsInValidfFile(false);
        //_handleFileRead.bind(event.target.files[0]);
      }

      setNationalIDCard(false);
      setDriversLicense(false);
      setPassport(false);

      if ("nationalIDCard_doc" == event.target.name) {
        setNationalIDCard(true);
      } else if ("driversLicense_doc" == event.target.name) {
        setDriversLicense(true);
      } else if ("passport_doc" == event.target.name) {
        setPassport(true);
      }
      userfile.preview = URL.createObjectURL(imageFile);
      userfile.title = imageFile.name;

      if (imageFile) {
        let reader = new FileReader();
        reader.onload = _handleReaderLoaded.bind(this);
        //reader.readAsBinaryString(imageFile);
        reader.readAsDataURL(imageFile);
      }
    } else {
      setIsInValidfFile(true);
      setIsFilePicked(false);
      userfile.preview = undefined;
      userfile.title = undefined;
    }
  };

  const handleClick = () => {
    try {
      if (isFilePicked) {
        setDocumentModalShow(true);
        userfile.preview = URL.createObjectURL(selectedFile);
        userfile.title = selectedFile.name;

        if (selectedFile) {
          //_handleFileRead.bind(this);
          let reader = new FileReader();
          reader.onload = _handleReaderLoaded.bind(this);
          //reader.readAsBinaryString(selectedFile);
          reader.readAsDataURL(selectedFile);
        }
      } else {
        userfile.preview = undefined;
        userfile.title = undefined;
      }
    } catch (e) {
      console.warn(e);
    }
  };

  const _handleReaderLoaded = (readerEvt) => {
    let binaryString = readerEvt.target.result;
    //binaryString = binaryString.replace(";base64", `;name=${selectedFile.name};base64`);
    // setBase64Image({
    //   //base64TextString : btoa(binaryString)
    //   base64TextString : btoa(encodeURIComponent(binaryString).replace(/%([0-9A-F]{2})/g, function(match, p1) {
    //     return String.fromCharCode('0x' + p1);
    //   }))
    // });
    setBase64Image({
      base64TextString: binaryString,
    });
  };

  const retakeClick = () => {
    setDocumentModalShow(false);

    setNationalIDCard(false);
    setDriversLicense(false);
    setPassport(false);

    setClickedSlide({ slide: "retake" });
    setSelectedFile(null);
    setIsFilePicked(false);
  };

  const savedocument = async () => {
    try {
      if (
        followup_data !== null ||
        followup_data !== undefined ||
        followup_data.atp_sub6 !== null
      ) {
        GtmDataLayer({
          question: "Verify Your identity",
        });
        let uploadData = "";
        uploadData = {
          imageFile: base64Image.base64TextString,
          captureType: selectedFileType.substring(
            selectedFileType.indexOf("doc")
          ),
          documentType: selectedFileType.substr(
            0,
            selectedFileType.indexOf("_")
          ),
        };
        const response = saveFLPUserIdUpload(
          uploadData,
          followup_data,
          queryString
        );

        window.scrollTo(0, 0);
        alert("hy");
        // history.push("/thankyou?uuid=" + currentUuid);
      }
    } catch (e) {
      console.warn("error");
      console.warn(e);
    }
  };

  const idSectionScroll = (e) => {
;
    setTimeout(() => {
      scrollRef.current.scrollIntoView({
        behavior: "smooth",
        bottom: 800,
      });
    }, 50);
  };
  const getUuid = () => {
    const query = new URLSearchParams(window.location.search);
    const query_uuid = query.get("uuid");
    const local_storage_uuid = localStorage.getItem("uuid");
    var uuid = query_uuid ? query_uuid : local_storage_uuid;
    return uuid;
  };

  useEffect(() => {
    if (!currentUuid) {
      const uuid = getUuid();
      if (uuid) {
        setCurrentUuid(uuid);
      }
    }
  }, []);

  return (
    <Fragment>
      <GetVisitorsParams />
      {/* <div className="IdUploadFollowupS2"> */}
      <div className={`tms-wrap2 bg-main-pr ${className}`}>
        {/* <div className="text-center loader-wrap loader1 hide">
            <div className="loader-logo">
              <img
                src="assets/img/a00187b31dd5a4ece0c8f809b69962eb.png"
                alt=""
              />
            </div>

            <div className="loader">
              <img src="assets/img/review.gif" alt="" />
              <div className="loader-text">
                <p className=" text-analys">Analysing your Results</p>
              </div>
            </div>
          </div> */}

        <div className={`main text-center main-div ${className}`}>
          <div className="brand-logo mt-lg-0 mt-md-2 mt-sm-2 mt-2">
            <img
              height="65"
              src="assets/img/a00187b31dd5a4ece0c8f809b69962eb.png"
              alt=""
            />
          </div>

          <div className="main-form-wraper">
            <div className="container">
              <div className="item-cent-flx ">
                <div className="  docum-slide3 mt-l-r documents-uploads">
                  <div className=" pt-lg-3 pt-md-3 pt-sm-3  pt-0  text-center">
                    <div className="row">
                      <div className="col-lg-6 offset-lg-3">
                        <img
                          height="115"
                          src="assets/FollowupS2/img/top-img.jpg"
                          alt=""
                        />
                        <h1 className="mt-2 mb-3 mt-md-2 mt-sm-2 mt-0 text-danger">
                          Verify Your identity{" "}
                        </h1>

                        <p className="text-p2">
                          {" "}
                          <i>
                            Tip - Please provide a photo of one of the
                            following:{" "}
                            <span className="text-primary">
                              National ID Card, Driver's License, Passport
                            </span>{" "}
                          </i>{" "}
                        </p>

                        <form
                          name="docs_form"
                          id="user_form"
                          method="POST"
                          encType="multipart/form-data"
                        >
                          <div className="uploading-part ">
                            <Accordion
                              className="accordion accordion-flush"
                              id="faqs"
                            >
                              <Accordion.Item
                                eventKey="1"
                                className="accordion-item"
                                onClick={selectDocId}
                              >
                                <Accordion.Button className="accordion-button idone">
                                  National ID Card
                                </Accordion.Button>
                                <Accordion.Body>
                                  <div className="col-lg-12 col-12 upload-area">
                                    {nationalIDCard && !isInValidfFile ? (
                                      <img
                                        src={userfile.preview}
                                        alt={userfile.title}
                                        style={{
                                          width: "100%",
                                          padding: "0px 69px",
                                        }}
                                      />
                                    ) : (
                                      <img
                                        src="assets/IdUpload/img/upload-demo.jpg"
                                        alt=""
                                      />
                                    )}
                                    <div className="row mt-3">
                                      <div className="col-lg-12 col-12">
                                        {!nationalIDCard && (
                                          <div>
                                            <span className="button-tab browsebut">
                                              <img
                                                src="assets/IdUpload/img/browse.png"
                                                alt=""
                                              />{" "}
                                              Browse from Your Device
                                              <input
                                                type="file"
                                                accept="image/*"
                                                id="idTypeFile"
                                                name="nationalIDCard_doc"
                                                onChange={changeFilesHandler}
                                                onClick={(e) =>
                                                  (e.target.value = null)
                                                }
                                              />
                                            </span>
                                            <span className="button-tab">
                                              <img
                                                src="assets/IdUpload/img/camera.png"
                                                alt=""
                                              />{" "}
                                              Take picture of Your Id{" "}
                                              <input
                                                type="file"
                                                accept="image/*"
                                                capture="camera"
                                                id="idTypeFileCamera"
                                                name="nationalIDCard_doc_cam"
                                                onChange={changeCameraHandler}
                                                onClick={(e) =>
                                                  (e.target.value = null)
                                                }
                                              />
                                            </span>
                                          </div>
                                        )}
                                        {nationalIDCard && (
                                          <div>
                                            {isInValidfFile && (
                                              <p className="error-msg">
                                                {errorMessage.txt}
                                              </p>
                                            )}
                                            {!isInValidfFile && (
                                              <span className="button-tab browsebut">
                                                Submit
                                                <input
                                                  type="button"
                                                  value="Submit"
                                                  onClick={savedocument}
                                                />
                                              </span>
                                            )}
                                            <span className="button-tab w-100 browsebut2">
                                              Retake Photo
                                              <input
                                                type="button"
                                                value="Retake Photo"
                                                onClick={retakeClick}
                                              />
                                            </span>
                                            {isLoading && <p>Please Wait...</p>}
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </Accordion.Body>
                              </Accordion.Item>

                              <Accordion.Item
                                eventKey="2"
                                className="accordion-item"
                                onClick={selectDocId}
                              >
                                <Accordion.Button className="accordion-button idtwo">
                                  Driver's License
                                </Accordion.Button>
                                <Accordion.Body>
                                  <div className="col-lg-12 col-12 upload-area">
                                    {driversLicense && !isInValidfFile ? (
                                      <img
                                        src={userfile.preview}
                                        alt={userfile.title}
                                        style={{
                                          width: "100%",
                                          padding: "0px 69px",
                                        }}
                                      />
                                    ) : (
                                      <img
                                        src="assets/IdUpload/img/upload-demo.jpg"
                                        alt=""
                                      />
                                    )}

                                    <div className="col-lg-12 col-12">
                                      {!driversLicense && (
                                        <div>
                                          <span className="button-tab browsebut">
                                            <img
                                              src="assets/IdUpload/img/browse.png"
                                              alt=""
                                            />{" "}
                                            Browse from Your Device
                                            <input
                                              type="file"
                                              accept="image/*"
                                              id="idTypeFile"
                                              name="driversLicense_doc"
                                              onChange={changeFilesHandler}
                                              onClick={(e) =>
                                                (e.target.value = null)
                                              }
                                            />
                                          </span>
                                          <a className="button-tab take-pic-id">
                                            <img
                                              src="assets/IdUpload/img/camera.png"
                                              alt=""
                                            />{" "}
                                            Take picture of Your Id
                                            <input
                                              type="file"
                                              accept="image/*"
                                              capture="camera"
                                              name="driversLicense_doc_cam"
                                              onChange={changeCameraHandler}
                                              onClick={(e) =>
                                                (e.target.value = null)
                                              }
                                            />
                                          </a>
                                        </div>
                                      )}
                                      {driversLicense && (
                                        <div>
                                          {isInValidfFile && (
                                            <p className="error-msg">
                                              {errorMessage.txt}
                                            </p>
                                          )}
                                          <span className="button-tab browsebut retakered">
                                            Retake Photo
                                            <input
                                              type="button"
                                              value="Retake Photo"
                                              onClick={retakeClick}
                                            />
                                          </span>
                                          {isLoading && <p>Please Wait...</p>}

                                          {!isInValidfFile && (
                                            <span className="button-tab browsebut">
                                              Submit
                                              <input
                                                type="button"
                                                value="Submit"
                                                onClick={savedocument}
                                              />
                                            </span>
                                          )}
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </Accordion.Body>
                              </Accordion.Item>

                              <Accordion.Item
                                eventKey="3"
                                className="accordion-item"
                                onClick={selectDocId}
                              >
                                <Accordion.Button className="accordion-button idthree">
                                  Passport
                                </Accordion.Button>
                                <Accordion.Body>
                                  <div className="col-lg-12 col-12 upload-area">
                                    {passport && !isInValidfFile ? (
                                      <img
                                        src={userfile.preview}
                                        alt={userfile.title}
                                        style={{
                                          width: "100%",
                                          padding: "0px 69px",
                                        }}
                                      />
                                    ) : (
                                      <img
                                        src="assets/IdUpload/img/upload-demo.jpg"
                                        alt=""
                                      />
                                    )}
                                    <div className="col-lg-12 col-12">
                                      {!passport && (
                                        <div>
                                          <span className="button-tab browsebut">
                                            <img
                                              src="assets/IdUpload/img/browse.png"
                                              alt=""
                                            />{" "}
                                            Browse from Your Device
                                            <input
                                              type="file"
                                              accept="image/*"
                                              id="idTypeFile"
                                              name="passport_doc"
                                              onChange={changeFilesHandler}
                                              onClick={(e) =>
                                                (e.target.value = null)
                                              }
                                            />
                                          </span>
                                          <a className="button-tab take-pic-id">
                                            <img
                                              src="assets/IdUpload/img/camera.png"
                                              alt=""
                                            />{" "}
                                            Take picture of Your Id
                                            <input
                                              type="file"
                                              accept="image/*"
                                              capture="camera"
                                              id="idTypeFileCamera"
                                              name="passport_doc_cam"
                                              onChange={changeCameraHandler}
                                              onClick={(e) =>
                                                (e.target.value = null)
                                              }
                                            />
                                          </a>
                                        </div>
                                      )}
                                      {passport && (
                                        <div>
                                          {isInValidfFile && (
                                            <p className="error-msg">
                                              {errorMessage.txt}
                                            </p>
                                          )}
                                          <span className="button-tab browsebut retakered">
                                            Retake Photo
                                            <input
                                              type="button"
                                              value="Retake Photo"
                                              onClick={retakeClick}
                                            />
                                          </span>
                                          {isLoading && <p>Please Wait...</p>}
                                          {!isInValidfFile && (
                                            <span className="button-tab browsebut">
                                              Submit
                                              <input
                                                type="button"
                                                value="Submit"
                                                onClick={savedocument}
                                              />
                                            </span>
                                          )}
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </Accordion.Body>
                              </Accordion.Item>
                            </Accordion>
                          </div>
                        </form>
                        <div className="doc-op">
                          <p className="fs-6">
                            {" "}
                            Document should be Clear &amp; Legible | Entire
                            Document should be Captured
                          </p>
                        </div>
                      </div>
                      <div className="  pt-3 pb-5 ">
                        <img
                          height="50"
                          className="safe-secure-img"
                          src="assets/img/safe and secure.png"
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* </div> */}
    </Fragment>
  );
};

export default IdUploadS2;
