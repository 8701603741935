import React, {
  useRef,
  useState,
  useEffect,
  useContext,
  useReducer,
} from "react";
import { useForm } from "react-hook-form";
import {
  questinnare1,
  questinnare2,
  answerArray,
  supportDocTypeArray,
  questinnaire76MB,
} from "../../Constants/Questions";
import { MonthNumberArray } from "../../Constants/Constants";
import { useQuestionnaire } from "../../Hooks/useQuestionnaire";
import { userInfo } from "../../Hooks/userInfo";
import VisitorParamsContext from "../../Contexts/VisitorParams";
import { ConsoleView } from "react-device-detect";
import { CheckUUID } from "../../Utility/CheckUUID";
import { useHistory } from "react-router-dom";
import { FormData } from "../../Utility/FormData";
import GetVisitorsParams from "../../Utility/GetVisitorsParams";
import "../../assets/css/QuestionnaireV9.scss";
import QuestionnaireLoder from "../Includes/Layouts/QuestionnaireV9/QuestionnaireLoder";
import QuestionnaireFooter from "../Includes/Layouts/QuestionnaireV9/QuestionnaireFooter";
import Questionnaire1 from "../Includes/Layouts/QuestionnaireV9/Questionnaire1";
import Questionnaire2 from "../Includes/Layouts/QuestionnaireV9/Questionnaire2";
import Questionnaire3 from "../Includes/Layouts/QuestionnaireV9/Questionnaire3";
import Questionnaire4 from "../Includes/Layouts/QuestionnaireV9/Questionnaire4";
import Questionnaire5 from "../Includes/Layouts/QuestionnaireV9/Questionnaire5";
import GtmDataLayer from "../../Utility/GtmDataLayer";
import { useUserVerify } from "../../Hooks/useUserVerify";
import { useTotalCarCheck } from "../../Hooks/useTotalCarCheck";
import Questionnaire31 from "../Includes/Layouts/QuestionnaireV9/Questionnaire31";
import Questionnaire32 from "../Includes/Layouts/QuestionnaireV9/Questionnaire32";
import Questionnaire45 from "../Includes/Layouts/QuestionnaireV9/Questionnaire45";
import Questionnaire46 from "../Includes/Layouts/QuestionnaireV9/Questionnaire46";
import { supportingdoc } from "../../Hooks/supportingdoc";
import QuestionnaireEmail from "../Includes/Layouts/QuestionnaireV9/QuestionnaireEmail";
import Questionnaire61 from "../Includes/Layouts/QuestionnaireV9/Questionnaire61";
import QuestionnaireSupportDoc from "../Includes/Layouts/QuestionnaireV9/QuestionnaireSupportDoc";
import QuestionnaireAgreementNoModal from "../Includes/Layouts/QuestionnaireV9/QuestionnaireAgreementNoModal";
import { useSkippedLeads } from "../../Hooks/useSkippedLeads";
import { userMilestoneUpdateV2 } from "../../Hooks/userMilestoneUpdateV2";
import Questionnaire2New from "../Includes/Layouts/QuestionnaireV9/Questionnaire2New";
import Questionnaire73 from "../Includes/Layouts/QuestionnaireV9/Questionnaire73";
import ConfirmSituation from "../Includes/Layouts/MB_UBRF_3/ConfirmSituation";
import { useUpdateMilestone } from "../../Hooks/useUpdateMilestone";

const initialState = {
  showSlide1: "show",
  showSlide2: "hide",
  showSlide3: "hide",
  showSlide4: "hide",
  showSlide4a: "hide",
  showSlide5: "hide",
  showSlide51: "hide",
  showSlide51a: "hide",
  showSlide5a: "hide",
  showSlide6: "hide",
  showSlide7: "hide",
  showSlide8: "hide",
  showSlide73: "hide",
  showSlide10: "hide",
  percentage: 0,
};

var incmAndExpDisplayStatus = "";

const FormReducer = (state, action) => {
  if (incmAndExpDisplayStatus != "hide") {
    incmAndExpDisplayStatus =
      action.payload.clickedSlide.incmAndExpDisplayStatus;
  }

  switch (action.type) {
    case "showSlide": {
      if (action.payload.clickedSlide.slide == "btn_qest_10") {
        GtmDataLayer({
          question: "vehicle registration",
        });
        return {
          ...state,
          showSlide1: "hide",
          showSlide2: "show",
          percentage: 10,
        };
      } else if (action.payload.clickedSlide.slide == "question_11") {
        GtmDataLayer({
          question: "Keeper date",
        });

        return {
          ...state,
          showSlide2: "hide",
          showSlide3: "show",
          percentage: 20,
        };
      } else if (action.payload.clickedSlide.slide == "question_12") {
        GtmDataLayer({
          question: "your vehicle?",
        });

        return {
          ...state,
          showSlide3: "hide",
          showSlide4: "show",
          percentage: 30,
        };
      } else if (action.payload.clickedSlide.slide == "question_43a") {
        GtmDataLayer({
          question: "Private plate?",
        });

        return {
          ...state,
          showSlide4: "hide",
          showSlide4a: "show",
          percentage: 44,
        };
      } else if (action.payload.clickedSlide.slide == "question_43") {
        GtmDataLayer({
          question: "Private plate?",
        });

        if (incmAndExpDisplayStatus == "hide") {
          return {
            ...state,
            showSlide1: "hide",
            showSlide4: "hide",
            showSlide5: "hide",
            showSlide51: "show",
            percentage: 50,
          };
        } else {
          return {
            ...state,
            showSlide1: "hide",
            showSlide4: "hide",
            showSlide5: "show",
            percentage: 44,
          };
        }
      } else if (action.payload.clickedSlide.slide == "question_32") {
        GtmDataLayer({
          question: "Privately registered plate",
        });

        if (incmAndExpDisplayStatus == "hide") {
          return {
            ...state,
            showSlide4a: "hide",
            showSlide5: "hide",
            showSlide51: "show",
            percentage: 50,
          };
        } else {
          return {
            ...state,
            showSlide4a: "hide",
            showSlide5: "show",
            percentage: 50,
          };
        }
      } else if (action.payload.clickedSlide.slide == "slide_4") {
        GtmDataLayer({
          question: "Income details",
        });

        return {
          ...state,
          showSlide1: "hide",
          showSlide5: "hide",
          // showSlide51: "show",
          // showSlide6: "show",
          // showSlide73: "show",
          showSlide51: "show",
          percentage: 60,
        };
      }
      // else if (action.payload.clickedSlide.slide == "question_73") {
      //   // GtmDataLayer({
      //   //   'question': 'Income details'
      //   // });

      //   return {
      //     ...state,
      //     showSlide73: "hide",
      //     showSlide51: "show",
      //     percentage: 65
      //   };
      // }
      else if (action.payload.clickedSlide.slide == "question_61") {
        GtmDataLayer({
          question: "reg. email",
        });
        return {
          ...state,
          showSlide51: "hide",
          showSlide10: "show",
          percentage: 70,
        };
      } else if (action.payload.clickedSlide.slide == "question_61a") {
        GtmDataLayer({
          question: "reg. email",
        });
        return {
          ...state,
          showSlide51: "hide",
          showSlide5a: "show",
          percentage: 75,
        };
      } else if (action.payload.clickedSlide.slide == "btn_qest_36") {
        GtmDataLayer({
          question: "further details",
        });
        return {
          ...state,
          showSlide51a: "show",
          showSlide5a: "hide",
          showSlide6: "hide",
          showSlide10: "hide",
          // showSlide6: "show",
          percentage: 88,
        };
      } else if (action.payload.clickedSlide.slide == "btn_qest_76") {
        GtmDataLayer({
          question: "situation statements",
        });
        return {
          ...state,
          showSlide51: "hide",
          showSlide5a: "hide",
          showSlide6: "show",
          showSlide10: "hide",
          showSlide51a: "hide",
          // showSlide6: "show",
          percentage: 88,
        };
      } else if (action.payload.clickedSlide.slide == "btn_qest_48") {
        GtmDataLayer({
          question: "email registered with",
        });

        return {
          ...state,
          showSlide51: "hide",
          showSlide5a: "hide",
          showSlide10: "show",
          // showSlide6: "show",
          SetPercentage: 80,
        };
      } else if (action.payload.clickedSlide.slide == "question_42") {
        GtmDataLayer({
          question: "What Documentation",
        });
        return {
          ...state,
          showSlide51a: "hide",
          showSlide5a: "hide",
          showSlide8: "show",
          // showSlide6: "show",
          percentage: 95,
        };
      } else if (action.payload.clickedSlide.slide == "question_5a") {
        GtmDataLayer({
          question: "select one",
        });

        return {
          ...state,
          showSlide6: "hide",
          showSlide7: "show",
          percentage: 10,
        };
      }
      // else if (action.payload.clickedSlide.slide == "question_5b") {
      //   GtmDataLayer({
      //     'question': 'select one'
      //   });
      //   return {
      //     ...state,
      //     showSlide6: "hide",
      //     showSlide8: "show",
      //   };
      // }

      // question_45
      else {
        return {
          ...state,
        };
      }
    }
    default:
      return state;
  }
};

const QuestionnaireV9 = () => {
  const {updateMilestone} = useUpdateMilestone();
  const { isCheck } = CheckUUID();
  const { getUserVerfyValidation, saveUserVerify } = useUserVerify();
  isCheck();
  const history = useHistory();
  const [completedQuestArr, setCompletedQuestArr] = useState([]);
  const { isLoading, SaveQuestionnaire } = useQuestionnaire();
  const { visitorParameters } = useContext(VisitorParamsContext);
  const [checkboxValues, SetCheckboxValues] = useState([]);
  const [displayError, SetDisplayError] = useState("d-none");
  const [modalSub1Ans, setModalSub1Ans] = useState(null);
  const [modalSub2Ans, setModalSub2Ans] = useState(null);
  const query_string = localStorage.getItem("querystring");
  const formdata = JSON.parse(localStorage.getItem("formData"));
  const [checkQuestion, setCheckQuestion] = useState(0);
  const [lastClickQuest, setLastClickQuest] = useState(null);
  const [t2a, setT2a] = useState();
  const scrollRef = useRef([]);
  const splitForm = useRef(null);
  const [state, dispatch] = useReducer(FormReducer, initialState);
  const { updateMilestoneV2 } = userMilestoneUpdateV2();
  const [splitName, setSplitName] = useState();
  const [splitNameForSkip, setSplitNameForSkip] = useState();
  const { getUserInfo } = userInfo();
  const { setFormData } = FormData();
  const [showLoad, setShowLoad] = useState("show");
  const [showUserName, setUserName] = useState();
  const [vehicle, setVehicle] = useState("");
  const [totalCarCheck, settotalCarCheck] = useState("");
  const [clickedSlide, setClickedSlide] = useState([]);
  const [validKeeperDate, setValidKeeperDate] = useState("");
  const [optionSelected] = useState([]);
  const [optionSelected76] = useState([]);
  const [t2aApiReqData, setT2aApiReqData] = useState({});
  const { totalCarCheckApi } = useTotalCarCheck();
  let [totalQuestArr, setTotalQuestArr] = useState([
    10, 11, 12, 43, 31, 36, 76, 45, 46, 42, 73,
  ]);
  const { getSupportingDoc } = supportingdoc();
  const [supportDocType, setSupportDocType] = useState("");
  const [supportDoc, setSupportDoc] = useState("");
  const [docType, setDocType] = useState("");
  const [userEmail, setUserEmail] = useState();
  const [agNoShow, setAgNoShow] = useState("hide");
  const { skippedLeads } = useSkippedLeads();

  const { register, errors, trigger, setError, clearErrors, getValues } =
    useForm({
      mode: "onBlur",
      reValidateMode: "onChange",
      defaultValues: {},
      resolver: undefined,
      context: undefined,
      criteriaMode: "firstError",
      shouldFocusError: true,
      shouldUnregister: true,
    });
  const getUuid = () => {
    const visitorData = visitorParameters.visitor_parameters;
    if (visitorData != "") {
      var uuid = visitorData.uuid;
    } else {
      const query = new URLSearchParams(window.location.search);
      const query_uuid = query.get("uuid");
      const local_storage_uuid = localStorage.getItem("uuid");
      var uuid = query_uuid ? query_uuid : local_storage_uuid;
    }
    return uuid;
  };
  const uuid = getUuid();
  const saveVehicleDetails = (vehicleData) => {
    setVehicle(vehicleData);
  };
  const savetotalCarCheck = (totalCarCheck) => {
    settotalCarCheck(totalCarCheck);
  };

  useEffect(() => {
    if (formdata === undefined || formdata === null || formdata == "") {
      (async () => {
        const response = await getUserInfo(uuid);
        const set_formdata = setFormData(response);
        let dataLength = response.data.response.length;
        if (dataLength > 0) {
          const pageName = response.data.response[0].page_name
            ? response.data.response[0].page_name
            : " ";
          setSplitName(pageName);
          if (
            localStorage.getItem("split_name") != undefined &&
            localStorage.getItem("split_name") != undefined
          ) {
            setSplitNameForSkip(localStorage.getItem("split_name"));
          } else {
            setSplitNameForSkip(pageName);
          }
          const userName = response.data.response[0].first_name
            ? response.data.response[0].first_name +
              " " +
              response.data.response[0].last_name
            : " ";
          var userDobN = response.data.response[0].user_dob.split("/");
          var DobDayF = userDobN[0];
          var DobMonthF = userDobN[1];
          var DobYearF = userDobN[2];

          setT2aApiReqData({
            uuid: uuid,
            fName: response.data.response[0].first_name,
            lName: response.data.response[0].last_name,
            pCode: response.data.response[0].postcode,
            addressLine1: response.data.response[0].address_line1,
            dob_y: DobYearF,
            dob_d: DobDayF,
            dob_m: DobMonthF,
            addressLine2: response.data.response[0].address_line2,
            postTown: response.data.response[0].town,
            country: response.data.response[0].county,
            title: response.data.response[0].title,
            addressLine3: response.data.response[0].address_line3,
            addressLine4: response.data.response[0].address_line4,
            county: response.data.response[0].county,
          });

          setSupportDocType(
            response.data.response[0]?.questionnaire[42]["answerId"]
          );

          setUserName(userName);
          setUserEmail(response.data.response[0].email);
          const timer = setTimeout(() => {
            setShowLoad("hide");
          }, 1000);
        }
      })();
    } else {
      const pageName = formdata.page_name;
      setSplitName(pageName);
      if (
        localStorage.getItem("split_name") != undefined &&
        localStorage.getItem("split_name") != undefined
      ) {
        setSplitNameForSkip(localStorage.getItem("split_name"));
      } else {
        setSplitNameForSkip(pageName);
      }
      const userName = formdata.txtFName + " " + formdata.txtLName;
      setUserName(userName);
      const dobMonth = formdata.DobMonth
        ? MonthNumberArray[formdata.DobMonth]
        : "";

      setT2aApiReqData({
        uuid: uuid,
        fName: formdata.txtFName,
        lName: formdata.txtLName,
        pCode: formdata.txtPostCode,
        addressLine1: formdata.txtHouseNumber,
        dob_y: formdata.DobYear,
        dob_m: dobMonth,
        dob_d: formdata.DobDay,
        addressLine2: formdata.txtAddress2,
        postTown: formdata.txtTown,
        country: formdata.txtCountry,
        title: formdata.lstSalutation,
        addressLine3: formdata.txtAddress3,
        addressLine4: formdata.txtAddress4 ? formdata.txtAddress4 : "",
        county: formdata.txtCounty,
      });
      const timer = setTimeout(() => {
        setShowLoad("hide");
      }, 1000);
      setSupportDocType(formdata.question_42);
      setSupportDoc(answerArray[formdata.question_42]);
      setDocType(supportDocTypeArray[formdata.question_42]);
      setUserEmail(formdata.txtEmail);
    }
  }, [splitName]);

  const onselectAnswer = async (e) => {
    let selectedAns = parseInt(e.target.getAttribute("data-ans-id"));
    let selectedQuest = parseInt(e.target.getAttribute("data-quest-id"));
    let anslabel = e.target.getAttribute("data-ans-label");
    const valuesreg = getValues();
    let validEmail = valuesreg["question_48"];

    if (e.target.name == "question_43" && selectedAns == "121") {
      setTotalQuestArr([...totalQuestArr, 44]);
    }

    setCompletedQuestArr([...completedQuestArr, selectedQuest]);

    if (e.target.name == "question_43" && selectedAns == "121") {
      setClickedSlide({ slide: "question_43a" });
      totalQuestArr.push(44);
    } else if (e.target.name == "question_61" && selectedAns == "163") {
      setClickedSlide({ slide: "question_61a" });
    } else if (e.target.name == "question_42") {
      if (selectedAns == "124") {
        GtmDataLayer({
          question: "What Documentation",
        });
        setAgNoShow("show");
      } else if (selectedAns == "123") {
        GtmDataLayer({
          question: "What Documentation",
        });
        redirectToPreview();
      } else {
        setClickedSlide({ slide: "question_42" });
      }
    } else {
      setClickedSlide({ slide: e.target.name });
    }

    let flage = false;
    let question_data = {};
    if (e.target.name == "btn_qest_36") {
      // GtmDataLayer({
      //   'question': 'Next BTN'
      // });
      if (selectedQuest) {
        flage = true;
        question_data = {
          question_id: 36,
          option_id: null,
          answer_text: anslabel,
          input_answer: optionSelected,
        };
      }
      pushPageChange();
      // if (supportDocType && supportDocType == '124' || supportDocType == '123') {
      //   pushPageChange();
      // }

      // else {
      setClickedSlide({ slide: "btn_qest_36" });
      // }
    } else if (e.target.name == "btn_qest_76") {
      setClickedSlide({ slide: "btn_qest_76" });
      if (selectedQuest) {
        flage = true;
        question_data = {
          question_id: selectedQuest,
          option_id: null,
          answer_text: anslabel,
          input_answer: optionSelected76,
        };
      }
    } else if (e.target.name == "question_11") {
      if (selectedAns && selectedQuest) {
        flage = true;
        question_data = {
          question_id: selectedQuest,
          option_id: null,
          answer_text: "",
          input_answer: e.target.value,
          valid_keeper_date: e.target.id,
        };
      }
    } else if (e.target.name == "btn_qest_48") {
      if (selectedQuest) {
        flage = true;
        question_data = {
          question_id: 48,
          option_id: null,
          answer_text: null,
          input_answer: validEmail,
        };
      }
    } else {
      if (selectedAns && selectedQuest) {
        flage = true;
        question_data = {
          question_id: selectedQuest,
          option_id: selectedAns,
          answer_text: anslabel,
          input_answer: "",
        };
      }
    }

    if (flage) {
      storeQuestionnire(question_data);
      var existingEntries = JSON.parse(localStorage.getItem("questionData"));
      if (existingEntries == null) existingEntries = [];
      localStorage.setItem(
        "currentquestionData",
        JSON.stringify(question_data)
      );
      existingEntries.push(question_data);
      localStorage.setItem("questionData", JSON.stringify(existingEntries));
    }
  };

  const saveInputAns = async (e) => {
    let selectedQuest = parseInt(e.target.getAttribute("data-quest-id"));
    setCompletedQuestArr([...completedQuestArr, selectedQuest]);
    const valuesreg = getValues();
    let regPlate = valuesreg["question_44"];
    let financeAgremmentNo = valuesreg["question_45"];
    let flage = false;
    let question_data = {};

    if (selectedQuest == "44" && regPlate) {
      setClickedSlide({ slide: "question_32" });
      flage = true;
      question_data = {
        question_id: selectedQuest,
        option_id: null,
        answer_text: "",
        input_answer: regPlate,
      };
    } else if (selectedQuest == "45" && financeAgremmentNo) {
      setClickedSlide({ slide: "question_45" });
      flage = true;
      question_data = {
        question_id: selectedQuest,
        option_id: null,
        answer_text: "",
        input_answer: financeAgremmentNo,
      };
    }

    if (flage) {
      storeQuestionnire(question_data);
      var existingEntries = JSON.parse(localStorage.getItem("questionData"));
      if (existingEntries == null) existingEntries = [];
      localStorage.setItem(
        "currentquestionData",
        JSON.stringify(question_data)
      );
      existingEntries.push(question_data);
      localStorage.setItem("questionData", JSON.stringify(existingEntries));

      if (selectedQuest == "45") {
        redirectToPreview();
      }
    }
  };
  const redirectToPreview = async () => {
    GtmDataLayer({
      question: "Supporting Documentation",
    });

    updateMilestone(visitorParameters.visitor_parameters.uuid,"questions","live");
    
    if (t2a == 1) {
      (async () => {
        const response = await updateMilestoneV2(
          visitorParameters.visitor_parameters.uuid,
          t2a
        );
      })();
    }
    history.push(
      "/view_docs?uuid=" + visitorParameters.visitor_parameters.uuid
    );
  };
  const SkippedLeads = async (type) => {
    // type : SkipAgreementNo,SkipDoc
    const skippedLeadsResponse = await skippedLeads(type, uuid, splitName);
    if (skippedLeadsResponse.data.status == "Success") {
      redirectToPreview();
    }
  };
  const skipAgNo = async (e) => {
    setAgNoShow("hide");
    SkippedLeads("SkipAgreementNo");
    // redirectToPreview();
  };

  const saveSlide4 = async (e) => {
    let selectedAns = parseInt(e.target.getAttribute("data-ans-id"));
    let selectedQuest = parseInt(e.target.getAttribute("data-quest-id"));
    let anslabel = e.target.getAttribute("data-ans-label");

    setCompletedQuestArr([...completedQuestArr, selectedQuest]);

    var answer31 = splitForm.current["question_31"].value;
    var answer32 = splitForm.current["question_32"].value;
    var answer33 = splitForm.current["question_33"].value;
    //var answer34 = splitForm.current["question_34"].value;
    var answer35 = splitForm.current["question_35"].value;

    var slide_array = [
      ["31", answer31],
      ["32", answer32],
      ["33", answer33],
      //["34", answer34],
      ["35", answer35],
    ];

    var question_data = {
      slide_4: slide_array,
    };
    setClickedSlide({ slide: "slide_4" });
    storeQuestionnire(question_data);

    let _existingEntries = JSON.parse(localStorage.getItem("questionData"));
    if (_existingEntries == null) _existingEntries = [];
    _existingEntries.push(question_data);
    localStorage.setItem("questionData", JSON.stringify(_existingEntries));
  };

  useEffect(() => {
    var questIntersection = totalQuestArr.filter(
      (x) => !completedQuestArr.includes(x)
    );

    let completeQuestCount = questIntersection.length;
    if (completeQuestCount == 1) {
      pushPageChange();
    }
  }, [completedQuestArr]);

  const handleCheckBoxChange = (e) => {
    SetDisplayError("d-none");
    let checkVal = e.target.value;
    if (e.target.checked == true) {
      SetCheckboxValues([...checkboxValues, checkVal]);
    } else {
      SetCheckboxValues(checkboxValues.filter((e) => e !== checkVal));
    }
  };
  const onModalClick = (e) => {
    let selectedAns = e.target.getAttribute("data-ans-id");
    let selectedQuest = e.target.getAttribute("data-quest-id");
    let selectedAnsTxt = e.target.getAttribute("data-ans-label");
    if (selectedQuest === "sub1") {
      setModalSub1Ans(selectedAns);
    } else if (selectedQuest === "sub2") {
      setModalSub2Ans(selectedAns);
    }
  };
  const storeQuestionnire = async (question_data) => {
    const response = await SaveQuestionnaire(
      visitorParameters.visitor_parameters,
      question_data,
      "question_store",
      visitorParameters.data,
      query_string,
      formdata
    );
  };

  const storeT2aResponse = async (t2a_data) => {
    const response = await saveUserVerify(
      visitorParameters.visitor_parameters,
      t2a_data,
      "person_verify_store",
      visitorParameters.data,
      query_string,
      t2aApiReqData
    );
  };
  const pushPageChange = () => {
    if (validKeeperDate) {
      (async () => {
        const totalCarCheckApiData = await totalCarCheckApi(
          vehicle.reg_no,
          visitorParameters.visitor_parameters.uuid,
          splitName
        );
        savetotalCarCheck(totalCarCheckApiData.data.vehicleData);
        var lender = totalCarCheckApiData.data.vehicleData.finance_company;
        localStorage.setItem("lenderName", lender);
        sett2aValidation();
        // if(lender != "Moneybarn" && lender != undefined && lender != null && lender != ""){
        //   window.scrollTo(0, 0);
        //   history.push("/unqualified?uuid=" + visitorParameters.visitor_parameters.uuid);
        // }else{
        //   sett2aValidation();
        // }
        //  if (supportDocType && supportDocType == '124' || supportDocType == '123') {
        //   sett2aValidation();
        //  }
        // else {
        // setClickedSlide({ slide: 'question_5b' });
        // sett2aValidation();
        // }
      })();
    } else {
      if (
        (supportDocType && supportDocType == "124") ||
        supportDocType == "123"
      ) {
        sett2aValidation();
      } else {
        setClickedSlide({ slide: "question_5b" });
        sett2aValidation();
      }
    }
  };

  const pushPageChangeV2 = () => {
    window.scrollTo(0, 0);
    history.push(
      "/view_docs?uuid=" + visitorParameters.visitor_parameters.uuid
    );
  };

  const sett2aValidation = () => {
    const useruuid = t2aApiReqData.uuid;
    const fName = t2aApiReqData.fName;
    const lName = t2aApiReqData.lName;
    const pCode = t2aApiReqData.pCode;
    const addressLine1 = t2aApiReqData.addressLine1;
    const dobYr = t2aApiReqData.dob_y;
    const dobDy = t2aApiReqData.dob_d;
    const addressLine2 = t2aApiReqData.addressLine2;
    const postTown = t2aApiReqData.postTown;
    const country = t2aApiReqData.country;
    const title = t2aApiReqData.title;
    const addressLine3 = t2aApiReqData.addressLine3;
    const addressLine4 = t2aApiReqData.addressLine4;
    const county = t2aApiReqData.county;
    let dobMon;
    if (t2aApiReqData.dob_m === undefined) {
      dobMon = formdata.DobMonth;
    } else {
      dobMon = t2aApiReqData.dob_m;
    }

    if (
      useruuid &&
      fName &&
      lName &&
      pCode &&
      addressLine1 &&
      dobYr &&
      dobMon &&
      dobDy
    ) {
      (async () => {
        const response = await getUserVerfyValidation(
          useruuid,
          fName,
          lName,
          pCode,
          addressLine1,
          dobYr,
          dobMon,
          dobDy,
          addressLine2,
          postTown,
          country,
          title,
          addressLine3,
          addressLine4,
          county
        );

        if (response.data.status == "success") {
          setT2a(1);
          localStorage.setItem("t2aValidation", 1);
          updateMilestone(visitorParameters.visitor_parameters.uuid,"kyc_check","live",1);
          // window.scrollTo(0, 0);
          //   // history.push("/preview?uuid=" + visitorParameters.visitor_parameters.uuid);
          // if (supportDocType && supportDocType == '124' || supportDocType == '123') {
          //   history.push("/view_docs?uuid=" + visitorParameters.visitor_parameters.uuid);
          // }
        } else {
          setT2a(0);
          localStorage.setItem("t2aValidation", 0);
          updateMilestone(visitorParameters.visitor_parameters.uuid,"kyc_check","live",2);
          // window.scrollTo(0, 0);
          // if (supportDocType && supportDocType == '124' || supportDocType == '123') {
          //   window.scrollTo(0, 0);
          //   history.push("/view_docs?uuid=" + visitorParameters.visitor_parameters.uuid);
          // }
        }

        storeT2aResponse(response.data);
      })();
    }
  };
  const handleDownClick = () => {
    scrollRef.current[lastClickQuest + 2]?.scrollIntoView({
      behavior: "smooth",
      block: "center",
      inline: "center",
    });
    setLastClickQuest(lastClickQuest + 1);
  };
  const handleUpClick = () => {
    scrollRef.current[lastClickQuest]?.scrollIntoView({
      behavior: "smooth",
      block: "center",
      inline: "center",
    });
    setLastClickQuest(lastClickQuest - 1);
  };

  const checkCarRegistration = (e) => {
    let selectedQuest = parseInt(e.target.getAttribute("data-quest-id"));
    // let selectedAns = parseInt(e.target.getAttribute("data-ans-id"));
    // let anslabel = e.target.getAttribute("data-ans-label");
    let selectedAns = null;
    let anslabel = splitForm.current["question_10"].value;
    setCompletedQuestArr([...completedQuestArr, selectedQuest]);

    if (selectedQuest) {
      let question_data = {
        question_id: selectedQuest,
        option_id: selectedAns,
        answer_text: anslabel,
        input_answer: anslabel,
      };
      storeQuestionnire(question_data);
      var existingEntries = JSON.parse(localStorage.getItem("questionData"));
      if (existingEntries == null) existingEntries = [];
      localStorage.setItem(
        "currentquestionData",
        JSON.stringify(question_data)
      );
      existingEntries.push(question_data);
      localStorage.setItem("questionData", JSON.stringify(existingEntries));
    }
    setClickedSlide({ slide: e.target.name });
  };

  const pushUnqualified = () => {
    window.scrollTo(0, 0);
    history.push(
      "/unqualified?uuid=" + visitorParameters.visitor_parameters.uuid
    );
  };

  const slideSkip = () => {
    setClickedSlide({ slide: "question_43" });
  };

  useEffect(() => {
    if (clickedSlide) {
      dispatch({ type: "showSlide", payload: { clickedSlide } });
    }
  }, [clickedSlide]);

  useEffect(() => {
    if (formdata != null && formdata.question_74 == "211") {
      setClickedSlide({ incmAndExpDisplayStatus: "hide" });
    }
    localStorage.removeItem("questionData");
  }, []);

  // useEffect(() => {
  //   (async () => {
  //     const response = await getSupportingDoc(uuid);
  //     let reponseData = response.data.response.data.support_doc_type ? response.data.response.data.support_doc_type: '';
  //     setSupportDocType(reponseData);
  //   })();
  // }, []);

  return (
    <>
      <GetVisitorsParams />
      <div className="questionnaireV9">
        <QuestionnaireLoder showUserName={showUserName} showLoad={showLoad} />

        <section
          className="formsection"
          style={
            showLoad === "hide" ? { display: "block" } : { display: "none " }
          }
        >
          <div className="container">
            <div className="row">
              <form
                ref={splitForm}
                name="split_form"
                id="user_form"
                method="POST"
                autoComplete="off"
                //onSubmit={handleSubmit}
              >
                <div className="offset-xl-3 col-xl-6 offset-lg-3 col-lg-6 offset-md-2 col-md-8 col-12">
                  <div className="radio-box animated fadeInUp">
                    <Questionnaire1
                      clsName={`space-top ${state.showSlide1}`}
                      //reff={(el) => (scrollRef.current[10] = el)}
                      uuid={uuid}
                      validation={register}
                      validationMsg={errors}
                      getValues={getValues}
                      trigger={trigger}
                      setError={setError}
                      page_name={splitName}
                      split_name={splitNameForSkip}
                      saveVehicleDetails={saveVehicleDetails}
                      savetotalCarCheck={savetotalCarCheck}
                      checkCarRegistration={checkCarRegistration}
                      slideSkip={slideSkip}
                    />

                    {/* <Questionnaire2
                      clsName={`space-top ${state.showSlide2}`}
                      reff={(el) => (scrollRef.current[11] = el)}
                      page_name={splitName}
                      uuid={visitorParameters.visitor_parameters.uuid}
                      vehicle={vehicle}
                      totalCarCheck={totalCarCheck}
                      savetotalCarCheck={savetotalCarCheck}
                      onselectAnswer={onselectAnswer}
                      setValidKeeperDate={setValidKeeperDate}
                    /> */}

                    <Questionnaire2New
                      clsName={`space-top ${state.showSlide2}`}
                      reff={(el) => (scrollRef.current[11] = el)}
                      page_name={splitName}
                      uuid={visitorParameters.visitor_parameters.uuid}
                      vehicle={vehicle}
                      totalCarCheck={totalCarCheck}
                      savetotalCarCheck={savetotalCarCheck}
                      onselectAnswer={onselectAnswer}
                      setValidKeeperDate={setValidKeeperDate}
                    />

                    <Questionnaire3
                      clsName={`space-top ${state.showSlide3}`}
                      reff={(el) => (scrollRef.current[12] = el)}
                      onselectAnswer={onselectAnswer}
                      vehicle={vehicle}
                      validKeeperDate={validKeeperDate}
                      pushUnqualified={pushUnqualified}
                      savetotalCarCheck={savetotalCarCheck}
                      uuid={uuid}
                      page_name={splitName}
                    />

                    <Questionnaire31
                      clsName={`space-top ${state.showSlide4}`}
                      reff={(el) => (scrollRef.current[43] = el)}
                      onselectAnswer={onselectAnswer}
                    />

                    <Questionnaire32
                      clsName={`space-top ${state.showSlide4a}`}
                      reff={(el) => (scrollRef.current[43] = el)}
                      saveInputAns={saveInputAns}
                      validation={register}
                      validationMsg={errors}
                      getValues={getValues}
                      trigger={trigger}
                      setError={setError}
                    />

                    <Questionnaire4
                      clsName={`space-top2 ${state.showSlide5}`}
                      //reff={(el) => (scrollRef.current[31] = el)}
                      questArr={questinnare1}
                      saveSlide4={saveSlide4}
                      name="question_31_35"
                      btnName="slide_4"
                      validation={register}
                      validationMsg={errors}
                      getValues={getValues}
                      trigger={trigger}
                      setError={setError}
                    />

                    {/* <Questionnaire73
                      clsName={`space-top2 ${state.showSlide73}`}
                      reff={(el) => (scrollRef.current[73] = el)}
                      onselectAnswer={onselectAnswer}
                      
                    /> */}

                    <Questionnaire61
                      clsName={`space-top2 ${state.showSlide51}`}
                      reff={(el) => (scrollRef.current[41] = el)}
                      onselectAnswer={onselectAnswer}
                      userEmail={userEmail}
                      validation={register}
                      validationMsg={errors}
                      getValues={getValues}
                      trigger={trigger}
                      setError={setError}
                    />

                    <QuestionnaireEmail
                      clsName={`space-top2 ${state.showSlide5a}`}
                      reff={(el) => (scrollRef.current[43] = el)}
                      validation={register}
                      validationMsg={errors}
                      getValues={getValues}
                      trigger={trigger}
                      setError={setError}
                      onselectAnswer={onselectAnswer}
                    />

                    <Questionnaire5
                      clsName={`space-top ${state.showSlide6}`}
                      reff={(el) => (scrollRef.current[36] = el)}
                      questArr={questinnare2}
                      onselectAnswer={onselectAnswer}
                      validation={register}
                      validationMsg={errors}
                      getValues={getValues}
                      trigger={trigger}
                      setError={setError}
                      optionSelected={optionSelected}
                    />
                    <ConfirmSituation
                      clsName={`space-top ${state.showSlide10}`}
                      reff={(el) => (scrollRef.current[76] = el)}
                      questArr={questinnaire76MB}
                      onselectAnswer={onselectAnswer}
                      validation={register}
                      validationMsg={errors}
                      getValues={getValues}
                      trigger={trigger}
                      setError={setError}
                      optionSelected76={optionSelected76}
                    />
                    <QuestionnaireSupportDoc
                      clsName={`space-top ${state.showSlide51a}`}
                      reff={(el) => (scrollRef.current[49] = el)}
                      onselectAnswer={onselectAnswer}
                    />

                    <Questionnaire45
                      clsName={`space-top ${state.showSlide7}`}
                      reff={(el) => (scrollRef.current[43] = el)}
                      saveInputAns={saveInputAns}
                      validation={register}
                      validationMsg={errors}
                      getValues={getValues}
                      trigger={trigger}
                      setError={setError}
                      pushPageChange={pushPageChange}
                    />

                    <Questionnaire46
                      clsName={`space-top ${state.showSlide8}`}
                      reff={(el) => (scrollRef.current[46] = el)}
                      saveInputAns={saveInputAns}
                      validation={register}
                      validationMsg={errors}
                      getValues={getValues}
                      trigger={trigger}
                      setError={setError}
                      pushPageChange={redirectToPreview}
                      docType={docType}
                      split_name={splitNameForSkip}
                      uuid={uuid}
                    />
                  </div>
                </div>
                <QuestionnaireAgreementNoModal
                  validation={register}
                  validationMsg={errors}
                  trigger={trigger}
                  saveInputAns={saveInputAns}
                  agNoShow={agNoShow}
                  skipAgNo={skipAgNo}
                />
              </form>
            </div>

            <QuestionnaireFooter
              percentage={state.percentage}
              onDownClick={handleDownClick}
              onUpClick={handleUpClick}
            />
          </div>
        </section>
      </div>
    </>
  );
};

export default QuestionnaireV9;
