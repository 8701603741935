import React, { useState } from 'react';
import ProgressBar from '../Common/ProgressBar';
import Select from 'react-select';
import RadioButton from '../../../UI/RadioButton';
import { useFollowupUserQuestions } from '../../../../Hooks/useFollowupUserQuestions';
import { useExternalFollowup } from "../../../../Hooks/useExternalFollowup";
import { useHistory } from "react-router-dom";


const BankName = ({
  className,
  slideChange,
  validation,
  SelectBank,
  setSelectBank,
  validationMsg,
  getValues,
  Year,
  lenderList,
  setBankName,
  setMatchCheckLoader,
  allFollowupData,
  loader,
  setLoader,
  userDetails,
  Uuid,
  setDivShow,
  backBtn
}) => {
  const [BankValidationMsg, setBankValidationMsg] = useState(false);
  const { saveFollowupUserQuestions, isLoading } = useFollowupUserQuestions();
  const agreementDate = localStorage?.getItem("agreementDate");
  const { getCustomerCheck, milestoneChase } = useExternalFollowup();
  const history = useHistory();

  const customStyles = {
    control: (base) => ({
      ...base,
      minHeight: 50,
    }),
  };

  const formData = getValues();

  const nextSlide = async (e) => {

    if (!SelectBank) {
      setBankValidationMsg("Please Select Bank");
      return;
    }
    const selectedBankValues = SelectBank.map(bank => bank.value);
    let LengthSelectedBank = selectedBankValues.length;

    if (LengthSelectedBank < 1 || selectedBankValues.includes("")) {
      setBankValidationMsg("Please Select Bank");
    } else if (LengthSelectedBank === 1 && selectedBankValues.includes("others")) {
      history.push(`/thankyou?uuid=${Uuid}`);
      
      await milestoneChase({ uuid: userDetails.uuid }, userDetails.user_id, "bank_select_milestone", 3, userDetails.source)
    } else {
      setBankName('hide');
      setMatchCheckLoader('show');
      let matchCheck = await getCustomerCheck({ uuid: userDetails.uuid }, userDetails.user_id, agreementDate, SelectBank)

      if (matchCheck.data.status === "success") {
        let budLender = matchCheck.data.provider;
        localStorage.setItem('bud_lender', budLender);
        setTimeout(() => {
          setMatchCheckLoader('hide');
          setDivShow('OBpage');
        }, 3000);
        await milestoneChase({ uuid: userDetails.uuid }, userDetails.user_id, "bank_select_milestone", 1, userDetails.source)
      } else {

        history.push(`/thankyou?uuid=${Uuid}`);
        await milestoneChase({ uuid: userDetails.uuid }, userDetails.user_id, "bank_select_milestone", 2, userDetails.source)
      }

    }
  };

  function handleSelect(opt) {
    setSelectBank(opt);
    setBankValidationMsg(false);
  }

  let BankList = [];

  if (lenderList.length > 0) {
    BankList = BankList.concat(lenderList.filter(function (val) {
      return !val.display_name.endsWith("Card") &&
        !val.display_name.endsWith("Business") &&
        !val.display_name.endsWith("card") &&
        !val.display_name.endsWith("Cards");
    }).map(function (val) {
      return {
        value: val.provider,
        label: val.display_name
      };
    }));
  }
  const backClick = async (e) => {
    setLoader(1);

    setBankName('hide');
    setDivShow('agreement');

    setTimeout(() => {
      setLoader(0);
    }, 1000);
  };

  return (
    <>
      <div className={`tms-wrap bg-main-pr ${className}`}>
        <div className={`text-center loader-wrap loader1 ${loader === 1 ? 'showload' : 'hide'}`}>
          {/* <div className="loader-logo">
            <img src="assets/img/a00187b31dd5a4ece0c8f809b69962eb.png" alt="" />
          </div> */}
          <div className="loader">
            <img src="assets/img/review.gif" alt="" />
          </div>
        </div>

        <div className={`main text-center main-div ${loader == 0 ? 'showload' : 'hide'}`}>
          <div className="brand-logo mt-lg-0 mt-md-2 mt-sm-2 mt-2">
            <img height="65" src="assets/img/a00187b31dd5a4ece0c8f809b69962eb.png" alt="" />
          </div>
          <div className="main-form-wraper" style={{ marginTop: '3rem' }}>
            <div className="container">
              <div className="item-cent-flx ">
                <div className='slide02'>
                  <div className="row">
                    <div className="col-lg-8 offset-lg-2">
                      <h1>At the time you secured your loan with {userDetails.lender} on {userDetails.agreementDate}, which bank do you used to
                        manage your income and expenditure?</h1>
                      <div className="row mt-4">
                        <div className="col-lg-12">
                          <div className="mb-3">
                            <Select
                              placeholder="Select Bank Name"
                              options={BankList}
                              aria-label="Username"
                              name="bankName"
                              id="bankName"
                              value={SelectBank}
                              onChange={handleSelect}
                              isSearchable={true}
                              styles={customStyles}
                              isMulti
                            />
                            {BankValidationMsg && (
                              <span className="error_msg">{BankValidationMsg}</span>
                            )}
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-lg-12">
                          <div className="mb-3">
                            <RadioButton
                              name="bankname"
                              id={"bankname"}
                              className="radio-button"
                              labelName="Next"
                              labelClassName='com-btn w-100'
                              onClick={nextSlide}
                              validation={validation()}
                            />
                          </div>

                          <div className="mt-4 mb-5">
                            <div className={`${backBtn}`}>
                            <button
                              className="btn-prev"
                              name="prevKeep"
                              children=" Previous"
                              onClick={backClick}>
                              <span className="me-2"> <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" className="bi bi-arrow-left" viewBox="0 0 16 16">
                                <path fill-rule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8" />
                              </svg></span>
                              Previous
                            </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="pb-lg-1 pb-md-1 pb-sm-1 pb-1 mt-lg-2 mt-lg-2 mt-md-2 mt-sm-2">
            <img height="50" src="assets/img/safe and secure.png" alt="" />
          </div>
        </div>
      </div>
    </>
  );
};

export default BankName;
