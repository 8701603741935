import React, { useState } from "react";
import RadioButton from "../../../UI/RadioButtonOutLabel";
import TextField from "../../../UI/TextField";

const QuestionnairEmployment_MB_NEW_OPT = ({
  clsName,
  onselectAnswer,
  reff,
}) => {

  const labelclicked = async (e) => {
    localStorage.setItem("employment_status", e.target.value);
    onselectAnswer(e);
  };

  const notSureclicked = async (e) => {
    localStorage.setItem("employment_status", "Not Sure");
    onselectAnswer(e);
  };

  return (
    <>
      <div className={`radio-box animated fadeInUp  text-center mt-0 ${clsName}`} ref={reff}>
        <div className="mb-3 col-lg-8 offset-lg-2 col-12 ">
          <h3 className="mb-3">
            At the time you secured finance, what was your employment status?
          </h3>

          <RadioButton
            key="Employed"
            id="emply"
            value="Employed"
            name="employment"
            className="radio-button"
            labelName="Employed"
            labelClassName="option_but next88  "
            onClick={labelclicked}
            dataAnsId={3}
            dataQuestId={2}
          />

          <RadioButton
            value="Unemployed"
            name="employment"
            className="radio-button"
            labelName="Unemployed"
            labelClassName='option_but next88'
            onClick={labelclicked}
            // validation={validation()}
            key="Unemployed"
            id="unemply"
            dataAnsId={199}
            dataQuestId={2}
          />

          <RadioButton
            key="Self-employed"
            id="self"
            value="Self-employed"
            name="employment"
            className="radio-button"
            labelName="Self-employed"
            labelClassName="option_but next88"
            onClick={labelclicked}
            dataAnsId={4}
            dataQuestId={2}
          />

          <RadioButton
            key="Student"
            id="student"
            value="Student"
            name="employment"
            className="radio-button"
            labelName="Student"
            labelClassName="option_but next88"
            onClick={labelclicked}
            dataAnsId={5}
            dataQuestId={2}
          />

          <RadioButton
            key="Retired"
            id="retired"
            value="Retired"
            name="employment"
            className="radio-button"
            labelName="Retired"
            labelClassName="option_but next88"
            onClick={labelclicked}
            dataAnsId={6}
            dataQuestId={2}
          />

          <RadioButton
            key="Benefits"
            id="benefits"
            value="Benefits main source of income"
            name="employment"
            className="radio-button"
            labelName="Benefits main source of income"
            labelClassName="option_but next88  "
            onClick={labelclicked}
            dataAnsId={200}
            dataQuestId={2}
          />

          <RadioButton
            key="Part_time"
            id="part_time"
            value="Part-time job"
            name="employment"
            className="radio-button"
            labelName="Part-time job"
            labelClassName="option_but next88  "
            onClick={labelclicked}
            dataAnsId={201}
            dataQuestId={2}
          />

          <RadioButton
            key="Temporary"
            id="temporary"
            value="Temporary worker"
            name="employment"
            className="radio-button"
            labelName="Temporary worker"
            labelClassName="option_but next88  "
            onClick={labelclicked}
            dataAnsId={202}
            dataQuestId={2}
          />

          <RadioButton
            key="Long_Term"
            id="long_Term"
            value="Long Term Sick"
            name="employment"
            className="radio-button"
            labelName="Long Term Sick"
            labelClassName="option_but next88  "
            onClick={labelclicked}
            dataAnsId={203}
            dataQuestId={2}
          />

          <RadioButton
            key="Maternity"
            id="maternity"
            value="Maternity"
            name="employment"
            className="radio-button"
            labelName="Maternity"
            labelClassName="option_but next88  "
            onClick={labelclicked}
            dataAnsId={204}
            dataQuestId={2}
          />

          <RadioButton
            key="Rolling"
            id="rolling"
            value="Rolling/Zero hours contract"
            name="employment"
            className="radio-button"
            labelName="Rolling/Zero hours contract"
            labelClassName="option_but next88  "
            onClick={labelclicked}
            dataAnsId={205}
            dataQuestId={2}
          />
          <p className="text-center">
            <a className="skip-btn skip1"
              id="Not sure"
              value="Not sure"
              name="employment"
              data-ans-id={195}
              data-quest-id={2}
              onClick={notSureclicked}>Not sure</a>
          </p>
        </div>
      </div>
    </>
  );
};

export default QuestionnairEmployment_MB_NEW_OPT;
