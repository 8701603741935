import React, { useState } from "react";
import SelectBox from "../../../UI/SelectBox";
import InputButton from "../../../UI/InputButton";
import { Modal } from "react-bootstrap";
import { YearPossession } from "../../../../Constants/Constants";
import RadioButton from "../../../UI/RadioButton";

const InitialYear = ({
  saveInputAns,
  validationMsg,
  clearErrors,
  validation,
  trigger,
  getValues,
  backClick,
  onSelectAnswer,
  setYearSelected,
  splitForm,
  setClickedSlide,
  setQuest49,
}) => {
  const [claimModal, setshowClaimModal] = useState(false);
  const data = getValues();
  const handleCloseclaimModal = (e) => {
    onSelectAnswer(e);
    setshowClaimModal(false);
  };

  const selectedYear = async (e) => {
    let errorFlag = 0;
    let yearresult = await trigger("initialyears");
    if (!yearresult) {
      errorFlag = 1;
      return false;
    }
    let yearSelected = data.initialyears;
    setYearSelected(yearSelected);
    // if (yearSelected < 2016) {
      saveInputAns(e);
      // setshowClaimModal(true);
    // } else {
      setClickedSlide({ slide: "initialyears" });
    //   saveInputAns(e);
    // }
  };

  return (
    <>
      <div className="question-content mt-0 scale-down-ver-center  px-3 py-lg-3 py-md-3 py-sm-2 py-2">
        <div className="row">
          <div className="col-lg-8 mb-2">
            <SelectBox
              className="form-select"
              OptionValue={YearPossession}
              name="initialyears"
              id="initialyears"
              onChange={() => clearErrors("initialyears")}
              clearErrors={clearErrors}
              myRef={validation({ required: "Please select year" })}
              validationMsg={
                validationMsg.initialyears && validationMsg.initialyears.message
              }
            ></SelectBox>
          </div>
          <div className="col-lg-4 mb-2">
            <button
              type="button"
              className=" btn-comm2 py-lg-3 py-md-3 py-sm-2 py-2 w-100 nextBtn "
              id="nextscroll"
              name="initialyears"
              onClick={selectedYear}
              data-quest-id={28}
            >
              Next &#8594;
            </button>
          </div>
        </div>
      </div>

      <Modal
        size="md"
        show={claimModal}
        onHide={handleCloseclaimModal}
        centered
        backdrop="static"
        className="modal fade "
        id="cannotClaim"
      >
        <div className="modal-content">
          <Modal.Body>
            <div className="text-center my-3">
              <svg
                width="50px"
                height="50px"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8.5 9C8.22386 9 8 8.77614 8 8.5C8 8.22386 8.22386 8 8.5 8C8.77614 8 9 8.22386 9 8.5C9 8.77614 8.77614 9 8.5 9Z"
                  fill="#EF8019"
                  stroke="#EF8019"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M15.5 9C15.2239 9 15 8.77614 15 8.5C15 8.22386 15.2239 8 15.5 8C15.7761 8 16 8.22386 16 8.5C16 8.77614 15.7761 9 15.5 9Z"
                  fill="#EF8019"
                  stroke="#EF8019"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
                  stroke="#EF8019"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M7.5 15.5C7.5 15.5 9 13.5 12 13.5C15 13.5 16.5 15.5 16.5 15.5"
                  stroke="#EF8019"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
            <div className="text-center">
              <h3>
                <b>Please confirm if you agree with the statement</b>
              </h3>
              <p>
                I only recently understood I had cause to complain when TMS
                Legal represented my interests regarding irresponsible lending.
                I considered my financial issues to be my problem and didn't
                link it to any irresponsible lending by the lender.
              </p>
            </div>
            <div className="input-group  prevname-Btnpop justify-content-center">
              <button
                value="131"
                id="agreement"
                className="btn-comm2modalreject py-lg-3 py-md-3 py-sm-2 py-2 w-100 mb-3 rounded"
                name="agreeyear"
                labelName="AGREE"
                labelClassName="option_but radio"
                type="button"
                data-quest-id={49}
                validation={validation()}
                onClick={handleCloseclaimModal}
              >
                Agree
              </button>

              <button
                value="132"
                id="agreementtt"
                className="btn-comm2modalreject py-lg-3 py-md-3 py-sm-2 py-2 w-100 rounded"
                name="agreeyear"
                labelName="DISAGREE"
                labelClassName="option_but radio"
                type="button"
                data-quest-id={49}
                validation={validation()}
                onClick={handleCloseclaimModal}
              >
                Disagree
              </button>
            </div>
          </Modal.Body>
        </div>
      </Modal>
    </>
  );
};
export default InitialYear;
