import "../../../../assets/MeritPage/scss/MeritPage.scss";

const MeritPopupVAQ_4_3_2 = ({
  meritPagePopUp,
  popUpModalBody,
  meritPopUpLoader,
  hardshipQuestionModal,
  checkedTwo,
  checkedOne,
  meritPopUpContent,
  ConstantMeritList,
  combinedMeritPageData,
  handleMeritPopUp,
  onSelectAnswer,
  handleMeritPopUpClose
}) => {

  const cfirstName = localStorage.getItem("UcFname") ? localStorage.getItem("UcFname") : '';

  return (
    <div className="MeritPage">
      <div className={`modal blackover fade ${meritPagePopUp ? "show" : "hide"}`} id="sample" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered modal-lg">
          <div className={`modal-content  goodnews_popup ${popUpModalBody == "show" ? "show" : "hide"}`}>
            <div className="modal-body">
              <div className={`text-center max-400 m-auto thakyoumsg ${meritPopUpLoader == "show" ? "show" : "hide"}`}>
                <div className="pb-lg-2 mt-5 ">
                  <img height="50" src="assets/MeritPage/img/logo.png" alt="" />
                </div>
                <div className="my-4 mt-5">
                  <img height="50" src="assets/MeritPage/img/loader-review2.svg" alt="" />
                </div>
                <div className="">
                  <h4 className="h4-thakyou">Thank you <strong>{cfirstName}</strong> for completing our assessment, which we will output into a
                    questionnaire format.</h4>
                  <h5 className="fst-italic fs-6"> Please note – you are able to self represent your complaint.</h5>
                </div>
              </div>


              <div className={`goodnewsrow ${hardshipQuestionModal == "show" ? "show" : "hide"}`}>
                <div className="questionnaire19">
                  <div className="container">
                    <div id="quetWraper" class="questionnair-section form-style mb-5 ps-0">
                      <form name="split_form" id="user_form" autocomplete="off" >
                        <ul className="p-0 m-0 questionist mb-5">
                          <li>
                            <div className="questitem_v2 mt-5" id="slide9">
                              <h4 className="que-title p-3 "><b>From your responses, we understand that you've had difficulties making repayments to Vanquis on time while meeting other commitments and believe your case has merit because you told us:</b></h4>

                              <div className="question-content mt-0 scale-down-ver-center px-3 py-lg-3 py-md-3 py-sm-2 py-2 pb-3">
                                <div className="row">
                                  <div className="col-lg-6 col-md-12">
                                    <div className=" mb-2">
                                      <button
                                        type="button"
                                        value="99"
                                        className={`btn-comm2 py-lg-3 py-md-3 py-sm-2 py-2 w-100  noissue ${checkedTwo}`}
                                        name="struggle"
                                        onClick={onSelectAnswer}
                                        data-quest-id={29}
                                        data-ans-id={99}
                                        id="idee"
                                      >
                                        Yes
                                      </button>
                                    </div>
                                  </div>
                                  <div className="col-lg-6 col-md-12">
                                    <div className=" mb-2">
                                      <button
                                        type="button"
                                        value="100"
                                        className={` btn-comm2 py-lg-3 py-md-3 py-sm-2 py-2 w-100  noissue${checkedOne}`}
                                        name="struggle"
                                        onClick={onSelectAnswer}
                                        data-quest-id={29}
                                        data-ans-id={100}
                                        id="ide2"
                                      >
                                        No
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>


                            </div>
                          </li>
                        </ul>
                      </form>
                    </div>
                  </div>
                </div>
              </div>


              <div className={`goodnewsrow ${meritPopUpContent == "show" ? "show" : "hide"}`}>
                <div className="text-end">
                  <button
                    type="button"
                    className="btn-close close22"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    onClick={handleMeritPopUpClose}
                  >
                  </button>
                </div>
                <div className="pb-3 text-center px-lg-4 px-md-4  px-sm-0">
                  <div className="title mb-2"> <strong className="textgood text-success">GOOD NEWS,</strong> </div>
                  <h4> From your responses, we understand that you've had difficulties making repayments to Vanquis on time while meeting other commitments and believe your case has merit because you told us:</h4>
                  <div className="d-flex justify-content-center  ">
                    <ul className="p-0 m-0 mt-2  text-start row  justify-content-center listfi ">
                      {ConstantMeritList.ReliantBorrowing && (
                        <li className="align-items-center">
                          <a href={`/PreviewMeritPage?category=1&values=${combinedMeritPageData}`} target="_blank" > Reliant on further borrowing </a>
                        </li>
                      )}
                      {ConstantMeritList.Overindebtness && (
                        <li className="align-items-center">
                          <a href={`/PreviewMeritPage?category=2&values=${combinedMeritPageData}`} target="_blank" > Overindebtedness </a>
                        </li>
                      )}
                      {ConstantMeritList.DebtEssentialBills && (
                        <li className="align-items-center">
                          <a href={`/PreviewMeritPage?category=3&values=${combinedMeritPageData}`} target="_blank" > Caused further debt with essential bills </a>
                        </li>
                      )}
                      {ConstantMeritList.CreditFileInformation && (
                        <li className="align-items-center">
                          <a href={`/PreviewMeritPage?category=4&values=${combinedMeritPageData}`} target="_blank" > Adverse credit file information </a>
                        </li>
                      )}
                      {ConstantMeritList.ImpactedNegatively && (
                        <li className="align-items-center">
                          <a href={`/PreviewMeritPage?category=5&values=${combinedMeritPageData}`} target="_blank" > Negative impact on circumstances </a>
                        </li>
                      )}
                      {ConstantMeritList.HighUtilisation && (
                        <li className="align-items-center">
                          <a href={`/PreviewMeritPage?category=6&values=${combinedMeritPageData}`} target="_blank" > High utilisation of existing credit </a>
                        </li>
                      )}
                      {ConstantMeritList.StruggledRepayments && (
                        <li className="align-items-center">
                          <a href={`/PreviewMeritPage?category=7&values=${combinedMeritPageData}`} target="_blank" > Struggling to make repayments </a>
                        </li>
                      )}
                      {ConstantMeritList.ClientFinancialArrears && (
                        <li className="align-items-center">
                          <a href={`/PreviewMeritPage?category=8&values=${combinedMeritPageData}`} target="_blank" > Financial arrears </a>
                        </li>
                      )}
                      {ConstantMeritList.CardNotManaged && (
                        <li className="align-items-center">
                          <a href={`/PreviewMeritPage?category=9&values=${combinedMeritPageData}`} target="_blank" > Credit account was not managed well </a>
                        </li>
                      )}
                      {ConstantMeritList.UnableRepayments && (
                        <li className="align-items-center">
                          <a href={`/PreviewMeritPage?category=10&values=${combinedMeritPageData}`} target="_blank" > Unable to maintain sustainable repayments </a>
                        </li>
                      )}

                    </ul>
                  </div>
                  <div className="mt-4 mb-3">
                    <h5 className="text-arrow"> I understand the merits of my claim and that I am able to self represent. To instruct TMS Legal to pursue your claim, Click the button below </h5>
                  </div>
                  <div className="mb-3">
                    <button
                      id="proceedBtn"
                      className=" btn-comm2 p-2    py-3 px-4 pulse"
                      onClick={handleMeritPopUp}
                    >
                      <span>Proceed with my claim</span>
                    </button>
                  </div>
                  <p className="fst-italic">  Next you just need to review and sign our online letter of authority, your questionnaire, our terms, DSAR request for your bank statements, FOS documentation and we will then initiate our background checking and onboarding process.
                  </p>
                  <p className="fst-italic">
                    We will complete our KYC (know your customer), checks, process your claim and send it to the lender.
                  </p>
                  <p className="fst-italic"> Should your claim be rejected, and we see merit in sending it to the Financial Ombudsman, we will inform you of this decision at that time.
                  </p>
                  <p className="fst-italic">Good to know: You do not need to use a no win no fee solicitor to make a complaint to your lender. If your complaint is not successful you can refer it to the Financial Ombudsman service for free.
                  </p>

                  <p className="fst-italic">Please note the lender may reduce the credit limit/suspend the card or remove/close the product. You acknowledge that by making this unaffordable/irresponsible lending complaint, it may mean that you won't be able to borrow from the lender in future.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MeritPopupVAQ_4_3_2;
