import React, { useState } from "react";
import RadioButtonQuest from "../../../../UI/RadioButtonQuest";
import InputButton from "../../../../UI/InputButton";
import Modal from "react-bootstrap/Modal";

const OldCard = ({ onselectAnswer, reff, display, trigger,isLoan }) => {

  const labelclicked = async (e) => {
      onselectAnswer(e);
  };

  return (
    <>
      <div style={{ display: display }}>
        <div className="radio-box question animated fadeInUp" ref={reff}>
          <h3>
            {isLoan ? 'Did you take the loan over 6 years ago?' : 'Did you take the card over 6 years ago?'}
          </h3>
          <RadioButtonQuest
            key="No"
            className="radio-button"
            labelName="No"
            labelClassName="option_but next-2"
            onClick={labelclicked}
            value="237"
            name="question_81"
            id="oneaaa"
            dataQuestId={81}
            dataAnsId={237}
            dataAnsLabel="No"
          />
          <RadioButtonQuest
            key="Yes"
            className="radio-button"
            labelName="Yes"
            labelClassName="option_but next-2"
            onClick={labelclicked}
            value="236"
            name="question_81"
            id="twobbb"
            dataQuestId={81}
            dataAnsId={236}
            dataAnsLabel="Yes"
          />
        </div>
      </div>
    </>
  );
};

export default OldCard;
