export const getAllLocalStorageData = () => {
    const localStorageData = {};
  
    // Retrieve all keys in localStorage
    const keys = Object.keys(localStorage);
  
    // Iterate through keys and get corresponding values
    keys.forEach(key => {
      const value = localStorage.getItem(key);
      localStorageData[key] = value;
    });
  
    return localStorageData;
  };
  
