import React from "react";
import AdtopiaLoadLP from "../../../Utility/AdtopiaLoadLP.js";
import Footer from "../../Includes/Layouts/VAQ_UB_4_3/Footer.js";
import FbViewPixel from "../../../Utility/FbViewPixel.js";
import "../../../assets/VAQ_UB_4_3_1_C/scss/VAQ_UB_4_3_1_C.scss";
import AccordSection from "../../Includes/Layouts/VAQ_UB_4_3/AccordSection.js";
import FormVAQ_UB_4_3_1_C from "../../Forms/FormVAQ_UB_4_3_1_C.js";

const VAQ_UB_4_3_1_C = () => {
  return (
    <>
      <AdtopiaLoadLP pageType="LP" splitName="VAQ_UB_4_3_1_C" />
      <FbViewPixel />
      <div className="VAQ_UB_4_3_1_C">
        <FormVAQ_UB_4_3_1_C />
        <AccordSection />
        <Footer />
      </div>
    </>
  );
};

export default VAQ_UB_4_3_1_C;
