import React, { useState, useEffect, useRef, useReducer } from "react";
import { useForm } from "react-hook-form";
import QuestionnaireFlpQ5 from "./MoneyBarn/QuestionnaireFlpQ5";
import {
  questinnare1,
  questinnare2,
  questinnaire76s3FLP,
  questinnare14,
  questinnare15,
  questinnare16,
} from "../../../../Constants/Questions";
import { useFollowupUserQuestions } from "../../../../Hooks/useFollowupUserQuestions";
import SignatureForm from "./SignatureForm";
import { useUserVerify } from "../../../../Hooks/useUserVerify";
import { userMilestoneUpdate } from "../../../../Hooks/userMilestoneUpdate";
import { useHistory } from "react-router-dom";
import { useTotalCarCheck } from "../../../../Hooks/useTotalCarCheck";
import { queryString } from "../../../../Utility/QueryString";
import { useOpenBanking } from "../../../../Hooks/useOpenBanking";
import QuestionnaireFlpVerify from "./MoneyBarn/QuestionnaireFlpVerify";
import QuestionnaireBankStmtConfirm from "./MoneyBarn/QuestionnaireBankStmtConfirm";
import QuestionnaireFlpQ76 from "./MoneyBarn/QuestionnaireFlpQ76";

const initialState = {
  hardships_or_struggle: false,
  q76: false,
  q36: false,
  provide_statement:false,
  signature: false,
  car_reg: false,
  keeper_date: false,
  loan_date: false,
  bank_select: false,
  bankStmtUpload: false,
  income_and_expenditure: false,
  email_confirmation: false,
  reg_email: false,
  private_plate_confirm: false,
  private_plate: false,
  finance_active: false,
  still_have_vehicle: false,
  employment_status: false,
  dependants: false,
  percentage: 0,
};
const SlideReducer = (state, action) => {
  switch (action.type) {
    case "showSlideChange": {
       if (action.payload == "hardships_or_struggle") {
        return {
          ...state,
          hardships_or_struggle: true,
          q76: false,
          q36: false,
          provide_statement:false,
          signature: false,
          car_reg: false,
          keeper_date: false,
          loan_date: false,
          bank_select: false,
          ob_consent: false,
          bankStmtUpload: false,
          income_and_expenditure: false,
          email_confirmation: false,
          reg_email: false,
          private_plate_confirm: false,
          private_plate: false,
          finance_active: false,
          still_have_vehicle: false,
          employment_status: false,
          dependants: false,
          percentage: 10,
        };
      }else if (action.payload == "q76") {
        return {
          ...state,
          hardships_or_struggle: false,
          q76: true,
          q36: false,
          signature: false,
          provide_statement:false,
          car_reg: false,
          keeper_date: false,
          loan_date: false,
          bank_select: false,
          ob_consent: false,
          bankStmtUpload: false,
          income_and_expenditure: false,
          email_confirmation: false,
          reg_email: false,
          private_plate_confirm: false,
          private_plate: false,
          finance_active: false,
          still_have_vehicle: false,
          employment_status: false,
          dependants: false,
          percentage: 10,
        };
      } else if (action.payload == "q36") {
        return {
          ...state,
          hardships_or_struggle: false,
          q76: false,
          q36: true,
          signature: false,
          provide_statement:false,
          car_reg: false,
          keeper_date: false,
          loan_date: false,
          bank_select: false,
          ob_consent: false,
          bankStmtUpload: false,
          income_and_expenditure: false,
          email_confirmation: false,
          reg_email: false,
          private_plate_confirm: false,
          private_plate: false,
          finance_active: false,
          still_have_vehicle: false,
          employment_status: false,
          dependants: false,
          percentage: 10,
        };
      } 
      else if (action.payload == "provide_statement") {
        return {
          ...state,
          hardships_or_struggle: false,
          q76: false,
          q36: false,
          provide_statement:true,
          signature: false,
          car_reg: false,
          keeper_date: false,
          loan_date: false,
          bank_select: false,
          ob_consent: false,
          bankStmtUpload: false,
          income_and_expenditure: false,
          email_confirmation: false,
          reg_email: false,
          private_plate_confirm: false,
          private_plate: false,
          finance_active: false,
          still_have_vehicle: false,
          employment_status: false,
          dependants: false,
          percentage: 10,
        };
      } 
      else if (action.payload == "signature") {
        return {
          ...state,
          hardships_or_struggle: false,
          q76: false,
          q36: false,
          signature: true,
          car_reg: false,
          keeper_date: false,
          loan_date: false,
          bank_select: false,
          ob_consent: false,
          bankStmtUpload: false,
          income_and_expenditure: false,
          email_confirmation: false,
          reg_email: false,
          private_plate_confirm: false,
          private_plate: false,
          finance_active: false,
          still_have_vehicle: false,
          employment_status: false,
          dependants: false,
          percentage: 15,
        };
      } else {
        return {
          ...state,
        };
      }
    }
  }
};

const MoneyBarnFlpMB = ({
  allFollowupData,
  pendingSlide,
  setshowLoad,
  currentUuid,
  keeperDates,
  userEmail,
  setPendingSlide,
  setDivShow,
  verifyData,
  t2aStatus,
  splitName,
  lenderList,
  userDetails,
  agreementDate,
  setAgreementDate,
  setDivStyle,
  setLoaderContent,
  reverseSlide 
}) => {
  const { register, errors, trigger, setError, clearErrors, getValues } =
    useForm({
      mode: "onBlur",
      reValidateMode: "onChange",
      defaultValues: {},
      resolver: undefined,
      context: undefined,
      criteriaMode: "firstError",
      shouldFocusError: true,
      shouldUnregister: true,
    });

  const [state, dispatch] = useReducer(SlideReducer, initialState);
  const [optionSelected] = useState([]);
  const [optSelected] = useState([]);
  const { saveFollowupUserQuestions, isLoading } = useFollowupUserQuestions();
  const splitForm = useRef(null);
  const [vehicle, setVehicle] = useState("");
  const [validKeeperDate, setValidKeeperDate] = useState(false);
  const { getUserVerfyValidation, saveFollowupUserVerify } = useUserVerify();
  const { updateMilestone } = userMilestoneUpdate();
  const history = useHistory();
  const { totalCarCheckApi } = useTotalCarCheck();
  const [totalCarCheck, settotalCarCheck] = useState(null);
  const [selectBank, setSelectBank] = useState([]);
  const { getCustomerCheck, milestoneChase } = useOpenBanking();
  const [hidePrev, setHidePrev] = useState("hide");

  // useEffect(() => {
  //   slideChange(pendingSlide);
  // }, [pendingSlide]);

  useEffect(() => {
    slideChange(pendingSlide);
  }, []);

  // const slideChange = (pendingArr) => {

  //   let pendingCount = pendingArr.length;
  //   if (pendingCount == 0) {
  //     history.push("/view_docs_flp-s2?uuid=" + userDetails.uuid);
  //   }

  //   pendingArr.every((element) => {
  //     dispatch({ type: "showSlideChange", payload: element });
  //   });
  // };

  const slideChange = (pendingArr) => {
    
    // Use Set to track unique elements while maintaining order
    const uniqueSet = new Set();
  
    // Filter duplicates and maintain order
    const uniquePendingArr = pendingArr.filter((element) => {
      if (!uniqueSet.has(element)) {
        uniqueSet.add(element);
        return true;
      }
      return false;
    });

    setPendingSlide(uniquePendingArr);
    let pendingCount = uniquePendingArr.length;
  
    if (pendingCount === 0) {
      history.push("/checkListFLPS3?" + queryString);
    }
  
    uniquePendingArr.every((element) => {
      dispatch({ type: "showSlideChange", payload: element });
    });
  };
  
  useEffect(() => {
    let skipBnkStmt= localStorage.getItem('skipBnkStmt');
    let skipBtn= localStorage.getItem('skipBtn');

    if (skipBnkStmt == 1) {
     setHidePrev('hide');
     removeFromPending("income_and_expenditure");
    } else if(skipBtn == 1 ) {
      if (pendingSlide.includes("income_and_expenditure")) {
        setHidePrev('show');   
      } else {
      setHidePrev('hide');
    }
    }
  }, []);

  const onselectAnswer = async (e) => {
    let selectedAns = parseInt(e.target.getAttribute("data-ans-id"));
    let selectedQuest = parseInt(e.target.getAttribute("data-quest-id"));
    let anslabel = e.target.getAttribute("data-ans-label");

    let flage = false;
    let question_data = {};
    if (e.target.name == "btn_qest_36") {
      if (selectedQuest) {
        flage = true;
        question_data = {
          question_id: 36,
          option_id: null,
          answer_text: anslabel,
          input_answer: optionSelected,
        };
      }
      setLoaderContent(Capitalize(userDetails.txtFName)+', Analysing your Results');
      removeFromPending("q36");
    } else if (e.target.name == "btn_qest_76") {
      if (selectedQuest) {
        flage = true;
        question_data = {
          question_id: 76,
          option_id: null,
          answer_text: anslabel,
          input_answer: optSelected,
        };
      }
      removeFromPending("q76");
    }
     else if (e.target.name == "verify") {
      if (selectedAns && selectedQuest) {
        flage = true;
        question_data = {
          question_id: selectedQuest,
          option_id: selectedAns,
          answer_text: "",
          input_answer: "",
        };
      }
      removeFromPending("hardships_or_struggle");
    } else if (e.target.name == "question_11") {
      if (selectedAns && selectedQuest) {
        flage = true;
        question_data = {
          question_id: selectedQuest,
          option_id: null,
          answer_text: "",
          input_answer: e.target.value,
          valid_keeper_date: e.target.id,
        };
      }
    } else if (e.target.name == "question_61") {
      setLoaderContent(Capitalize(userDetails.txtFName)+', Analysing your Results');
      if (selectedAns && selectedQuest) {
        flage = true;
        question_data = {
          question_id: selectedQuest,
          option_id: selectedAns,
          answer_text: anslabel,
          input_answer: "",
        };
      }
      removeFromPending("email_confirmation");
      if (selectedAns == 162 || selectedAns == 195 ) {
        removeFromPending("reg_email");
      }else{   
        window.scrollTo(0, 0);
        // pendingSlide.unshift("reg_email");
        // slideChange(pendingSlide);

        const poppedElement = pendingSlide.shift(); // Pop the first element

        if (poppedElement !== "reg_email") {
          pendingSlide.unshift("reg_email");
        }
      }
      // alert(selectedAns);
    } else if (e.target.name == "question_43") {

      if (selectedAns && selectedQuest) {
        flage = true;
        question_data = {
          question_id: selectedQuest,
          option_id: selectedAns,
          answer_text: anslabel,
          input_answer: "",
        };
      }
      removeFromPending("private_plate_confirm");
      if (selectedAns == 122 || selectedAns == 195) {
        removeFromPending("private_plate");
      }else{   
        window.scrollTo(0, 0);
        pendingSlide.unshift("private_plate");
        slideChange(pendingSlide);
      }
    } else if (e.target.name == "stillactive") {;
      if (selectedAns && selectedQuest) {
        flage = true;
        question_data = {
          question_id: selectedQuest,
          option_id: selectedAns,
          answer_text: "",
          input_answer:"",
        };
      }
      removeFromPending("finance_active");
    } else if (e.target.name == "btn_qest_44a") {
      if (selectedAns && selectedQuest) {
        flage = true;
        question_data = {
          question_id: selectedQuest,
          option_id: selectedAns,
          answer_text: "",
          input_answer: anslabel,
        };
      }
      removeFromPending("private_plate");
    } else if (e.target.name == "stillVehicle") {
      if (selectedAns && selectedQuest) {
        flage = true;
        question_data = {
          question_id: selectedQuest,
          option_id: selectedAns,
          answer_text: "",
          input_answer: e.target.value,
        };
      }
      removeFromPending("still_have_vehicle");
    } else if (e.target.name == "employment") {
      if (selectedAns && selectedQuest) {
        flage = true;
        question_data = {
          question_id: selectedQuest,
          option_id: selectedAns,
          answer_text: "",
          input_answer: e.target.value,
        };
      }
      removeFromPending("employment_status");
    } else if (e.target.name == "dependent") {
      if (selectedAns && selectedQuest) {
        flage = true;
        question_data = {
          question_id: selectedQuest,
          option_id: selectedAns,
          answer_text: "",
          input_answer: e.target.value,
        };
      }
      removeFromPending("dependants");
    }
    else if (e.target.name == "question_73" || e.target.name == "question_74") {
      if (selectedAns && selectedQuest) {
        flage = true;
        question_data = {
          question_id: selectedQuest,
          option_id: selectedAns,
          answer_text: anslabel,
          input_answer: "",
        };
      }
      removeFromPending("provide_statement");
    }
    else {
      if (selectedAns && selectedQuest) {
        flage = true;
        question_data = {
          question_id: selectedQuest,
          option_id: selectedAns,
          answer_text: anslabel,
          input_answer: "",
        };
      }
    }

    if (flage) {
      storeFollowupQuestionnire(question_data);
      var existingEntries = JSON.parse(localStorage.getItem("questionData"));
      if (existingEntries == null) existingEntries = [];
      localStorage.setItem(
        "currentquestionData",
        JSON.stringify(question_data)
      );
      existingEntries.push(question_data);
      localStorage.setItem("questionData", JSON.stringify(existingEntries));
    }

    // if(e.target.name == 'question_43' && selectedAns == '121') {
    //   setQuest43('yes');
    // }
  };

  const removeFromPending = (slidename) => {
    window.scrollTo(0, 0);
    const index = pendingSlide.indexOf(slidename);
    if (index > -1) {
      pendingSlide.splice(index, 1);
      // pendingSlide = pendingSlide.filter(item => item !== slidename);
    }
    slideChange(pendingSlide);

  };

  const addPending = (slidename) => {

    const index = reverseSlide.indexOf(slidename);
    const slideShow = reverseSlide[index+1];


    // if (!pendingSlide.includes(slideShow)) {
    if(slidename == "q76"){
      pendingSlide.unshift("hardships_or_struggle");
    } else if(slidename == "q36"){
      pendingSlide.unshift("q76");
    }else if(slidename == "provide_statement"){
      pendingSlide.unshift("q36");
    }
    else if(slidename == "loan_date"){
      pendingSlide.unshift("keeper_date");
      pendingSlide.unshift("car_reg");
    }else if(slidename == "bank_select"){
      pendingSlide.unshift("loan_date");
      pendingSlide.unshift("keeper_date");
      pendingSlide.unshift("car_reg");
    }else if(slidename == "income_and_expenditure"){
      pendingSlide.unshift("income_and_expenditure");
    }else if(slidename == "reg_email"){
      pendingSlide.unshift("reg_email");
      pendingSlide.unshift("email_confirmation");
    }else if(slidename == "private_plate"){
      pendingSlide.unshift("private_plate_confirm");
    }else if(slidename == "stillactive"){
      pendingSlide.unshift("private_plate_confirm");
    }else if(slidename == "private_plate"){
      pendingSlide.unshift("finance_active");
    }else if(slidename == "still_have_vehicle"){
      pendingSlide.unshift("finance_active");
    }else if(slidename == "employment_status"){
      pendingSlide.unshift("still_have_vehicle");
    }else if(slidename == "dependants"){
      pendingSlide.unshift("employment_status");
    }else{
      pendingSlide.unshift(slideShow);
    }
    slideChange(pendingSlide);
  // }
    window.scrollTo(0, 0);
  };

  const storeFollowupQuestionnire = async (question_data) => {
    const response = await saveFollowupUserQuestions(
      question_data,
      allFollowupData,
      "followup_question_storeS2"
    );
  };

  const checkCarRegistration = (carRegNo) => {
    setLoaderContent(Capitalize(userDetails.txtFName)+", Please wait while we save your progress...");
    removeFromPending("car_reg");
    let selectedQuest = 10;
    let selectedAns = null;

    if (selectedQuest) {
      let question_data = {
        question_id: selectedQuest,
        option_id: selectedAns,
        answer_text: carRegNo,
        input_answer: carRegNo,
      };
      storeFollowupQuestionnire(question_data);
      var existingEntries = JSON.parse(localStorage.getItem("questionData"));
      if (existingEntries == null) existingEntries = [];
      localStorage.setItem(
        "currentquestionData",
        JSON.stringify(question_data)
      );
      existingEntries.push(question_data);
      localStorage.setItem("questionData", JSON.stringify(existingEntries));
    }
  };

  const saveVehicleDetails = (vehicleData) => {
    setVehicle(vehicleData);
  };

  const Capitalize = (str) =>{
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  const slideSkip = () => {
    removeFromPending("car_reg");
    removeFromPending("keeper_date");
    removeFromPending("private_plate_confirm");
    removeFromPending("private_plate");
    setLoaderContent(Capitalize(userDetails.txtFName)+", Please wait while we save your progress...");
  };

  const analyzingFn = async (date_index) => {
    setshowLoad("show");
    setDivStyle("hide");
    if (date_index == 0) {
      (async () => {
        const totalCarCheckApiData = await totalCarCheckApi(
          vehicle.reg_no,
          verifyData.uuid,
          splitName
        );

        savetotalCarCheck(totalCarCheckApiData.data.vehicleData);

        let tcc_lender = totalCarCheckApiData.data.vehicleData.finance_company;
        let agreement_date =
          totalCarCheckApiData.data.vehicleData.agreement_date;

        if (
          tcc_lender == "Moneybarn" &&
          (agreement_date != null || agreement_date != "")
        ) {
          // Create a new Date object
          var dateObj = new Date(agreement_date);

          // Extract the year, month, and day
          var year = dateObj.getFullYear();
          var month = dateObj.toLocaleString("default", { month: "long" });

          var formattedDate = year + " " + month;
          setAgreementDate(formattedDate);
          removeFromPending("keeper_date");
          removeFromPending("loan_date");
        } else {
          removeFromPending("keeper_date");
        }
      })();
    } else {
      removeFromPending("keeper_date");
    }

    const useruuid = verifyData.uuid;
    const fName = verifyData.fName;
    const lName = verifyData.lName;
    const pCode = verifyData.pCode;
    const addressLine1 = verifyData.addressLine1;
    const dobDay = verifyData.day;
    const dobMonth = verifyData.month;
    const dobYear = verifyData.year;
    const addressLine2 = verifyData.addressLine2;
    const postTown = verifyData.postTown;
    const country = verifyData.country;
    const title = verifyData.title;
    const addressLine3 = verifyData.addressLine3;
    const addressLine4 = verifyData.addressLine4;
    const county = verifyData.county;

    if (
      useruuid &&
      fName &&
      lName &&
      pCode &&
      addressLine1 &&
      t2aStatus == null
    ) {
      (async () => {
        const response = await getUserVerfyValidation(
          useruuid,
          fName,
          lName,
          pCode,
          addressLine1,
          dobYear,
          dobMonth,
          dobDay,
          addressLine2,
          postTown,
          country,
          title,
          addressLine3,
          addressLine4,
          county
        );
        storeT2aResponse(response.data);

        if (response.data.status == "success") {
          localStorage.setItem("t2a_result", 1);
          setshowLoad("hide");
          setDivStyle("how");
        } else {
          localStorage.setItem("t2a_result", 0);
          setshowLoad("hide");
          setDivStyle("how");
        }
      })();
    } else {
      localStorage.setItem("t2a_result", t2aStatus);
      setshowLoad("hide");
      setDivStyle("how");
      let email_skip = localStorage.getItem("email_doc");
      if (useruuid && email_skip != 1) {
        (async () => {
          const response = await updateMilestone(
            useruuid,
            t2aStatus,
            allFollowupData
          );
        })();
      }
    }
  };

  const storeT2aResponse = async (t2a_data) => {
    const response = await saveFollowupUserVerify(
      allFollowupData,
      t2a_data,
      "person_verify_store_flp",
      queryString,
      verifyData
    );
  };
  const savetotalCarCheck = (totalCarCheck) => {
    settotalCarCheck(totalCarCheck);
  };

  const saveInputAns = async (e) => {
    let selectedQuest = parseInt(e.target.getAttribute("data-quest-id"));
    const valuesreg = getValues();
    let regPlate = valuesreg["question_44"];
    let agreementNo = valuesreg["question_45"];
    let question_48 = valuesreg["question_48"];
    let modalAgreementNo = valuesreg["modal_question_45"];
    let flage = false;
    let question_data = {};

    if (selectedQuest == "44" && regPlate) {
      flage = true;
      question_data = {
        question_id: selectedQuest,
        option_id: null,
        answer_text: "",
        input_answer: regPlate,
      };
      removeFromPending("private_plate");
    }
    if (selectedQuest == "45" && agreementNo) {
      flage = true;
      question_data = {
        question_id: selectedQuest,
        option_id: null,
        answer_text: "",
        input_answer: agreementNo,
      };
      removeFromPending("slide1");
    }
    if (selectedQuest == "45" && modalAgreementNo) {
      //pushPageChange();
      removeFromPending("slide10");
      flage = true;
      question_data = {
        question_id: selectedQuest,
        option_id: null,
        answer_text: "",
        input_answer: modalAgreementNo,
      };
    }
    if (selectedQuest == "48" && question_48) {
      flage = true;
      question_data = {
        question_id: selectedQuest,
        option_id: null,
        answer_text: "",
        input_answer: question_48,
      };
      removeFromPending("reg_email");
    }

    if (flage) {
      storeFollowupQuestionnire(question_data);
      var existingEntries = JSON.parse(localStorage.getItem("questionData"));
      if (existingEntries == null) existingEntries = [];
      localStorage.setItem(
        "currentquestionData",
        JSON.stringify(question_data)
      );
      existingEntries.push(question_data);
      localStorage.setItem("questionData", JSON.stringify(existingEntries));
    }
  };

  const saveSlide4 = async (e) => {
    let selectedAns = parseInt(e.target.getAttribute("data-ans-id"));
    let selectedQuest = parseInt(e.target.getAttribute("data-quest-id"));
    let anslabel = e.target.getAttribute("data-ans-label");

    // setCompletedQuestArr([...completedQuestArr, selectedQuest, 32, 33, 35]);

    var answer31 = splitForm.current["question_31"].value;
    var answer32 = splitForm.current["question_32"].value;
    var answer33 = splitForm.current["question_33"].value;
    //var answer34 = splitForm.current["question_34"].value;
    var answer35 = splitForm.current["question_35"].value;

    var slide_array = [
      ["31", answer31],
      ["32", answer32],
      ["33", answer33],
      //["34", answer34],
      ["35", answer35],
    ];

    var question_data = {
      slide_4: slide_array,
    };
    removeFromPending('income_and_expenditure');
    storeFollowupQuestionnire(question_data);

    let _existingEntries = JSON.parse(localStorage.getItem("questionData"));
    if (_existingEntries == null) _existingEntries = [];
    _existingEntries.push(question_data);
    localStorage.setItem("questionData", JSON.stringify(_existingEntries));
  };

  const matchCheckFn = async (selectBank) => {
    setLoaderContent(Capitalize(userDetails.txtFName)+', Analysing your Results');
    setshowLoad("show");
    setDivStyle("hide");
    let t2a_status = localStorage.getItem("t2a_result");

    if (t2a_status == undefined || t2a_status == "null" || t2a_status == "") {
      await manualT2aFn(selectBank);
    } else {
      let matchCheck = await getCustomerCheck(
        { uuid: userDetails.uuid },
        userDetails.user_id,
        agreementDate,
        [selectBank]
      );
      if (matchCheck.data.status === "success") {
        let budLender = matchCheck.data.provider;
        localStorage.setItem("bud_lender", budLender);
        localStorage.setItem("match_check", 1);
        const uploadPage = "/proceed-ob-s2" + "?" + queryString;
        window.location.href = uploadPage;
      } else {
        localStorage.setItem("match_check", 0);
        const uploadPage = "/ob_bank_stmt_upload-s2" + "?" + queryString;
        window.location.href = uploadPage;
      }
    }
  };

  const manualT2aFn = async (selectBank) => {
    const useruuid = verifyData.uuid;
    const fName = verifyData.fName;
    const lName = verifyData.lName;
    const pCode = verifyData.pCode;
    const addressLine1 = verifyData.addressLine1;
    const dobDay = verifyData.day;
    const dobMonth = verifyData.month;
    const dobYear = verifyData.year;
    const addressLine2 = verifyData.addressLine2;
    const postTown = verifyData.postTown;
    const country = verifyData.country;
    const title = verifyData.title;
    const addressLine3 = verifyData.addressLine3;
    const addressLine4 = verifyData.addressLine4;
    const county = verifyData.county;

    if (
      useruuid &&
      fName &&
      lName &&
      pCode &&
      addressLine1 &&
      t2aStatus == null
    ) {
      (async () => {
        const response = await getUserVerfyValidation(
          useruuid,
          fName,
          lName,
          pCode,
          addressLine1,
          dobYear,
          dobMonth,
          dobDay,
          addressLine2,
          postTown,
          country,
          title,
          addressLine3,
          addressLine4,
          county
        );
        storeT2aResponse(response.data);

        if (response.data.status == "success") {
          localStorage.setItem("t2a_result", 1);

          let matchCheck = await getCustomerCheck(
            { uuid: userDetails.uuid },
            userDetails.user_id,
            agreementDate,
            [selectBank]
          );
          if (matchCheck.data.status === "success") {
            let budLender = matchCheck.data.provider;
            localStorage.setItem("bud_lender", budLender);
            localStorage.setItem("match_check", 1);
            const uploadPage = "/proceed-ob-s2" + "?" + queryString;
            window.location.href = uploadPage;
            setshowLoad("hide");
            setDivStyle("how");
          } else {
            localStorage.setItem("match_check", 0);
            const uploadPage = "/ob_bank_stmt_upload-s2" + "?" + queryString;
            window.location.href = uploadPage;
          }
        } else {
          localStorage.setItem("t2a_result", 0);
          let matchCheck = await getCustomerCheck(
            { uuid: userDetails.uuid },
            userDetails.user_id,
            agreementDate,
            [selectBank]
          );
          if (matchCheck.data.status === "success") {
            let budLender = matchCheck.data.provider;
            localStorage.setItem("bud_lender", budLender);
            localStorage.setItem("match_check", 1);
            const uploadPage = "/proceed-ob-s2" + "?" + queryString;
            window.location.href = uploadPage;
          } else {
            localStorage.setItem("match_check", 0);
            const uploadPage = "/ob_bank_stmt_upload-s2" + "?" + queryString;
            window.location.href = uploadPage;
          }
        }
      })();
    } else {
      localStorage.setItem("t2a_result", t2aStatus);
      setshowLoad("hide");
      setDivStyle("how");
      let email_skip = localStorage.getItem("email_doc");
      if (useruuid && email_skip != 1) {
        (async () => {
          const response = await updateMilestone(
            useruuid,
            t2aStatus,
            allFollowupData
          );
        })();
      }
    }
  };

  return (
    <>
      {state.signature ? (
        <SignatureForm
          allFollowupData={allFollowupData}
          removeFromPending={removeFromPending}
          splitData={userDetails}
          split_name={splitName}
          uuid={currentUuid}
        />
      ) : (
        <div className="tms-wrap2 bg-main-pr">
          <div
            className="main text-center main-div "
            style={{ display: "block" }}
          >
            <div className="brand-logo mt-lg-0 mt-md-2 mt-sm-2 mt-2">
              <img
                height="65"
                src="assets/img/a00187b31dd5a4ece0c8f809b69962eb.png"
                alt=""
              />
            </div>

            <div className="main-form-wraper form-part">
              <div className="container">
                <div className="item-cent-flx ">
                  <form
                    ref={splitForm}
                    name="split_form"
                    id="user_form"
                    method="POST"
                    autoComplete="off"
                  >

                    {state.hardships_or_struggle && (
                      <QuestionnaireFlpVerify
                        questArr={questinnare16}
                        onselectAnswer={onselectAnswer}
                        validation={register}
                      />
                    )}
                      <QuestionnaireFlpQ5
                          className={(state.q36 == true) ? 'show' : 'hide'}
                          questArr={questinnare2}
                          onselectAnswer={onselectAnswer}
                          optionSelected={optionSelected}
                          addPending={addPending}
                      />
                      <QuestionnaireFlpQ76
                          className={(state.q76 == true) ? 'show' : 'hide'}
                          questArr={questinnaire76s3FLP}
                          onselectAnswer={onselectAnswer}
                          optSelected={optSelected}
                          addPending={addPending}
                      />
                      <QuestionnaireBankStmtConfirm
                        onselectAnswer={onselectAnswer}
                        className={(state.provide_statement == true) ? 'show' : 'hide'}
                        userEmail={userEmail}
                        validation={register}
                        validationMsg={errors}
                        getValues={getValues}
                        trigger={trigger}
                        addPending={addPending}
                      />
                  </form>
                </div>
              </div>
              <div className="mb-lg-5 mt-3 pt-3 secure ">
                <img
                  height="50"
                  className="safe-secure-img"
                  src="assets/img/safe and secure.png"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export default MoneyBarnFlpMB;
