import React, { useState } from "react";
import RadioButtonQuest from "../../../../UI/RadioButtonQuest";

const DebtOrOutstandingBalance = ({ onselectAnswer, reff, display, trigger }) => {
  const labelclicked = async (e) => {
    onselectAnswer(e);
  };

  return (
    <>
      <div style={{ display: display }}>
        <div className="radio-box question animated fadeInUp" ref={reff}>
          <h3>
          Do you have any debt/outstanding balance with Vanquis?
          </h3>
          <RadioButtonQuest
            key="YES"
            className="radio-button"
            labelName="Yes"
            labelClassName="option_but next-2"
            onClick={labelclicked}
            value="Yes"
            name="question_89"
            id="question_89_Yes"
            dataQuestId={89}
            dataAnsId={245}
            dataAnsLabel="Yes"
          />
          <RadioButtonQuest
            key="NO"
            className="radio-button"
            labelName="NO"
            labelClassName="option_but next-2"
            onClick={labelclicked}
            value="NO"
            name="question_89"
            id="question_89_No"
            dataQuestId={89}
            dataAnsId={246}
            dataAnsLabel="No"
          />
        </div>
      </div>
    </>
  );
};

export default DebtOrOutstandingBalance;
