import React, {
  Fragment,
  useState,
  useEffect,
  useReducer,
  useContext,
  useRef
} from "react";
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import Accordion from "react-bootstrap/Accordion";
import { useIdUpload } from "../../Hooks/useIdUpload";
import GtmDataLayer from "../../Utility/GtmDataLayer";
import { queryString } from "../../Utility/QueryString";
// import VisitorParamsContext from "../../../../Contexts/VisitorParams";
import VisitorParamsContext from "../../Contexts/VisitorParams";
import GetVisitorsParams from "../../Utility/GetVisitorsParams";


const userfile = {
  preview: undefined,
  title: undefined,
};

const IdUpload_MB_UFGN_1 = ({ className }) => {
  const history = useHistory();
  const formParameters = JSON.parse(localStorage.getItem("formData"));
  const split_name = localStorage.getItem("split_name");
  // const { saveFLPUserIdUpload, isLoading } = useIdUpload();
  const { saveIdUpload, isLoading } = useIdUpload();

  const [clickedSlide, setClickedSlide] = useState([]);
  // const [state, dispatch] = useReducer(FormReducer, initialState);
  const [selectedDocType, setSelectedDocType] = useState();
  const [selectedFile, setSelectedFile] = useState();
  const [selectedFileType, setSelectedFileType] = useState();
  const [isFilePicked, setIsFilePicked] = useState(false);
  const [isInValidfFile, setIsInValidfFile] = useState(false);
  const [documentModalShow, setDocumentModalShow] = useState(false);
  const [nationalIDCard, setNationalIDCard] = useState(false);
  const [driversLicense, setDriversLicense] = useState(false);
  const [passport, setPassport] = useState(false);
  const [base64Image, setBase64Image] = useState();
  const [errorMessage, setErrorMessage] = useState();
  const scrollRef = useRef(null);
  const { visitorParameters } = useContext(VisitorParamsContext);

  const followup_data = JSON.parse(localStorage.getItem("followup_data"));
  const user_token = localStorage.getItem("user_token");
  const [currentUuid, setCurrentUuid] = useState();

  const selectDocId = (e) => {
    retakeClick();
    // setSelectedDocType(e.target.value);
  };

  const changeCameraHandler = (event) => {
    setSelectedFile(event.target.files[0]);
    let imageFile = event.target.files[0];
    if (imageFile) {
      setIsFilePicked(true);
      let extension = event.target.files[0].type;
      let imgsize = event.target.files[0].size;
      let allowedExtensions = ["image/jpeg", "image/png"];
      if (allowedExtensions.indexOf(extension) === -1) {
        setIsInValidfFile(true);
        setErrorMessage({
          txt: "The uploaded ID type should be an image in jpeg,png or jpg",
        });
      } else if (imgsize > 1024 * 1024 * 20) {
        setIsInValidfFile(true);
        setErrorMessage({ txt: "Please choose a file less than 20mb" });
      } else {
        setSelectedFileType(event.target.name);
        setIsInValidfFile(false);
      }

      setNationalIDCard(false);
      setDriversLicense(false);
      setPassport(false);

      if ("nationalIDCard_doc_cam" == event.target.name) {
        setNationalIDCard(true);
      } else if ("driversLicense_doc_cam" == event.target.name) {
        setDriversLicense(true);
      } else if ("passport_doc_cam" == event.target.name) {
        setPassport(true);
      }

      userfile.preview = URL.createObjectURL(imageFile);
      userfile.title = imageFile.name;

      if (imageFile) {
        let reader = new FileReader();
        reader.onload = _handleReaderLoaded.bind(this);
        //reader.readAsBinaryString(imageFile);
        reader.readAsDataURL(imageFile);
      }
    } else {
      setIsInValidfFile(true);
      setIsFilePicked(false);
      userfile.preview = undefined;
      userfile.title = undefined;
    }
  };

  const changeFilesHandler = (event) => {
    setSelectedFile(event.target.files[0]);
    let imageFile = event.target.files[0];
    if (imageFile) {
      setIsFilePicked(true);
      let extension = event.target.files[0].type;
      let imgsize = event.target.files[0].size;

      let allowedExtensions = ["image/jpeg", "image/png"];
      if (allowedExtensions.indexOf(extension) === -1) {
        setIsInValidfFile(true);
        setErrorMessage({
          txt: "The uploaded ID type should be an image in jpeg,png or jpg",
        });
      } else if (imgsize > 1024 * 1024 * 20) {
        setIsInValidfFile(true);
        setErrorMessage({ txt: "Please choose a file less than 20mb" });
      } else {
        setSelectedFileType(event.target.name);
        setIsInValidfFile(false);
        //_handleFileRead.bind(event.target.files[0]);
      }

      setNationalIDCard(false);
      setDriversLicense(false);
      setPassport(false);

      if ("nationalIDCard_doc" == event.target.name) {
        setNationalIDCard(true);
      } else if ("driversLicense_doc" == event.target.name) {
        setDriversLicense(true);
      } else if ("passport_doc" == event.target.name) {
        setPassport(true);
      }
      userfile.preview = URL.createObjectURL(imageFile);
      userfile.title = imageFile.name;

      if (imageFile) {
        let reader = new FileReader();
        reader.onload = _handleReaderLoaded.bind(this);
        //reader.readAsBinaryString(imageFile);
        reader.readAsDataURL(imageFile);
      }
    } else {
      setIsInValidfFile(true);
      setIsFilePicked(false);
      userfile.preview = undefined;
      userfile.title = undefined;
    }
  };

  const _handleReaderLoaded = (readerEvt) => {
    let binaryString = readerEvt.target.result;
    //binaryString = binaryString.replace(";base64", `;name=${selectedFile.name};base64`);
    // setBase64Image({
    //   //base64TextString : btoa(binaryString)
    //   base64TextString : btoa(encodeURIComponent(binaryString).replace(/%([0-9A-F]{2})/g, function(match, p1) {
    //     return String.fromCharCode('0x' + p1);
    //   }))
    // });

    // setBase64Image({
    //   base64TextString: binaryString,
    // });
    setBase64Image(binaryString);

  };

  const retakeClick = () => {
    setDocumentModalShow(false);

    setNationalIDCard(false);
    setDriversLicense(false);
    setPassport(false);

    setClickedSlide({ slide: "retake" });
    setSelectedFile(null);
    setIsFilePicked(false);
  };


  const savedocument = async () => {
    if (selectedFile) {
      setIsInValidfFile(false);
      try {
        if (visitorParameters.data !== null || visitorParameters.data !== undefined) {
          let uploadData = '';
          uploadData = {
            imageFile: base64Image,
            captureType: selectedFileType.substring(
              selectedFileType.indexOf("doc")
            ),
            documentType: selectedFileType.substr(
              0,
              selectedFileType.indexOf("_")
            ),
          };

          const response = saveIdUpload(
            uploadData,
            visitorParameters,
            formParameters,
            queryString,
          
          );
          // pushPageChange();
          history.push("/CreditCheck_MB_UFGN_1?uuid=" + visitorParameters.visitor_parameters.uuid);
        }
      } catch (e) {
        console.warn("error");
        console.warn(e);
        setIsInValidfFile(true);
        setErrorMessage("Please try again");
      }
    } else {
      setIsInValidfFile(true);
      setErrorMessage("Please choose an option");
    }
  };
  return (
    <>
    <Fragment>
      {/* <GetVisitorsParams /> */}
      <div className={`tms-wrap2 bg-main-pr  ${className}`}>
        <div className={`main text-center main-div ${className}`}>

          <div className="brand-logo mt-lg-0 mt-md-2 mt-sm-2 mt-2">
            <img
              height="65"
              src="assets/MB_UFGN_1_KYC/img/a00187b31dd5a4ece0c8f809b69962eb.png"
              alt=""
            />
          </div>

          <div className="container pt-lg-3 pt-md-3 pt-sm-3  pt-0  text-center">
            <div className="row">
              <div className="col-lg-6 offset-lg-3 mt-2 mb-3">



                <img height={115} src="assets/MB_UFGN_1_KYC/img/top-img.jpg" alt="" />
                <h1 className="mt-2 mb-3 mt-md-2 mt-sm-2 mt-0 text-danger">
                  Verify Your identity{" "}
                </h1>
              </div>
            </div>
          </div>

          <div
            className={`docum-slide3 mt-l-r documents-uploads`}

          >
            <div className="container pt-lg-3 pt-md-3 pt-sm-3  pt-0  text-center">

              <div className="row">
                <div className="col-lg-6 offset-lg-3 mt-2">



                  {/* <img height={115} src="assets/MB_UFGN_1_KYC/img/top-img.jpg" alt="" /> */}
                  {/* <h1 className="mt-2 mb-3 mt-md-2 mt-sm-2 mt-0 text-danger">
                    Verify Your identity{" "}
                  </h1> */}
                  <p className="text-p2 mt-2">
                    {" "}
                    <i>
                      Tip - Please provide a photo of one of the following:{" "}
                      <span className="text-primary">
                        National ID Card, Driver's License, Passport
                      </span>{" "}
                    </i>{" "}
                  </p>

                  <div className="uploading-part">
                    <Accordion
                      className="accordion accordion-flush"
                      id="faqs"
                    >
                      <Accordion.Item
                        eventKey="1"
                        className="accordion-item"
                        onClick={selectDocId}
                      >
                        <Accordion.Button className="accordion-button idone">
                          National ID Card
                        </Accordion.Button>
                        <Accordion.Body>
                          <div className="col-lg-12 col-12 upload-area">
                            {nationalIDCard && !isInValidfFile ? (
                              <img src={userfile.preview} alt={userfile.title} style={{ width: '100%', padding: '0px 69px' }} />
                            ) : (
                              <img src="assets/IdUpload/img/upload-demo.jpg" alt="" />
                            )}
                            {!nationalIDCard && (
                              <div className="row mt-3">
                                <div className="col-lg-6 col-md-6 col-sm-12">
                                  <span className="button-tab w-100 browsebut2">
                                    <img src="assets/IdUpload/img/browse.png" alt="" /> Browse from Your Device
                                    <input
                                      type="file"
                                      accept="image/*"
                                      id="idTypeFile"
                                      name="nationalIDCard_doc"
                                      onChange={changeFilesHandler}
                                      onClick={(e) => (e.target.value = null)}
                                    />
                                  </span>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-12">
                                  <span className="button-tab w-100 browsebut2">
                                    <img src="assets/IdUpload/img/camera.png" alt="" /> Take picture of Your Id
                                    <input
                                      type="file"
                                      accept="image/*"
                                      capture="camera"
                                      id="idTypeFileCamera"
                                      name="nationalIDCard_doc_cam"
                                      onChange={changeCameraHandler}
                                      onClick={(e) => (e.target.value = null)}
                                    />
                                  </span>
                                </div>
                              </div>
                            )}
                            {nationalIDCard && (
                              <div className="row mt-3">
                                {isInValidfFile && <p className="error-msg">{errorMessage.txt}</p>}
                                {!isInValidfFile && (
                                 
                                  <div className="col-lg-6 col-md-6 col-sm-12">
                                    <input
                                      type="button"
                                      className="button-tab btn-danger w-100 retakered retakered-btn next-12 "
                                      defaultValue="Retake Photo"
                                    onClick={retakeClick}
                                    />
                                  </div>
                                )}
                                

                                <div className="col-lg-6 col-md-6 col-sm-12">
                                  <a>
                                    <input
                                      type="button"
                                      className="button-tab  w-100 browsebut"
                                      defaultValue="Submit"
                                      onClick={savedocument}
                                    />
                                  </a>
                                </div>
                                {isLoading && <p>Please Wait...</p>}
                              </div>
                            )}
                          </div>
                        </Accordion.Body>

                      </Accordion.Item>

                      <Accordion.Item
                        eventKey="2"
                        className="accordion-item"
                        onClick={selectDocId}
                      >
                        <Accordion.Button
                          className="accordion-button idtwo"
                        >
                          Driver's License
                        </Accordion.Button>
                        <Accordion.Body>
                          <div className="col-lg-12 col-12 upload-area">
                            {driversLicense && !isInValidfFile ? (
                              <img src={userfile.preview} alt={userfile.title} style={{ width: '100%', padding: '0px 69px' }} />
                            ) : (
                              <img src="assets/IdUpload/img/upload-demo.jpg" alt="" />
                            )}

                            {!driversLicense && (
                              <div className="row mt-3">
                                <div className="col-lg-6 col-md-6 col-sm-12">
                                  <span className="button-tab w-100 browsebut2">
                                    <img src="assets/IdUpload/img/browse.png" alt="" /> Browse from Your Device
                                    <input
                                      type="file"
                                      accept="image/*"
                                      id="idTypeFile"
                                      name="driversLicense_doc"
                                      onChange={changeFilesHandler}
                                      onClick={(e) => (e.target.value = null)}
                                    />
                                  </span>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-12">
                                  <span className="button-tab w-100 browsebut2">
                                    <img src="assets/IdUpload/img/camera.png" alt="" /> Take picture of Your Id
                                    <input
                                      type="file"
                                      accept="image/*"
                                      capture="camera"
                                      name="driversLicense_doc_cam"
                                      onChange={changeCameraHandler}
                                      onClick={(e) => (e.target.value = null)}
                                    />
                                  </span>
                                </div>
                              </div>
                            )}

                            {driversLicense && (
                              <div>
                                {isInValidfFile && <p className="error-msg">{errorMessage.txt}</p>}
                                <div className="row mt-3">
                                  <div className="col-lg-6 col-md-6 col-sm-12">
                                    <input
                                      type="button"
                                      className="button-tab btn-danger w-100 retakered retakered-btn next-12"
                                      value="Retake Photo"
                                      onClick={retakeClick}
                                    />
                                  </div>
                                  <div className="col-lg-6 col-md-6 col-sm-12">
                                    {!isInValidfFile && (
                                      <input
                                        type="button"
                                        className="button-tab w-100 browsebut"
                                        value="Submit"
                                      onClick={savedocument}
                                      />
                                    )}
                                  </div>
                                </div>
                                {isLoading && <p>Please Wait...</p>}
                              </div>
                            )}

                          </div>
                        </Accordion.Body>

                      </Accordion.Item>

                      <Accordion.Item
                        eventKey="3"
                        className="accordion-item"
                        onClick={selectDocId}
                      >
                        <Accordion.Button
                          className="accordion-button idthree"
                        >
                          Passport
                        </Accordion.Button>
                        <Accordion.Body>
                          <div className="col-lg-12 col-12 upload-area">
                            {passport && !isInValidfFile ? (
                              <img src={userfile.preview} alt={userfile.title} style={{ width: '100%', padding: '0px 69px' }} />
                            ) : (
                              <img src="assets/IdUpload/img/upload-demo.jpg" alt="" />
                            )}

                            {!passport && (
                              <div className="row mt-3">
                                <div className="col-lg-6 col-md-6 col-sm-12">
                                  <span className="button-tab w-100 browsebut2">
                                    <img src="assets/IdUpload/img/browse.png" alt="" /> Browse from Your Device
                                    <input
                                      type="file"
                                      accept="image/*"
                                      id="idTypeFile"
                                      name="passport_doc"
                                      onChange={changeFilesHandler}
                                      onClick={(e) => (e.target.value = null)}
                                    />
                                  </span>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-12">
                                  <span className="button-tab w-100 browsebut2">
                                    <img src="assets/IdUpload/img/camera.png" alt="" /> Take picture of Your Id
                                    <input
                                      type="file"
                                      accept="image/*"
                                      capture="camera"
                                      id="idTypeFileCamera"
                                      name="passport_doc_cam"
                                      onChange={changeCameraHandler}
                                      onClick={(e) => (e.target.value = null)}
                                    />
                                  </span>
                                </div>
                              </div>
                            )}

                            {passport && (
                              <div>
                                {isInValidfFile && <p className="error-msg">{errorMessage.txt}</p>}
                                <div className="row mt-3">
                                  <div className="col-lg-6 col-md-6 col-sm-12">
                                    <input
                                      type="button"
                                      className="button-tab btn-danger w-100 retakered retakered-btn next-12"
                                      value="Retake Photo"
                                      onClick={retakeClick}
                                    />
                                  </div>
                                  <div className="col-lg-6 col-md-6 col-sm-12">
                                    {!isInValidfFile && (
                                      <input
                                        type="button"
                                        className="button-tab w-100 browsebut"
                                        value="Submit"
                                      onClick={savedocument}
                                      />
                                    )}
                                  </div>
                                </div>
                                {isLoading && <p>Please Wait...</p>}
                              </div>
                            )}

                          </div>
                        </Accordion.Body>

                      </Accordion.Item>
                    </Accordion>
                  </div>

                  <div className="doc-op">
                    <p className="fs-6">
                      {" "}
                      Document should be Clear &amp; Legible | Entire Document
                      should be Captured
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      </Fragment>
    </>
  )
}

export default IdUpload_MB_UFGN_1