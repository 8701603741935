import React, { useContext, useRef, useState } from "react";
import InputButton from "../UI/InputButton";
import TextField from "../UI/TextField";
import { DateOfBirth } from "../../Utility/DateOfBirth";
import { useForm } from "react-hook-form";
import PostCode from "../Includes/Layouts/FollowupS2/MoneyBarn/PostCode";
import VisitorParamsContext from "../../Contexts/VisitorParams";
import Loader from "../Includes/Layouts/FollowupS2/Loader";
import { useUserVerify } from "../../Hooks/useUserVerify";
import { useHistory } from "react-router-dom";
import Loader2 from "../Includes/Layouts/FollowupS2/Loader2";
import TextField2 from "../UI/TextField2";
import PostCode2 from "../Includes/Layouts/FollowupS2/MoneyBarn/PostCode2";
import SelectBox from "../UI/SelectBox";
// import { Salutation } from "../../../../Constants/Questions";
import { Salutation } from "../../Constants/Questions";
import AddressTextField from "../UI/AddressTextField";

const EditPersonalDetailsS2 = ({
  className,
  setPreviewPage,
  setEditDetails,
  setLoader,
  loader,
  reloader,
  btndisable,
  setReloader,
}) => {
  const { register, errors, trigger, setError, clearErrors, getValues } =
    useForm({
      mode: "onBlur",
      reValidateMode: "onChange",
      defaultValues: {},
      resolver: undefined,
      context: undefined,
      criteriaMode: "firstError",
      shouldFocusError: true,
      shouldUnregister: true,
    });
    const splitForm = useRef(null);
  const query_string = localStorage.getItem("querystring");
  const uuid = localStorage.getItem("uuid");

  let local_fName = localStorage.getItem("fName");
  let local_lName = localStorage.getItem("lName");
  let local_pCode = localStorage.getItem("pCode");
  let local_address_line1 = localStorage.getItem("addressLine1");
  let local_dob_yr = localStorage.getItem("dobYr");
  let local_dob_month = localStorage.getItem("dobMon");
  let local_dob_day = localStorage.getItem("dobDy");
  let local_address_line2 = localStorage.getItem("addressLine2");
  let local_town = localStorage.getItem("postTown");
  let local_country = localStorage.getItem("country");
  let local_title = localStorage.getItem("title");
  let local_addressLine3 = localStorage.getItem("addressLine3");
  let local_addressLine4 = localStorage.getItem("addressLine4");
  let local_county = localStorage.getItem("county");
  let UcFname = localStorage.getItem("UcFname");
  let local_district = localStorage.getItem("district");

  // const [t2aApiReqData, setT2aApiReqData] = useState({});
  const { visitorParameters } = useContext(VisitorParamsContext);
  const { getUserVerfyValidation, saveFollowupUserVerify } = useUserVerify();
  const [showloader, setShowloader] = useState("hide");
  const history = useHistory();
  const allFollowupData = localStorage.getItem("followupData");
  const [confirmClicked, setConfirmClicked] = useState(false);

  const [displayPostcode, setDisplayPostcode] = useState("hide");

  const commaSeparatedString = [
    local_address_line1,
    local_address_line2,
    local_addressLine3,
    local_addressLine4,
    local_county,
    local_town,
    local_country,
  ]
    .filter(Boolean)
    .join(",");

  const basicDetailsValidation = async (e) => {

    if (displayPostcode === "hide") {
      // setShowloader("show");

      // setEditDetails("hide");

      let errorFlag = 0;

      let salutation = await trigger("lstSalutation");

      let txtLNameResult;
      let txtFNameResult;
      let lstDobDayResult;
      let lstDobMonthResult;
      let lstDobYearResult;
      let PostCodeResult;
      let addressSelect;
      let Lookup_Address_Btn;

      if (salutation) {
        txtFNameResult = await trigger("txtFName");
      } else {
        document.getElementById("lstSalutation").focus();
        return false;
      }
      if (txtFNameResult) {
        txtLNameResult = await trigger("txtLName");
      } else {
        document.getElementById("txtFName").focus();
        return false;
      }
      if (txtLNameResult) {
        lstDobDayResult = await trigger("DobDay");
      } else {
        document.getElementById("txtLName").focus();
        return false;
      }
      if (lstDobDayResult) {
        lstDobMonthResult = await trigger("DobMonth");
      } else {
        document.getElementById("DobDay").focus();
        return false;
      }
      if (lstDobMonthResult) {
        lstDobYearResult = await trigger("DobYear");
      } else {
        document.getElementById("DobMonth").focus();
        return false;
      }
      if (lstDobYearResult) {
        // PostCodeResult = await trigger("txtPostCode");
      } else {
        document.getElementById("DobYear").focus();
        return false;
      }


      if (
        !salutation ||
        !txtFNameResult ||
        !txtLNameResult ||
        !lstDobDayResult ||
        !lstDobMonthResult ||
        !lstDobYearResult
        // !PostCodeResult ||
        // !addressSelect
      ) {
        errorFlag = 1;
      }
      if (errorFlag === 0) {

        setShowloader("show");
        const values = getValues();
        const formdata = values;
        let t2aApiReqData = {};

        // let dobMonth = formdata.DobMonth ? MonthNumberArray[formdata.DobMonth] : '';

        let fName = formdata.txtFName;
        let lName = formdata.txtLName;
        let pCode = local_pCode;
        let addressLine1 = local_address_line1;
        let dobYr = formdata.DobYear;
        // let dobMon = dobMonth;
        let dobMon = formdata.DobMonth;
        let dobDy = formdata.DobDay;
        let addressLine2 = local_address_line2;
        let postTown = local_town;
        let country = local_country;
        let title = formdata.lstSalutation ? formdata.lstSalutation : "";
        let addressLine3 = local_addressLine3 ? local_addressLine3 : "";
        let addressLine4 = local_addressLine4 ? local_addressLine4 : "";
        let county = local_county;
        let District = local_district ? local_district : "";
        

       



        t2aApiReqData = {
          uuid: uuid,
          fName: fName,
          lName: lName,
          pCode: pCode,
          addressLine1: addressLine1,
          dob_y: dobYr,
          dob_m: dobMon,
          dob_d: dobDy,
          addressLine2: addressLine2,
          postTown: postTown,
          country: country,
          title: title,
          addressLine3: addressLine3,
          addressLine4: addressLine4 ? formdata.txtAddress4 : "",
          county: county,
          district: District ? District : "",
        };

        if (
          uuid &&
          fName &&
          lName &&
          pCode &&
          addressLine1 &&
          dobYr &&
          dobMon &&
          dobDy
        ) {

         
          (async () => {
            const response = await getUserVerfyValidation(
              uuid,
              fName,
              lName,
              pCode,
              addressLine1,
              dobYr,
              dobMon,
              dobDy,
              addressLine2,
              postTown,
              country,
              title,
              addressLine3,
              addressLine4,
              county
            );

            if (response.data.status == "success") {
              history.push("/credit_check-s2?uuid=" + uuid);
            } else {
              setShowloader("hide");
              setEditDetails("hide");
              setPreviewPage("show");
            }
            storeT2aResponse(response.data);
          })();
        }

        const storeT2aResponse = async (t2a_data) => {
          const response = await saveFollowupUserVerify(
            JSON.parse(allFollowupData),
            t2a_data,
            "person_verify_store_flpS3",
            query_string,
            t2aApiReqData
          );
        };

      } else {
        return false;
      }
      return;
    }

    let errorFlag = 0;

    let salutation = await trigger("lstSalutation");
    let txtLNameResult;
    let txtFNameResult;
    let lstDobDayResult;
    let lstDobMonthResult;
    let lstDobYearResult;
    let PostCodeResult;
    let addressSelect;
    let Lookup_Address_Btn;

    if (salutation) {
      txtFNameResult = await trigger("txtFName");
    } else {
      document.getElementById("lstSalutation").focus();
      return false;
    }
    if (txtFNameResult) {
      txtLNameResult = await trigger("txtLName");
    } else {
      document.getElementById("txtFName").focus();
      return false;
    }
    if (txtLNameResult) {
      lstDobDayResult = await trigger("DobDay");
    } else {
      document.getElementById("txtLName").focus();
      return false;
    }
    if (lstDobDayResult) {
      lstDobMonthResult = await trigger("DobMonth");
    } else {
      document.getElementById("DobDay").focus();
      return false;
    }
    if (lstDobMonthResult) {
      lstDobYearResult = await trigger("DobYear");
    } else {
      document.getElementById("DobMonth").focus();
      return false;
    }
    if (lstDobYearResult) {
      PostCodeResult = await trigger("txtPostCode");
    } else {
      document.getElementById("DobYear").focus();
      return false;
    }
    if (PostCodeResult) {
      // Lookup_Address_Btn=await trigger("Lookup_Address_Btn");
      addressSelect = await trigger("address1");
      // if (!addressSelect) {
      //   setConfirmClicked(e);
      // }
    } else {
      document.getElementById("txtPostCode").focus();
      return false;
    }
    if (addressSelect) {
    } else {
      document.getElementById("address1").focus();
      return false;
    }

    if (
      !salutation ||
      !txtFNameResult ||
      !txtLNameResult ||
      !lstDobDayResult ||
      !lstDobMonthResult ||
      !lstDobYearResult ||
      !PostCodeResult ||
      !addressSelect
    ) {
      errorFlag = 1;
    }
    if (errorFlag === 0) {
      // if(salutation == "Mrs" || salutation == "Ms" || salutation == "Miss" || salutation == "Dame" || salutation == "Lady" ){
      //   setshowPrevModal(true);
      // }else{
      //   slideChange(e);
      // }
      //    slideChange(e);
      validatePostCode();
    } else {
      return false;
    }
  };

  const validatePostCode = async () => {
    setShowloader("show");
    const form = splitForm.current;
    const values = getValues();
    const formdata = values;
    let t2aApiReqData = {};

    // let dobMonth = formdata.DobMonth ? MonthNumberArray[formdata.DobMonth] : '';

    let fName = formdata.txtFName;
    let lName = formdata.txtLName;
    let pCode = formdata.txtPostCode;
    let addressLine1 = formdata.txtHouseNumber;
    let dobYr = formdata.DobYear;
    // let dobMon = dobMonth;
    let dobMon = formdata.DobMonth;
    let dobDy = formdata.DobDay;
    let addressLine2 = formdata.txtAddress2;
    let postTown = formdata.txtTown;
    let country = formdata.txtCountry;
    let title = formdata.lstSalutation ? formdata.lstSalutation : "";
    let addressLine3 = formdata.txtAddress3 ? formdata.txtAddress3 : "";
    let addressLine4 = formdata.txtAddress4 ? formdata.txtAddress4 : "";
    let county = formdata.txtCounty;
    let District = form["txtDistrict"].value;
    // let District=formdata.txtDistrict;

    t2aApiReqData = {
      uuid: uuid,
      fName: fName,
      lName: lName,
      pCode: pCode,
      addressLine1: addressLine1,
      dob_y: dobYr,
      dob_m: dobMon,
      dob_d: dobDy,
      addressLine2: addressLine2,
      postTown: postTown,
      country: country,
      title: title,
      addressLine3: addressLine3,
      addressLine4: addressLine4 ? formdata.txtAddress4 : "",
      county: county,
      district: District ? District : "",
    };

    if (
      uuid &&
      fName &&
      lName &&
      pCode &&
      addressLine1 &&
      dobYr &&
      dobMon &&
      dobDy
    ) {
      (async () => {
        const response = await getUserVerfyValidation(
          uuid,
          fName,
          lName,
          pCode,
          addressLine1,
          dobYr,
          dobMon,
          dobDy,
          addressLine2,
          postTown,
          country,
          title,
          addressLine3,
          addressLine4,
          county
        );

        if (response.data.status == "success") {
          history.push("/credit_check-s2?uuid=" + uuid);
        } else {
          setShowloader("hide");
          setEditDetails("hide");
          setPreviewPage("show");
        }
        storeT2aResponse(response.data);
      })();
    }

    const storeT2aResponse = async (t2a_data) => {
      const response = await saveFollowupUserVerify(
        JSON.parse(allFollowupData),
        t2a_data,
        "person_verify_store_flpS3",
        query_string,
        t2aApiReqData
      );
    };
  };

  const namevalidate = async (e) => {
    if (e.target.value.trim() == "" || e.target.value.trim().length < 3) {
      var name = e.target.name == "txtFName" ? "First Name" : "Last Name";
      setError(e.target.name, {
        type: "manual",
        message: "Your " + name + " is Invalid. Please Recheck",
      });
      if (e.target.value.trim().length == 0) {
        e.target.value = e.target.value.trim();
      }
    }
    return false;
  };
  return (
    <>

      <div className={`tms-wrap2 bg-main-pr ${className}`}>
        <Loader2 fname={UcFname} showLoad={showloader} />
        <div className={showloader === "show" ? "hide" : "show"}>
          <div className={`main text-center main-div`}>
            <div className="brand-logo mt-lg-0 mt-md-2 mt-sm-2 mt-2">
              <img
                height="65"
                src="assets/img/a00187b31dd5a4ece0c8f809b69962eb.png"
                alt=""
              />
            </div>

            <form
            ref={splitForm}
            >
              <div className="main-form-wraper">
                <div className="container">
                  <div className="item-cent-flx ">
                    <div className=" docum-slide2 mt-l-r">
                      <div className=" pt-lg-3 pt-md-3 pt-sm-3  pt-0  text-center">
                        <div className="row">
                          <div className="col-lg-6 offset-lg-3">
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                              }}
                            >
                              <h1 className=" mb-3 mt-md-2 mt-sm-2 mt-0 h4">
                                Edit Your Personal Details
                              </h1>
                              {/* <a href="#" className="edit_icn1 fw-bold">
                                {" "}
                                <i className="bi bi-pencil-square"></i> &nbsp;
                                Edit
                              </a> */}
                            </div>

                            <div className="mb-3">
                              <SelectBox
                                className="form-select form-control"
                                OptionValue={Salutation}
                                defaultValue={local_title}
                                name="lstSalutation"
                                id="lstSalutation"
                                onChange={() => clearErrors("lstSalutation")}
                                clearErrors={clearErrors}
                                myRef={register({
                                  required: "Please Select Title",
                                })}
                                validationMsg={
                                  errors.lstSalutation &&
                                  errors.lstSalutation.message
                                }
                              ></SelectBox>
                            </div>

                            <div className=" mb-3  ">
                              <TextField2
                                type="text"
                                className="form-control frm_name1"
                                placeholder="First Name"
                                value={local_fName}
                                name="txtFName"
                                id="txtFName"
                                onBlur={namevalidate}
                                validation={register({
                                  required: "Please Enter First Name",
                                  minLength: {
                                    value: 3,
                                    message: "Please Enter Valid First Name",
                                  },
                                  pattern: {
                                    value: /^[a-zA-Z\s]+$/,
                                    message:
                                      "Your Name is Invalid. Please Recheck",
                                  },
                                })}
                              ></TextField2>
                              {errors.txtFName && (
                                <span className="error_msg " id="email_err">
                                  {errors.txtFName.message}
                                </span>
                              )}
                            </div>

                            <div className=" mb-3 ">
                              <TextField2
                                type="text"
                                className="form-control frm_name1"
                                placeholder="Last Name"
                                value={local_lName}
                                name="txtLName"
                                id="txtLName"
                                onBlur={namevalidate}
                                validation={register({
                                  required: "Please Enter Last Name",
                                  minLength: {
                                    value: 3,
                                    message: "Please Enter Valid Last Name",
                                  },
                                  pattern: {
                                    value: /^[a-zA-Z\s]+$/,
                                    message:
                                      "Your Name is Invalid. Please Recheck",
                                  },
                                })}
                              ></TextField2>
                              {errors.txtLName && (
                                <span className="error_msg " id="email_err">
                                  {errors.txtLName.message}
                                </span>
                              )}
                            </div>

                            <fieldset className="scheduler-border text-start w-100">
                              <DateOfBirth
                                textDob="Date Of Birth"
                                validation={register}
                                validationMsg={errors}
                                dob_day={local_dob_day}
                                dob_month={local_dob_month}
                                dob_yr={local_dob_yr}
                              />
                            </fieldset>

                            {/* <div className={`scheduler-border text-start w-100 ${displayPostcode === "new-lookupadd" ? 'hide' : ''}`}> */}
                            <fieldset
                              className={`scheduler-border text-start lookupadd w-100 ${displayPostcode === "new-lookupadd"
                                  ? "hide"
                                  : ""
                                }`}
                            >
                              {/* <div className="person_detail_edit form-control  hero-input"> */}
                              <legend className="scheduler-border">
                                Address{" "}
                              </legend>
                              <div>
                                {/* <p className="form-control frm_name1">{commaSeparatedString}</p> */}

                                <AddressTextField
                                  type="text"
                                  // placeholder="Your Postcode"
                                  value={commaSeparatedString}
                                  name="existing_address"
                                  id="existing_address"
                                  className="form-control frm_name1"
                                  dataId="txtPostCode"
                                  readonly={true}
                                  autoComplete="off"
                                />

                                <InputButton
                                  style={{ float: "none" }}
                                  name="Address_edit_Btn"
                                  // validation={validation()}
                                  className="form-control frm_name1 lookbnt mt-2"
                                  value="Edit"
                                  btnType="button"
                                  onClick={() =>
                                    setDisplayPostcode("new-lookupadd")
                                  }
                                />

                                {/* <button className="form-control frm_name1 lookbnt" type="button" onClick={() => setDisplayPostcode("new-lookupadd")}>edit</button> */}
                              </div>
                            </fieldset>

                            <div>
                              <PostCode2
                                validation={register}
                                validationMsg={errors}
                                trigger={trigger}
                                clearErrors={clearErrors}
                                setError={setError}
                                className={displayPostcode}
                                getValues={getValues}
                                pCode={local_pCode}
                                address_line1={local_address_line1}
                                address_line2={local_address_line2}
                                town={local_town}
                                country={local_country}
                                addressLine3={local_addressLine3}
                                addressLine4={local_addressLine4}
                                county={local_county}
                                confirmClicked={confirmClicked}
                                splitForm={splitForm}

                              //  onClick={lookupValidation}
                              //  splitForm={splitForm}
                              //  scrollView={scrollfrmv2div}
                              //  slideChange={slideChange}
                              //  backClick={previousSlideChange}
                              //  setPreviousAddressData={setPreviousAddressData}
                              />
                            </div>

                            <div></div>
                          </div>
                        </div>

                        <div className="row mt-3">
                          <div className="col-lg-6 offset-lg-3  mb-2 text-start">
                            <div>
                              <InputButton
                                name="personalvalidate"
                                className="option_but w-100 btn-proceed"
                                id=""
                                value="Confirm"
                                btnType="button"
                                onClick={basicDetailsValidation}
                                style={{ float: "none" }}
                              />
                            </div>

                            <p className="fs-6 mt-2"> </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="  pt-3 pb-5 ">
                            <img
                              height="50"
                              className="safe-secure-img"
                              src="assets/img/safe and secure.png"
                              alt=""
                            />
                          </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      {/* </div> */}
    </>
  );
};
export default EditPersonalDetailsS2;
