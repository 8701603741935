import React, { useContext, useEffect } from 'react';
import { AppDomain, AppLpDummytUrl } from "../Constants/EnvConstants";
import VisitorParamsContext from "../Contexts/VisitorParams";
import { useUserAgent } from "../Hooks/useUserAgent";
import { urlParams } from "../Utility/QueryString";
import { useHistory } from "react-router-dom";

const GetVisitorsParams = () => {
  const history = useHistory();
  const referrer = document.referrer;
  const { getUserAgent } = useUserAgent();
  const { visitorParameters } = useContext(VisitorParamsContext);
  const full_url = window.location.href;
  const query_string_arr = full_url.split("?");
  var queryString = "";
  if (query_string_arr != null) {
    queryString = query_string_arr[1];
  }
  useEffect(() => {
    const fetchData = async () => {
      if (visitorParameters.data.length === 0 || !visitorParameters.data) {
        const query_uuid = urlParams.get("uuid");
        const local_storage_uuid = localStorage.getItem('uuid');
        const uuid = query_uuid || local_storage_uuid;
  
        const response = await getUserAgent(
          window.navigator.userAgent,
          "LP",
          queryString,
          AppDomain,
          window.location.href,
          referrer
        );
  
        let pathname = "/" + AppLpDummytUrl;
        if (
          response.data.blacklisted &&
          pathname !== window.location.pathname &&
          typeof AppLpDummytUrl !== "undefined"
        ) {
          history.push("/" + AppLpDummytUrl + "?uuid=" + uuid);
        } else {
          visitorParameters.data = response.data.response.data;
          visitorParameters.visitor_parameters = { uuid: uuid };
        }
      }
    };
  
    fetchData();
  }, []);  
}

export default GetVisitorsParams;