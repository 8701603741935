import React, {
  Fragment,
  useState,
  useEffect,
  useReducer,
  useContext,
  useRef,
} from "react";
import "../../assets/NDY_ID_UPLOAD/css/Newday_id_upload.scss";
import "../../assets/NDY_ID_UPLOAD/css/animate.css";
import Accordion from "react-bootstrap/Accordion";
import uploaddemo from "../../assets/NDY_ID_UPLOAD/img/upload-demo.jpg";
import VisitorParamsContext from "../../Contexts/VisitorParams";
import GetVisitorsParams from "../../Utility/GetVisitorsParams";
import { queryString } from "../../Utility/QueryString";
import { useIdUpload } from "../../Hooks/useIdUpload";
import { useHistory } from "react-router-dom";
import { FormData } from "../../Utility/FormData";
import GtmDataLayer from "../../Utility/GtmDataLayer";
import { useUpdateMilestone } from "../../Hooks/useUpdateMilestone";

const initialState = {
  showSlide1: "show",
  showSlide2: "hide",
};

const userfile = {
  preview: undefined,
  title: undefined,
};

const FormReducer = (state, action) => {
  switch (action.type) {
    case "showSlide": {
      if (action.payload.clickedSlide.slide == "slide1") {
        return {
          ...state,
          showSlide1: "hide",
          showSlide2: "show",
        };
      } else if (action.payload.clickedSlide.slide == "retake") {
        return {
          ...state,
          showSlide1: "show",
          showSlide2: "hide",
        };
      } else {
        return {
          ...state,
        };
      }
    }
  }
};

const NDY_ID_UPLOAD = () => {
  const [idLoader, SetIdLoader] = useState("show");
  const [iduploadDiv, SetIdUploadDiv] = useState("hide");
  const history = useHistory();
  const { visitorParameters } = useContext(VisitorParamsContext);
  const formParameters = JSON.parse(localStorage.getItem("formData"));
  const { saveIdUpload, isLoading } = useIdUpload();
  const [clickedSlide, setClickedSlide] = useState([]);
  const [state, dispatch] = useReducer(FormReducer, initialState);
  const [selectedFile, setSelectedFile] = useState();
  const [selectedFileType, setSelectedFileType] = useState();
  const [isFilePicked, setIsFilePicked] = useState(false);
  const [isInValidfFile, setIsInValidfFile] = useState(false);
  const [documentModalShow, setDocumentModalShow] = useState(false);
  const [nationalIDCard, setNationalIDCard] = useState(false);
  const [driversLicense, setDriversLicense] = useState(false);
  const [passport, setPassport] = useState(false);
  const [base64Image, setBase64Image] = useState();
  const [errorMessage, setErrorMessage] = useState();
  const firstName = localStorage.getItem("UcFname");
  const scrollToDiv = useRef();
  const {updateMilestone} = useUpdateMilestone();



  
  useEffect(() => {
    setTimeout(() => {
        scrollToDiv.current.scrollIntoView({ behavior: "smooth", top: 0 });
    }, 50);
}, []);


  useEffect(() => {
    const timer = setTimeout(() => {
      SetIdLoader("hide");
      SetIdUploadDiv("show");
    }, 2000);
  }, []);

  useEffect(() => {
    if (clickedSlide) {
      dispatch({ type: "showSlide", payload: { clickedSlide } });
    }
  }, [clickedSlide]);

  const selectDocId = (e) => {
    retakeClick();
    // setSelectedDocType(e.target.value);
  };

  const changeCameraHandler = (event) => {
    setSelectedFile(event.target.files[0]);
    let imageFile = event.target.files[0];
    if (imageFile) {
      setIsFilePicked(true);
      let extension = event.target.files[0].type;
      let imgsize = event.target.files[0].size;
      let allowedExtensions = ["image/jpeg", "image/png"];
      if (allowedExtensions.indexOf(extension) === -1) {
        setIsInValidfFile(true);
        setErrorMessage({
          txt: "The uploaded ID type should be an image in jpeg,png or jpg",
        });
      } else if (imgsize > 1024 * 1024 * 20) {
        setIsInValidfFile(true);
        setErrorMessage({ txt: "Please choose a file less than 20mb" });
      } else {
        setSelectedFileType(event.target.name);
        setIsInValidfFile(false);
      }
      setNationalIDCard(false);
      setDriversLicense(false);
      setPassport(false);

      if ("nationalIDCard_doc_cam" == event.target.name) {
        setNationalIDCard(true);
      } else if ("driversLicense_doc_cam" == event.target.name) {
        setDriversLicense(true);
      } else if ("passport_doc_cam" == event.target.name) {
        setPassport(true);
      }

      userfile.preview = URL.createObjectURL(imageFile);
      userfile.title = imageFile.name;

      if (imageFile) {
        let reader = new FileReader();
        reader.onload = _handleReaderLoaded.bind(this);
        //reader.readAsBinaryString(imageFile);
        reader.readAsDataURL(imageFile);
      }
    } else {
      setIsInValidfFile(true);
      setIsFilePicked(false);
      userfile.preview = undefined;
      userfile.title = undefined;
    }
  };

  const changeFilesHandler = (event) => {
    setSelectedFile(event.target.files[0]);
    let imageFile = event.target.files[0];
    if (imageFile) {
      setIsFilePicked(true);
      let extension = event.target.files[0].type;
      let imgsize = event.target.files[0].size;
      let allowedExtensions = ["image/jpeg", "image/png"];
      if (allowedExtensions.indexOf(extension) === -1) {
        setIsInValidfFile(true);
        setErrorMessage({
          txt: "The uploaded ID type should be an image in jpeg,png or jpg",
        });
      } else if (imgsize > 1024 * 1024 * 20) {
        setIsInValidfFile(true);
        setErrorMessage({ txt: "Please choose a file less than 20mb" });
      } else {
        setSelectedFileType(event.target.name);
        setIsInValidfFile(false);
        //_handleFileRead.bind(event.target.files[0]);
      }
      setNationalIDCard(false);
      setDriversLicense(false);
      setPassport(false);

      if ("nationalIDCard_doc" == event.target.name) {
        setNationalIDCard(true);
      } else if ("driversLicense_doc" == event.target.name) {
        setDriversLicense(true);
      } else if ("passport_doc" == event.target.name) {
        setPassport(true);
      }
      userfile.preview = URL.createObjectURL(imageFile);
      userfile.title = imageFile.name;

      if (imageFile) {
        let reader = new FileReader();
        reader.onload = _handleReaderLoaded.bind(this);
        //reader.readAsBinaryString(imageFile);
        reader.readAsDataURL(imageFile);
      }
    } else {
      setIsInValidfFile(true);
      setIsFilePicked(false);
      userfile.preview = undefined;
      userfile.title = undefined;
    }
  };

  const _handleReaderLoaded = (readerEvt) => {
    let binaryString = readerEvt.target.result;
    //binaryString = binaryString.replace(";base64", `;name=${selectedFile.name};base64`);
    // setBase64Image({
    //   //base64TextString : btoa(binaryString)
    //   base64TextString : btoa(encodeURIComponent(binaryString).replace(/%([0-9A-F]{2})/g, function(match, p1) {
    //     return String.fromCharCode('0x' + p1);
    //   }))
    // });
    setBase64Image({
      base64TextString: binaryString,
    });
  };

  const retakeClick = () => {
    setDocumentModalShow(false);

    setNationalIDCard(false);
    setDriversLicense(false);
    setPassport(false);

    setClickedSlide({ slide: "retake" });
    setSelectedFile(null);
    setIsFilePicked(false);
  };

  const savedocument = async () => {
    GtmDataLayer({
      'question': 'identity verification'
    });
    try {
      if (
        visitorParameters.data !== null ||
        visitorParameters.data !== undefined
      ) {
        let uploadData = "";
        uploadData = {
          imageFile: base64Image.base64TextString,
          captureType: selectedFileType.substring(
            selectedFileType.indexOf("doc")
          ),
          documentType: selectedFileType.substr(
            0,
            selectedFileType.indexOf("_")
          ),
        };
        const response = saveIdUpload(
          uploadData,
          visitorParameters,
          formParameters,
          queryString
        );
        updateMilestone(visitorParameters.visitor_parameters.uuid,"id_upload","live");

        window.scrollTo(0, 0);
        history.push(
          "/thankyouNDY?uuid=" + visitorParameters.visitor_parameters.uuid
        );
      }
    } catch (e) {
      console.warn("error");
      console.warn(e);
    }
  };
  return (
    <>
      <GetVisitorsParams />
      <div className="Ndy_id_upload">
        <header>
          <div className="container" ref={scrollToDiv}>
            <div className="row">
              <div className="col-xl-12 col-12 col-md-12 col-lg-12 logo text-center">
                <img src="assets/NDY_ID_UPLOAD/img/logo-white.png" alt="" />
              </div>
            </div>
          </div>
        </header>
        <section className="content">
          <div className={`container text-center ${idLoader}`}>
            <div className={`loader-wrap text-center text-light `}>
              <div>
                <img
                  height={80}
                  src="assets/NDY_ID_UPLOAD/img/loader-review2.svg"
                  alt=""
                />
                <h6 className="mt-3">
                {/* {firstName} we need a few more details before we can proceed. */}
                Please Wait...
                </h6>
                {/* <p>(Don't worry, it will only take a minute.)</p> */}
              </div>
            </div>
          
          </div>
          <div className={`container text-center ${iduploadDiv}`}>
              <div className="intro" style={{ marginBottom: '20px' }}>
              <h2>Thank you</h2>
              <h3>
                Your claim is now registered with us, and we will commence the onboarding process.
              </h3>
            </div>
            <div className={`row`} id="quetWraper">
              <div className="col-xl-12 col-lg-12 col-12 intro">
                <img src="assets/NDY_ID_UPLOAD/img/top-img.png" alt="" />
                <h2>Verify Your identity</h2>
                <h3>You must provide 1 proof(s) of address to proceed.</h3>
                <p>
                  Tip - Please provide a photo of one of the following:{" "}
                  <strong>National ID Card, Driver's License, Passport</strong>
                </p>
              </div>
              <div className="uploading-part col-xl-6 offset-xl-3 col-lg-6 offset-lg-3 col-12">
                <Accordion className="accordion accordion-flush" id="faqs">
                  <Accordion.Item
                    eventKey="1"
                    className="accordion-item"
                    onClick={selectDocId}
                  >
                    <Accordion.Button className="accordion-button collapsed idone">
                      National ID Card
                    </Accordion.Button>

                    <Accordion.Body>
                      <div className="col-lg-12  col-12 upload-area">
                        {nationalIDCard && !isInValidfFile ? (
                          <img
                            src={userfile.preview}
                            alt={userfile.title}
                            style={{ width: "100%", padding: "0px 69px" }}
                          />
                        ) : (
                          <img src={uploaddemo} alt="" />
                        )}

                        <div className="col-lg-12 col-12">
                          {!nationalIDCard && (
                            <div>
                              <span className="button-tab browsebut">
                                <img
                                  src="assets/NDY_ID_UPLOAD/img/browse.png"
                                  alt=""
                                />
                                Browse from Your Device
                                <input
                                  type="file"
                                  accept="image/*"
                                  id="idTypeFile"
                                  name="nationalIDCard_doc"
                                  onChange={changeFilesHandler}
                                  onClick={(e) => (e.target.value = null)}
                                />
                              </span>
                              <div className="button-tab">
                                <input
                                  type="file"
                                  accept="image/*"
                                  capture="camera"
                                  name="nationalIDCard_doc_cam"
                                  onChange={changeCameraHandler}
                                  onClick={(e) => (e.target.value = null)}
                                />
                                <img
                                  src="assets/NDY_ID_UPLOAD/img/camera.png"
                                  alt=""
                                />
                                Take picture of Your Id
                              </div>
                            </div>
                          )}
                          {nationalIDCard && (
                            <div>
                              {isInValidfFile && (
                                <p className="error-msg mt-2">
                                  {errorMessage.txt}
                                </p>
                              )}
                              <div className="button-tab">
                                <input
                                  type="button"
                                  value="Retake Photo"
                                  onClick={retakeClick}
                                />
                                <img
                                  src="assets/NDY_ID_UPLOAD/img/camera.png"
                                  alt=""
                                />
                                Retake Photo
                              </div>
                              {isLoading && <p>Please Wait...</p>}
                              <div>
                                {!isInValidfFile && (
                                  <div className="btn btn-success id-success">
                                    <button
                                      type="button"
                                      className="btn"
                                      value="Submit"
                                      onClick={savedocument}
                                      style={{
                                        border: "none",
                                        cursor: "pointer",
                                        color: "white"
                                      }}
                                    >
                                    SUBMIT
                                    </button>
                                  </div>
                                )}
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item
                    eventKey="2"
                    className="accordion-item"
                    onClick={selectDocId}
                  >
                    <Accordion.Button className="accordion-button collapsed idtwo">
                      Driver's License
                    </Accordion.Button>
                    <Accordion.Body>
                      <div className="col-lg-12  col-12 upload-area">
                        {driversLicense && !isInValidfFile ? (
                          <img
                            src={userfile.preview}
                            alt={userfile.title}
                            style={{ width: "100%", padding: "0px 69px" }}
                          />
                        ) : (
                          <img src={uploaddemo} alt="" />
                        )}

                        <div className="col-lg-12 col-12">
                          {!driversLicense && (
                            <div>
                              <span className="button-tab browsebut">
                                <img
                                  src="assets/NDY_ID_UPLOAD/img/browse.png"
                                  alt=""
                                />
                                Browse from Your Device
                                <input
                                  type="file"
                                  accept="image/*"
                                  id="idTypeFile"
                                  name="driversLicense_doc"
                                  onChange={changeFilesHandler}
                                  onClick={(e) => (e.target.value = null)}
                                />
                              </span>
                              <div className="button-tab">
                                <input
                                  type="file"
                                  accept="image/*"
                                  capture="camera"
                                  name="driversLicense_doc_cam"
                                  onChange={changeCameraHandler}
                                  onClick={(e) => (e.target.value = null)}
                                />
                                <img
                                  src="assets/NDY_ID_UPLOAD/img/camera.png"
                                  alt=""
                                />
                                Take picture of Your Id
                              </div>
                            </div>
                          )}

                          {driversLicense && (
                            <div>
                              {isInValidfFile && (
                                <p className="error-msg mt-2">
                                  {errorMessage.txt}
                                </p>
                              )}
                              <div className="button-tab">
                                <input
                                  type="button"
                                  value="Retake Photo"
                                  onClick={retakeClick}
                                />
                                <img
                                  src="assets/NDY_ID_UPLOAD/img/camera.png"
                                  alt=""
                                />
                                Retake Photo
                              </div>
                              {isLoading && <p>Please Wait...</p>}

                              <div>
                                {!isInValidfFile && (
                                  <div className="btn btn-success id-success">
                                  <button
                                      type="button"
                                      className="btn"
                                      value="Submit"
                                      onClick={savedocument}
                                      style={{
                                        border: "none",
                                        cursor: "pointer",
                                        color: "white"
                                      }}
                                    >
                                    SUBMIT
                                    </button>
                                  </div>
                                )}
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item
                    eventKey="3"
                    className="accordion-item"
                    onClick={selectDocId}
                  >
                    <Accordion.Button className="accordion-button collapsed idthree">
                      Passport
                    </Accordion.Button>
                    <Accordion.Body>
                      <div className="col-lg-12  col-12 upload-area">
                        {passport && !isInValidfFile ? (
                          <img
                            src={userfile.preview}
                            alt={userfile.title}
                            style={{ width: "100%", padding: "0px 69px" }}
                          />
                        ) : (
                          <img src={uploaddemo} alt="" />
                        )}
                        <div className="col-lg-12 col-12">
                          {!passport && (
                            <div>
                              <span className="button-tab browsebut">
                                <img
                                  src="assets/NDY_ID_UPLOAD/img/browse.png"
                                  alt=""
                                />
                                Browse from Your Device
                                <input
                                  type="file"
                                  accept="image/*"
                                  id="idTypeFile"
                                  name="passport_doc"
                                  onChange={changeFilesHandler}
                                  onClick={(e) => (e.target.value = null)}
                                />
                              </span>
                              <div className="button-tab">
                                <input
                                  type="file"
                                  accept="image/*"
                                  capture="camera"
                                  name="passport_doc_cam"
                                  onChange={changeCameraHandler}
                                  onClick={(e) => (e.target.value = null)}
                                />
                                <img
                                  src="assets/NDY_ID_UPLOAD/img/camera.png"
                                  alt=""
                                />
                                Take picture of Your Id
                              </div>
                            </div>
                          )}

                          {passport && (
                            <div>
                              {isInValidfFile && (
                                <p className="error-msg mt-2">
                                  {errorMessage.txt}
                                </p>
                              )}
                              <div className="button-tab">
                                <input
                                  type="button"
                                  value="Retake Photo"
                                  onClick={retakeClick}
                                />
                                <img
                                  src="assets/NDY_ID_UPLOAD/img/camera.png"
                                  alt=""
                                />
                                Retake Photo
                              </div>
                              {isLoading && <p>Please Wait...</p>}
                              <div>
                                {!isInValidfFile && (
                                  <div className="btn btn-success id-success">
                                  <button
                                      type="button"
                                      className="btn"
                                      value="Submit"
                                      onClick={savedocument}
                                      style={{
                                        border: "none",
                                        cursor: "pointer",
                                        color: "white"
                                      }}
                                    >
                                    SUBMIT
                                    </button>
                                  </div>
                                )}
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
              <div className="doc-op">
                <ul>
                  <li> Document should be Clear &amp; Legible</li>
                  <li> Entire Document should be Captured</li>
                </ul>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};
export default NDY_ID_UPLOAD;
