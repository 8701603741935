import React, { useState } from "react";
const OutstandBal = ({
  clsName,
  onSelectAnswer,
  validation,
  backClick,
  setOutBalace,
  previous
}) => {
  const [checkedOne, setCheckedOne] = useState("unchecked");
  const [checkedTwo, setCheckedTwo] = useState("unchecked");
  let toggle;
  if (previous == "206") {
    toggle = "WhatYearThisHappen";
  } else if (previous == "207") {
    toggle = "SoldOnDebt"
  }
  const optionSelected = async (e) => {
    if (e.target.value === "245") {
      setCheckedOne("checked");
      setOutBalace("245")
      onSelectAnswer(e);
    } else {
      setCheckedOne("unchecked");
    }

    if (e.target.value === "246") {
      setCheckedTwo("checked");
      setOutBalace("246")
      onSelectAnswer(e);
    } else {
      setCheckedTwo("unchecked");
    }
  }

  return (
    <>
      <div className="question-content mt-0 scale-down-ver-center px-3 py-lg-3 py-md-3 py-sm-2 py-2 ">
        <div className="row">
          <div className="col-6">
            <div className=" mb-2">
              <button
                type="button"
                className={`btn-comm2 py-lg-3 py-md-3 py-sm-2 py-2 w-100 nextBtn ${checkedTwo}`}
                onClick={optionSelected}
                value="245"
                name="OutstandBalance"
                id="nextscroll"
                data-quest-id={89}
                data-ans-id={245}
                data-ans-label={null}
              >Yes
              </button>
            </div>
          </div>
          <div className="col-6">
            <div className=" mb-2">
              <button
                type="button"
                className={`btn-comm2 py-lg-3 py-md-3 py-sm-2 py-2 w-100 nextBtn ${checkedOne}`}
                value="246"
                name="OutstandBalance"
                onClick={optionSelected}
                id="nextscroll"
                data-quest-id={89}
                data-ans-id={246}
                data-ans-label={null}
              >No
              </button>
            </div>
          </div>
        </div>
        <p className="text-center bck-marwid">
          <button
            name={toggle}
            className="btn btn-secondary backbtn1a"
            id=""
            value=""
            type="button"
            onClick={backClick}

          >Go Back</button>
        </p>
      </div>



    </>
  )
}
export default OutstandBal;