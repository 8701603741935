import React, { useState,useRef } from "react";
import { SalutationVAQUB4 } from "../../../../Constants/Questions";
import SelectBox from "../../../UI/SelectBox";
import ContactDetails from "./ContactDetails";
import Sign from "./Sign";
import PostCode from "./PostCode";
import { useFormContext } from "react-hook-form";
import Input from "./Input";
import DateOfBirth from "./DateOfBirth";
import PrevAddressPopup from "./PrevAddressPopup";
import Banks from "./Banks";

const PersonalDetails = ({
  formSubmit,
  pageChange,
  divShow,
  setSignatureDataURL,
  setPreviousAddressData,
  lenderList,
  SelectBank,
  setSelectBank}) => {
  const {
    trigger,
    clearErrors,
    register,
    getValues,
    errors,
    setError,
  } = useFormContext();
  // const form = splitForm.current;
  const question_3 = getValues();
  const [showRegisterPop, setShowRegisterPop] = useState(false);
  const [titleQuest, seTitleQuest] = useState(false);
  const [BankValidationMsg, setBankValidationMsg] = useState(false);
  const [dobday, setDobday] = useState("");
  const [dobmonth, setDobmonth] = useState("");
  const [dobyear, setDobyear] = useState("");
  const [notDobValid, setNotDobValid] = useState(false);
  const divRefOne = useRef(null);

  const isLeapYear = (year) => {
    return (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;
  };
  
  const isValidDayInMonth = (day, month, year) => {
    const daysInMonth = [31, isLeapYear(year) ? 29 : 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
    return day >= 1 && day <= daysInMonth[month - 1];
  };
  
  const validateDateOfBirth = (dobDay, dobMonth, dobYear) => {
    const monthNames = [
      "January", "February", "March", "April",
      "May", "June", "July", "August",
      "September", "October", "November", "December"
    ];
  
    const isValidMonth = monthNames.includes(dobMonth);
    const isValidYear = dobYear >= 1910;
  
    if (isValidMonth && isValidYear && isValidDayInMonth(dobDay, monthNames.indexOf(dobMonth) + 1, dobYear)) {
      return true; 
    } else {
      return false;
    }
  };

  const basicDetailsValidation = async (e) => {
    let errorFlag = 0;
    let LengthSelectedBank = SelectBank.length;
    let ValueSelectedBank = SelectBank.value;
    localStorage.setItem("bud_lender",ValueSelectedBank);
    localStorage.setItem("lenderArray",JSON.stringify([SelectBank]));
 
    var lstSalutationResult = await trigger("lstSalutation");
 
    var salutation = question_3.lstSalutation;
    var txtFNameResult;
    var txtLNameResult;
    var lstDobDayResult;
    var lstDobMonthResult;
    var lstDobYearResult;
    var lstPostResult;
    var address1Result;
    var txtPhoneResult;
    var txtEmailResult;
    var lender;
    if (lstSalutationResult) {
      txtFNameResult = await trigger("txtFName");
    } else {
      document.getElementById("lstSalutation").focus();
      return false;
    }
    if (txtFNameResult) {
      txtLNameResult = await trigger("txtLName");
    } else {
      document.getElementById("txtFName").focus();
      return false;
    }
    if (txtLNameResult) {
      lstPostResult = await trigger("txtPostCode");
    } else {
      document.getElementById("txtLName").focus();
      return false;
    }
    if (lstPostResult) {
      address1Result = await trigger("address1");
    } else {
      document.getElementById("txtPostCode").focus();
      return false;
    }
    if (address1Result) {
      lstDobDayResult = await trigger("DobDay");
    } else {
      document.getElementById("address1").focus();
      return false;
    }
    if (lstDobDayResult) {
      lstDobMonthResult = await trigger("DobMonth");
    } else {
      document.getElementById("DobDay").focus();
      return false;
    }
    if (lstDobMonthResult) {
      lstDobYearResult = await trigger("DobYear");
    } else {
      document.getElementById("DobMonth").focus();
      return false;
    }
    if (lstDobYearResult) {
      txtPhoneResult = await trigger("txtPhone");
    } else {
      document.getElementById("DobYear").focus();
      return false;
    }
    if (txtPhoneResult) {
      txtEmailResult = await trigger("txtEmail");
    } else {
      document.getElementById("txtPhone").focus();
      return false;
    }
    if (txtEmailResult) {
      document.getElementById("bankNamediv").focus();
    } else {
      document.getElementById("txtEmail").focus();
      return false;
    }
    if (LengthSelectedBank < 1 || ValueSelectedBank == "") {
      divRefOne.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
      setBankValidationMsg("Please select your Bank");
      return false;
    } else {
      setBankValidationMsg("");
    }
    const dobIsValid = validateDateOfBirth(dobday, dobmonth, dobyear);
    if (!dobIsValid) {

      setNotDobValid(true);
      document.getElementById("DobDay").focus();
      document.getElementById("DobMonth").focus();
      document.getElementById("DobYear").focus();
      return false;
    }else{
      setNotDobValid(false);
    }

    if (
      !lstSalutationResult ||
      !txtFNameResult ||
      !txtLNameResult ||
      !lstDobDayResult ||
      !lstDobMonthResult ||
      !lstDobYearResult ||
      !lstPostResult ||
      !txtPhoneResult ||
      !txtEmailResult ||
      !SelectBank ||
      !dobIsValid
    ) {
      errorFlag = 1;
    }
    if (errorFlag === 0) {
      if (salutation == "Mrs" || salutation == "Ms" || salutation == "Miss" || salutation == "Dame" || salutation == "Lady") {
        seTitleQuest(true);
      }
      else {
        seTitleQuest(false);
      }
      return true;
    } else {
      return false;
    }
  };
  const handleClose = () => {
    setShowRegisterPop(false);
  };

  const openPrevPopup = async () => {
    setShowRegisterPop(true);
  };
  const namevalidate = async (e) => {
    if (e.target.value.trim() == "" || e.target.value.trim().length < 3) {
      var name = e.target.name == "txtFName" ? "First Name" : "Last Name";
      setError(e.target.name, {
        type: "manual",
        message: "Your " + name + " is invalid. Please recheck",
      });
      if (e.target.value.trim().length == 0) {
        e.target.value = e.target.value.trim();
      }
    }
    return false;
  };
  return (
    <>

      <div className={`sidesection py-2 pt-0 ${divShow}`}>
        <div>
          <div className="form-style form-body p-4 pb-5">
            <h2 className="mb-3  title">Enter Personal Details</h2>
            <div className="row">
              <div className="col-lg-2 mb-2">
                <SelectBox
                  className="form-select select1"
                  OptionValue={SalutationVAQUB4}
                  name="lstSalutation"
                  id="lstSalutation"
                  onChange={() => clearErrors("lstSalutation")}
                  clearErrors={clearErrors}
                  myRef={register({ required: "Please select your title" })}
                  validationMsg={
                    errors.lstSalutation && errors.lstSalutation.message
                  }
                ></SelectBox>
              </div>
              <div className="col-lg-5 ps-lg-0">
                <div className="mb-2">
                  <Input
                    type="text"
                    placeholder="First Name"
                    className="form-control"
                    name="txtFName"
                    id="txtFName"
                    rules={{
                      required: "Please enter your first name",
                      minLength: {
                        value: 3,
                        message: "Please enter valid first name",
                      },
                      pattern: {
                        value: /^[a-zA-Z\s]+$/,
                        message: "Your name is invalid. Please recheck",
                      },
                    }}
                    transform={(value) =>
                      value.replace(/^[ ]+/g, "").replace(/[^a-zA-Z\s]/g, "")
                    }
                  />
                </div>
              </div>
              <div className="col-lg-5 ps-lg-0">
                <div className="mb-2">
                  <Input
                    type="text"
                    placeholder="Last Name"
                    className="form-control"
                    name="txtLName"
                    id="txtLName"
                    rules={{
                      required: "Please enter your last name",
                      minLength: {
                        value: 3,
                        message: "Please enter valid last name",
                      },
                      pattern: {
                        value: /^[a-zA-Z\s]+$/,
                        message: "Your name is invalid. Please recheck",
                      },
                    }}
                    transform={(value) =>
                      value.replace(/^[ ]+/g, "").replace(/[^a-zA-Z\s]/g, "")
                    }
                  />
                </div>
              </div>
              <PostCode />
            </div>
            <PrevAddressPopup
              show={showRegisterPop}
              handleClose={handleClose}
              formSubmit={formSubmit}
              setPreviousAddressData={setPreviousAddressData}
              titleQuest={titleQuest}
            />

            <DateOfBirth 
              setDobday={setDobday}
              setDobmonth={setDobmonth}
              setDobyear={setDobyear}
              notDobValid={notDobValid}
            />

            <ContactDetails />
            <Banks
              lenderList={lenderList}
              SelectBank={SelectBank}
              setSelectBank={setSelectBank}
              BankValidationMsg={BankValidationMsg}
              setBankValidationMsg={setBankValidationMsg}
              divRefOne={divRefOne}  
            />

            <Sign
              formSubmit={formSubmit}
              pageChange={pageChange}
              basicDetailsValidation={basicDetailsValidation}
              openPrevPopup={openPrevPopup}
              setSignatureDataURL={setSignatureDataURL}
            />

          </div>
        </div>
      </div>
    </>
  );
};

export default PersonalDetails;
