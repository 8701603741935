import React, { useState } from "react";
import SelectBox from "../../../UI/SelectBox";
import InputButton from "../../../UI/InputButton";
import { Modal } from "react-bootstrap";
import { ClosedYearsV3 } from "../../../../Constants/Constants";
import RadioButton from "../../../UI/RadioButton";
import RadioButtonQuest from "../../../UI/RadioButtonQuest";

const InitialYear = ({
  saveInputAns,
  validationMsg,
  clearErrors,
  validation,
  trigger,
  getValues,
  backClick,
  onSelectAnswer,
  setYearSelected,
  splitForm,
  setClickedSlide,
  setQuest49,
}) => {
  const [claimModal, setshowClaimModal] = useState(false);
  const data = getValues();
  let intialYear = data["initialyears"];
  
  const selectedYear = async (e) => {
    if (e.target.name == "openYearCredit") {
      setYearSelected(e.target.value)
      saveInputAns(e);
    } else {
      let errorFlag = 0;
      let yearresult = await trigger("initialyears");
      if (!yearresult) {
        errorFlag = 1;
        return false;
      } else {
        setYearSelected(intialYear);
        saveInputAns(e);
      }
    }
  }

  return (
    <>
      <div className="question-content mt-0 scale-down-ver-center  px-3 py-lg-3 py-md-3 py-sm-2 py-2">
      <div className="mb-2">
            <ul className="p-0 m-0 option-grid">
              {["2024", "2023", "2022", "2021", "2020", "2019", "2018"].map((year, index) => (
                <div className="optionbtn">
                <li key={year}>
                  <RadioButtonQuest
                    type="button"
                    //className="btn-comm2 py-lg-3 py-md-3 py-sm-2 py-2 w-100 nextBtn"
                    id={`optionsCardYr${index + 1}`}
                    name="openYearCredit"
                    value={year}
                    labelName={year}
                    onClick={selectedYear}
                    dataQuestId={28}
                  >
                    {year}
                  </RadioButtonQuest>
                </li>
                </div>
              ))}
            </ul>
          </div>
        <div className="row">
          <div className="col-lg-8 mb-2">
            <SelectBox
              className="form-select"
              OptionValue={ClosedYearsV3}
              name="initialyears"
              id="initialyears"
              onChange={() => clearErrors("initialyears")}
              clearErrors={clearErrors}
              myRef={validation({ required: "Please select year" })}
              validationMsg={
                validationMsg.initialyears && validationMsg.initialyears.message
              }
            ></SelectBox>
          </div>
          <div className="col-lg-4 mb-2">
            <button
              type="button"
              className=" btn-comm2 py-lg-3 py-md-3 py-sm-2 py-2 w-100 nextBtn "
              id="nextscroll"
              name="initialyears"
              onClick={selectedYear}
              data-quest-id={28}
            >
              Next &#8594;
            </button>
          </div>
        </div>
      </div>
    </>
  );
};
export default InitialYear;
