import React, { useState } from "react";
import OtpInputBox from "../../../../UI/OtpInputBox";

const BankAccNo = ({
  validationMsg,
  clearErrors,
  validation,
  trigger,
  getValues,
  backClick,
  saveInputAns,
  onselectAnswer,
  reff,
  clsName,
  setAccNum,
  SkippedLeads,
}) => {
  const [accNo, setAccNo] = useState("");
  const [error, setError] = useState(false);

  const handlebtnChange = (accNo) => {
    setAccNo(accNo);
    setAccNum(accNo);
  };
  const skipNext = () => {
    // let array = JSON.parse(localStorage.getItem('questionData'));
    // array = array.filter(item => item.question_id !== 87);
    // localStorage.setItem('questionData', JSON.stringify(array));
    SkippedLeads("skipFLPAccountNumber", "accnum");
  };

  const handleNext = async (e) => {
    if (accNo.length != 8) {
      setError(true);
      return;
    } else if (parseInt(accNo) === 0) {
      setError(true);
      return;
    } else {
      setError(false);
      saveInputAns(e);
    }
  };

  return (
    <>
      <div
        className={`radio-box animated fadeInUp  ${clsName}`}
        id="slide-15"
        ref={reff}
      >
        <h3>
          <b>For your bank to locate your account please provide your Account Number</b>
        </h3>
      </div>
      <div className="question-content mt-0 scale-down-ver-center px-3 pb-lg-3 pb-md-3 pb-sm-2 pb-2">
        <div className="row">
          <div className="col-lg-12 mb-2">
            <div className="otp-input">
              <OtpInputBox
                length={8}
                onChange={handlebtnChange}
                className={"otp-input-field"}
              />
            </div>
            {error && (
              <span className="error_msg mt-2">
                Please enter a valid Bank Account Number
              </span>
            )}
          </div>
          <div className="col-lg-12 mt-2">
            <button
              type="button"
              className="btn-next btncls1"
              id="nextscroll"
              name="accnum"
              onClick={handleNext}
              data-quest-id={87}
            >
              Next
            </button>
          </div>
          <span className="warning-text">
            {
              "(If you prefer not to, you can skip this step)"
            }
          </span>
          <div className="col-lg-12">
          <div className="mb-2 mt-3 text-center">
            <a className="backbtn skip-btn" name="sortcode" onClick={skipNext}>
              {"Skip"}
            </a>
          </div>
        </div>
          <span className="col-12 text-center mt-1">
            <img
              src={"../../../../assets/Quest_VAQ_UB_4_3/img/secure_icon.png"}
              style={{ width: "81px" }}
            />
          </span>
        </div>
        
      </div>
    </>
  );
};

export default BankAccNo;
