import React, { useState } from "react";
import RadioButtonQuest from "../../../../UI/RadioButtonQuest";
import Button from "../../../../UI/Button";


const QuestionnaireBankStmtConfirm = ({
  clsName,
  reff,
  onselectAnswer,
  userEmail,
  className,
  addPending,
  hidePrev
}) => {
  const labelclicked = async (e) => {
    const answer = e.target.value;
    if (answer === "BankStatements") {
      localStorage.setItem("bankStmtConfirm",1);
    } 
    onselectAnswer(e);
   
  };
  return (
    <>
      <div className={className}>
        <div className="row">
          <div className="col-lg-6 offset-lg-3">
            <h1 className="title">
              {" "}
              Did Moneybarn ask you to provide any of the below when you bought the car?
            </h1>

            <div className="row mt-4">
              <div className="col-lg-12">
                <div className=" col-lg-12 mb-pd text-center ">
                  <div className="btn-comm com-btn  ">
                    <RadioButtonQuest
                      key="Payslips"
                      className="radio-button"
                      labelName="Payslips"
                      labelClassName="option_but  Payslips"
                      // labelCountString="a"
                      onClick={labelclicked}
                      value="Payslips"
                      name="question_74"
                      id="Payslips"
                      dataQuestId={74}
                      dataAnsId={210}
                      dataAnsLabel="Payslips"
                    />
                    <RadioButtonQuest
                      key="BankStatements"
                      className="radio-button"
                      labelName="Bank Statements"
                      labelClassName="option_but  BankStatements"
                      // labelCountString="b"
                      onClick={labelclicked}
                      value="BankStatements"
                      name="question_74"
                      id="BankStatements"
                      dataQuestId={74}
                      dataAnsId={211}
                      dataAnsLabel="BankStatements"
                    />
                    <RadioButtonQuest
                      key="CantRemember"
                      className="radio-button"
                      labelName="Can't remember"
                      labelClassName="option_but  CantRemember"
                      // labelCountString="b"
                      onClick={labelclicked}
                      value="CantRemember"
                      name="question_74"
                      id="CantRemember"
                      dataQuestId={74}
                      dataAnsId={212}
                      dataAnsLabel="CantRemember"
                    />
                  
                  </div>
                  <div class={`text-center back ${hidePrev}`}>
                    <Button 
                    className="btn btn-link mt-2 h2" 
                    id="back1" 
                    type="button"
                    buttonText="<< Previous"
                    onClick={()=>addPending("provide_statement")}
                    />
                </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default QuestionnaireBankStmtConfirm;