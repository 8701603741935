import React, { useState } from 'react';
import OtpInputBox from '../../../UI/OtpInputBox';
import Accordion from "react-bootstrap/Accordion";

const SortCode = ({
    saveInputAns,
    backClick,
    SkippedLeads,
    setClickedSlide,
    setSortCode,
    lenderName
}) => {
    const [scode, setSCode] = useState("");
    const [error, setError] = useState(false);

    const handlebtnChange = (scode) => {
        setSCode(scode);
        setSortCode(scode);
    };
    const skipNext = () => {
        let array = JSON.parse(localStorage.getItem('questionData'));
        array = array.filter(item => item.question_id !== 86);
        localStorage.setItem('questionData', JSON.stringify(array));
        SkippedLeads("skipSortCode", false);
        setClickedSlide({ slide: "sortcode" });
    };

    const handleNext = async (e) => {
        if (scode.length != 6) {
            setError(true);
            return;
        } else if (parseInt(scode) === 0) {
            setError(true);
            return;
        } else {
            setError(false)
            saveInputAns(e);
        }
    };


    return (
        <>
            <div className="question-content mt-0 scale-down-ver-center px-3 pb-lg-3 pb-md-3 pb-sm-2 pb-2">
                <div className="mb-3">
                    <Accordion>
                        <Accordion.Item className="custom-accordion" eventKey="0">
                            <Accordion.Header>
                                <div className="p-1 text-primary text-decoration-underline">
                                    Why are we asking for this information?
                                </div>
                            </Accordion.Header>
                            <Accordion.Body>
                                When submitting a DSAR, it is essential to provide the
                                bank with sufficient details to identify the correct
                                records associated with your account. The bank account
                                number and sort code are crucial identifiers that
                                allow the bank to locate all pertinent information
                                quickly and accurately. This ensures that your DSAR is
                                processed efficiently and that all relevant data is
                                included in the response
                                <br />
                                <br />
                                Please rest assured that this information will be used
                                solely for the purpose of submitting your DSAR to{" "}
                                {lenderName} and will be handled with the utmost
                                confidentiality in accordance with applicable data
                                protection laws
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                </div>
                <div className="row">
                    {("others" !== lenderName && "" !== lenderName) && (
                        <h1 className="que-title p-3"><b>Lender: {lenderName}</b></h1>
                    )}
                    <div className="col-lg-8 mb-2">
                        <div className="otp-input">
                            <OtpInputBox
                                length={6}
                                onChange={handlebtnChange}
                                className={'otp-input-field'}
                            />
                        </div>
                        {error && (
                            <span className='error_msg mt-2'>Please enter a valid Sort Code</span>
                        )}
                    </div>
                    <div className="col-lg-4 mb-2 ">
                        <button
                            type="button"
                            className="btn-comm2 py-lg-3 py-md-3 py-sm-2 py-2 w-100 nextBtn "
                            id="nextscroll"
                            name="sortcode"
                            onClick={handleNext}
                            data-quest-id={86}
                        >
                            Next &#8594;
                        </button>
                    </div>
                    <div className="col-lg-4 col-12 m-auto mb-2 mt-2">
                        <button
                            name="sortcode"
                            className="btn btn-green-skip py-lg-3 py-md-3 py-sm-2 py-2 "
                            id=""
                            value=""
                            type="button"
                            onClick={skipNext}
                        >
                            Skip
                        </button>
                    </div>
                    <span className='warning-text'>{"(if you prefer not to provide it, you can skip this step)"}</span>
                    <span className='col-12 text-center mt-1'><img src={"../../../../assets/Quest_VAQ_UB_4_2/img/secure_iconV2.webp"} style={{ width: "81px" }} /></span>
                </div>
                {/* <div className="row">
                  <div className="mb-2 mt-3 text-center">
                    <a className="backbtn skip-btn" name="sortcode" onClick={skipNext}>{"Skip"}</a>
                </div>
          </div> */}

                <p className="text-center bck-marwid">
                    <button
                        name="bank_acc_no"
                        className="btn btn-secondary backbtn1a"
                        id=""
                        value=""
                        type="button"
                        onClick={backClick}
                    >
                        Go Back
                    </button>
                </p>
            </div>
        </>
    );
};

export default SortCode;