import React, { useReducer, useState } from "react";
import ProgressBar from "../../Layouts/Common/ProgressBar";
import AddressTextField from "../../../UI/AddressTextField";
import AddressSelectBox from "../../../UI/AddressSelectBox";
import HiddenField from "../../../UI/HiddenField";
import InputButton from "../../../UI/InputButton";
import { usePostCodeAddress } from "../../../../Hooks/usePostCodeAddress";
import PreviousPostCode from "../VAQ_UB_1/PreviousPostcode";

const initialState = {
  lookUp: true,
  nextClick: false,
  checkSelect: false,
  getDetails: [],
  getAddress: [],
  showManualAddress: "hide",
  getPostcode: "#",
  clickManualLink: 0,
  readonly: true,
};
const postcodeReducer = (state, action) => {
  switch (action.type) {
    case "validationSucess": {
      return {
        ...state,
        lookUp: action.payload.lookup,
        nextClick: action.payload.next_click,
        getPostcode: action.payload.postcode,
        getAddress: action.payload.get_address,
        checkSelect: action.payload.check_select,
        showManualAddress: action.payload.manual_address,
      };
    }
    case "getExtraAddressDetails": {
      return {
        ...state,
        getDetails: action.payload.get_details,
        showManualAddress: action.payload.manual_address,
      };
    }
    case "showManualDiv": {
      return {
        ...state,
        showManualAddress: "show",
        lookUp: false,
        nextClick: true,
        checkSelect: false,
        clickManualLink: action.payload.manual_click,
        readonly: false,
      };
    }
    case "resetaddress": {
      return {
        ...state,
        getDetails: [],
        getAddress: [],
        showManualAddress: "hide",
        lookUp: true,
        nextClick: false,
        checkSelect: false,
      };
    }
    default:
      return state;
  }
};

const PostCode = ({
  validation,
  validationMsg,
  clearErrors,
  setError,
  slideChange,
  trigger,
  className,
  backClick,
  scrollView,
  setPreviousAddressData,
  lender
}) => {
  const [postgetModalone, setPostgetModalone] = useState(false);

  const { getPostcodeValidation, getPostcodeAddress, getSplitPostcodeAddress } =
    usePostCodeAddress();
  const [state, dispatch] = useReducer(postcodeReducer, initialState);
  const [postCodeAddress, setPostCodeAddress] = useState([]);
  const [postCodeEntry, setPostCodeEntry] = useState("0");
  const checkValidation = async (e) => {
    e.preventDefault();
    dispatch({
      type: "resetaddress",
      get_address: [],
    });
    const txtPostCode = await trigger("txtPostCode");
    if (txtPostCode) {
      await getPostcodeAddress(e.target.value)
        .then((res) => {
          if (res.data.status === "0") {
            setError("txtPostCode", {
              type: "manual",
              message: "Please Enter Valid Postcode",
            });
          }
          if (res.data.length > 0) {
            dispatch({
              type: "validationSucess",
              payload: {
                lookup: false,
                next_click: true,
                postcode: e.target.value,
                get_address: res.data,
                check_select: true,
                manual_address: "hide",
              },
            });
          }
        })
        .catch(function () {
          setError("txtPostCode", {
            type: "manual",
            message: "Please Enter Valid Postcode",
          });
        });
    }
  };
  const getValue = async (e) => {
    e.preventDefault();
    // document.getElementById("address1").classList.remove("anim_ylw");
    // document.getElementById("NextButton").classList.add("anim_ylw");
    clearErrors([
      "txtHouseNumber",
      "txtAddress2",
      "txtCounty",
      "txtTown",
      "txtCountry",
    ]);
    if (e.target.value != "") {
      const getData = await getSplitPostcodeAddress(
        e.target.value,
        state.getPostcode
      );
      dispatch({
        type: "getExtraAddressDetails",
        payload: { get_details: getData.data, manual_address: "show" },
      });
      setPostCodeAddress(getData.data);
      clearErrors([
        "txtHouseNumber",
        "txtAddress2",
        "txtCounty",
        "txtTown",
        "txtCountry",
      ]);
    } else {
      dispatch({
        type: "getExtraAddressDetails",
        payload: { get_details: [], manual_address: "hide" },
      });
      setPostCodeAddress([]);
    }
  };

  const manualEntry = () => {
    dispatch({
      type: "showManualDiv",
      payload: { get_details: [], manual_address: "hide" },
    });

    setTimeout(() => {
      scrollView.current.scrollIntoView({
        behavior: "smooth",
        top: 0,
      });
    }, 50);
  };

  const lookupValidation = async (e) => {
    await trigger("txtPostCode");
  };

  const checkPostCodeEntry = () => {
    const addressLine1 = document.getElementById("txtHouseNumber").value;
    const addressLine2 = document.getElementById("txtAddress2").value;
    const town = document.getElementById("txtTown").value;
    const county = document.getElementById("txtCounty").value;
    const country = document.getElementById("txtCountry").value;

    if (
      postCodeAddress.length === 0 ||
      postCodeAddress.Country !== country ||
      postCodeAddress.County !== county ||
      postCodeAddress.Line1 !== addressLine1 ||
      postCodeAddress.Line2 !== addressLine2 ||
      postCodeAddress.Town !== town
    ) {
      setPostCodeEntry("1");
    }
  };

  const addressValidation = async (e) => {
    let errorFlag = 0;
    const txtPostCode = await trigger("txtPostCode");
    var manual_txtHouseNumber;
    var manual_txtTown;
    var manual_txtCountry;
    var txtAddress;
    if (txtPostCode) {
      manual_txtHouseNumber = await trigger("txtHouseNumber");
      txtAddress = await trigger("address1");
    } else {
      document.getElementById("txtPostCode").focus();
      return false;
    }
    if (manual_txtHouseNumber) {
      manual_txtTown = await trigger("txtTown");
    } else {
      return false;
    }
    if (manual_txtTown) {
      manual_txtCountry = await trigger("txtCountry");
    } else {
      return false;
    }
    if (
      !txtPostCode ||
      !manual_txtHouseNumber ||
      !manual_txtTown ||
      !manual_txtCountry
    ) {
      errorFlag = 1;
    }

    if (state.clickManualLink === 0) {
      const address1 = await trigger("address1");
      if (!address1) {
        errorFlag = 1;
      }
    }
    if (errorFlag === 0) {
      checkPostCodeEntry();
      // slideChange(e);
      setPostgetModalone(true);
    } else {
      return false;
    }
  };
  const HiddenFields = () => (
    <>
      <HiddenField
        name="txtDistrict"
        inputId="txtDistrict"
        inputValue={state.getDetails !== "#" ? state.getDetails.district : ""}
        validation={validation()}
      />
      <HiddenField
        name="txtAddress3"
        inputId="txtAddress3"
        inputValue={state.getDetails !== "#" ? state.getDetails.line_3 : ""}
        validation={validation()}
      />
      <HiddenField
        name="txtUdprn"
        inputId="txtUdprn"
        inputValue={state.getDetails !== "#" ? state.getDetails.Udprn : ""}
      />
      <HiddenField
        name="txtDeliveryPointSuffix"
        inputId="txtDeliveryPointSuffix"
        inputValue={
          state.getDetails !== "#" ? state.getDetails.deliverypointsuffix : ""
        }
      />
      <HiddenField
        name="txtPz_mailsort"
        inputId="txtPz_mailsort"
        inputValue={
          state.getDetails !== "#" ? state.getDetails.pz_mailsort : ""
        }
      />
      <HiddenField
        name="txtStreet"
        inputId="txtStreet"
        inputValue={
          state.getDetails.line_3 !== "#" ? state.getDetails.line_3 : ""
        }
      />
    </>
  );
  return (
    <>
      <div id="slide-10" className={`${className}`}>
        <ProgressBar value={80} />
        {/* <h3>Your Address</h3> */}
        <div className="col-lg-12 p-0">
          <h3>What is your current address?</h3>
        </div>
        <HiddenField
          name="pCode_EntryType"
          inputId="pCode_EntryType"
          inputValue={postCodeEntry}
        />
        <div className="input-section">
          <div className="form-group mb-3">
            <div className="input-group post">
              <AddressTextField
                type="text"
                placeholder="Your Postcode"
                name="txtPostCode"
                id="txtPostCode"
                className="form-control"
                dataId="txtPostCode"
                onBlur={checkValidation}
                autoComplete="off"
                validation={validation({
                  required: "Please Enter Postcode",
                  pattern: {
                    value:
                      /^(([A-Z]{1,2}[0-9]{1,2} ?[0-9][A-Z]{2})|([A-Z]{1,2}[0-9][A-Z] ?[0-9][A-Z]{2,3}))$/i,
                    message: "Please Enter a Valid Postcode",
                  },
                  minLength: {
                    value: 5,
                    message: "Please Enter a Valid Postcode",
                  },
                })}
                validationMsg={
                  validationMsg.txtPostCode && validationMsg.txtPostCode.message
                }
              />
            </div>
          </div>
          <div
            className="col-lg-12 col-12  p-0 text-right slide8_lkup"
            style={state.lookUp ? { display: "block" } : { display: "none" }}
          >
            <div style={{ width: "100%" }} className="main_1">
              <InputButton
                style={{ float: "none" }}
                name="next"
                className="btn btn-next anim_ylw"
                value="Lookup Address"
                btnType="button"
                onClick={lookupValidation}
              />
            </div>
          </div>
          <div
            className={`form-group col-lg-12 col-12 p-0 adj_width mb-3`}
            id="currentAddressCollapse"
            style={
              state.checkSelect ? { display: "block" } : { display: "none" }
            }
          >
            <AddressSelectBox
              className="form-control"
              OptionValue={state.getAddress}
              name="address1"
              id="address1"
              onChange={getValue}
              myRef={validation({ required: "Please Select Address" })}
              validationMsg={
                validationMsg.address1 && validationMsg.address1.message
              }
            />
          </div>
          <div
            className="col-12 text-center"
            style={
              state.getDetails.length === 0
                ? { display: "block" }
                : { display: "none" }
            }
          ></div>
          <div className={`slide8_add row ${state.showManualAddress}`}>
            <div
              className={`col-lg-12 form-group required-field adj_width mb-3`}
            >
              <AddressTextField
                type="text"
                placeholder="Address Line 1 *"
                name="txtHouseNumber"
                id="txtHouseNumber"
                className="form-control  hero-input"
                dataId="txtHouseNumber"
                autoComplete="off"
                readonly={state.readonly}
                validation={validation({
                  required: "Please Enter Address Line 1",
                })}
                validationMsg={
                  validationMsg.txtHouseNumber &&
                  validationMsg.txtHouseNumber.message
                }
                value={state.getDetails.line_1}
              />
              <i
                className="validate validate_success"
                aria-hidden="true"
                style={{ display: "none" }}
              ></i>
            </div>

            <div className={`col-lg-12 form-group adj_width mb-3`}>
              <AddressTextField
                type="text"
                placeholder="Address Line 2"
                name="txtAddress2"
                id="txtAddress2"
                className="form-control  hero-input"
                dataId="txtAddress2"
                autoComplete="off"
                readonly={state.readonly}
                validation={validation()}
                validationMsg=""
                value={state.getDetails.line_2}
              />
              <i
                className="validate validate_success"
                aria-hidden="true"
                style={{ display: "none" }}
              ></i>
            </div>

            <div className={`col-lg-12 form-group adj_width mb-3`}>
              <AddressTextField
                type="text"
                placeholder="County"
                name="txtCounty"
                id="txtCounty"
                className="form-control  hero-input"
                dataId="txtCounty"
                autoComplete="off"
                readonly={state.readonly}
                validation={validation()}
                validationMsg=""
                value={state.getDetails.county}
              />
              <i
                className="validate validate_success"
                aria-hidden="true"
                style={{ display: "none" }}
              ></i>
            </div>

            <div className={`col-lg-12 form-group adj_width mb-3`}>
              <AddressTextField
                type="text"
                placeholder="Town *"
                name="txtTown"
                id="txtTown"
                className="form-control  hero-input"
                dataId="txtTown"
                autoComplete="off"
                readonly={state.readonly}
                validation={validation({ required: "Please Enter Town" })}
                validationMsg={
                  validationMsg.txtTown && validationMsg.txtTown.message
                }
                value={state.getDetails.town}
              />
              <i
                className="validate validate_success"
                aria-hidden="true"
                style={{ display: "none" }}
              ></i>
            </div>
            <div className={`col-lg-12 form-group adj_width mb-3`}>
              <AddressTextField
                type="text"
                placeholder="Country *"
                name="txtCountry"
                id="txtCountry"
                className="form-control  hero-input"
                dataId="txtCountry"
                autoComplete="off"
                readonly={state.readonly}
                validation={validation({ required: "Please Enter Country" })}
                validationMsg={
                  validationMsg.txtCountry && validationMsg.txtCountry.message
                }
                value={state.getDetails.country}
              />
              <i
                className="validate validate_success"
                aria-hidden="true"
                style={{ display: "none" }}
              ></i>
            </div>
            {Object.keys(state.getDetails).length > 0 ? <HiddenFields /> : null}
          </div>

          <div
            className="col-lg-12 col-12 text-center p-0"
            style={state.nextClick ? { display: "block" } : { display: "none" }}
          >
            <div style={{ width: "100%" }} className="main_1">
              <InputButton
                name="postcodeone"
                className="btn next08  regNextBtn"
                id="postcodeone"
                value="Next"
                btnType="button"
                onClick={addressValidation}
                style={{ float: "none" }}
              />
            </div>
          </div>
          <div className="clearfix"></div>
          <div className="col-12 text-center pt-3">
            <p className="pfrp">
              <a
                className="text-dark"
                href="#"
                id="entermanuely"
                onClick={manualEntry}
              >
                [ Having Issues? Click Here to Enter Manually ]
              </a>
            </p>
          </div>
          <div style={{display: "inline-block",width: "100%",marginBottom:"59px"}}>
          <p className="text-center">
            <span
              className="back6 back-btn btn-link"
              name="back10"
              children="<< Previous"
              onClick={backClick}
            ></span>
          </p>
          </div>
        </div>
        <PreviousPostCode
          show={postgetModalone}
          setPostgetModalone={setPostgetModalone}
          validation={validation}
          validationMsg={validationMsg}
          clearErrors={clearErrors}
          setError={setError}
          slideChange={slideChange}
          trigger={trigger}
          className={className}
          backClick={backClick}
          scrollView={scrollView}
          setPreviousAddressData={setPreviousAddressData}
          lender={lender}
        />
      </div>
    </>
  );
};

export default PostCode;
