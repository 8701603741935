import React, { useState } from "react";
import TextField from "../../../../UI/TextField";
import InputButton from "../../../../UI/InputButton";

//import RadioButtonQuest from "../../../UI/RadioButtonQuest";
const AmountModal = ({
  questArr,
  validation,
  validationMsg,
  monthlyExpShow,
  otherExpShow,
  handleChange,
  totalDebitValidation,
  totalOtherDebitValidation,
  showModel1,
  showModel2,
  newDayData
}) => {


  return (
    <>
      <div role="dialog" className={`fade modal blackover ${showModel1}`}>
        <div className="modal-dialog modal-md modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body">
              <h3 className="text-center fonts15"><u>Provide your total Loan and Credit card expenses you have with your Lenders Other than {newDayData.lender} </u></h3>
              <p><i>(Many of our customers have expenses with multiple lenders. So you can provide the total sum of expenses you pay with all Lenders)</i></p>
              <div className="input-group mb-3 justify-content-center">
                <div className="mb-3 mt-3 col-12 p-0">
                  <div className="input-group mb-3">
                    <TextField
                      type="tel"
                      className="form-control"
                      placeholder="Provide your total Loan and Credit card expenses"
                      // name="question_51"
                      name="question_59"
                      onChange={handleChange}
                      validation={validation({
                        required: "Please Enter Total Loan and Credit card expenses",
                        pattern: {
                          value: /^[ A-Za-z0-9_@./#&+-£]*$/,
                          message:
                            "Total Loan and Credit card expenses is Invalid. Please Recheck",
                        },
                      })}
                      validationMsg={
                        validationMsg.question_59 &&
                        validationMsg.question_59.message
                      }
                    ></TextField>
                  </div>
                  <InputButton
                    name="eeeee"
                    className="btn-next"
                    id=""
                    value="Next"
                    btnType="button"
                    onClick={totalDebitValidation}
                    style={{ float: "none" }}
                    data-quest-id={59}
                    data-ans-id={null}
                    data-ans-label={null}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div role="dialog" className={`fade modal blackover ${showModel2}`}>
        <div className="modal-dialog modal-md modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body">
              <h3 className="text-center fonts15"><u>Provide your total other monthly expenses you have</u></h3>
              <p><i>(This can be a total of Fuel, Clothing, Betting, Bills, Coffee/Tea etc)</i></p>
              <div className="input-group mb-3 justify-content-center">
                <div className="mb-3 mt-3 col-12 p-0">
                  <div className="input-group mb-3">
                    <TextField
                      type="tel"
                      className="form-control"
                      placeholder="Provide your total Loan and Credit card expenses"
                      // name="question_51"
                      name="question_60"
                      onChange={handleChange}
                      validation={validation({
                        required: "Please Enter Total Loan and Credit card expenses",
                        pattern: {
                          value: /^[ A-Za-z0-9_@./#&+-£]*$/,
                          message:
                            "Total Loan and Credit card expenses is Invalid. Please Recheck",
                        },
                      })}
                      validationMsg={
                        validationMsg.question_60 &&
                        validationMsg.question_60.message
                      }
                    ></TextField>
                  </div>
                  <InputButton
                    name="sssss"
                    className="btn-next"
                    id=""
                    value="Next"
                    btnType="button"
                    onClick={totalOtherDebitValidation}
                    style={{ float: "none" }}

                    data-quest-id={60}
                    data-ans-id={null}
                    data-ans-label={null}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AmountModal;