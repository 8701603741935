import React, { useState, useEffect, useRef, useReducer } from "react";
import { useForm } from "react-hook-form";
import Modal from "react-bootstrap/Modal";
import { useFollowupUserQuestions } from "../../../../Hooks/useFollowupUserQuestions";
import { questinnare1, questinnare2Very,questinnaire76MB, questinnare2VeryV2 } from "../../../../Constants/Questions";
import QuestionnaireFlpQ5 from "../Followup/QuestionnaireFlpQ5";
import QuestionnaireFlpFANUpload from "../Followup/QuestionnaireFlpFANUpload";
import QuestionnaireFlpAgreementNo from "./QuestionnaireFlpAgreementNo";
import QuestionnaireEmail from "../Followup/QuestionnaireEmail";
import "../../../../assets/css/QuestionnaireV7.scss";
import { useUserVerify } from "../../../../Hooks/useUserVerify";
import { userMilestoneUpdate } from "../../../../Hooks/userMilestoneUpdate";
import { queryString } from "../../../../Utility/QueryString";
import { useHistory } from "react-router-dom";
import { useSkippedLeads } from '../../../../Hooks/useSkippedLeads';
import HighestBal from "./NewDay/HighestBal";
import ClosedYear from "./NewDay/ClosedYear";
import LimitIncrease from "./NewDay/LimitIncrease";
import CreditLimit from "./NewDay/CreditLimit";
import MinPayment from "./NewDay/MinPayment";
import Amounts from "./NewDay/Amounts";
import EmailQuestion from "./NewDay/EmailQuestion";
import QuestionnaireFooter from "./QuestionnaireFooter";
import AmountModal from "./NewDay/AmountModal";
import QuestionnaireFlpQ76 from "../Followup/QuestionnaireFlpQ76";

const initialState = {
  showSlide1: "hide",
  showSlide2: "hide",
  showSlide3: "hide",
  showSlide4: "hide",
  showSlide5: "hide",
  showSlide6: "hide",
  showSlide7: "hide",
  showSlide8: "hide",
  showSlide9: "hide",
  showSlide10a: "hide",
  showSlide10: "hide",
  showSlide11: "hide",
};
const QuestionnaireReducer = (state, action) => {
  switch (action.type) {
    case "showSlideChange": {
      if (action.payload == "slide1") {
        return {
          ...state,
          showSlide1: "show",
          showSlide2: "hide",
          showSlide3: "hide",
          showSlide4: "hide",
          showSlide5: "hide",
          showSlide6: "hide",
          showSlide7: "hide",
          showSlide8: "hide",
          showSlide9: "hide",
          showSlide10a: "hide",
          showSlide10: "hide",
          showSlide11: "hide",
          percentage: 10,
        };
      } else if (action.payload == "slide2") {
        return {
          ...state,
          showSlide1: "hide",
          showSlide2: "show",
          showSlide3: "hide",
          showSlide4: "hide",
          showSlide5: "hide",
          showSlide6: "hide",
          showSlide7: "hide",
          showSlide8: "hide",
          showSlide9: "hide",
          showSlide10a: "hide",
          showSlide10: "hide",
          showSlide11: "hide",
          percentage: 20,
        };
      } else if (action.payload == "slide3") {
        return {
          ...state,
          showSlide1: "hide",
          showSlide2: "hide",
          showSlide3: "show",
          showSlide4: "hide",
          showSlide5: "hide",
          showSlide6: "hide",
          showSlide7: "hide",
          showSlide8: "hide",
          showSlide9: "hide",
          showSlide10a: "hide",
          showSlide10: "hide",
          showSlide11: "hide",
          percentage: 30,
        };
      } else if (action.payload == "slide4") {
        return {
          ...state,
          showSlide1: "hide",
          showSlide2: "hide",
          showSlide3: "hide",
          showSlide4: "show",
          showSlide5: "hide",
          showSlide6: "hide",
          showSlide7: "hide",
          showSlide8: "hide",
          showSlide9: "hide",
          showSlide10a: "hide",
          showSlide10: "hide",
          showSlide11: "hide",
          percentage: 40,
        };
      } else if (action.payload == "slide5") {
        return {
          ...state,
          showSlide1: "hide",
          showSlide2: "hide",
          showSlide3: "hide",
          showSlide4: "hide",
          showSlide5: "show",
          showSlide6: "hide",
          showSlide7: "hide",
          showSlide8: "hide",
          showSlide9: "hide",
          showSlide10a: "hide",
          showSlide10: "hide",
          showSlide11: "hide",
          percentage: 50,
        };
      } else if (action.payload == "slide6") {
        return {
          ...state,
          showSlide1: "hide",
          showSlide2: "hide",
          showSlide3: "hide",
          showSlide4: "hide",
          showSlide5: "hide",
          showSlide6: "show",
          showSlide7: "hide",
          showSlide8: "hide",
          showSlide9: "hide",
          showSlide10a: "hide",
          showSlide10: "hide",
          showSlide11: "hide",
          percentage: 60,
        };
      } else if (action.payload == "slide7") {
        return {
          ...state,
          showSlide1: "hide",
          showSlide2: "hide",
          showSlide3: "hide",
          showSlide4: "hide",
          showSlide5: "hide",
          showSlide6: "hide",
          showSlide7: "show",
          showSlide8: "hide",
          showSlide9: "hide",
          showSlide10a: "hide",
          showSlide10: "hide",
          showSlide11: "hide",
          percentage: 70,
        };
      } else if (action.payload == "slide8") {
        return {
          ...state,
          showSlide1: "hide",
          showSlide2: "hide",
          showSlide3: "hide",
          showSlide4: "hide",
          showSlide5: "hide",
          showSlide6: "hide",
          showSlide7: "hide",
          showSlide8: "show",
          showSlide9: "hide",
          showSlide10a: "hide",
          showSlide10: "hide",
          showSlide11: "hide",
          percentage: 80,
        };
      } else if (action.payload == "slide9") {
        return {
          ...state,
          showSlide1: "hide",
          showSlide2: "hide",
          showSlide3: "hide",
          showSlide4: "hide",
          showSlide5: "hide",
          showSlide6: "hide",
          showSlide7: "hide",
          showSlide8: "hide",
          showSlide9: "show",
          showSlide10a: "hide",
          showSlide10: "hide",
          showSlide11: "hide",
          percentage: 85,
        };
      } else if (action.payload == "slide10a") {
        return {
          ...state,
          showSlide1: "hide",
          showSlide2: "hide",
          showSlide3: "hide",
          showSlide4: "hide",
          showSlide5: "hide",
          showSlide6: "hide",
          showSlide7: "hide",
          showSlide8: "hide",
          showSlide9: "hide",
          showSlide10a: "show",
          showSlide10: "hide",
          showSlide11: "hide",
          percentage: 90,
        };
      } else if (action.payload == "slide10") {
        return {
          ...state,
          showSlide1: "hide",
          showSlide2: "hide",
          showSlide3: "hide",
          showSlide4: "hide",
          showSlide5: "hide",
          showSlide6: "hide",
          showSlide7: "hide",
          showSlide8: "hide",
          showSlide9: "hide",
          showSlide10a: "hide",
          showSlide10: "show",
          showSlide11: "hide",
          percentage: 93,
        };
      } else if (action.payload == "slide11") {
        return {
          ...state,
          showSlide1: "hide",
          showSlide2: "hide",
          showSlide3: "hide",
          showSlide4: "hide",
          showSlide5: "hide",
          showSlide6: "hide",
          showSlide7: "hide",
          showSlide8: "hide",
          showSlide9: "hide",
          showSlide10a: "hide",
          showSlide10: "hide",
          showSlide11: "show",
          percentage: 95,
        };
      } else {
        return {
          ...state,
        };
      }
    }
  }
};
const NewDayQuestionForm = ({
  questionsPop,
  allFollowupData,
  pendingQuestionnaire,
  documentType,
  docType,
  sett2aValidation,
  t2aApiReqData,
  t2aStatus,
  currentUuid,
  setDivShow,
  splitName,
  newDayData
}) => {
  const history = useHistory();
  const [state, dispatch] = useReducer(QuestionnaireReducer, initialState);
  const [show, setShow] = useState(false);
  const [completedQuestArr, setCompletedQuestArr] = useState([]);
  const { saveFollowupUserQuestions, isLoading } = useFollowupUserQuestions();
  const splitForm = useRef(null);
  const modalValue = useRef(null);
  const [optionSelected] = useState([]);
  const [optionSelected76] = useState([]);
  const { getUserVerfyValidation,saveFollowupUserVerify } = useUserVerify();
  const { updateMilestone } = userMilestoneUpdate();
  const { skippedLeads } = useSkippedLeads();
  const [clickedModel, setClickedModel] = useState([]);
  const [monthlyExpShow, setMonthlyExpShow] = useState("hide");
  const [otherExpShow, setOtherExpShow] = useState("hide");

  const { register, errors, trigger, setError, clearErrors, getValues } =
    useForm({
      mode: "onBlur",
      reValidateMode: "onChange",
      defaultValues: {},
      resolver: undefined,
      context: undefined,
      criteriaMode: "firstError",
      shouldFocusError: true,
      shouldUnregister: true,
    });

  useEffect(() => {
    slideChange(pendingQuestionnaire);
  }, [pendingQuestionnaire]);

  const sendT2aApiReqDataAPI = () => {
    const useruuid = t2aApiReqData.uuid;
    const fName = t2aApiReqData.fName;
    const lName = t2aApiReqData.lName;
    const pCode = t2aApiReqData.pCode;
    const addressLine1 = t2aApiReqData.addressLine1;
    const dobDay = t2aApiReqData.day;
    const dobMonth = t2aApiReqData.month;
    const dobYear = t2aApiReqData.year;
    const addressLine2 = t2aApiReqData.addressLine2;
    const postTown = t2aApiReqData.postTown;
    const country = t2aApiReqData.country;
    const title = t2aApiReqData.title;
    const addressLine3 = t2aApiReqData.addressLine3;
    const addressLine4 = t2aApiReqData.addressLine4;
    const county = t2aApiReqData.county;

    if (
      useruuid &&
      fName &&
      lName &&
      pCode &&
      addressLine1 &&
      t2aStatus == null
    ) {
      (async () => {
        const response = await getUserVerfyValidation(
          useruuid,
          fName,
          lName,
          pCode,
          addressLine1,
          dobYear,
          dobMonth,
          dobDay,
          addressLine2,
          postTown,
          country,
          title,
          addressLine3,
          addressLine4,
          county,
        );
        storeT2aResponse(response.data);

        if (response.data.status == "success") {
          localStorage.setItem("t2a_result", 1);
          localStorage.setItem("split_name", splitName);
          history.push("/view_docs_flp?uuid=" + currentUuid);
        } else {
          localStorage.setItem("t2a_result", 0);
          setDivShow("idUpload");
        }
      })();
    } else {
      localStorage.setItem("t2a_result", t2aStatus);
      let email_skip = localStorage.getItem("email_doc");
      if (useruuid && email_skip != 1) {
        (async () => {
          const response = await updateMilestone(
            useruuid,
            t2aStatus,
            allFollowupData
          );
        })();
      }

      if (t2aStatus == 1) {
        history.push("/view_docs_flp?uuid=" + currentUuid);
      } else {
        setDivShow("idUpload");
      }
    }
  };
  const slideChange = (pendingArr) => {
    let pendingCount = pendingArr.length;
    if (pendingCount == 0) {
      setShow(false);
      sendT2aApiReqDataAPI();
    }

    pendingArr.every((element) => {
      dispatch({ type: "showSlideChange", payload: element });
    });
  };
  const storeFollowupQuestionnire = async (question_data) => {
    const response = await saveFollowupUserQuestions(
      question_data,
      allFollowupData,
      "followup_question_store"
    );
  };

  const storeT2aResponse = async (t2a_data) => {
    const response = await saveFollowupUserVerify(
      allFollowupData,
      t2a_data,
      "person_verify_store_flp",
      queryString,
      t2aApiReqData
    );
  };

  const onselectAnswer = async (e) => {
    let selectedAns = parseInt(e.target.getAttribute("data-ans-id"));
    let selectedQuest = parseInt(e.target.getAttribute("data-quest-id"));
    let anslabel = e.target.getAttribute("data-ans-label");
    let flage = false;
    let question_data = {};
    if (e.target.name == "question_54") {
      if (selectedQuest) {
        flage = true;
        question_data = {
          question_id: 54,
          option_id: null,
          answer_text: anslabel,
          input_answer: optionSelected,
        };
      }
      removeFromPending("slide4");
    }
    if (e.target.name == "question_55") {
      if (selectedQuest) {
        flage = true;
        question_data = {
          question_id: 55,
          option_id: null,
          answer_text: anslabel,
          input_answer: optionSelected,
        };
      }
      removeFromPending("slide5");
    }
    if (e.target.name == "question_56") {
      if (selectedQuest) {
        flage = true;
        question_data = {
          question_id: 56,
          option_id: null,
          answer_text: anslabel,
          input_answer: optionSelected,
        };
      }
      removeFromPending("slide6");
    }
    if (e.target.name == "question_61") {
      if (selectedQuest) {
        flage = true;
        question_data = {
          question_id: 61,
          option_id: null,
          answer_text: anslabel,
          input_answer: optionSelected,
        };
      }
      if (selectedAns == 162) {
        removeFromPending("slide8");
        removeFromPending("slide9");
      } else {
        removeFromPending("slide8");
      }

    }
    if (e.target.name == "btn_qest_36") {
      if (selectedQuest) {
        flage = true;
        question_data = {
          question_id: 36,
          option_id: null,
          answer_text: anslabel,
          input_answer: optionSelected,
        };
      }
      removeFromPending("slide10");
    }
    if (e.target.name == "btn_qest_76") {
      if (selectedQuest) {
        flage = true;
        question_data = {
          question_id: 76,
          option_id: null,
          answer_text: anslabel,
          input_answer: optionSelected76,
        };
      }
      removeFromPending("slide10a");
    } 
    else {
      if (selectedAns && selectedQuest) {
        flage = true;
        question_data = {
          question_id: selectedQuest,
          option_id: selectedAns,
          answer_text: anslabel,
          input_answer: "",
        };
      }
    }

    if (flage) {
      storeFollowupQuestionnire(question_data);
      var existingEntries = JSON.parse(localStorage.getItem("questionData"));
      if (existingEntries == null) existingEntries = [];
      localStorage.setItem(
        "currentquestionData",
        JSON.stringify(question_data)
      );
      existingEntries.push(question_data);
      localStorage.setItem("questionData", JSON.stringify(existingEntries));
    }
  };

  const saveIncomeExpenditure = async (e) => {
    let selectedQuest = parseInt(e.target.getAttribute("data-quest-id"));
    const values = getValues();
    setCompletedQuestArr([...completedQuestArr, selectedQuest, 32, 33, 35]);

    var answer31 = splitForm.current["question_31"].value;
    var answer32 = splitForm.current["question_32"].value;
    var answer33 = splitForm.current["question_33"].value;
    var answer35 = splitForm.current["question_35"].value;

    var slide_array = [
      ["31", answer31],
      ["32", answer32],
      ["33", answer33],
      ["35", answer35],
    ];

    var question_data = {
      slide_4: slide_array,
    };
    removeFromPending("slide2");
    storeFollowupQuestionnire(question_data);

    let _existingEntries = JSON.parse(localStorage.getItem("questionData"));
    if (_existingEntries == null) _existingEntries = [];
    _existingEntries.push(question_data);
    localStorage.setItem("questionData", JSON.stringify(_existingEntries));
  };

  const saveInputAns = async (e) => {
    let selectedQuest = parseInt(e.target.getAttribute("data-quest-id"));
    setCompletedQuestArr([...completedQuestArr, selectedQuest]);
    const valuesreg = getValues();
    let highestBalance = valuesreg["highest_balance"];
    let agreementNo = valuesreg["question_45"];
    let email = valuesreg["question_48"];
    let closeYr = valuesreg["question_52"];
    let flage = false;
    let question_data = {};
    if (selectedQuest == "45" && agreementNo) {
      flage = true;
      question_data = {
        question_id: selectedQuest,
        option_id: null,
        answer_text: "",
        input_answer: agreementNo,
      };
      removeFromPending("slide1");
    }
    if (selectedQuest == "52" && closeYr) {
      flage = true;
      question_data = {
        question_id: selectedQuest,
        option_id: null,
        answer_text: "",
        input_answer: closeYr,
      };
      removeFromPending("slide2");
    }
    if (selectedQuest == "53" && highestBalance) {
      flage = true;
      question_data = {
        question_id: selectedQuest,
        option_id: null,
        answer_text: "",
        input_answer: highestBalance,
      };
      removeFromPending("slide3");
    }
    if (selectedQuest == "48" && email) {
      flage = true;
      question_data = {
        question_id: selectedQuest,
        option_id: null,
        answer_text: "",
        input_answer: email,
      };
      removeFromPending("slide9");
    }

    if (flage) {
      storeFollowupQuestionnire(question_data);
      var existingEntries = JSON.parse(localStorage.getItem("questionData"));
      if (existingEntries == null) existingEntries = [];
      localStorage.setItem(
        "currentquestionData",
        JSON.stringify(question_data)
      );
      existingEntries.push(question_data);
      localStorage.setItem("questionData", JSON.stringify(existingEntries));
    }
  };

  const removeFromPending = (slidename) => {
    const index = pendingQuestionnaire.indexOf(slidename);
    if (index > -1) {
      pendingQuestionnaire.splice(index, 1);
    }
    slideChange(pendingQuestionnaire);
  };

  const SkipAgreement = async () => {
    const skippedLeadsResponse = await skippedLeads('SkipAgreementNoFlp', currentUuid, splitName);
    removeFromPending('slide1');
  };

  const pushPageChange = () => {
    removeFromPending("slide11");
  };

  const saveSlide4 = async (e) => {
    let selectedAns = parseInt(e.target.getAttribute("data-ans-id"));
    let selectedQuest = parseInt(e.target.getAttribute("data-quest-id"));
    let anslabel = e.target.getAttribute("data-ans-label");

    setCompletedQuestArr([...completedQuestArr, selectedQuest]);

    var answer31 = splitForm.current["question_31"].value;
    var answer32 = splitForm.current["question_32"].value;
    var answer33 = splitForm.current["question_33"].value;
    // var answer34 = splitForm.current["question_34"].value;
    //   var answer35 = splitForm.current["question_35"].value;

    let answer57 = splitForm.current["question_57"].value;
    let answer58 = splitForm.current["question_58"].value;
    let answer59 = splitForm.current["question_59"].value;
    let answer60 = splitForm.current["question_60"].value;

    var slide_array = [
      ["31", answer31],
      ["32", answer32],
      ["33", answer33],
      // ["34", answer34],
      // ["35", answer35],
      ["57", answer57],
      ["58", answer58]
    ];
    if (answer59 != '') {
      slide_array.push(["59", answer59]);
    }
    if (answer60 != '') {
      slide_array.push(["60", answer60]);
    }

    var question_data = {
      slide_4: slide_array,
    };
    removeFromPending("slide7");
    storeFollowupQuestionnire(question_data);

    let _existingEntries = JSON.parse(localStorage.getItem("questionData"));
    if (_existingEntries == null) _existingEntries = [];
    _existingEntries.push(question_data);
    localStorage.setItem("questionData", JSON.stringify(_existingEntries));
  };

  const handleChange = (e) => {
    // const re = /^[0-9\b]+$/;
    const re = /^[1-9]\d*$/;
    if (e.target.value !== "" && !re.test(e.target.value)) {
      e.target.value = e.target.value.replace(/\D/g, "") * 1;
    }
    if (e.target.value !== "") {
      e.target.value =
        "£" + e.target.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
  };

  const totalDebitValidation = async (e) => {
    e.preventDefault();
    let errorFlag = 0;
    const values = getValues();

    let question31Result = await trigger("question_59");
    if (!question31Result) {
      errorFlag = 1;
      return false;
    }
    if (errorFlag == 0) {
      let question58Result = splitForm.current["question_58"].value;
      if (question58Result == '153') {
        setMonthlyExpShow('hide');
        setOtherExpShow('show');

      } else {
        setMonthlyExpShow('hide');
        saveSlide4(e);
      }
    }
  }

  const totalOtherDebitValidation = async (e) => {
    e.preventDefault();
    let errorFlag = 0;

    let question60Result = await trigger("question_60");
    if (!question60Result) {
      errorFlag = 1;
      return false;
    }
    if (errorFlag == 0) {
      setOtherExpShow('hide');
      setMonthlyExpShow('hide');
      // setClickedModel({ model: "monthly", status: "hide" });
      // setClickedModel({ model: "other", status: "hide" });
      saveSlide4(e);
    }
  }

  return (
    <>
      <div className="QuestionnaireV7">
        <div className="formsection">
          <div className="container">
            <div className="row">
              <form
                ref={splitForm}
                name="split_form"
                id="user_form"
                method="POST"
                autoComplete="off"
              >
                <div className="offset-xl-3 col-xl-6 offset-lg-3 col-lg-6 offset-md-2 col-md-8 col-12">
                  <div className="radio-box animated fadeInUp">
                    <div
                      className={`question1 animated fadeInUp space-top ${state.showSlide1}`}
                      id="breakdown01"
                    >
                      <QuestionnaireFlpAgreementNo
                        questArr={questinnare1}
                        labelclicked={onselectAnswer}
                        onselectAnswer={onselectAnswer}
                        validation={register}
                        validationMsg={errors}
                        getValues={getValues}
                        trigger={trigger}
                        setError={setError}
                        saveInputAns={saveInputAns}
                        SkipAgreement={SkipAgreement}
                      />
                    </div>
                    <div
                      className={`question2 animated fadeInUp space-top ${state.showSlide2}`}
                      id="breakdown1"
                    >
                      <ClosedYear
                        questArr={questinnare1}
                        labelclicked={onselectAnswer}
                        onselectAnswer={onselectAnswer}
                        validation={register}
                        validationMsg={errors}
                        getValues={getValues}
                        trigger={trigger}
                        setError={setError}
                        saveIncomeExpenditure={saveIncomeExpenditure}
                        splitForm={splitForm}
                        newDayData={newDayData}
                        saveInputAns={saveInputAns}
                      />
                    </div>
                    <div
                      className={`question2 animated fadeInUp space-top ${state.showSlide3}`}
                      id="breakdown1"
                    >
                      <HighestBal
                        questArr={questinnare1}
                        labelclicked={onselectAnswer}
                        onselectAnswer={onselectAnswer}
                        validation={register}
                        validationMsg={errors}
                        getValues={getValues}
                        trigger={trigger}
                        setError={setError}
                        saveIncomeExpenditure={saveIncomeExpenditure}
                        splitForm={splitForm}
                        newDayData={newDayData}
                        saveInputAns={saveInputAns}
                      />
                    </div>
                    <div
                      className={`question3 animated fadeInUp text-center ${state.showSlide4}`}
                      id="breakdown2"
                    >
                      <LimitIncrease
                        validation={register}
                        getValues={getValues}
                        trigger={trigger}
                        validationMsg={errors}
                        onselectAnswer={onselectAnswer}
                      />
                    </div>
                    <div
                      className={`question3 animated fadeInUp text-center ${state.showSlide5}`}
                      id="breakdown2"
                    >
                      <CreditLimit
                        validation={register}
                        getValues={getValues}
                        trigger={trigger}
                        validationMsg={errors}
                        onselectAnswer={onselectAnswer}
                      />
                    </div>
                    <div
                      className={`question4 animated fadeInUp ${state.showSlide6}`}
                      id="breakdown3"
                    >
                      <MinPayment
                        validation={register}
                        getValues={getValues}
                        trigger={trigger}
                        validationMsg={errors}
                        onselectAnswer={onselectAnswer}
                      />
                    </div>
                    <div
                      className={`question4 animated fadeInUp ${state.showSlide7}`}
                      id="breakdown3"
                    >
                      <Amounts
                        questArr={questinnare1}
                        saveSlide4={saveSlide4}
                        name="question_31_35"
                        btnName="slide_4"
                        validation={register}
                        validationMsg={errors}
                        getValues={getValues}
                        trigger={trigger}
                        setError={setError}
                        splitForm={splitForm}
                        setClickedModel={setClickedModel}
                        setOtherExpShow={setOtherExpShow}
                        setMonthlyExpShow={setMonthlyExpShow}
                      />
                    </div>
                    <div
                      className={`question4 animated fadeInUp space-top ${state.showSlide8}`}
                      id="breakdown3"
                    >
                      <EmailQuestion
                        onselectAnswer={onselectAnswer}
                        newDayData={newDayData}

                      />
                    </div>
                    <div
                      className={`question4 animated fadeInUp space-top ${state.showSlide9}`}
                      id="breakdown3"
                    >
                      <QuestionnaireEmail
                        validation={register}
                        getValues={getValues}
                        trigger={trigger}
                        validationMsg={errors}
                        saveInputAns={saveInputAns}
                        lender={newDayData.lender}
                      />
                    </div>

                    <div className={`question9 animated fadeInUp space-top2 ${state.showSlide10a}`} id="breakdow10">
                    <QuestionnaireFlpQ76
                      questArr={questinnaire76MB}
                      onselectAnswer={onselectAnswer}
                      optionSelected={optionSelected76}
                    />
                  </div>

                    <div
                      className={`question4 animated fadeInUp space-top ${state.showSlide10}`}
                      id="breakdown3"
                    >
                      <QuestionnaireFlpQ5
                        questArr={questinnare2VeryV2}
                        onselectAnswer={onselectAnswer}
                        optionSelected={optionSelected}
                      />
                    </div>
                    <div
                      className={`question5 animated fadeInUp space-top ${state.showSlide11} radio-box`}
                      id="breakdown4"
                    >
                      <QuestionnaireFlpFANUpload
                        questArr={questinnare2VeryV2}
                        validation={register}
                        validationMsg={errors}
                        getValues={getValues}
                        trigger={trigger}
                        setError={setError}
                        docType={docType}
                        documentType={documentType}
                        allFollowupData={allFollowupData}
                        sett2aValidation={sett2aValidation}
                        pushPageChange={pushPageChange}
                        setShow={setShow}
                      />
                    </div>

                  </div>
                </div>
                <AmountModal
                  forwardRef={splitForm}
                  validation={register}
                  validationMsg={errors}
                  handleChange={handleChange}
                  showModel1={monthlyExpShow}
                  showModel2={otherExpShow}
                  totalDebitValidation={totalDebitValidation}
                  totalOtherDebitValidation={totalOtherDebitValidation}
                  newDayData={newDayData}

                />
              </form>

            </div>
          </div>
        </div>
      </div>
      <QuestionnaireFooter
        percentage={state.percentage}
      />
    </>
  );
};
export default NewDayQuestionForm;
