import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
// import "../../../../assets/OpenBanking/css/styleV1.scss";
import "../../../../assets/FollowupFos/css/customfos.scss";
import "../../../../assets/FollowupFos/css/stylefos.scss";
import "../../../../assets/FollowupFos/css/homefos.scss";
import "../../../../assets/FollowupFos/css/questionnairefos.scss";
import "../../../../assets/FollowupFos/css/signaturefos.scss";
import { queryString } from "../../../../Utility/QueryString";
import { useExternalFollowup } from "../../../../Hooks/useExternalFollowup";
import { Api } from "../../../../api/Api";
import ObUnqualified from "../../../Pages/ObUnqualified";

const CreditCheckFos = () => {
  const [loader, setLoader] = useState(1);
  const [credLoader, setCredLoader] = useState(0);
  const [proceedLoader, sePproceedLoader] = useState(0);
  const [iframeUrl, setIframeUrl] = useState(null);
  const [btndisable, setBtnDisable] = useState(false);  
  const [urlPortion, setUrlPortion] = useState();
  const [uuid, setUuid] = useState(null);

 
  const fname = localStorage.getItem("firstname");
  const credcheckStat = localStorage.getItem("credStat");
  const history = useHistory();
  const { OpenBankVisit, storeClickResponse, milestoneChase } = useExternalFollowup();
  const query = new URLSearchParams(window.location.search);
  const query_uuid = query.get("uuid");
  const local_storage_uuid = localStorage.getItem("uuid");
  const query_source = localStorage.getItem("source");
  var source = query_source ? query_source : "FLP";
  const user_data = { uuid: uuid, source: source };
  let btn_label = '';
  let page = '';
  // let urlPortion
  const userId = localStorage.getItem("user_id");
useEffect(()=>{
  if (query_uuid) {
    setUuid(query_uuid);
  } else {
    setUuid(local_storage_uuid);
  }
},[])

  useEffect(() => {
    if (localStorage.getItem("url_portion")) {
      setUrlPortion(localStorage.getItem("url_portion"));
    }else {
      (async () => {
        try {
          const response = await Api.get(
            "/api/v1/user-details-external?query_string=" + queryString,
            {}
          );
          if (response.data.status === "Success") {
            let uuid = response.data.response.uuid;
            let lstSalutation = response.data.response.lstSalutation;
            let txtFName = response.data.response.txtFName;
            let txtLName = response.data.response.txtLName;
            let txtEmail = response.data.response.txtEmail;
            let txtPhone = response.data.response.txtPhone;
            let txtPostcode = response.data.response.txtPostcode;
            let txtAddressline1 = response.data.response.txtAddressline1;
            let txtAddressline2 = response.data.response.txtAddressline2;
            let town = response.data.response.town;
            let dob = response.data.response.dob;
           let  urlParameter = `title=${lstSalutation}&fname=${txtFName}&lname=${txtLName}&email=${txtEmail}&phone=${txtPhone}&dob=${dob}&abodenumber=&building_name=${txtAddressline1}&straddr=${txtAddressline2}&street=&city=${town}&zip=${txtPostcode}&clientRefId=${uuid}`;
            // return urlPortion;
            setUrlPortion(urlParameter);
            setUuid(uuid)
          }
        } catch (error) {
          console.error(
            "An error occurred while making the API request:",
            error
          );
        }

      })();
    }
  }, []);
  useEffect(() => {

    (async () => {
      if (uuid && urlPortion ) {
        let payload = { "page": 'CREDIT_CHECK', type: 'visit', source };
        await storeClickResponse({ uuid: uuid }, "ob_creditcheck_history", uuid, payload);

        setTimeout(() => {
          setLoader(0);
        }, 1500);

        let iframUrl = "https://uk.rspcdn.com/xprr/red/PID/3916?" + urlPortion;
        setIframeUrl(iframUrl);

        if (credcheckStat == "0" || credcheckStat == null || credcheckStat == "") {
          // history.push("/thankyou?uuid=" + local_storage_uuid);
          return false;
        }
      } else {
        //  history.push("/thankyou");
      }

    })();

  }, [urlPortion,uuid]);

  const redirectCreditCheck = async (e) => {

    setBtnDisable(true);
    setLoader(1);
    // setCredLoader(1);

    btn_label = e.target.getAttribute('data-btn-label');
    page = e.target.getAttribute('data-page-label');

    let payload = { page, type: 'btn_click', btn_label, source, 'click_btn': '3' };
    await storeClickResponse({ uuid: uuid }, "ob_creditcheck_history", uuid, payload);
    await milestoneChase({ uuid: uuid }, userId, "credit_check_milestone", 1, source)

    const flpResponse = await OpenBankVisit(
      "creditcheck_stats_ext",
      uuid,
      user_data,
      "proceed-creditcheck",
      queryString,
      "v1"
    );
    setTimeout(() => {
      setLoader(0);
      setCredLoader(1);
    }, 1500);
  };

  return (
    <>
      <div className="FollowupFosV1">
        <div className="tms-wrap bg-main-pr">
          <div className={`text-center loader-wrap loader1 ${loader === 1 ? 'showload' : 'hide'}`}>
            {/* <div className="loader-logo">
              <img src="assets/img/a00187b31dd5a4ece0c8f809b69962eb.png" alt="" />
            </div> */}
            <div className="loader">
              <img src="assets/img/review.gif" alt="" />
            </div>
          </div>
          <div className={`${credLoader == 0 ? "showload" : "hide"}`}>
            <div
              className={`main text-center main-div  ${loader == 0 ? "showload" : "hide"
                }`}
            >
              <div className="brand-logo mt-lg-0 mt-md-2 mt-sm-2 mt-2">
                <img
                  height="65"
                  src="assets/img/a00187b31dd5a4ece0c8f809b69962eb.png"
                  alt=""
                />
              </div>

              <div className="main-form-wraper" style={{ marginTop: '3rem' }}>
                <div className="container">
                  <div className="item-cent-flx ">

                    <div className='slide4' >
                      <div className="  text-center">
                        <div className="col-lg-8 offset-lg-2 px-4 mb-2 text-center">
                          <h1 className="  mt-md-2 mt-sm-2 mt-0 text-danger">We require your credit report to complete our
                            comprehensive investigation and finalise your complaint </h1>
                          <p style={{ maxWidth: '800px' }} className="my-3 ">We have partnered with UK Credit Ratings to offer a FREE,
                            comprehensive, and simple - to -use service. </p>

                        </div>

                        <div className="row   mt-md-2 mt-sm-3 mt-0">
                          <div className="col-lg-6 offset-lg-3 px-4 mb-2 text-center">

                            <div>

                              <button
                                onClick={redirectCreditCheck}
                                disabled={btndisable}
                                data-btn-label="access_free_credit_report"
                                data-page-label="CREDIT_CHECK"
                                className="btn-comm2 second disablebtn"
                              >
                                {" "}
                                Access Free Credit Report <span style={{ lineHeight: '21px !important' }}> {'›'}</span>{" "}
                              </button>


                              <p className="mt-3 text2b mb-2"> 24/7 Monitoring, Individual Credit Review. Correct Credit Errors.</p>
                              <p className="mt-0 text3b text-danger"><img src="assets/img/clock.png" style={{ width: '3%' }} /> Estimated Processing 1 minutes
                              </p>
                            </div>
                          </div>

                        </div>
                        <div className="text-center mt-4"><img src="assets/FollowupFos/img/uk-credits.png" alt="" /></div>
                        <div className="pb-lg-1 pb-md-1 pb-sm-1 pb-1 mt-lg-2 mt-lg-2 mt-md-2 mt-sm-2 ">
                          <img height="50" src="assets/FollowupFos/img/safe and secure.png" alt="" />
                        </div>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>


          <div
            className={`${credLoader == 1 ? "showload" : "hide"
              } iframe-container`}
          >
            <iframe src={iframeUrl} frameborder="0"></iframe>
          </div>
        </div>
      </div>
    </>
  );
};
export default CreditCheckFos;
