
import { Api } from "../api/Api";
import React, { useContext } from "react";
import VisitorParamsContext from "../Contexts/VisitorParams";
import { useErrorWebhook } from "./useErrorWebhook";


export const useUpdateMilestone = () => {

    const { visitorParameters } = useContext(VisitorParamsContext);
  // const uuid = visitorParameters.visitor_parameters.uuid;
  // console.log('user Verify uuid::' + uuid);
  const { ErrorWebhookTrigger } = useErrorWebhook();

    const updateMilestone = async (uuid,milestone,source,status) => {
      console.log("uuid,milestone,source,status:"+uuid,milestone,source,status );
        const response = await Api.post("update_milestone", {
            uuid,
            milestone,
            source,
            status
          }).catch(function (error) {
            const erroMessage = {
              serviceName: 'MILESTONE UPDATE',
              errorReason: error.message,
              errorCode: error.code,
              uuid: uuid
            }
            ErrorWebhookTrigger(erroMessage);
          });
          return response;

    }

    const updateUserMilestoneStatsQuest = async (uuid,source) => {
        const response = await Api.post("update_question_milestone", {
            uuid,
            source,
          }).catch(function (error) {
            const erroMessage = {
              serviceName: 'MILESTONE UPDATE',
              errorReason: error.message,
              errorCode: error.code,
              uuid: uuid
            }
            ErrorWebhookTrigger(erroMessage);
          });
          return response;

    }
    return {updateMilestone ,updateUserMilestoneStatsQuest };
}