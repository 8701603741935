import React, { useState, useRef } from 'react';
import CheckBox from './../../../UI/CheckBox';

const Further2 = ({
  questArr,
  onselectAnswer,
  optionSelected,
  addPending,
  className
}) => {
  const [showError, setshowError] = useState("hide");
  const checkboxRefs = useRef({});

  const labelclicked = async (e) => {
    let selectedCount = optionSelected.length;
    if (selectedCount == 0) {
      setshowError("show");
    } else {
      onselectAnswer(e);
    }
  };

  const onSelectOption = (e) => {
    if (e.target.checked === true) {
      setshowError("hide");
      optionSelected.push({ id: +e.target.value });
      checkBoxDisable(e.target.value);
    } else {
      let deselect = e.target.value;
      const decre = optionSelected.map((OptionArray, index) => {
        if (deselect == OptionArray.id) {
          optionSelected.splice(index, 1);
        }
        if (optionSelected.length == 0) {
          checkBoxEnable();
        }
      });
    }
  };

  let checkArray = questArr.answers;
  const lenderCheckBox = checkArray.map((checArr, index) => {
    return (
      <li className={`adjustbalewidfull ${checArr.value == 155 ? 'hide' : 'show'}`} key={checArr.value}>
        <div className='optionbtn' ref={(e) => checkboxRefs.current[checArr.value] = e}>
          <CheckBox
            dataId={`confirmSituation${checArr.value}`}
            name="further1[]"
            onChange={onSelectOption}
            className=""
            CheckBoxText={checArr.label}
            value={checArr.value}
          />
        </div>
      </li>
    );
  });

  const checkBoxDisable = (checkBoxId) => {
    if (checkBoxId != 155) {
      checkArray.map((checArr, index) => {
        checkboxRefs.current[checArr.value].childNodes[0].disabled = false;
        checkboxRefs.current[checArr.value].childNodes[1].classList.remove("disabledcheckbox");
      })
      checkboxRefs.current[155].childNodes[0].disabled = true;
      checkboxRefs.current[155].childNodes[1].classList.add("disabledcheckbox");
    } else if (checkBoxId == 155) {
      checkArray.map((checArr, index) => {
        checkboxRefs.current[checArr.value].childNodes[0].disabled = true;
        checkboxRefs.current[checArr.value].childNodes[1].classList.add("disabledcheckbox");
      })
      checkboxRefs.current[155].childNodes[0].disabled = false;
      checkboxRefs.current[155].childNodes[1].classList.remove("disabledcheckbox");
    }
  }

  const checkBoxEnable = () => {
    checkArray.map((checArr, index) => {
      checkboxRefs.current[checArr.value].childNodes[0].disabled = false;
      checkboxRefs.current[checArr.value].childNodes[1].classList.remove("disabledcheckbox");
    })
  }

  return (
    <>
      <div className={`container ${className}`}>
        <div className="questitem mt-5">
          <h1 class="que-title p-3">Thanks for that, <b>please confirm if any of these statements</b> also accurately describe your situation.</h1>
          <div className="question-content mt-0 scale-down-ver-center px-3 py-lg-3 py-md-3 py-sm-2 py-2">
            <div className="row">
              <div className="col-lg-12 mb-2">
                <ul className="p-0 m-0 option-grid">
                  {lenderCheckBox}
                </ul>
              </div>
              <div className="col-lg-12 mb-2 mt-2">
                <span className={`error_msg ${showError}`} id="email_err">
                  Please select at least one
                </span>
                <button
                  className="qb3 btn-comm2 py-lg-3 py-md-3 py-sm-2 py-2 w-100 nextBtn"
                  name="question_36"
                  type="button"
                  id="nextscroll"
                  data-quest-id={76}
                  onClick={labelclicked}
                >
                  Next &#8594; </button>
              </div>
            </div>
            <p className="text-center bck-marwid">
              <button name="struggle" 
              className="btn btn-secondary backbtn1a" 
              id="" 
              value=""
              onClick={()=>addPending("further1")}
              type="button">Go Back
              </button>
            </p>
          </div>
        </div>
        <div className="tipbox tip2 show">
          Your feedbacks helps support the basis of claims.
          <div className="arrow  arrow-top"></div>
        </div>
      </div>
    </>
  )
}

export default Further2;