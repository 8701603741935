import { Modal } from "react-bootstrap";
import InputButton from "../../../../UI/InputButton";

export default function DifficultyModal({claimModal,handleCloseclaimModal}) {
  return (
    <Modal size="md" show={claimModal} onHide={handleCloseclaimModal} centered backdrop="static" className="claimdeinial " id="cannotClaim">
    <div className="modal-content">
        <Modal.Body>
            <h3 className="text-center my-4">Sorry we are unable to assist with this claim, as NewDay are very likely to time-bar it, and not review it.</h3>
            <div className="input-group mb-3 prevname-Btnpop justify-content-center">
                <InputButton
                    className="btn next08  regNextBtnNew text-center"
                    name="modalBack"
                    value="Back"
                    btnType="button"
                    onClick={handleCloseclaimModal}
                />
            </div>
        </Modal.Body>
    </div>
</Modal>
  )
}
