import React from "react";
import AdtopiaLoadLP from "../../../Utility/AdtopiaLoadLP.js";
import FormVAQ_UB_4_3_1 from "../../Forms/FormVAQ_UB_4_3_1.js";
import Footer from "../../Includes/Layouts/VAQ_UB_4_3/Footer.js";
import FbViewPixel from "../../../Utility/FbViewPixel.js";
import "../../../assets/VAQ_UB_4_3/scss/VAQ_UB_4_3.scss";
import AccordSection from "../../Includes/Layouts/VAQ_UB_4_3/AccordSection.js";

const VAQ_UB_4_3_1 = () => {
  return (
    <>
      <AdtopiaLoadLP pageType="LP" splitName="VAQ_UB_4_3_1" />
      <FbViewPixel />
      <div className="VAQ_UB_4_3">
        <FormVAQ_UB_4_3_1 />
        <AccordSection />
        <Footer />
      </div>
    </>
  );
};

export default VAQ_UB_4_3_1;
