import {  WebBookErrorPage } from "../api/Api";
import { currentdate, currenttime } from "../Utility/CurrentDateTime";
import {
  AppEnv
} from "../Constants/EnvConstants";

export const useErrorNotFoundHook = () => {
    
    const ErrorWebhookPost = (content) => {
      const fullURL = window.location.href;
      const { serviceName, urlParams, localStroageData, referalUrl } = content;
      const message = `
    {
        Service Name : ${serviceName}
        URLParameters: ${JSON.stringify(urlParams)}
        Local Storage Data : ${JSON.stringify(localStroageData)}
        Referal URL: ${referalUrl}
        Full URL:${fullURL}
        Date time : ${currentdate} ${currenttime}
        Environment : ${AppEnv}
    }
    `;
        const response = WebBookErrorPage.post("", {
          text: message,
        });
     
    };
  return { ErrorWebhookPost };
};
