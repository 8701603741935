import React, { useEffect, useState } from 'react';
import SelectBox from '../../../../UI/SelectBox';
import AnalyzeModal from '../AnalayzeModal';
import { accCloseYear } from "../../../../../Constants/Constants";



const CreditcardOpenYr = ({
	clsName,
	reff,
	// checkCarRegistration,
	validation,
	validationMsg,
	trigger,
	getValues,
	setError,
	page_name,
	uuid,
	split_name,
	saveInputAns,
	splitData,
	isLoan,
}) => {
	const [closeYear, setAccCloseyear] = useState([]);

	useEffect(() => {
		const accCloseYear = [{ label: "Select Year", value: "" }];
		const startAccYear = 2024;
		const endAccYear = 2000;
		let aa = [];
		for (var k = startAccYear; k >= endAccYear; k--) {
			let objAccYear = {};
			objAccYear["label"] = k;
			objAccYear["value"] = k;
			//setAccClsYear([...accClsYear,objAccYear]);
			aa.push(objAccYear);
			// accCloseYear.push(objAccYear);
		}
		// console.log(aa);
		setAccCloseyear([...accCloseYear, ...aa]);

	}, []);


	const [loader, showLoader] = useState(false);

	const handleClick = async (e) => {
		let creditCardOpenYr = await trigger("creditopenyears");
		let errorFlag = 0;
		if (!creditCardOpenYr) {
			errorFlag = 1;
		}
		if (errorFlag === 0) {
			// onselectAnswer(e);  
			saveInputAns(e);
		}
		else {
			return false;
		}
	};

	return (
		<>
			<div className={`radio-box animated fadeInUp ${clsName}`} ref={reff} >
				<h3><b>{isLoan ? 'What year did you open your Vanquis loan?' : 'What year did you open your Vanquis credit card?'}</b></h3>
				<div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 p-0">
					<SelectBox
						className="form-select form-control"
						OptionValue={closeYear}
						name="creditopenyears"
						id="creditopenyears"
						myRef={validation({
							required: "Please Select Year"
						})}
						validationMsg={validationMsg.creditopenyears && validationMsg.creditopenyears.message}
					/>
				</div>
				<div className="col-xl-12 col-lg-12 text-center p-0" >
					<button
						type="button"
						className="btn-next"
						onClick={handleClick}
						data-quest-id={88}
						data-ans-id={null}
						data-ans-label={null}
						name="creditopenyear"
					>{`NEXT`}</button>
				</div>
			</div>
			<AnalyzeModal modalClass={loader} />
		</>
	)
}

export default CreditcardOpenYr;
