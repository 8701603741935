import React, { useState } from 'react';
import TextField from '../../../../UI/TextField';
import AnalyzeModal from '../AnalayzeModal';
import { useCarRegisterValidation } from '../../../../../Hooks/useCarRegisterValidation';
import { useSkippedLeads } from '../../../../../Hooks/useSkippedLeads';

const QuestionnaireFlpVehicle = ({
	reff,
	display,
	validation,
	validationMsg,
	trigger,
	getValues,
	setError,
	allFollowupData,
	checkCarRegistration,
	saveVehicleDetails,
	splitName,
	currentUuid,
	slideSkip
}) => {
	const { registrationvalidation } = useCarRegisterValidation();
	const [loader, showLoader] = useState(false);
	const { skippedLeads } = useSkippedLeads();
	const { skippedLeadsFLP } = useSkippedLeads()

	const handleClick = async (e) => {
		let registration_no = await trigger("question_10");
		if (registration_no) {
			const fieldResult = getValues();
			let carReg = fieldResult["question_10"];
			let carRegNo = carReg.replace(/\s+/g, "");
			CarRegisterValidationApi(e, carRegNo);
		} else {
			return false;
		}
	};

	const CarRegisterValidationApi = async (e, carRegNo) => {
		showLoader(true);
		const getRegisterValidation = await registrationvalidation(carRegNo, currentUuid, splitName);
		if (getRegisterValidation.data.validity == "1") {
			//slideChange(e);
			let vehicle = getRegisterValidation.data.vehicleData;
			vehicle.reg_no = carRegNo;
			saveVehicleDetails(vehicle);
			setTimeout(() => {
				showLoader(false);
			}, 1000);
			let keeperData = JSON.parse(vehicle.keeper_dates);
			let keeperArray = keeperData.keeper_dates;
			let keeperLength = keeperArray.length;

			checkCarRegistration(e);
		} else {
			setError("question_10", {
				type: "manual",
				message: "Your Vehicle Registration is Invalid. Please Recheck!",
			});
			//showLoader('hide');
			showLoader(false);
			return false;
		}
	};
	const SkipRegistration = async (e) => {
		const skippedLeadsResponse = await skippedLeadsFLP(e.target.name, allFollowupData, splitName);
		if (skippedLeadsResponse.data.status == "Success") {
			slideSkip();
		}
	}
	return (
		<>
			<div style={{ 'display': display }}>
				<div className="question animated fadeInUp" ref={reff} >
					<h3 style={{ color: "#444", textAlign: "center" }} className="mb-2">If you have your Vehicle Registration to hand, please enter now:</h3>
					<div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 p-0">
						<span className="gb" style={{ height: '60px !important' }}>
							<img src="/assets/img/gb.png" alt="" width="16px" />
							<p className="text-white">GB</p>
						</span>
						<TextField
							type="text"
							className="car-reg"
							placeholder="(e.g.ME12DAR)"
							name="question_10"
							validation={validation({
								required: "Please Enter Vehicle Registration",
								minLength: {
									value: 3,
									message: "Please Enter Valid Vehicle Registration",
								},
								pattern: {
									value: /^(?=.*[a-zA-Z])(?=.*[0-9])[a-zA-Z0-9 ]+$/,
									message:
										"Your Vehicle Registration is Invalid. Please Recheck",
								},
							})}
							validationMsg={
								validationMsg.question_10 &&
								validationMsg.question_10.message
							}
						></TextField>
					</div>
					<div className="col-xl-12 col-lg-12 text-center p-0" >
						<button
							type="button"
							className="btn-next"
							onClick={handleClick}
							data-quest-id={10}
							data-ans-id={null}
							data-ans-label={null}
							name="btn_qest_10"
						>{`Lookup >>`}</button>
					</div>
					<div className="w-100 text-center pb-3">
						<a href="#" name="skipRegistration" onClick={SkipRegistration} style={{ fontSize: "16px" }}>SKIP {">>"} I don't have it to hand</a>
					</div>
				</div>
			</div>
			<AnalyzeModal modalClass={loader} />
		</>
	)
}

export default QuestionnaireFlpVehicle;