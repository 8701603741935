import React, { useState, useEffect, useRef, useReducer } from "react";
import { useForm } from "react-hook-form";
import QuestionnaireFlpQ5 from "./MoneyBarn/QuestionnaireFlpQ5";
import QuestionnaireFlpVehicle from "./MoneyBarn/QuestionnaireFlpVehicle";
import QuestionnaireFlpKdate from "./MoneyBarn/QuestionnaireFlpKdate";
import BankName from "./BankName";
import YearOfPossession from "./YearOfPossession";
import BankStmtUploadV2 from "./BankStmtUploadV2";
import {
  questinnare1,
  questinnare2,
  questinnare14,
  questinnare15,
  questinnare16,
} from "../../../../Constants/Questions";
import { useFollowupUserQuestions } from "../../../../Hooks/useFollowupUserQuestions";
import { useUserVerify } from "../../../../Hooks/useUserVerify";
import { userMilestoneUpdate } from "../../../../Hooks/userMilestoneUpdate";
import { useHistory } from "react-router-dom";
import { useTotalCarCheck } from "../../../../Hooks/useTotalCarCheck";
import { queryString } from "../../../../Utility/QueryString";
import { useOpenBanking } from "../../../../Hooks/useOpenBanking";
import Loader from "./Loader";
import Tms_logo from "../../../../assets/FollowupS3Car/img/logo.png"

const initialState = {
  hardships_or_struggle: false,
  q36: false,
  signature: false,
  car_reg: false,
  keeper_date: false,
  loan_date: false,
  bank_select: false,
  bankStmtUpload: false,
  income_and_expenditure: false,
  email_confirmation: false,
  reg_email: false,
  private_plate_confirm: false,
  private_plate: false,
  finance_active: false,
  still_have_vehicle: false,
  employment_status: false,
  dependants: false,
  percentage: 0,
};
const SlideReducer = (state, action) => {
  switch (action.type) {
    case "showSlideChange": {
       if (action.payload == "car_reg") {
        return {
          ...state,
          hardships_or_struggle: false,
          q36: false,
          signature: false,
          car_reg: true,
          keeper_date: false,
          loan_date: false,
          bank_select: false,
          ob_consent: false,
          bankStmtUpload: false,
          income_and_expenditure: false,
          email_confirmation: false,
          reg_email: false,
          private_plate_confirm: false,
          private_plate: false,
          finance_active: false,
          still_have_vehicle: false,
          employment_status: false,
          dependants: false,
          percentage: 20,
        };
      } else if (action.payload == "keeper_date") {
        return {
          ...state,
          hardships_or_struggle: false,
          q36: false,
          signature: false,
          car_reg: false,
          keeper_date: true,
          loan_date: false,
          bank_select: false,
          ob_consent: false,
          bankStmtUpload: false,
          income_and_expenditure: false,
          email_confirmation: false,
          reg_email: false,
          private_plate_confirm: false,
          private_plate: false,
          finance_active: false,
          still_have_vehicle: false,
          employment_status: false,
          dependants: false,
          percentage: 25,
        };
      } else if (action.payload == "loan_date") {
        return {
          ...state,
          hardships_or_struggle: false,
          q36: false,
          signature: false,
          car_reg: false,
          keeper_date: false,
          loan_date: true,
          bank_select: false,
          ob_consent: false,
          bankStmtUpload: false,
          income_and_expenditure: false,
          email_confirmation: false,
          reg_email: false,
          private_plate_confirm: false,
          private_plate: false,
          finance_active: false,
          still_have_vehicle: false,
          employment_status: false,
          dependants: false,
          percentage: 30,
        };
      } else if (action.payload == "bank_select") {
        return {
          ...state,
          hardships_or_struggle: false,
          q36: false,
          signature: false,
          car_reg: false,
          keeper_date: false,
          loan_date: false,
          bank_select: true,
          ob_consent: false,
          bankStmtUpload: false,
          income_and_expenditure: false,
          email_confirmation: false,
          reg_email: false,
          private_plate_confirm: false,
          private_plate: false,
          finance_active: false,
          still_have_vehicle: false,
          employment_status: false,
          dependants: false,
          percentage: 35,
        };
      } else {
        return {
          ...state,
        };
      }
    }
  }
};

const MoneyBarnFlpCar = ({
  allFollowupData,
  pendingSlide,
  setshowLoad,
  currentUuid,
  keeperDates,
  userEmail,
  setPendingSlide,
  setDivShow,
  verifyData,
  t2aStatus,
  splitName,
  lenderList,
  userDetails,
  agreementDate,
  setAgreementDate,
  setDivStyle,
  setLoaderContent,
  reverseSlide,
  isBankStmtProvided
}) => {
  const { register, errors, trigger, setError, clearErrors, getValues } =
    useForm({
      mode: "onBlur",
      reValidateMode: "onChange",
      defaultValues: {},
      resolver: undefined,
      context: undefined,
      criteriaMode: "firstError",
      shouldFocusError: true,
      shouldUnregister: true,
    });

  const [state, dispatch] = useReducer(SlideReducer, initialState);
  const [optionSelected] = useState([]);
  const { saveFollowupUserQuestions, isLoading } = useFollowupUserQuestions();
  const splitForm = useRef(null);
  const [vehicle, setVehicle] = useState("");
  const [validKeeperDate, setValidKeeperDate] = useState(false);
  const { updateMilestone } = userMilestoneUpdate();
  const history = useHistory();
  const { totalCarCheckApi } = useTotalCarCheck();
  const [totalCarCheck, settotalCarCheck] = useState(null);
  const [selectBank, setSelectBank] = useState([]);
  const { getCustomerCheck, milestoneChase } = useOpenBanking();
  // const [showCarLoader, setCarLoader] = useState("show");

  // useEffect(() => {
  //   slideChange(pendingSlide);
  // }, [pendingSlide]);

  useEffect(() => {
    slideChange(pendingSlide);
  }, []);

  const slideChange = (pendingArr) => {

    // Use Set to track unique elements while maintaining order
    const uniqueSet = new Set();

    // Filter duplicates and maintain order
    const uniquePendingArr = pendingArr.filter((element) => {
      if (!uniqueSet.has(element)) {
        uniqueSet.add(element);
        return true;
      }
      return false;
    });

    setPendingSlide(uniquePendingArr);
    let pendingCount = uniquePendingArr.length;

    if (pendingCount === 0) {
      // history.push("/checkListFLPS3?uuid=" + userDetails.uuid);
    }

    uniquePendingArr.every((element) => {
      dispatch({ type: "showSlideChange", payload: element });
    });
  };


  const onselectAnswer = async (e) => {
    let selectedAns = parseInt(e.target.getAttribute("data-ans-id"));
    let selectedQuest = parseInt(e.target.getAttribute("data-quest-id"));
    let anslabel = e.target.getAttribute("data-ans-label");

    let flage = false;
    let question_data = {};
    if (e.target.name == "question_11") {
      if (selectedAns && selectedQuest) {
        flage = true;
        question_data = {
          question_id: selectedQuest,
          option_id: null,
          answer_text: "",
          input_answer: e.target.value,
          valid_keeper_date: e.target.id,
        };
      }
    }  else {
      if (selectedAns && selectedQuest) {
        flage = true;
        question_data = {
          question_id: selectedQuest,
          option_id: selectedAns,
          answer_text: anslabel,
          input_answer: "",
        };
      }
    }

    if (flage) {
      storeFollowupQuestionnire(question_data);
      var existingEntries = JSON.parse(localStorage.getItem("questionData"));
      if (existingEntries == null) existingEntries = [];
      localStorage.setItem(
        "currentquestionData",
        JSON.stringify(question_data)
      );
      existingEntries.push(question_data);
      localStorage.setItem("questionData", JSON.stringify(existingEntries));
    }
  };

  const removeFromPending = (slidename) => {
    window.scrollTo(0, 0);
    const index = pendingSlide.indexOf(slidename);
    if (index > -1) {
      pendingSlide.splice(index, 1);
      // pendingSlide = pendingSlide.filter(item => item !== slidename);
    }
    slideChange(pendingSlide);

  };

  const addPending = (slidename) => {

    const index = reverseSlide.indexOf(slidename);
    const slideShow = reverseSlide[index + 1];


    if (slidename == "keeper_date") {
      pendingSlide.unshift("car_reg");
    } else if (slidename == "loan_date") {
      pendingSlide.unshift("keeper_date");
      pendingSlide.unshift("car_reg");
    } else if (slidename == "bank_select") {
      pendingSlide.unshift("loan_date");
      pendingSlide.unshift("keeper_date");
      pendingSlide.unshift("car_reg");
    }else {
      pendingSlide.unshift(slideShow);
    }
    slideChange(pendingSlide);
    window.scrollTo(0, 0);
  };

  const storeFollowupQuestionnire = async (question_data) => {
    const response = await saveFollowupUserQuestions(
      question_data,
      allFollowupData,
      "followup_question_storeS2"
    );
  };

  const checkCarRegistration = (carRegNo) => {
    setLoaderContent(Capitalize(userDetails.txtFName) + ", Please wait while we save your progress...");
    removeFromPending("car_reg");
    let selectedQuest = 10;
    let selectedAns = null;

    if (selectedQuest) {
      let question_data = {
        question_id: selectedQuest,
        option_id: selectedAns,
        answer_text: carRegNo,
        input_answer: carRegNo,
      };
      storeFollowupQuestionnire(question_data);
      var existingEntries = JSON.parse(localStorage.getItem("questionData"));
      if (existingEntries == null) existingEntries = [];
      localStorage.setItem(
        "currentquestionData",
        JSON.stringify(question_data)
      );
      existingEntries.push(question_data);
      localStorage.setItem("questionData", JSON.stringify(existingEntries));
    }
  };

  const saveVehicleDetails = (vehicleData) => {
    setVehicle(vehicleData);
  };

  const Capitalize = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  const slideSkip = () => {
    removeFromPending("car_reg");
    removeFromPending("keeper_date");
    removeFromPending("private_plate_confirm");
    removeFromPending("private_plate");
    setLoaderContent(Capitalize(userDetails.txtFName) + ", Please wait while we save your progress...");
  };

  const analyzingFn = async (date_index) => {
    setshowLoad("show");
    setDivStyle("hide");
    if (date_index == 0) {
      (async () => {
        const totalCarCheckApiData = await totalCarCheckApi(
          vehicle.reg_no,
          verifyData.uuid,
          splitName
        );

        savetotalCarCheck(totalCarCheckApiData.data.vehicleData);

        let tcc_lender = totalCarCheckApiData.data.vehicleData.finance_company;
        let agreement_date =
          totalCarCheckApiData.data.vehicleData.agreement_date;

        if (
          tcc_lender == "Moneybarn" &&
          (agreement_date != null || agreement_date != "")
        ) {
          // Create a new Date object
          var dateObj = new Date(agreement_date);

          // Extract the year, month, and day
          var year = dateObj.getFullYear();
          var month = dateObj.toLocaleString("default", { month: "long" });

          var formattedDate = year + " " + month;
          setAgreementDate(formattedDate);
          localStorage.setItem("agreementDate", formattedDate);
          removeFromPending("keeper_date");
          removeFromPending("loan_date");
          if(isBankStmtProvided === 1){
            localStorage.setItem("BankStatementFlp", 1);
            history.push("/checkListFLPS3?" + queryString);
          }
          setshowLoad("hide");
          setDivStyle("how");
        } else {
          removeFromPending("keeper_date");
          setTimeout(function() {
            setshowLoad("hide");
            setDivStyle("how");
        }, 3000);
        }
      })();
    } else {
      removeFromPending("keeper_date");
      setshowLoad("hide");
      setDivStyle("how");
    }
  };


  const savetotalCarCheck = (totalCarCheck) => {
    settotalCarCheck(totalCarCheck);
  };

  const saveInputAns = async (e) => {
    let selectedQuest = parseInt(e.target.getAttribute("data-quest-id"));
    const valuesreg = getValues();
    let regPlate = valuesreg["question_44"];
    let agreementNo = valuesreg["question_45"];
    let question_48 = valuesreg["question_48"];
    let modalAgreementNo = valuesreg["modal_question_45"];
    let flage = false;
    let bud_lender= selectBank.value;
    let question_data = {};

    if (selectedQuest == "44" && regPlate) {
      flage = true;
      question_data = {
        question_id: selectedQuest,
        option_id: null,
        answer_text: "",
        input_answer: regPlate,
      };
      removeFromPending("private_plate");
    }
    if (selectedQuest == "45" && agreementNo) {
      flage = true;
      question_data = {
        question_id: selectedQuest,
        option_id: null,
        answer_text: "",
        input_answer: agreementNo,
      };
      removeFromPending("slide1");
    }
    if (selectedQuest == "45" && modalAgreementNo) {
      //pushPageChange();
      removeFromPending("slide10");
      flage = true;
      question_data = {
        question_id: selectedQuest,
        option_id: null,
        answer_text: "",
        input_answer: modalAgreementNo,
      };
    }
    if (selectedQuest == "48" && question_48) {
      flage = true;
      question_data = {
        question_id: selectedQuest,
        option_id: null,
        answer_text: "",
        input_answer: question_48,
      };
      removeFromPending("reg_email");
    }
    if (selectedQuest == "64" && bud_lender) {
      flage = true;
      question_data = {
        question_id: selectedQuest,
        option_id: null,
        answer_text: "",
        input_answer: bud_lender,
      };
      removeFromPending("bank_select");
    }

    if (flage) {
      storeFollowupQuestionnire(question_data);
      var existingEntries = JSON.parse(localStorage.getItem("questionData"));
      if (existingEntries == null) existingEntries = [];
      localStorage.setItem(
        "currentquestionData",
        JSON.stringify(question_data)
      );
      existingEntries.push(question_data);
      localStorage.setItem("questionData", JSON.stringify(existingEntries));
    }
  };

 

  const matchCheckFn = async (selectBank) => {
    setLoaderContent(Capitalize(userDetails.txtFName) + ', Analysing your Results');
    setshowLoad("show");
    setDivStyle("hide");
    let t2a_status = localStorage.getItem("t2a_result");
    let agreementDate = localStorage.getItem("agreementDate");
   
      let matchCheck = await getCustomerCheck(
        { uuid: userDetails.uuid },
        userDetails.user_id,
        agreementDate,
        [selectBank]
      );
      if (matchCheck.data.status === "success") {
        let budLender = matchCheck.data.provider;
        localStorage.setItem("bud_lender", budLender);
        localStorage.setItem("match_check", 1);
        const uploadPage = "/proceed-ob-s3" + "?" + queryString;
        window.location.href = uploadPage;
      } else {
        localStorage.setItem("match_check", 0);
        const uploadPage = "/bank_statement_S3" + "?" + queryString;
        window.location.href = uploadPage;
      }
  };

 

  return (
    <>
    

      <div
        className="slide connect-account vehicleregist  pb-3"
        // style={
        //   showCarLoader === "hide" ? { display: "block" } : { display: "none " }
        // }
      >
        <div className="wrap-app mt-3 text-center">
          <div className="logo-two loader-logo text-center pt-3 mb-5">
            {" "}
            <img src={Tms_logo} alt="" />
          </div>
          <BankName
            className={(state.bank_select == true) ? 'show' : 'hide'}
            validation={register}
            getValues={getValues}
            validationMsg={errors}
            trigger={trigger}
            clearErrors={clearErrors}
            setError={setError}
            lenderList={lenderList}
            allFollowupData={allFollowupData}
            selectBank={selectBank}
            setSelectBank={setSelectBank}
            userDetails={userDetails}
            matchCheckFn={matchCheckFn}
            addPending={addPending}
            saveInputAns={saveInputAns}
          />


          <QuestionnaireFlpVehicle
            className={(state.car_reg == true) ? 'show' : 'hide'}
            questArr={questinnare14}
            validation={register}
            validationMsg={errors}
            getValues={getValues}
            trigger={trigger}
            setError={setError}
            checkCarRegistration={checkCarRegistration}
            saveVehicleDetails={saveVehicleDetails}
            splitName={splitName}
            currentUuid={currentUuid}
            slideSkip={slideSkip}
            allFollowupData={allFollowupData}
          />
          <QuestionnaireFlpKdate
            className={(state.keeper_date == true) ? 'show' : 'hide'}
            questArr={questinnare15}
            onselectAnswer={onselectAnswer}
            setValidKeeperDate={setValidKeeperDate}
            vehicle={vehicle}
            keeperDates={keeperDates}
            removeFromPending={removeFromPending}
            analyzingFn={analyzingFn}
            addPending={addPending}
          />
          <YearOfPossession
            className={(state.loan_date == true) ? 'show' : 'hide'}
            validation={register}
            clearErrors={clearErrors}
            getValues={getValues}
            trigger={trigger}
            validationMsg={errors}
            Year={2015}
            allFollowupData={allFollowupData}
            removeFromPending={removeFromPending}
            setAgreementDate={setAgreementDate}
            addPending={addPending}
            matchCheckFn={matchCheckFn}
            selectBank={selectBank}
            agreementDate={agreementDate}
            isBankStmtProvided={isBankStmtProvided}
            queryString={queryString}
          />

        </div>
      </div>
    </>
  );
};
export default MoneyBarnFlpCar;
