import React, { useState, useContext, useEffect, useRef, useReducer } from "react";
import TextField from "../../../UI/TextField";
import { useForm } from "react-hook-form";
import { useFollowupUserQuestions } from "../../../../Hooks/useFollowupUserQuestions";
import Questionaire77 from "../IncomeS3/Questionaire77";
import Questionaire78 from "../IncomeS3/Questionaire78";
import Questionaire79 from "../IncomeS3/Questionaire79";
import DependentStatus from "../IncomeS3/DependentStatus";
import Questionaire80 from "../IncomeS3/Questionaire80";
import { wereUalreadyPaying } from "../../../../Constants/Questions";
import IncomeAndExpV3 from "../IncomeS3/IncomeAndExpV3";
const initialState = {
    showSlide1: "show",
    showSlide78: "hide",
    showSlide3dep: "hide",
    showSlide79: "hide",
    showSlide80: "hide",
    percentage: 0,
};

const IncomeExpenditure = ({
    className,
    setIncomeInputDiv,
    setOptionDiv,
    allFollowupData
}) => {

    const [prevStill, setPrevStill] = useState("");
    const FormReducer = (state, action) => {
        switch (action.type) {
            case "showSlide": {
                if (action.payload.clickedSlide.slide == "yes_recieved") {
                    return {
                        ...state,
                        showSlide1: "hide",
                        showSlide78: "show",
                        percentage: 65
                    };
                }
                else if (action.payload.clickedSlide.slide == "no_recieved") {
                    return {
                        ...state,
                        showSlide1: "hide",
                        showSlide78: "hide",
                        showSlide3dep: "show",
                        percentage: 65
                    };
                }
                else if (action.payload.clickedSlide.slide == "question_78") {
                    return {
                        ...state,
                        showSlide5: "hide",
                        showSlide78: "hide",
                        showSlide3dep: "show",
                        percentage: 65
                    };
                }
                else if (action.payload.clickedSlide.slide == "question_3") {
                    return {
                        ...state,
                        showSlide5: "hide",
                        showSlide3dep: "hide",
                        showSlide79: "show",
                        percentage: 65
                    };
                }
                else if (action.payload.clickedSlide.slide == "btn_qest_79") {
                    return {
                        ...state,
                        showSlide79: "hide",
                        // showSlide80: "show",
                        percentage: 65
                    };
                }
                else {
                    return {
                        ...state,
                    };
                }
            }
            case "previousSlides": {
                if (action.payload.previousSlide.slide == "backto77") {
                    return {
                        ...state,
                        showSlide1: "show",
                        showSlide3dep: "hide",
                        showSlide78: "hide",
                    };
                } else if (action.payload.previousSlide.slide == "back22") {
                    if (prevStill == "226") {
                        return {
                            ...state,
                            showSlide1: "hide",
                            showSlide78: "show",
                            showSlide3dep: "hide"
                        };
                    } else {
                        return {
                            ...state,
                            showSlide1: "show",
                            showSlide78: "hide",
                            showSlide3dep: "hide"
                        };
                    }
                } else if (action.payload.previousSlide.slide == "backto78") {
                    return {
                        ...state,
                        showSlide79: "hide",
                        showSlide3dep: "show",
                        showSlide80: "hide",
                    };
                } 
                // else if (action.payload.previousSlide.slide == "backto79") {
                //     return {
                //         ...state,
                //         showSlide79: "show",
                //         showSlide80: "hide",
                //     };
                // }
                 else {
                    return state;
                }
            }
            default:
                return state;
        }
    };


    const { saveFollowupUserQuestions, isLoading } = useFollowupUserQuestions();
    const splitForm = useRef(null);
    const queryString = localStorage.getItem("queryString");
    const scrollRef = useRef([]);
    const [state, dispatch] = useReducer(FormReducer, initialState);
    const [clickedSlide, setClickedSlide] = useState([]);
    const [checklistOptions, setChecklistOptions] = useState([]);
    const [previousSlide, setPreviousSlide] = useState([]);

    useEffect(() => {
        if (previousSlide) {
            dispatch({ type: "previousSlides", payload: { previousSlide } });
        }
    }, [previousSlide]);

    useEffect(() => {
        if (clickedSlide) {
            dispatch({ type: "showSlide", payload: { clickedSlide } });
        }
    }, [clickedSlide]);

    const previousSlideChange = (e) => {
        setPreviousSlide({ slide: e.target.getAttribute("name") });
    };

    const { register, errors, trigger, setError, clearErrors, getValues, reset } =
        useForm({
            mode: "onBlur",
            reValidateMode: "onChange",
            defaultValues: {},
            resolver: undefined,
            context: undefined,
            criteriaMode: "firstError",
            shouldFocusError: true,
            shouldUnregister: true,
        });

    const handleChange = (e) => {
        // const re = /^[0-9\b]+$/;
        const re = /^[1-9]\d*$/;
        if (e.target.value !== "" && !re.test(e.target.value)) {
            e.target.value = e.target.value.replace(/\D/g, "") * 1;
        }
        if (e.target.value !== "") {
            e.target.value =
                "£" + e.target.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        }
    };


    const selectClick = async (e) => {
        e.preventDefault();
        let errorFlag = 0;
        const values = getValues();
        let question31Result = await trigger("question_31");
        if (!question31Result) {
            errorFlag = 1;
            return false;
        }

        let question32Result = await trigger("question_32");
        if (!question32Result) {
            errorFlag = 1;
            return false;
        }

        let question33Result = await trigger("question_33");
        if (!question33Result) {
            errorFlag = 1;
            return false;
        }

        // let question34Result = await trigger("question_34");
        // if (!question34Result) {
        //   errorFlag = 1;
        //   return false;
        // }

        let question35Result = await trigger("question_35");
        if (!question35Result) {
            errorFlag = 1;
            return false;
        }

        if (errorFlag === 0) {
            saveSlide4(e);
        }
    };

    const saveSlide4 = async (e) => {
        let selectedAns = parseInt(e.target.getAttribute("data-ans-id"));
        let selectedQuest = parseInt(e.target.getAttribute("data-quest-id"));
        let anslabel = e.target.getAttribute("data-ans-label");

        // setCompletedQuestArr([...completedQuestArr, selectedQuest, 32, 33, 35]);

        var answer31 = splitForm.current["question_31"].value;
        var answer32 = splitForm.current["question_32"].value;
        var answer33 = splitForm.current["question_33"].value;
        //var answer34 = splitForm.current["question_34"].value;
        var answer35 = splitForm.current["question_35"].value;

        var slide_array = [
            ["31", answer31],
            ["32", answer32],
            ["33", answer33],
            //["34", answer34],
            ["35", answer35],
        ];
        var question_data = {
            slide_4: slide_array,
        };
        storeFollowupQuestionnire(question_data);

        let _existingEntries = JSON.parse(localStorage.getItem("questionData"));
        if (_existingEntries == null) _existingEntries = [];
        _existingEntries.push(question_data);
        localStorage.setItem("questionData", JSON.stringify(_existingEntries));
    };

    const storeFollowupQuestionnire = async (question_data) => {
        let response = await saveFollowupUserQuestions(
            question_data,
            allFollowupData,
            "followup_question_storeS2"
        );
    };

    const storeFollowupQuestionnire80 = async (question_data) => {
        let response80 = await saveFollowupUserQuestions(
            question_data,
            allFollowupData,
            "followup_question_storeS2"
        );

        if (response80.data.status == "Success") {
            localStorage.setItem("BankStatementFlp", 1);
            setIncomeInputDiv("hide");
            pushPageChange();
        }
    };

    const pushPageChange = () => {
        localStorage.setItem("BankStatementFlp", 1);
        const uploadPage = "/checkListFLPS3" + "?" + queryString;
        window.location.href = uploadPage;

    }

    const onselectAnswer = async (e) => {
        let selectedAns = parseInt(e.target.getAttribute("data-ans-id"));
        let selectedQuest = parseInt(e.target.getAttribute("data-quest-id"));
        let anslabel = e.target.getAttribute("data-ans-label");
        const valuesreg = getValues();
        let flage = false;
        let question_data = {};
        if (e.target.name == "question_77") {
            if (e.target.value == "226") {
                setClickedSlide({ slide: "yes_recieved" });
            } else if (e.target.value == "227") {
                setClickedSlide({ slide: "no_recieved" });
            }
            if (e.target.name == "question_77") {
                flage = true;
                question_data = {
                    question_id: 77,
                    option_id: e.target.value,
                    answer_text: null,
                    input_answer: null,
                };
            }
        }
        else if (e.target.name == "btn_qest_79") {
            setClickedSlide({ slide: "btn_qest_79" });
            flage = true;
            question_data = {
                question_id: selectedQuest,
                option_id: null,
                answer_text: "",
                input_answer: checklistOptions,
            };
        }
        else {
            if (selectedAns && selectedQuest) {
                flage = true;
                question_data = {
                    question_id: selectedQuest,
                    option_id: selectedAns,
                    answer_text: anslabel,
                    input_answer: "",
                };
            }
        }

        if (flage) {
            storeFollowupQuestionnire(question_data);
            var existingEntries = JSON.parse(localStorage.getItem("questionData"));
            if (existingEntries == null) existingEntries = [];
            localStorage.setItem(
                "currentquestionData",
                JSON.stringify(question_data)
            );
            existingEntries.push(question_data);
            localStorage.setItem("questionData", JSON.stringify(existingEntries));
        }
    };

    const saveInputAns = async (e) => {
        let selectedQuest = parseInt(e.target.getAttribute("data-quest-id"));
        const valuesreg = getValues();
        let cardMonthlyIncome = splitForm.current["question_78"].value;;
        let Dependent = splitForm.current["question_3"].value;


        let flage = false;
        let question_data = {};


        if (selectedQuest == "78" && cardMonthlyIncome) {
            setClickedSlide({ slide: "question_78" });
            flage = true;
            question_data = {
                question_id: selectedQuest,
                option_id: null,
                answer_text: "",
                input_answer: cardMonthlyIncome,
            };
        }
        else if (selectedQuest == "3" && Dependent) {
            setClickedSlide({ slide: "question_3" });
            flage = true;
            question_data = {
                question_id: selectedQuest,
                option_id: null,
                answer_text: "",
                input_answer: Dependent,
            };
        }

        if (flage) {
            storeFollowupQuestionnire(question_data);
            var existingEntries = JSON.parse(localStorage.getItem("questionData"));
            if (existingEntries == null) existingEntries = [];
            localStorage.setItem(
                "currentquestionData",
                JSON.stringify(question_data)
            );
            existingEntries.push(question_data);
            localStorage.setItem("questionData", JSON.stringify(existingEntries));
        }
    };


    const goBackOpt = () => {
        reset();
        setIncomeInputDiv("hide");
        setOptionDiv("show");
    };

    const saveSlide80 = async (e) => {
        let selectedAns = parseInt(e.target.getAttribute("data-ans-id"));
        let selectedQuest = parseInt(e.target.getAttribute("data-quest-id"));


        const values = getValues();
        let slide_array = [];

        if (checklistOptions.some((option) => option.id === 228)) {
            let answer228 = splitForm.current["question_228_229"].value;
            slide_array.push(["228", answer228]);
        }
        if (checklistOptions.some((option) => option.id === 229)) {
            let answer229 = splitForm.current["question_228_229"].value;
            slide_array.push(["229", answer229]);
        }
        if (checklistOptions.some((option) => option.id === 230)) {
            let answer230 = splitForm.current["question_230"].value;
            slide_array.push(["230", answer230]);
        }
        if (checklistOptions.some((option) => option.id === 231)) {
            let answer231 = splitForm.current["question_231"].value;
            slide_array.push(["231", answer231]);
        }
        if (checklistOptions.some((option) => option.id === 232)) {
            let answer232 = splitForm.current["question_232"].value;
            slide_array.push(["232", answer232]);
        }
        if (checklistOptions.some((option) => option.id === 233)) {
            let answer233 = splitForm.current["question_233"].value;
            slide_array.push(["233", answer233]);
        }
        if (checklistOptions.some((option) => option.id === 234)) {
            let answer234 = splitForm.current["question_234"].value;
            slide_array.push(["234", answer234]);
        }
        if (checklistOptions.some((option) => option.id === 235)) {
            let answer235 = splitForm.current["question_235"].value;
            slide_array.push(["235", answer235]);
        }


        var question_data = {
            slide_80: ["80", JSON.stringify(slide_array)],
        };

        storeFollowupQuestionnire80(question_data);
        let _existingEntries = JSON.parse(localStorage.getItem("questionData"));
        if (_existingEntries == null) _existingEntries = [];
        let existingIndex = _existingEntries.findIndex(entry => entry.question_id === question_data.question_id);
        if (existingIndex !== -1) {
            _existingEntries.splice(existingIndex, 1);
        }
        _existingEntries.push(question_data);
        localStorage.setItem("questionData", JSON.stringify(_existingEntries));
    };

    const saveIncomeAndExpense = (checklistOptions, inputListValues) => {
        var question_data_79 = {
          question_id: 79,
          option_id: null,
          answer_text: null,
          input_answer: checklistOptions,
        };
        var question_data_80 = {
          slide_80: ["80", JSON.stringify(inputListValues)],
        };
    
    
        setClickedSlide({ slide: "wereAlreadyPaying" });
        storeFollowupQuestionnire80(question_data_79);
        storeFollowupQuestionnire80(question_data_80);
        setDataToLocalStorage(79, question_data_79);
        setDataToLocalStorage(80, question_data_80);
      }
    
      const setDataToLocalStorage = (quest_id, quest_data) => {
        let _existingEntries = JSON.parse(localStorage.getItem("questionData"));
        if (_existingEntries == null) _existingEntries = [];
        let existingIndex;
        if(quest_id == 80) {
          existingIndex = _existingEntries.findIndex(entry => (entry?.slide_80));
        } else {
          existingIndex = _existingEntries.findIndex(entry => entry.question_id == quest_id);
        }
        if (existingIndex !== -1) {
          _existingEntries.splice(existingIndex, 1);
        }
        _existingEntries.push(quest_data);
        localStorage.setItem("questionData", JSON.stringify(_existingEntries));
      }



    return (
        <>
            <div className={`${className}`}>
                <div className="logo-two loader-logo text-center pt-3 mb-5">
                    {" "}
                    <img src="assets/BankStatemenFLPS3/img/logo.png" alt="" />
                </div>


                <div className="mb-3">
                    <div className="tms-wrap2 bg-main-pr">

                        <div className="main-form-wraper form-part">
                            <div className="container">
                                <div className="item-cent-flx ">
                                    <form
                                        ref={splitForm}
                                        name="split_form"
                                        id="user_form"
                                        method="POST"
                                        autoComplete="off"
                                    >

                                        {/* // New Slides for Income */}
                                        <Questionaire77
                                            onselectAnswer={onselectAnswer}
                                            clsName={`space-top2 ${state.showSlide1}`}
                                            reff={(el) => (scrollRef.current[77] = el)}
                                            setPrevStill={setPrevStill}
                                            goBackOpt={goBackOpt}
                                        />

                                        <Questionaire78
                                            saveInputAns={saveInputAns}
                                            clsName={`space-top2 ${state.showSlide78}`}
                                            reff={(el) => (scrollRef.current[78] = el)}
                                            validation={register}
                                            validationMsg={errors}
                                            trigger={trigger}
                                            backClick={previousSlideChange}
                                        />

                                        <DependentStatus
                                            saveInputAns={saveInputAns}
                                            clsName={`space-top2 ${state.showSlide3dep}`}
                                            reff={(el) => (scrollRef.current[3] = el)}
                                            validation={register}
                                            validationMsg={errors}
                                            trigger={trigger}
                                            backClick={previousSlideChange}
                                        />
                                        <IncomeAndExpV3
                                        clsName={` ${state.showSlide79}`}
                                        reff={(el) => (scrollRef.current[79] = el)}
                                        backClick={previousSlideChange}
                                        saveIncomeAndExpense={saveIncomeAndExpense}
                                        />


                                        {/* <Questionaire79
                                            // clsName={` ${state.showSlide79}`}
                                            reff={(el) => (scrollRef.current[79] = el)}
                                            onselectAnswer={onselectAnswer}
                                            questArr1={wereUalreadyPaying}
                                            validation={register}
                                            validationMsg={errors}
                                            getValues={getValues}
                                            trigger={trigger}
                                            setError={setError}
                                            checklistOptions={checklistOptions}
                                            setChecklistOptions={setChecklistOptions}
                                            backClick={previousSlideChange}
                                        /> */}

                                        {/* <Questionaire80
                                            clsName={`space-top2 ${state.showSlide80}`}
                                            reff={(el) => (scrollRef.current[80] = el)}
                                            validation={register}
                                            validationMsg={errors}
                                            getValues={getValues}
                                            trigger={trigger}
                                            setError={setError}
                                            splitForm={splitForm}
                                            questArr={wereUalreadyPaying}
                                            saveSlide80={saveSlide80}
                                            checklistOptions={checklistOptions}
                                            backClick={previousSlideChange}
                                        /> */}


                                    </form>
                                </div>
                                {/* <p className="text-center">
                                    <a href="#" className="back5" onClick={goBackOpt}>
                                        &lt;&lt; Previous
                                    </a>
                                </p> */}
                            </div>
                        </div>
                    </div>



                </div>
            </div>

        </>
    );

};
export default IncomeExpenditure;