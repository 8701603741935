import React, { useState, useEffect, useRef } from 'react';
import starimg from "../../../../assets/VAQ_UB_4_3_1_D/img/stars-5.svg";
import { Carousel } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import "../../../assets/VAQ_UB_4_3_1_D/scss/trustpilot.scss";

const Trustpilot = () => {
    const [activeIndex, setActiveIndex] = useState(0);
    const animationDuration = 30000; // Duration in milliseconds

    // Automatically transition to the next slide every 40 seconds
    useEffect(() => {
        const timer = setTimeout(() => {
            setActiveIndex((prevIndex) => (prevIndex + 1) % 5); // Change 5 to the number of items
        }, animationDuration);

        return () => clearTimeout(timer); // Clean up the timer on unmount or when dependencies change
    }, [activeIndex]);

    const items = [
       
        {
            name: 'Dean',
            date: 'September 05, 2024',
            title: 'Excellent experience',
            content: 'Excellent experience. They did everything for me. Within a week of winning the claim, the money was in my account. It does take time but it’s worth it. Don’t worry if they don’t contact you for a few months; they are going through the process. I started to worry with no contact, but I emailed them and they responded within a day or two.',
        },
        {
            name: 'Lorraine King',
            date: 'September 13, 2024',
            title: 'I got back almost £1,500',
            content: 'I\'m stunned they got back almost £1,500 for me after I saw their ad on Facebook. Yes, their commission is a hefty 45%, but I didn\'t expect to get any money back, so I am not complaining.',
        },
        {
            name: 'Michael Crosbie',
            date: 'September 09, 2024',
            title: 'The service from TMS was second to none',
            content: 'The service from TMS was second to none. Very informative, kept me up to date with all the proceedings. Extremely professional. Can\'t praise highly enough for the incredible work and amazing result.',
        },
        {
            name: 'Sam',
            date: 'September 06, 2024',
            title: 'I\'ve received great service from this company',
            content: 'I\'ve received great service from this company since making a claim last year. The claim was taking quite a long time but they always kept in contact with regular updates. When I won the claim, they were very quick to get the money paid directly to my bank account. Would definitely use them again!',
        },
        {
            name: 'Paul Hammers Jackson',
            date: 'September 03, 2024',
            title: 'Great review from me contact was very good',
            content: 'Great review from me contact was very good, always replied when messaged, quick response too. A long process but not in any way TMC\'s fault. Can’t fault, would recommend. For me, a nice payout too thank you, that’s helped towards my wedding. Brilliant, thanks again.',
        }
    ];

    return (
        <>
        <div className='TRUST-PILOT'>
            <Carousel
                id="carouselExampleCaptions"
                className="carousel slide"
                activeIndex={activeIndex}
                onSelect={(selectedIndex) => setActiveIndex(selectedIndex)}
                interval={null} // Disable default interval       
            >
                {items.map((item, index) => (
                    <Carousel.Item key={index} className="carousel-item">
                        <a href="https://www.trustpilot.com/review/pba-claims.co.uk" target="_blank" rel="noopener noreferrer">
                            <div className="row">
                                <div className="col-12 col-lg-12 sliderstar">
                                    <img src={starimg} alt="Trustpilot star rating" style={{ height: '20px' }} />
                                </div>
                                <div className="col-12 col-lg-12 sliderhead">
                                    <p><b>{item.name}</b>, {item.date}</p>
                                    <h5>{item.title}</h5>
                                </div>
                                <div className="col-12">
                                    <div className="scroldiv">
                                        {/* <marquee behavior="scroll" direction="left" scrollamount="5">
                                            <p>{item.content}</p>
                                        </marquee> */}
                                        <div className="marquee-container">
                                            <p className="marquee-content mb-2">{item.content}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </a>
                    </Carousel.Item>
                ))}
            </Carousel>
            </div>
        </>
    );
};

export default Trustpilot;
