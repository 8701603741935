import React, {
  useState,
  useEffect,
} from "react";
import { Api } from "../../api/Api";
// import "../../assets/OpenBanking/css/styleV1.scss";
import "../../assets/OpenBankingV2/css/splashV2.scss";
import "../../assets/OpenBankingV2/css/custom.scss";
import "../../assets/OpenBankingV2/css/styleV2.scss";
import "../../assets/OpenBankingV2/css/your_affordability_check.scss";
// import "../../assets/MB_OBV1/scss/modules/MB_OBV1.scss";
import { queryString,urlParams } from "../../Utility/QueryString";
import { useHistory } from "react-router-dom";
import { useOpenBanking } from "../../Hooks/useOpenBanking";
import InputButton from "../UI/InputButton";
import { AppDomain } from "../../Constants/EnvConstants";
import GtmDataLayer from "../../Utility/GtmDataLayer";
import OB_CreditCheckV2 from "../Includes/Layouts/ObFollowupV2/OB_CreditCheckV2";
import OB_AffordabilityCheckV2 from "../Includes/Layouts/ObFollowupV2/OB_AffordabilityCheckV2";
import YearOfPossession from "../Includes/Layouts/ObFollowupV2/YearOfPossession";
import OB_SplashV2 from "../Includes/Layouts/ObFollowupV2/OB_SplashV2";
import BankName from "../Includes/Layouts/ObFollowupV2/BankName";
import { useForm } from "react-hook-form";
import MatchCheckLoader from "../Includes/Layouts/ObFollowupV2/MatchCheckLoader";
import BankStmtUploadV2 from "../Includes/Layouts/ObFollowupV2/BankStmtUploadV2"; 

const OpenBankingProceedV2 = () => { 
  const [userDetails, setUserDetails] = useState({});
  const [loader, setLoader] = useState(1);
  const [reloader, setReloader] = useState(1);
  const [affordabilityStat, setAffordabilityStat] = useState('hide');
  const [obCredStat, setObCredStat] = useState('hide');
  const [obSplashStat, setObSplashStat] = useState('hide');
  const history = useHistory();
  const { getVisitorID, getAccessToken, createCustomer, getProviders, getAuthGatewayUrl, storeApiResponse, OpenBankVisit, storeClickResponse, milestoneChase } = useOpenBanking();
  const [btndisable, setBtnDisable] = useState(false);
  const [visitorId, setVisitorId] = useState(0);
  const [iframeUrl, setIframeUrl] = useState(null);
  const [yearOfPossession, setYearOfPossession] = useState('hide');
  const [bankName, setBankName] = useState('hide');
  const [Year, setYear] = useState(2015);
  const [userData, setUserData] = useState();
  const [SelectBank, setSelectBank] = useState([]);
  const [lenderList, setLenderList] = useState([]);
  const [skip, setSkip] = useState(false);
  const [Uuid, setUuid] = useState();
  const [matchCheckLoader, setMatchCheckLoader] = useState('show');
  const [bankStmtUpload, setBankStmtUpload] = useState('hide');

  let redirectUrl = '';
  let redirectlink = '';
  let btn_label = '';
  let page = '';
  const query = new URLSearchParams(window.location.search);
  const query_source = query.get("atp_sub6");
  var source = query_source ? query_source : "live";

  const { register, errors, trigger, setError, clearErrors, getValues } =
    useForm({
      mode: "onBlur",
      reValidateMode: "onChange",
      defaultValues: {},
      resolver: undefined,
      context: undefined,
      criteriaMode: "firstError",
      shouldFocusError: true,
      shouldUnregister: true,
    });

  useEffect(() => {
    (async () => {
      const response = await Api.get(
        "/api/v1/user-basic-details-chase?query_string=" + queryString,
        {}
      );
      if (response.data.status === "Success") {
        let user_data = response.data.response.user_data;
        let uuid = response.data.response.uuid;
        let lender = response.data.response.lender;
        let source = response.data.response.source;
        let lstSalutation = response.data.response.lstSalutation;
        let txtFName = response.data.response.txtFName;
        let txtLName = response.data.response.txtLName;
        let txtEmail = response.data.response.txtEmail;
        let txtPhone = response.data.response.txtPhone;
        let is_test = response.data.response.is_test;
        let token = response.data.response.user_data.atp_sub2;
        let pixel = response.data.response.user_data.pixel;
        let txtPostcode = response.data.response.txtPostcode;
        let txtAddressline1 = response.data.response.txtAddressline1;
        let txtAddressline2 = response.data.response.txtAddressline2;
        let txtAddressline3 = response.data.response.txtAddressline3;
        let town = response.data.response.town;
        let dob = response.data.response.dob;
        let userId = response.data.response.user_id;
        let obcreduser = response.data.response.obcreduser;
        let obstat = response.data.response.obstat;
        let credstat = response.data.response.credstat;
        let obclosestat = response.data.response.obclosestat;
        let agreementDate = response.data.response.agreement_date;
        let splash_milestone = response.data.response.splash_milestone;
        let agreement_date_milestone = response.data.response.agreement_date_milestone;
        let bank_select_milestone = response.data.response.bank_select_milestone;
        let open_banking_milestone = response.data.response.open_banking_milestone;
        let file_upload_milestone = response.data.response.file_upload_milestone;
        let credit_check_milestone = response.data.response.credit_check_milestone;
        let chase_user = response.data.response.chase_user;
        let lenders = response.data.response.providers;
        let bank_name = response.data.response.lender;
        let agree_date_entered = response.data.response.agree_date_entered;

        localStorage.setItem('is_test', is_test);
        localStorage.setItem('user_data', JSON.stringify(user_data));
        localStorage.setItem('user_id', userId);
        localStorage.setItem('source', source);

        if (uuid == null) {
          history.push("/thankyou?uuid=" + uuid);
        }

        setUserData(user_data);
        setUuid(uuid);

        setLenderList(lenders);

        setUserDetails({
          uuid: uuid,
          lender: lender,
          source: source,
          lstSalutation: lstSalutation,
          txtFName: txtFName,
          txtLName: txtLName,
          txtEmail: txtEmail,
          txtPhone: txtPhone,
          is_test: is_test,
          token: token,
          pixel: pixel,
          txtPostcode: txtPostcode,
          txtAddressline1: txtAddressline1,
          txtAddressline2: txtAddressline2,
          txtAddressline3: txtAddressline3,
          town: town,
          dob: dob,
          user_id: userId,
          obcreduser: obcreduser,
          obstat: obstat,
          credstat: credstat,
          obclosestat,
          agreementDate: agreementDate,
          agree_date_entered,
          bankName:bank_name
        });

   
        const visitor = await getVisitorID(
          uuid,
          user_data,
          'new'
        )

        if (visitor.data.status == "success") {
          setVisitorId(visitor.data.visitorId);
        }

        const flpResponse = await OpenBankVisit(
          "open_bank_visit",
          uuid,
          user_data,
          "OpenBankingProceedV1",
          queryString,
          "proceed-obV2"
        );

        if (chase_user == 0) {
          page = "chaseUser";
        } else if (splash_milestone == null || splash_milestone == 3) {
          page = "splash";
        } else if (agreementDate === null || agreementDate === '') {
          page = "agreement";
        }
        else if (agreement_date_milestone == null && splash_milestone == 2) {
          page = "agreement";
        } else if (bank_select_milestone == null) {
          page = "bankSelect";
        } else if (open_banking_milestone == null && bank_select_milestone == 1 && credit_check_milestone != 1) {
          page = "bankSelect"; //OBpage
        } else if (file_upload_milestone == null && open_banking_milestone != 1) {

          page = "bankStmtUpload";
        } else if (credit_check_milestone == null || credit_check_milestone == 1  ) {

          page = "creditCheck";
        } else {
          console.log("no data");
        }
       
        setTimeout(() => {
          setDivShow(page, uuid);
          setLoader(0);
        }, 2000);
        
      } else {
        setLoader(0);
        history.push("/thankyou?uuid=" + response.data.response.uuid);
        return false;
      }

    })();

  }, []);

  const setDivShow = (page,uuid) => {
    
    if (page == "chaseUser") {
      history.push("/thankyou?uuid=" + uuid);
    }

    if (page == "splash") {
      setObSplashStat('show');
    } else if (page == "agreement") {
      setObSplashStat('hide');
      setYearOfPossession('show');
    } else if (page == "bankSelect") {
      setObSplashStat('hide');
      setYearOfPossession('hide');
      setBankName('show');
    } else if (page == "OBpage") {
      setObSplashStat('hide');
      setBankName('hide')
      setAffordabilityStat('show');
    } else if (page == "bankStmtUpload") {
      setObSplashStat('hide');
      // setBankStmtUpload('show');
      history.push( `/thankyou?uuid=${uuid}`);
    } else if (page == "creditCheck") {
      setObSplashStat('hide');
      history.push( `/thankyou?uuid=${uuid}`);
    } else {
      //history.push("/thankyou?uuid=" + uuid);
    }
  };


  useEffect(() => {


    (async () => {

      let urlPortion = 'title=' + userDetails.lstSalutation + '&fname=' + userDetails.txtFName + '&lname=' + userDetails.txtLName + '&email=' + userDetails.txtEmail + '&phone=' + userDetails.txtPhone + '&dob=' + userDetails.dob + '&abodenumber=' +  '&building_name=' + userDetails.txtAddressline1 + '&straddr=' + userDetails.txtAddressline2 + '&street=' + '&city=' + userDetails.town + '&zip=' + userDetails.txtPostcode + '&clientRefId=' + userDetails.uuid;

      localStorage.setItem('url_portion', urlPortion);
      localStorage.setItem('uuid', userDetails.uuid);
      localStorage.setItem('firstname', userDetails.txtFName);
      localStorage.setItem('credStat', userDetails.credstat);
      localStorage.setItem('agreementDate', userDetails.agreementDate);

      if (userDetails.uuid) {

        let payload = { "page": 'OB_SPLASH', type: 'visit', "source": userDetails.source };
        await storeClickResponse({ uuid: userDetails.uuid }, "ob_creditcheck_history", userDetails.uuid, payload);

      }

      // if (userDetails.obclosestat == '1') {
      //   history.push("/ob_splash_cancel?uuid=" + userDetails.uuid + "&atp_sub6=" + source);
      //   return false;
      // }


      if ((userDetails.obcreduser == '1') && (userDetails.obstat == '' || userDetails.obstat == '0' || userDetails.obstat == null) && (userDetails.credstat == '' || userDetails.credstat == '0' || userDetails.credstat == null)) {

        history.push("/thankyou?uuid=" + userDetails.uuid);
        return false;

      }

      // if (userDetails.obcreduser == '1') {
      //     setAffordabilityStat('hide');

      //     if (userDetails.obstat == '' || userDetails.obstat == '0' || userDetails.obstat == null) {

      //       if (userDetails.credstat == '1') {
      //         history.push("/credit_check?"+ queryString);
      //         return false;

      //       } else {

      //         setLoader(0);
      //         history.push("/thankyou?uuid=" + userDetails.uuid);
      //         return false;

      //       }

      //     } else if(userDetails.obstat == '1' && (userDetails.credstat == '0' || userDetails.credstat == null)) {

      //       setObCredStat('hide');
      //       // setAffordabilityStat('show');

      //     } else {

      //       setAffordabilityStat('hide');
      //       // setObCredStat('show');

      //     }

      // } else {

      //   setObCredStat('hide');
      //   // setAffordabilityStat('show');

      // }  

      // let iframUrl = 'https://uk.rspcdn.com/xprr/red/PID/3916?'+urlPortion;
      // setIframeUrl(iframUrl);

    })();


  }, [userDetails]);



  const pushProceedLink = async (e) => {

    setBtnDisable(true);
    setLoader(1);
    //await milestoneChase({ uuid: userDetails.uuid }, userDetails.user_id, "open_banking_milestone", 1);
    btn_label = e.target.getAttribute('data-btn-label');
    page = e.target.getAttribute('data-page-label');

    let payload = { page, type: 'btn_click', btn_label, "source": userDetails.source, 'click_btn': '1' };
    await storeClickResponse({ uuid: userDetails.uuid }, "ob_creditcheck_history", userDetails.uuid, payload);

    GtmDataLayer({
      'question': 'OPEN BANKING'
    });

    localStorage.setItem('visitorId', visitorId);
    const response1 = await getAccessToken(userDetails.uuid, userDetails.source, userDetails.is_test, visitorId);

    if (response1 == undefined || response1 == null || response1 == '') {
      setLoader(0);
      setReloader(0);
      return false;
    }

    if (response1.data.status == "success") {
      //(response.data.access_token_ID);
      let payload = { "milestone_key": "access_token", "source": userDetails.source };
      await storeApiResponse(userDetails, "op_milestone_update", userDetails.uuid, payload);
      const customerResponse = await createCustomer(userDetails.uuid, userDetails.source, userDetails.lstSalutation, userDetails.txtFName, userDetails.txtLName, userDetails.txtEmail, userDetails.txtPhone, userDetails.is_test, visitorId)

      if (customerResponse == undefined || response1 == null || response1 == '') {
        setLoader(0);
        setReloader(0);
        return false;
      }


      if (customerResponse.data.status == "success") {
        localStorage.setItem('customer_id', customerResponse.data.customer_ID);
        let payload = { "milestone_key": "customer_create", "customer_id": customerResponse.data.customer_ID, 'source': userDetails.source };
        await storeApiResponse(userDetails, "op_customer_store", userDetails.uuid, payload);
        // const providerResponse = await getProviders(userDetails.uuid,userDetails.source,userDetails.is_test);
        // if (providerResponse.data.status === "success") {
        //     const providers = providerResponse.data.providers;
        //     const optionData = providers.map((value, index) => {
        //         return (
        //           <option value={value.provider} key={index}>
        //             {value.display_name}
        //           </option>
        //         );
        //       });
        // }

        redirectUrl = (userDetails.source) ? AppDomain + "/ob-analyzeV2?uuid=" + userDetails.uuid + "&atp_sub6=" + userDetails.source : AppDomain + "/ob-analyzeV2?uuid=" + userDetails.uuid;

        var bud_lender = localStorage.getItem('bud_lender');
        let lenderArray = (bud_lender == null) ? '' : [bud_lender];
        var response2 = await getAuthGatewayUrl(userDetails.uuid, customerResponse.data.customer_ID, redirectUrl, userDetails.source, userDetails.is_test, visitorId, lenderArray)

        if (response2 == undefined || response2 == null || response1 == '') {
          setLoader(0);
          setReloader(0);
          return false;
        }
        
        if (response2.data.status == "success") {
          payload = { "milestone_key": "lender_redirection", 'source': userDetails.source, "lender": lenderArray };
          await storeApiResponse(userDetails, "op_milestone_update", userDetails.uuid, payload);
          let url = response2.data.gateway_url;
          window.location.href = url;
          //setLoader(0)
        }
      }
    }
  }

  const redirectTankyou = async (e) => {
    setBtnDisable(true);
    setLoader(1)
    GtmDataLayer({
      'question': 'Pre-Paid Envelopes'
    });
    await milestoneChase({ uuid: userDetails.uuid }, userDetails.user_id, "open_banking_milestone", 2)
    btn_label = e.target.getAttribute('data-btn-label');
    page = e.target.getAttribute('data-page-label');


    let payload = { page, type: 'btn_click', "btn_label": btn_label, "source": userDetails.source, 'click_btn': '2' };
    await storeClickResponse({ uuid: userDetails.uuid }, "ob_creditcheck_history", userDetails.uuid, payload);

    const flpResponse = await OpenBankVisit(
      "envelopes_stats",
      userDetails.uuid,
      userDetails,
      "proceed-open-banking",
      queryString,
      "v1"
    );


    if (userDetails.obcreduser == '1' && userDetails.obstat == '1' && userDetails.credstat == '1') {

      redirectlink = "/credit_check?" + queryString;
    } else {
      setAffordabilityStat('hide');
      // setBankStmtUpload('show');
      // history.push("/credit_checkV2?" + queryString)
      history.push( `/thankyou?uuid=${Uuid}`)

      setTimeout(() => {
        setLoader(0);
        //   history.push(redirectlink);
      }, 1000);
      //redirectlink = "/envelope-thankyou?uuid=" + userDetails.uuid
    }

    // setTimeout(() => {
    //   setLoader(0);
    //   history.push(redirectlink);
    // }, 1500);
  }

  const keepMyFileLinkOne = async (e) => {

    setBtnDisable(true);
    setLoader(1);

    btn_label = e.target.getAttribute('data-btn-label');
    page = e.target.getAttribute('data-page-label');


    let payload = { page, type: 'btn_click', "btn_label": btn_label, "source": userDetails.source, 'click_btn': '7' };
    await storeClickResponse({ uuid: userDetails.uuid }, "ob_creditcheck_history", userDetails.uuid, payload);
    await milestoneChase({ uuid: userDetails.uuid }, userDetails.user_id, "splash_milestone", 1)

    // let payloadinitial = { "page": 'OB_LP', type: 'visit', "source": userDetails.source };
    // await storeClickResponse({ uuid: userDetails.uuid }, "ob_creditcheck_history", userDetails.uuid, payloadinitial);


    setObSplashStat('hide');
    
   if (userDetails.agreementDate === null || userDetails.agreementDate === '' || userDetails.agree_date_entered !== null ) {
     setYearOfPossession('show');
   } else {
     setBankName('show');
   }

    setBtnDisable(false);

    setTimeout(() => {
      setLoader(0);
    }, 1000);

  }


  const keepMyFileLinkTwo = async (e) => {
    setBtnDisable(true);
    setLoader(1);

    btn_label = e.target.getAttribute('data-btn-label');
    page = e.target.getAttribute('data-page-label');


    let payload = { page, type: 'btn_click', "btn_label": btn_label, "source": userDetails.source, 'click_btn': '7' };
    await storeClickResponse({ uuid: userDetails.uuid }, "ob_creditcheck_history", userDetails.uuid, payload);
    await milestoneChase({ uuid: userDetails.uuid }, userDetails.user_id, "splash_milestone", 2)

    let payloadinitial = { "page": 'OB_LP', type: 'visit', "source": userDetails.source };
    await storeClickResponse({ uuid: userDetails.uuid }, "ob_creditcheck_history", userDetails.uuid, payloadinitial);


    setObSplashStat('hide');
    setYearOfPossession('show');

    setBtnDisable(false);

    setTimeout(() => {
      setLoader(0);
    }, 1000);
  }



  const closeMyFileLink = async (e) => {

    setBtnDisable(true);

    btn_label = e.target.getAttribute('data-btn-label');
    page = e.target.getAttribute('data-page-label');


    let payload = { page, type: 'btn_click', "btn_label": btn_label, "source": userDetails.source, 'click_btn': '8' };
    await storeClickResponse({ uuid: userDetails.uuid }, "ob_creditcheck_history", userDetails.uuid, payload);

    await milestoneChase({ uuid: userDetails.uuid }, userDetails.user_id, "splash_milestone", 3)
    history.push("/ob_splash_cancel?uuid=" + userDetails.uuid + "&atp_sub6=" + source);
    return false;

  }

  const pushPageChange = () => {
    // history.push("/credit_checkV2?" + queryString)
    history.push( `/thankyou?uuid=${Uuid}`);

  }




  return (
    <>
      <div className="ObFollowUpV2">
        <OB_SplashV2
          obSplashStat={obSplashStat}
          btndisable={btndisable}
          keepMyFileLinkOne={keepMyFileLinkOne}
          keepMyFileLinkTwo={keepMyFileLinkTwo}
          closeMyFileLink={closeMyFileLink}
          loader={loader}
          userDetails={userDetails}
          setMatchCheckLoader={setMatchCheckLoader}
        />

        <YearOfPossession
          className={`${yearOfPossession}`}
          validation={register}
          clearErrors={clearErrors}
          getValues={getValues}
          trigger={trigger}
          validationMsg={errors}
          Year={Year}
          allFollowupData={userData}
          setYearOfPossession={setYearOfPossession}
          setBankName={setBankName}
          //setLenderList={setLenderList}
          Uuid={Uuid}
          loader={loader}
          setObSplashStat={setObSplashStat}
          setLoader={setLoader}
          userDetails={userDetails}
          setMatchCheckLoader={setMatchCheckLoader}
        />

        <BankName
          className={`${bankName}`}
          validation={register}
          getValues={getValues}
          validationMsg={errors}
          trigger={trigger}
          Year={Year}
          SelectBank={SelectBank}
          setSelectBank={setSelectBank}
          clearErrors={clearErrors}
          setError={setError}
          lenderList={lenderList}
          skip={skip}
          setBankName={setBankName}
          setMatchCheckLoader={setMatchCheckLoader}
          allFollowupData={userData}
          loader={loader}
          setAffordabilityStat={setAffordabilityStat}
          setObSplashStat={setObSplashStat}
          setYearOfPossession={setYearOfPossession}
          setBankStmtUpload={setBankStmtUpload}
          setLoader={setLoader}
          userDetails={userDetails}
          pushPageChange={pushPageChange}
        />


        <MatchCheckLoader
          matchCheckLoader={matchCheckLoader}
          setMatchCheckLoader={setMatchCheckLoader}
          setAffordabilityStat={setAffordabilityStat}
        />

        <OB_AffordabilityCheckV2
          setLoader={setLoader}
          loader={loader}
          reloader={reloader}
          btndisable={btndisable}
          setReloader={setReloader}
          pushProceedLink={pushProceedLink}
          redirectTankyou={redirectTankyou}
          affordabilityStat={affordabilityStat}
          setMatchCheckLoader={setMatchCheckLoader}
        />

        <BankStmtUploadV2
          setLoader={setLoader}
          className={`${bankStmtUpload}`}
          loader={loader}
          pushPageChange={pushPageChange}
          allFollowupData={userData}
          userDetails={userDetails}
        />

        {/* <OB_CreditCheckV2
        setLoader={setLoader}
        loader={loader}
        reloader={reloader}
        btndisable = {btndisable}
        obCredStat = {obCredStat} 
        pushProceedLink={pushProceedLink}
        redirectTankyou={redirectTankyou}
      /> */}
      </div>
    </>

  );
};

export default OpenBankingProceedV2;