import React, {
    useRef,
    useState,
    useEffect,
    useContext,
    useReducer,
} from "react";
import "../../assets/PersonKyc_MB_UFGN_2/css/person_edit.scss";
import VisitorParamsContext from "../../Contexts/VisitorParams";
import { useUserVerify } from "../../Hooks/useUserVerify";
import { MonthNameArray } from "../../Constants/Constants";
import { queryString } from "../../Utility/QueryString";
import { userInfo } from "../../Hooks/userInfo";
import tms_logo from "../../assets/AnalyseMB/img/a00187b31dd5a4ece0c8f809b69962eb.png";
import loader_img from "../../assets/AnalyseMB/img/review.gif";
import { PossessionMonth, YearPossession } from "../../Constants/Constants";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import GetVisitorsParams from "../../Utility/GetVisitorsParams";
import GtmDataLayer from "../../Utility/GtmDataLayer";
import { useLogRecord } from "../../Hooks/UseLogRecord";
import { Api } from "../../api/Api";
import { useUpdateMilestone } from "../../Hooks/useUpdateMilestone";


const VerifyFLPS3 = () => {

    const { visitorParameters } = useContext(VisitorParamsContext);
    const { UserDataInfo } = userInfo();
    const { getUserVerfyValidation, saveFollowupUserVerify } = useUserVerify();
    const [verifyData, setVerifyData] = useState({});
    const [t2aStatus, setT2aStatus] = useState("");
    const formdata = JSON.parse(localStorage.getItem("formData"));
    const [UserUUid, setUserUuid] = useState("");
    // const [user_id, SetUser_id] = useState("");
    const history = useHistory();
    const splitForm = useRef(null);
    const { updateLogRecord } = useLogRecord();
    const {updateMilestone} = useUpdateMilestone();
    let flp_source = localStorage.getItem('atp_sub6');

    const [userDetails, setUserDetails] = useState([]);
    const query_string = localStorage.getItem("queryString");
    const querystring = queryString;
    const queryStrings = query_string ? query_string : querystring;

    const { register, errors, trigger, setError, clearErrors, getValues } =
        useForm({
            mode: "onBlur",
            reValidateMode: "onChange",
            defaultValues: {},
            resolver: undefined,
            context: undefined,
            criteriaMode: "firstError",
            shouldFocusError: true,
            shouldUnregister: true,
        });

    let useruuid;
    let fName;
    let lName;
    let pCode;
    let addressLine1;
    let dobYr;
    let dobDy;
    let addressLine2;
    let postTown;
    let country;
    let title;
    let addressLine3;
    let addressLine4;
    let county;
    let dobMon;
    let email;
    let user_id;
    let is_test;
    let dob;
    let followupDetails;
    let phone;


    const getCurrentDateTime = () => {
        const currentDate = new Date();

        const year = currentDate.getFullYear();
        const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
        const day = currentDate.getDate().toString().padStart(2, '0');
        const hours = currentDate.getHours().toString().padStart(2, '0');
        const minutes = currentDate.getMinutes().toString().padStart(2, '0');
        const seconds = currentDate.getSeconds().toString().padStart(2, '0');


        return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    };

    useEffect(() => {
        (async () => {
            const response = await Api.get(
                "/api/v1/followup/get-pending-details-s2?query_string=" + queryString,
                {}
            );
            if (response.data.status === "Success") {
                followupDetails = response.data.response.followup_data;
                localStorage.setItem("followupData", JSON.stringify(followupDetails));
                useruuid = response.data.response.uuid;
                localStorage.setItem("uuid", useruuid);
                fName = response.data.response.first_name;
                lName = response.data.response.last_name;
                pCode = response.data.response.postcode;
                addressLine1 = response.data.response.address_line1;
                dobDy = response.data.response.dob_day;
                dobMon = response.data.response.dob_month;
                dobYr = response.data.response.dob_yr;
                email = response.data.response.email;
                phone = response.data.response.phone;
                addressLine2 = response.data.response.address_line2;
                postTown = response.data.response.town;
                country = response.data.response.country;
                title = response.data.response.title;
                addressLine3 = response.data.response.address_line3;
                addressLine4 = response.data.response.address_line4;
                county = response.data.response.county;
                user_id = response.data.response.user_id;
                is_test = response.data.response.is_test;
                dob = response.data.response.dob;

                // visitorParameters.email = email || false;
  
                localStorage.setItem("source", followupDetails.atp_sub6);
                setTimeout(() => {
                    veripyCall();

                }, 2000);
            } else {
                console.log("user not found");
            }
        })();
    }, []);

    const veripyCall = () => {

        if (useruuid && fName && lName && pCode && addressLine1 && dobYr && dobMon && dobDy) {
            const startTime = performance.now();
            let startDateTime = getCurrentDateTime();
            (async () => {
                const response = await getUserVerfyValidation(
                    useruuid,
                    fName,
                    lName,
                    pCode,
                    addressLine1,
                    dobYr,
                    dobMon,
                    dobDy,
                    addressLine2,
                    postTown,
                    country,
                    title,
                    addressLine3,
                    addressLine4,
                    county,
                );
                let endDateTime = getCurrentDateTime();

                const endTime = performance.now();

                const Timems = endTime - startTime;

                const timeTaken = (Timems / 1000).toFixed(2);  // Convert milliseconds to seconds


                if (response.data.status == "success") {
                    localStorage.setItem("t2aValidation", 1);
                    updateMilestone(useruuid,"kyc_check",flp_source,1);
                } else {
                    localStorage.setItem("t2aValidation", 0);
                    updateMilestone(useruuid,"kyc_check",flp_source,2);
                }
                await storeT2aResponse(response.data);
                const LogRecord = await updateLogRecord(
                    useruuid,
                    "Person_Search",
                    timeTaken,
                    startDateTime,
                    endDateTime
                );
            })();
        }

    }

 


    const storeT2aResponse = async (t2a_data) => {
        try {
            const response = await saveFollowupUserVerify(
                followupDetails,
                t2a_data,
                "person_verify_store_flp",
                query_string,
                {
                    useruuid: useruuid,
                    fName: fName,
                    lName: lName,
                    pCode: pCode,
                    addressLine1: addressLine1,
                    dobYr: dobYr,
                    dobDy: dobDy,
                    addressLine2: addressLine2,
                    postTown: postTown,
                    country: country,
                    title: title,
                    addressLine3: addressLine3,
                    addressLine4: addressLine4,
                    county: county,
                    dobMon: dobMon,
                    is_test: is_test,
                });
            const t2a_Validation = localStorage.getItem("t2aValidation");

            if (t2a_Validation == '0' || t2a_Validation == 0) {
                window.scrollTo(0, 0);
                // history.push("/PersonDataKyc?uuid=" + visitorParameters.visitor_parameters.uuid);
                const uploadPage = "PersonKycFLPS3" + "?" + queryString;
                window.location.href = uploadPage;
            } else {
                localStorage.setItem("KycFlp", 1);
                window.scrollTo(0, 0);
                const uploadPage = "/checkListFLPS3" + "?" + queryString;
                window.location.href = uploadPage;            }
        } catch (error) {
            console.error("Error storing T2a response:", error);
        }
    };






    return (
        <>
            <GetVisitorsParams />
            <div className="kyc_edit">
                <div className="logo-two text-center pt-3" >
                    {" "}
                    <img src="assets/PersonKyc_MB_UFGN_2/img/logo.png" alt="" />
                </div>
                <div class="container-fluid">

                    <div className="slide  page-loader loader1  ">
                        <div className="wrap-app mt-3 text-center">
                            <div className="mb-5 pb-5">
                                <img height="100" className="mb-2" src="assets/PersonKyc_MB_UFGN_2/img/loader-review2.gif" alt="" />
                                <p className="mb-5">Please wait while we authenticate your personal information.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
};

export default VerifyFLPS3;