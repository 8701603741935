import React, { useEffect } from 'react';
import "../../assets/css/NotFound.scss";
import { useErrorNotFoundHook } from '../../Hooks/useErrorNotFoundHook';
import { getAllLocalStorageData } from '../../Utility/GetLocalStorageData';

const NotFound = () => {

   const { ErrorWebhookPost } = useErrorNotFoundHook();

   useEffect(() => {
     const localData = getAllLocalStorageData();

     // Get the full URL
     const fullURL = window.location.href;

     // Extract URL parameters
     const urlParams = new URLSearchParams(window.location.search);
     const paramsValues = {};
     for (const [key, value] of urlParams.entries()) {
       paramsValues[key] = value;
     }
     const referringURL = document.referrer;
     const content = {
       serviceName: "NotFound Error",
       urlParams: paramsValues,
       localStroageData: localData,
       referalUrl: referringURL,
     };
     ErrorWebhookPost(content);
   }, []);

    return (
        <>
            <div className="NotFound">
                <div className="flex-center position-ref full-height">
                <div className="code">
                        404            </div>
                    <div className="message" style={{ padding: "10px" }}>
                        Not Found
                </div>
                </div>
            </div>
        </>
    )
}

export default NotFound;