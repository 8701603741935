import React, { useEffect, useState } from 'react';
import ProgressBar from '../Common/ProgressBar';
import Select from 'react-select';
import RadioButton from '../../../UI/RadioButton';
import { useFollowupUserQuestions } from '../../../../Hooks/useFollowupUserQuestions';
import { useOpenBanking } from "../../../../Hooks/useOpenBanking";
import { useHistory } from "react-router-dom";


const BankName = ({
  className,
  slideChange,
  validation,
  SelectBank,
  setSelectBank,
  validationMsg,
  trigger,
  clearErrors,
  getValues,
  setError,
  Year,
  textHeading,
  lenderList,
  skip,
  setBankName,
  setMatchCheckLoader,
  allFollowupData,
  loader,
  setAffordabilityStat,
  setObSplashStat,
  setYearOfPossession,
  setBankStmtUpload,
  setLoader,
  userDetails,
  pushPageChange
}) => {
  useEffect(() => {
    if (className === "show") {
      setMatchCheckLoader("hide");
    }
  }, [className]);
  const [BankValidationMsg, setBankValidationMsg] = useState(false);
  const { saveFollowupUserQuestions, isLoading } = useFollowupUserQuestions();
  const agreementDate = localStorage?.getItem("agreementDate"); 
  const { getCustomerCheck,milestoneChase,storeFosLenders } = useOpenBanking();
  const history = useHistory();
  const customStyles = {
    control: (base) => ({
      ...base,
      minHeight: 50,
    }),
  };

  const formData = getValues();

  const nextSlide = async (e) => {
    let LengthSelectedBank = SelectBank.length;
    let ValueSelectedBank = SelectBank.value;

    let user_banks = SelectBank.filter((e) => e.value !== 'others');
    // setSelectBank(existVal => {
    //   return existVal.filter((e) => e.value !== 'others')
    // })

    if (LengthSelectedBank < 1 || ValueSelectedBank == "") {
      setBankValidationMsg("Please Select Bank");
    } else {
         setBankName('hide');
         setMatchCheckLoader('show');
         
         let budLender =  user_banks[0]?.value;

         if (budLender === undefined || budLender === null || budLender == "" ) {
            
          setTimeout(() => {
                setMatchCheckLoader('hide');
                pushPageChange();
              }, 3000);

              await milestoneChase({ uuid: userDetails.uuid },userDetails.user_id,"bank_select_milestone",2)

         } else {
           
           localStorage.setItem('bud_lender', user_banks[0]?.value);
           setTimeout(() => {
             setMatchCheckLoader('hide');
             setAffordabilityStat('show');
            }, 3000);
            
            // await storeFosLenders({ uuid: userDetails.uuid },'fos_lender_save', userDetails.uuid,SelectBank);
            
            // await milestoneChase({ uuid: userDetails.uuid },userDetails.user_id,"bank_select_milestone",1)
            
            let matchCheck = await getCustomerCheck({ uuid: userDetails.uuid },userDetails.user_id,agreementDate,SelectBank);

            if(matchCheck.data.status === "success"){ 
          let budLender = matchCheck.data.provider;
          localStorage.setItem('bud_lender', budLender);
            setTimeout(() => {
              setMatchCheckLoader('hide');
              setAffordabilityStat('show');
            }, 3000);
            await milestoneChase({ uuid: userDetails.uuid },userDetails.user_id,"bank_select_milestone",1)
         }else{
          setTimeout(() => {
            setMatchCheckLoader('hide');
            setBankStmtUpload('show');
            // history.push("/credit_checkV2?" + queryString)
            pushPageChange();
          }, 3000);
            await milestoneChase({ uuid: userDetails.uuid },userDetails.user_id,"bank_select_milestone",2)
         }
          }
    }
  };

  function handleSelect(opt) {
   setSelectBank(opt);
   setBankValidationMsg(false);
  }

  let BankList = [];

  if (lenderList.length > 0) {
    BankList = BankList.concat(lenderList.filter(function (val) {
      return !val.display_name.endsWith("Card") &&
        !val.display_name.endsWith("Business") &&
        !val.display_name.endsWith("card") &&
        !val.display_name.endsWith("Cards");
    }).map(function (val) {
      return {
        value: val.provider,
        label: val.display_name
      };
    }));
  }
  const backClick = async (e) => { 
    setLoader(1);
    setBankName('hide');
    if (userDetails.agreementDate === null || userDetails.agreementDate === '' || userDetails.agree_date_entered !== null) {
    setYearOfPossession('show');
    } else {
      setObSplashStat('show');
    }

    setTimeout(() => {
      setLoader(0);
    }, 1000);
  };

  const notInList = async (e) => { 
    setLoader(1);

    setYearOfPossession('hide');
    setBankName('hide');
    setBankStmtUpload('show');

    setTimeout(() => {
      setLoader(0);
    }, 1000);
  };


  return (
    <>
      <div className={`bg-main-pr ${className}`}>
        {/* <div className="text-center loader-wrap" > */}
        <div className="loader_1">
          <div className={`text-center loader-wrap2 ${loader == 1 ? 'showload' : 'hide'}`}>
            <div className="loader">
              <img src="assets/img/review.gif" alt="" />
            </div>
          </div>
        </div>

          <div className={`main text-center main-div ${loader == 0 ? 'showload' : 'hide'}`}>
            <div className="brand-logo mt-lg-0 mt-md-2 mt-sm-2 mt-2">
              <img height="65" src="assets/OpenBankingV2/img/a00187b31dd5a4ece0c8f809b69962eb.png" alt="" />
            </div>


            <div className="mt-val">
              <div className="container">
                <div className="item-cent-flx ">

                  <div className='slide02'>
                    <div className="row">
                      <div className="col-lg-8 offset-lg-2">
                        <h1>Please confirm which bank you used to serve your loan payments with {userDetails.bankName}</h1>
                        <div className="row mt-4">
                          <div className="col-lg-12">
                            <div className="mb-3">
                              <Select
                                placeholder="Select Bank Name"
                                options={BankList}
                                aria-label="Username"
                                name="bankName"
                                id="bankName"
                                value={SelectBank}
                                onChange={handleSelect}
                                isSearchable={true}
                                styles={customStyles}
                                isMulti
                              // validation={validation({
                              //   required: "Please Enter Bank Name",
                              //   minLength: {
                              //     value: 3,
                              //     message: "Please Enter valid A Bank Name",
                              //   }
                              // })}
                              // validationMsg={validationMsg?.bankName?.message}
                              />
                              {BankValidationMsg && (
                                <span className="error_msg">{BankValidationMsg}</span>
                              )}
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-lg-12">
                            <div>
                              <RadioButton
                              name="bankname"
                              id={"bankname"}
                              className="radio-button"
                              labelName="Next"
                              labelClassName='com-btn w-100'
                              onClick={nextSlide}
                              validation={validation()}
                            />
                             </div>
                             {/* <div className="mt-4 mb-3">
                              <button 
                                className="btn-prev"
                                name="notinlist"
                                children=" not" 
                                onClick={notInList}> 
                                <i className="bi bi-arrow-left"></i> 
                                Not in this List  
                              </button> 
                              </div> */}
                            <div className="mt-4 mb-5">
                              <button 
                                className="btn-prev"
                                name="prevKeep"
                                children=" Previous" 
                                onClick={backClick}> 
                               <span className="me-2"> <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" className="bi bi-arrow-left" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8"/>
</svg></span>
                                Previous 
                              </button> 
                              </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>

            <div className="mb-lg-5 mb-md-5 mb-sm-3 mb-3 mt-lg-5 mt-lg-3 mt-md-5 mt-sm-5 ">
              <img height="50" src="assets/OpenBankingV2/img/safe and secure.png" alt="" />
            </div>
          </div>
        </div>
      {/* </div> */}
    </>
  );
};

export default BankName;
