import React, { useState } from 'react';
import RadioButton from '../../../../UI/RadioButton';
import Modal from "react-bootstrap/Modal";
//import { BankList } from "../../../../Constants/Lender";
import Select from 'react-select';


const BankName = ({
  clsName,
  saveInputAns,
  validation,
  SelectBank,
  setSelectBank,
  validationMsg,
  trigger,
  clearErrors,
  getValues,
  setError,
  // Year,
  backClick,
  textHeading,
  lenderList,
  // skip
}) => {

  const [BankValidationMsg, setBankValidationMsg] = useState(false);


  const customStyles = {
    control: base => ({
      ...base,
      //height: 50,
      minHeight: 50
    })
  };

  const nextSlide = async (e) => {
    let LengthSelectedBank = SelectBank.length;
    let ValueSelectedBank = SelectBank.value;
   
    if (LengthSelectedBank < 1 || ValueSelectedBank == "") {
      setBankValidationMsg(true);
    } else {
       localStorage.setItem("bud_lender",ValueSelectedBank);
       localStorage.setItem("lenderArray",JSON.stringify([SelectBank]));
      saveInputAns(e);
    }
  }


  function handleSelect(opt) {
    setSelectBank(opt);
    setBankValidationMsg(false);
  }
  let BankList = []; //[{ value: null,  label: "---Select Bank Name---" }];
  // if(lenderList.length >0){
  //   lenderList.map(function (val) {
  //     let lenderData = { value: val.provider, label:val.display_name}
  //     BankList.push(lenderData);
  //   })
  // }

  if (lenderList.length > 0) {
    BankList = BankList.concat(lenderList.filter(function (val) {
      return !val.display_name.endsWith("Card") &&
        !val.display_name.endsWith("Business") &&
        !val.display_name.endsWith("card") &&
        !val.display_name.endsWith("Cards");
    }).map(function (val) {
      return {
        value: val.provider,
        label: val.display_name
      };
    }));
  }
  return (
    <>
      <div className={`radio-box animated fadeInUp ${clsName}`} id="slide3">
        <h3> Who were you banking with when you opened your account with Vanquis?</h3>

        <div className="input-section mb-3">
          <Select
            placeholder="Select Bank Name"
            options={BankList}
            aria-label="Username"
            name="bankName"
            id="bankName"
            value={SelectBank}
            onChange={handleSelect}
            isSearchable={true}
            styles={customStyles}
            // isMulti
          />
          {BankValidationMsg && (<span id="bank_err" className="error_msg">Please select your Bank</span>)}
        </div>
        <div className="btn-comm">

          <RadioButton
            name="bankname"
            id={"bankname"}
            className="radio-button"
            labelName="Next"
            dataQuestId={64}
            labelClassName={`option_but  next3a`}
            onClick={nextSlide}
            validation={validation()}
          />
        </div>
        <span className="warning-text">
            {
              "We require your bank statements as these show your financial circumstances around the time of opening the account with Vanquis. We can request these from your bank. To do so, please provide the following"
            }
          </span>
      
      </div>

    </>
  );
};

export default BankName;