import React,{ useState } from 'react';
import RadioButtonQuest from "../../../../UI/RadioButtonQuest";
import SelectBox from '../../../../UI/SelectBox';
import TextField from '../../../../UI/TextField';
import InputButton from "../../../../UI/InputButton";
import {Amount} from "../../../../../Constants/Constants";


const QuestionnaireSupportDoc = ({
	clsName,
	reff,
	onselectAnswer,
  getValues,
  trigger,
  saveInputAns,

}) => {
    const [skipAgNo, setSkipAgNo] = useState(false);
    const [selectedOption, setSelectedOption] = useState('');
    

        // 	const handleClick = async (e) => {
        // 	let highestBalance = await trigger("document");
        // 	let errorFlag = 0;
        // 	if(!highestBalance) {
        // 	 errorFlag=1;
        // 	 }
        // 		if(errorFlag === 0){    
        // 			saveInputAns(e);   
        // 		} 
        // 		else 
        // 		{
        // 		    return false;
        // 		}	
        // };

        const handleOptionChange = (e) => {
          const values = getValues();
          setSelectedOption(e.target.value);
          saveInputAns(e);
          onselectAnswer(e);

        };
        

       
	return (
		<>
			<div className={`radio-box animated fadeInUp ${clsName}`} ref={reff}>
        <h3>
        {`What supporting documentation are you able to provide to bolster your complaint with Lending Stream?`}
        </h3>

        <RadioButtonQuest
          key="1"
          className="radio-button"
          labelName="Your Credit Agreement Number"
          labelClassName="option_but next-2"
          // labelCountString="a"        
          value="124"
          checked={selectedOption === '124'}
          name="question_42"
          id="agreeone"
          onClick={handleOptionChange}
          dataQuestId={42}
          dataAnsId={124}
          dataAnsLabel="Your Credit Agreement Number"
        />

        <RadioButtonQuest
          key="2"
          className="radio-button"
          labelName="Any Documents Relating to Your Credit Agreement"
          labelClassName="option_but next-2"
          // labelCountString="b"
          onClick={handleOptionChange}
          value="125"
          checked={selectedOption === '125'}
          name="question_42"
          id="agreetwo"
          dataQuestId={42}
          dataAnsId={125}
          dataAnsLabel="Any Documents Relating to Your Credit Agreement"
        />

        <RadioButtonQuest
          key="3"
          className="radio-button"
          labelName="A Statement of Account"
          labelClassName="option_but next-2"
          // labelCountString="c"
          onClick={handleOptionChange}
          value="120"
          checked={selectedOption === '120'}
          name="question_42"
          id="agreethree"
          dataQuestId={42}
          dataAnsId={120}
          dataAnsLabel="A Statement of Account"
        />
        <RadioButtonQuest
          key="4"
          className="radio-button"
          labelName="Bank Statements (One Month Before and After the Start of Your Credit(s)"
          labelClassName="option_but next-2"
          // labelCountString="d"
          onClick={handleOptionChange}
          value="126"
          checked={selectedOption === '126'}
          name="question_42"
          id="agreefour"
          dataQuestId={42}
          dataAnsId={126}
          dataAnsLabel="Bank Statements (One Month Before and After the Start of Your Credit(s)"
        />
        <RadioButtonQuest
          key="5"
          className="radio-button"
          labelName="Arrears Letters"
          labelClassName="option_but next-2"
          // labelCountString="d"
          onClick={handleOptionChange}
          value="127"
          checked={selectedOption === '127'}
          name="question_42"
          id="agreefive"
          dataQuestId={42}
          dataAnsId={127}
          dataAnsLabel="Arrears Letters"
        />
        <RadioButtonQuest
          key="6"
          className="radio-button"
          labelName="None of the Above"
          labelClassName="option_but next-2"
          // labelCountString="d"
          onClick={handleOptionChange}
          value="123"
          checked={selectedOption === '123'}
          name="question_42"
          id="agreesix"
          dataQuestId={42}
          dataAnsId={123}
          dataAnsLabel="None of the Above"
        />
      </div>
  		</>
	)
}


export default QuestionnaireSupportDoc;