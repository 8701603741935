import React, { useEffect, useState } from 'react';
import "../../../../assets/css/loa1.scss";
import Logo from "../../../../assets/img/logo_1.png";
import {
    ReliantBorrowing,
    Overindebtness,
    DebtEssentialBills,
    CreditFileInformation,
    ImpactedNegatively,
    HighUtilisation,
    StruggledRepayments,
    ClientFinancialArrears,
    CardNotManaged,
    UnableRepayments
} from "../../../../Constants/Constants";

const MeritDataListVAQ_C = ({
    category,
    InputValues,
    setShowModal,
}) => {


    const [matchedItems, setMatchedItems] = useState([]);

    const categories = {
        1: ReliantBorrowing,
        2: Overindebtness,
        3: DebtEssentialBills,
        4: CreditFileInformation,
        5: ImpactedNegatively,
        6: HighUtilisation,
        7: StruggledRepayments,
        8: ClientFinancialArrears,
        9: CardNotManaged,
        10: UnableRepayments
    };

    const categoryNames = {
        1: "Reliant on further borrowing",
        2: "Overindebtness",
        3: "Caused further debt with essential bills",
        4: "Adverse credit file information",
        5: "Negative impact on circumstances",
        6: "High utilisation of existing credit ",
        7: "Struggled to make repayments",
        8: "Financial arrears",
        9: "Credit card account was not managed well",
        10: "Unable to maintain sustainsable repayments"
    };

    useEffect(() => {

        if (category === 0) {
            // Handle the case where category is 0
            setMatchedItems([]);
        } else if (category in categories) {
            const selectedCategory = categories[category];

            // Ensure InputValues is an array of numbers
            const valuesArray = Array.isArray(InputValues) ? InputValues.filter(value => !isNaN(value)) : [];

            const filteredItems = valuesArray.map(value => ({
                id: value,
                description: selectedCategory[value] || 'Description not available'
            })).filter(item => item.description !== 'Description not available');

            setMatchedItems(filteredItems);
        } else {
            console.warn(`Category ${category} is not supported.`);
            setMatchedItems([]); // Clear items if the category is not supported
        }
    }, [category, InputValues]);
    
    
    

 



    return (
        <>
            <div className={`modal blackover fade ${category ? "show" : "hide"}`} id="sample" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-lg">
                    <div className={`modal-content  goodnews_popup `}>
                        <div className="modal-body">
                            <div className={`pb-3 text-center px-lg-4 px-md-4  px-sm-01  `}>
                                <div className="text-end">
                                    <button
                                        type="button"
                                        className="btn-close close22"
                                        data-bs-dismiss="modal"
                                        aria-label="Close"
                                        onClick={() => setShowModal(0)}>
                                    </button>
                                </div>
                                <div className="d-flex justify-content-start">
                                    <div className="">
                                        <div className="body-class container">
                                            <section className="body_all">
                                                <div className="page-break" />
                                                <table className="adition_page3">
                                                    <tbody style={{ display: "table" }}>
                                                        <tr>
                                                            <td style={{ width: "100%" }}>
                                                                <center>
                                                                    <img src={Logo} style={{ height: "60px" }} alt="Logo" />
                                                                </center>
                                                                <h2 className="questh2 mt-2">{categoryNames[category]}</h2>
                                                                <br />
                                                                <div className="text-start">
                                                                {matchedItems.length > 0 && (
                                                                    <div>
                                                                        <ol>
                                                                            {matchedItems.map(item => (
                                                                                <li key={item.id}>{item.description}</li>
                                                                            ))}
                                                                        </ol>
                                                                    </div>
                                                                )}
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </section>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </>
    );
};

export default MeritDataListVAQ_C;
