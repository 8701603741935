import React, { useState } from "react";
import TextField from "../../../UI/TextField";
import InputButton from "../../../UI/InputButton";
import {questionnaire59, questionnaire60 } from "../../../../Constants/Questions"; 

//import RadioButtonQuest from "../../../UI/RadioButtonQuest";
const QuestionnaireAgreementNoModal = ({
  validation,
  validationMsg,
  trigger, 
  agNoShow,
  saveInputAns,
  skipAgNo
}) => {


//   const questionId = questArr.questionId ? questArr.questionId : 0; 

        const agreementValidation = async (e) => {
            let agreement_no = await trigger("modal_question_45");
            if (agreement_no) {
            saveInputAns(e);
            // setAgNoShow('hide');
            } else {
            return false;
            }
        };
        

  return (
    <>
      <div role="dialog" className={`fade modal blackover ${agNoShow}`}>
          <div className="modal-dialog modal-md modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-body">
                <h3 className="text-center fonts15">Enter your Credit Agreement Number</h3>
                <div className="input-group mb-3 justify-content-center">
                  <div className="mb-3 mt-3 col-12 p-0">
                    <div className="input-group mb-3">
                      <TextField
                        type="tel"
                        className="form-control"
                        placeholder="Credit Agreement Number"
                        // name="question_51"
                        name="modal_question_45"
                        validation={validation({
                          required: "Please Enter Agreement Number",
                          minLength: {
                            value: 3,
                            message: "Please Enter Valid Agreement Number",
                          },
                          pattern: {
                            value: /^\d*[1-9]\d*$/,
                            message:
                              "Agreement Number is Invalid. Please Recheck",
                          },
                        })}
                        validationMsg={
                          validationMsg.modal_question_45 &&
                          validationMsg.modal_question_45.message
                        }
                      ></TextField>
                    </div>

                    <InputButton
                      name="agreement_no"
                      className="btn-next regNextBtn text-center"
                      id=""
                      value="Next"
                      onClick={agreementValidation}
                      style={{ float: "none" }}
                      dataQuestId = "45"
                    />
                    <p className="text-center">
                    <a
                      name="skip_agree_no"
                      className=""
                      id=""
                      onClick={skipAgNo}
                      style={{ float: "none" }}
                    >
                      SKIP for now and follow up ASAP
                    </a>
                    </p>

                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
    </>
  );
};

export default QuestionnaireAgreementNoModal;