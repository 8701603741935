

import React, { useState } from "react";
import TextField from "../../../../UI/TextField";
import InputButton from "../../../../UI/InputButton";
import RadioButtonQuest from "../../../../UI/RadioButtonQuest";

//import RadioButtonQuest from "../../../UI/RadioButtonQuest";
const AgreeModal = ({
    agreeModalShow,
    onselectAnswer,
    setAgreeModalShow
}) => { 
    const AgreeSlide = async (e) => {
		onselectAnswer(e);
		setAgreeModalShow('hide');
	};
  return (
    <>
    <div role="dialog" className={`fade modal blackover ${agreeModalShow}`}>
				<div className="modal-dialog modal-md modal-dialog-centered">
					<div className="modal-content">
						<div className="modal-body">

							<h3 className="text-center"><b>Please confirm if you agree with the statement</b></h3>
							<p> I only recently understood I had cause to complain when TMS Legal represented my interests regarding irresponsible lending. I considered my financial issues to be my problem and didn't link it to any irresponsible lending by the lender.</p>
							<div className="input-group mb-3 prevname-Btnpop justify-content-center">



								<RadioButtonQuest
									key="YES"
									className="radio-button"
									labelName="AGREE"
									labelClassName="option_but next-2"
									// labelCountString="a"
									onClick={AgreeSlide}
									value="131"
									name="agreeYear"
									id="onethre"
									dataQuestId={49}
									dataAnsId={131}
									dataAnsLabel="AGREE"
								/>


								<RadioButtonQuest
									key="YES"
									className="radio-button"
									labelName="DISAGREE"
									labelClassName="option_but next-2"
									// labelCountString="a"
									onClick={AgreeSlide}
									value="132"
									name="agreeYear"
									id="onethre"
									dataQuestId={49}
									dataAnsId={132}
									dataAnsLabel="DISAGREE"
								/>


							</div>
						</div>
					</div>
				</div>
			</div>
    </>
  );
};

export default AgreeModal;