import "../../assets/CheckMyId/scss/main.scss";

import logo from "../../assets/CheckMyId/img/logo-white.png";
import thumpsup from "../../assets/CheckMyId/img/thumpsup.png";

import { useState } from "react";
import Footer from "../Includes/Layouts/CheckMyId/Footer";
import { useLocation, useHistory } from "react-router-dom";
import { Api } from "../../api/Api";

function CheckMyId() {
  const [slideTwo, setSlideTwo] = useState(false);
  const location = useLocation();
  const history = useHistory();
  const searchParams = new URLSearchParams(location.search);
  const uuid = searchParams.get("uuid");
  const splitName = localStorage.getItem("split_name")? localStorage.getItem("split_name") :searchParams.get("split_name") ;

  const slideChange = async () => {
    const clickStore = await Api.post('api/v1/user_click_store',{
      uuid,
      page_name:splitName,
      click_type:"check_id_digitally"
    });
    if (clickStore?.data?.status == "Success") {
      setSlideTwo(true);
      localStorage.clear();
    }
  };

  const handleClick = async(event) => {
    event.preventDefault();
    const clickStore = await Api.post('api/v1/user_click_store',{
      uuid,
      page_name:splitName,
      click_type:"id_online"
    });
    history.push(`/VAQ_UB_4_ID?uuid=${uuid}`);
  };

  return (
    <div className="checkmyidwrapper">
      <div className="checkmyid">
        <div className="wrapper_div">
          <header className="pt-3 text-center">
            <div className="container">
              <img src={logo} height={60} alt="logo" />
            </div>
          </header>
          <section className="content-section">
            <div className="container">
              <div className="row">
                <div
                  className="col-lg-10 col-12 slide1 text-center offset-lg-1"
                  style={{ display: slideTwo ? "none" : "" }}
                >
                  <h1>Thankyou.</h1>
                  <p>
                    Your claim is now registered with us, and we will commence
                    the onboarding process. You may continue online with your ID
                    check, or we will attempt to complete the checks digitally,
                    and contact you if we are unsuccessful.
                  </p>
                  <div className="button-section">
                    <a className="btn-c nextbutid" onClick={slideChange}>
                      <span>Check my ID Digitally</span>
                    </a>
                    <a className="btn-c" onClick={handleClick}>
                      <span>Continue my ID check online</span>
                    </a>
                  </div>
                </div>
                <div
                  className="col-lg-12 col-12 slide2 text-center"
                  style={{ display: slideTwo ? "" : "none" }}
                >
                  <img src={thumpsup} height={100} className="mb-5" />
                  <h2>Thankyou for submitting your claim to TMS Legal. </h2>
                  <p>
                    We will now email you copies of your signed documents and
                    commence with your digital ID checks.
                  </p>
                  <p>
                    We will call you shortly to verify your documents and answer
                    any questions.
                  </p>
                </div>
              </div>
            </div>
          </section>
        </div>
        <Footer />
      </div>
    </div>
  );
}

export default CheckMyId;
