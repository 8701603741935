import React, { useEffect, useState } from 'react';
import "../../assets/css/loa1.scss";
import Logo from "../../assets/img/logo_1.png";
import {
  ReliantBorrowing,
  Overindebtness,
  DebtEssentialBills,
  CreditFileInformation,
  ImpactedNegatively,
  HighUtilisation,
  StruggledRepayments,
  ClientFinancialArrears,
  CardNotManaged,
  UnableRepayments
} from "../../Constants/Constants";

const PreviewMeritPage = () => {
  const searchQuery = new URLSearchParams(window.location.search);
  const category = searchQuery.get("category");
  const InputValues = searchQuery.get("values");

  const [matchedItems, setMatchedItems] = useState([]);

  const categories = {
    1: ReliantBorrowing,
    2: Overindebtness,
    3: DebtEssentialBills,
    4: CreditFileInformation,
    5: ImpactedNegatively,
    6: HighUtilisation,
    7: StruggledRepayments,
    8: ClientFinancialArrears,
    9: CardNotManaged,
    10: UnableRepayments
  };

  const categoryNames = {
    1: "Reliant on further borrowing",
    2: "Overindebtness",
    3: "Caused further debt with essential bills",
    4: "Adverse credit file information",
    5: "Negative impact on circumstances",
    6: "High utilisation of existing credit ",
    7: "Struggled to make repayments",
    8: "Financial arrears",
    9: "Credit card account was not managed well",
    10: "Unable to maintain sustainsable repayments"
  };

  useEffect(() => {
    if (category in categories) {
      const selectedCategory = categories[category];
      const valuesArray = InputValues.split(',').map(Number);

      const filteredItems = valuesArray.map(value => ({
        id: value,
        description: selectedCategory[value] || ''
      })).filter(item => item.description);

      setMatchedItems(filteredItems);
    } else {
      console.error(`Category ${category} is not supported.`);
    }
  }, [category, InputValues]);

  return (
    <>
      <div className="loa-sec loa-sec-2">
        <div className="body-class container">
          <section className="body_all">
            <div className="page-break" />
            <table className="adition_page3">
              <tbody style={{ display: "table" }}>
                <tr>
                  <td style={{ width: "100%" }}>
                    <center>
                      <img src={Logo} style={{ height: "60px" }} alt="Logo" />
                    </center>
                    <h2 className="questh2">{categoryNames[category]}</h2>
                    <br />
                    {matchedItems.length > 0 && (
                      <div>
                        <ol>
                          {matchedItems.map(item => (
                            <li key={item.id}>{item.description}</li>
                          ))}
                        </ol>
                      </div>
                    )}
                  </td>
                </tr>
              </tbody>
            </table>
          </section>
        </div>
      </div>
    </>
  );
};

export default PreviewMeritPage;
