import React, { useState } from "react";
import ProgressBar from "../Common/ProgressBar";
import SelectBox from "../../../UI/SelectBox";
// import { PossessionYear } from "../../../../Constants/Constants";
import { PossessionMonth } from "../../../../Constants/Constants";
import InputButton from "../../../UI/InputButton";
import RadioButton from "../../../UI/RadioButton";
import { useFollowupUserQuestions } from "../../../../Hooks/useFollowupUserQuestions";
import { useOpenBanking } from "../../../../Hooks/useOpenBanking";
import * as EnvConstants from "../../../../Constants/EnvConstants";
import Button from "../../../UI/Button";

const YearOfPossession = ({
  validationMsg,
  clearErrors,
  validation,
  trigger,
  getValues,
  Year,
  allFollowupData,
  removeFromPending,
  setAgreementDate,
  addPending,
  className
}) => {
  const { saveFollowupUserQuestions, isLoading } = useFollowupUserQuestions();
  const { getVisitorID, getAccessToken, getProviders, milestoneChase } =
    useOpenBanking();
  const [previousBtn, setPreviousBtn] = useState("");
  const environment = EnvConstants.AppEnv;
  const isTest = environment == "prod" ? false : true;
  // const [agreeModalShow, setAgreeModalShow] = useState("hide");
  const formData = getValues();
  let yearSelected = formData.YearOfPossession;
  const nextSlide = async (e) => {
    let errorFlag = 0;
    let yearresult = await trigger("YearOfPossession");
    let monthresult = await trigger("MonthOfPossession");

    if (!yearresult || !monthresult) {
      return false;
    }
    // if (yearSelected < 2017) {
    //   setAgreeModalShow('show')
    // } else {
    //  onselectAnswer();
    // }
    formData.question_65 = formData.YearOfPossession.concat(
      " ",
      formData.MonthOfPossession
    );
    let question_65 = formData.question_65;
    setPreviousBtn(question_65);

    let flage = false;
    let question_data = {};
    if (e.target.name == "yearposession") {
      // if (selectedQuest) {
      flage = true;
      question_data = {
        question_id: 65,
        option_id: null,
        answer_text: question_65,
        input_answer: question_65,
      };
      // }
    } else {
      // if (selectedAns && selectedQuest) {
      //   flage = true;
      //   question_data = {
      //     question_id: selectedQuest,
      //     option_id: selectedAns,
      //     answer_text: anslabel,
      //     input_answer: "",
      //   };
      // }
    }

    if (flage) {
      storeFollowupQuestionnire(question_data);
      localStorage.setItem("agreementDate", question_65);
      setAgreementDate(question_65);
      var existingEntries = JSON.parse(localStorage.getItem("questionData"));
      if (existingEntries == null) existingEntries = [];
      localStorage.setItem(
        "currentquestionData",
        JSON.stringify(question_data)
      );
      existingEntries.push(question_data);
      localStorage.setItem("questionData", JSON.stringify(existingEntries));
      removeFromPending("loan_date");
    }
  };

  const storeFollowupQuestionnire = async (question_data) => {
    const response = await saveFollowupUserQuestions(
      question_data,
      allFollowupData,
      "followup_question_store"
    );
  };

  const PossessionYear = [{ label: "Select Year", value: "" }];
  {
    const startofYear = 2023;
    const endofYear = Year;
    for (var k = startofYear; k >= endofYear; k--) {
      var objYearOfPossession = {};
      objYearOfPossession["label"] = k;
      objYearOfPossession["value"] = k;
      PossessionYear.push(objYearOfPossession);
    }
  }

  return (
    <>
      <div className={className}>
        <div className="row">
          <div className="col-lg-6 offset-lg-3">
          <h3 className="mt-md-2 mt-sm-2 mt-0 text_red_3a">
            We were unable to locate your loan with Moneybarn.{" "}
          </h3>
          <h1 className=" my-4 mt-md-2 mt-sm-2 mt-0 text_red_2a ">
            Please confirm the approximate date you took this loan out.{" "}
          </h1>
            <div className="row mt-4">
              <div className="col-lg-12">
                <div className="row">
                  <div className="col-lg-6">
                    <div className="mb-3">
                      <SelectBox
                        className="form-select form-control"
                        OptionValue={PossessionMonth}
                        name="MonthOfPossession"
                        id="MonthOfPossession"
                        onChange={() => clearErrors("MonthOfPossession")}
                        clearErrors={clearErrors}
                        myRef={validation({ required: "Please Select Month" })}
                        validationMsg={
                          validationMsg.MonthOfPossession &&
                          validationMsg.MonthOfPossession.message
                        }
                      ></SelectBox>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="mb-3">
                      <SelectBox
                        className="form-select form-control"
                        OptionValue={PossessionYear}
                        name="YearOfPossession"
                        id="YearOfPossession"
                        onChange={() => clearErrors("YearOfPossession")}
                        clearErrors={clearErrors}
                        myRef={validation({ required: "Please Select Year" })}
                        validationMsg={
                          validationMsg.YearOfPossession &&
                          validationMsg.YearOfPossession.message
                        }
                      ></SelectBox>
                    </div>
                  </div>
                </div>

                <div>
                  <InputButton
                    name="yearposession"
                    className="com-btn btn-next w-100 mb-3"
                    id=""
                    value="Next"
                    btnType="button"
                    onClick={nextSlide}
                    style={{ float: "none" }}
                  />
                </div>
                <div class="text-center back">
                    <Button 
                    className="btn btn-link mt-2 h2" 
                    id="back1" 
                    type="button"
                    buttonText="<< Previous"
                    onClick={()=>addPending("loan_date")}
                    />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default YearOfPossession;
