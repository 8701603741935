import React, { useState } from "react";
import { Employmentsts,EmploymentNDY } from "../../../../Constants/Constants";
import SelectBox from "../../../UI/SelectBox";
import RadioButtonQuest from "../../../UI/RadioButtonQuest";

const EmploymentStatus = ({
  clsName,
  onSelectAnswer,
  validationMsg,
  clearErrors,
  validation,
  trigger,
  splitForm,
  getValues,
  backClick
}) => {
  const [questResult, setQuestResult] = useState(false);
  const [checkedOne, setCheckedOne] = useState("unchecked");
  const [checkedTwo, setCheckedTwo] = useState("unchecked");
  const [checkedThree, setCheckedThree] = useState("unchecked");
  const [checkedFour, setCheckedFour] = useState("unchecked");
  const [checkedFive, setCheckedFive] = useState("unchecked");
  const [checkedSix, setCheckedSix] = useState("unchecked");
  const [checkedSeven, setCheckedSeven] = useState("unchecked");
  const [checkedEight, setCheckedEight] = useState("unchecked");
  const [checkedNine, setCheckedNine] = useState("unchecked");
  const [checkedTen, setCheckedTen] = useState("unchecked");
  const [checkedEleven, setCheckedEleven] = useState("unchecked");

  const optionSelected = async (e) => {
    if (e.target.value == "3") {
      setCheckedOne("checked");
    } else {
      setCheckedOne("unchecked");
    }
    if (e.target.value == "199") {
      setCheckedTwo("checked");
    } else {
      setCheckedTwo("unchecked");
    }
    if (e.target.value == "4") {
      setCheckedThree("checked");
    } else {
      setCheckedThree("unchecked");
    }
    if (e.target.value == "5") {
      setCheckedFour("checked");
    } else {
      setCheckedFour("unchecked");
    }
    if (e.target.value == "6") {
      setCheckedFive("checked");
    } else {
      setCheckedFive("unchecked");
    }
    if (e.target.value == "200") {
      setCheckedSix("checked");
    } else {
      setCheckedSix("unchecked");
    }
    if (e.target.value == "201") {
      setCheckedSeven("checked");
    } else {
      setCheckedSeven("unchecked");
    }
    if (e.target.value == "202") {
      setCheckedEight("checked");
    } else {
      setCheckedEight("unchecked");
    }
    if (e.target.value == "203") {
      setCheckedNine("checked");
    } else {
      setCheckedNine("unchecked");
    }
    if (e.target.value == "204") {
      setCheckedTen("checked");
    } else {
      setCheckedTen("unchecked");
    }
    if (e.target.value == "205") {
      setCheckedEleven("checked");
    } else {
      setCheckedEleven("unchecked");
    }
    // if (e.target.value == "195") {
    //   setCheckedTwelve("checked");
    // } else {
    //   setCheckedTwelve("unchecked");
    // }
    setQuestResult(false);

  }
  const handleClick = async (e) => {
    let errorFlag = 0;
    let optionselect = splitForm.current["employment"].value;
    if (optionselect == '') {
      errorFlag = 1;
      setQuestResult(true);
      return false;
    } else {
      console.log("hello");
      onSelectAnswer(e);
    }
  };
  return (
    <>
      <div className="question-content mt-0 scale-down-ver-center px-3 py-lg-3 py-md-3 py-sm-2 py-2 ">
        <div className="col-lg-12 mb-2">
          <ul class="p-0 m-0 option-grid option-gridV2">
            <li>
              <div class="optionbtn">
                <RadioButtonQuest
                  name="employment"
                  id="Employed"
                  labelClassName={`${checkedOne}`}
                  value="3"
                  labelName="Employed"
                  onClick={optionSelected}
                  dataQuestId={2}
                  dataAnsId={3}
                />
              </div>
            </li>
            <li>
              <div class="optionbtn">
                <RadioButtonQuest
                  name="employment"
                  id="Unemployed"
                  labelClassName={`${checkedTwo}`}
                  value="199"
                  labelName="Unemployed"
                  onClick={optionSelected}
                  dataQuestId={2}
                  dataAnsId={199}
                />
              </div>
            </li>
            <li>
              <div class="optionbtn">
                <RadioButtonQuest
                  name="employment"
                  id="Selfemployed"
                  labelClassName={`${checkedThree}`}
                  value="4"
                  labelName="Selfemployed"
                  onClick={optionSelected}
                  dataQuestId={2}
                  dataAnsId={4}
                />
              </div>
            </li>
            <li>
              <div class="optionbtn">
                <RadioButtonQuest
                  name="employment"
                  id="Student"
                  labelClassName={`${checkedFour}`}
                  value="5"
                  labelName="Student"
                  onClick={optionSelected}
                  dataQuestId={2}
                  dataAnsId={5}
                />
              </div>
            </li>
            <li>
              <div class="optionbtn">
                <RadioButtonQuest
                  name="employment"
                  id="Retired"
                  labelClassName={`${checkedFive}`}
                  value="6"
                  labelName="Retired"
                  onClick={optionSelected}
                  dataQuestId={2}
                  dataAnsId={6}
                />
              </div>
            </li>
            <li>
              <div class="optionbtn">
                <RadioButtonQuest
                  name="employment"
                  id="Benefitsincome"
                  labelClassName={`${checkedSix}`}
                  value="200"
                  labelName="Benefits main source of income"
                  onClick={optionSelected}
                  dataQuestId={2}
                  dataAnsId={200}
                />
              </div>
            </li>
            <li>
              <div class="optionbtn">
                <RadioButtonQuest
                  name="employment"
                  id="Parttime"
                  labelClassName={`${checkedSeven}`}
                  value="201"
                  labelName="Part-time job"
                  onClick={optionSelected}
                  dataQuestId={2}
                  dataAnsId={201}
                />
              </div>
            </li>
            <li>
              <div class="optionbtn">
                <RadioButtonQuest
                  name="employment"
                  id="Temporaryworker"
                  labelClassName={`${checkedEight}`}
                  value="202"
                  labelName="Temporary worker"
                  onClick={optionSelected}
                  dataQuestId={2}
                  dataAnsId={202}
                />
              </div>
            </li>
            <li>
              <div class="optionbtn">
                <RadioButtonQuest
                  name="employment"
                  id="LongSick"
                  labelClassName={`${checkedNine}`}
                  value="203"
                  labelName="Long Term Sick"
                  onClick={optionSelected}
                  dataQuestId={2}
                  dataAnsId={203}
                />
              </div>
            </li>
            <li>
              <div class="optionbtn">
                <RadioButtonQuest
                  name="employment"
                  id="Maternity"
                  labelClassName={`${checkedTen}`}
                  value="204"
                  labelName="Maternity"
                  onClick={optionSelected}
                  dataQuestId={2}
                  dataAnsId={204}
                />
              </div>
            </li>
            <li>
              <div class="optionbtn">
                <RadioButtonQuest
                  name="employment"
                  id="contract"
                  labelClassName={`${checkedEleven}`}
                  value="205"
                  labelName="Rolling/Zero hours contract"
                  onClick={optionSelected}
                  dataQuestId={2}
                  dataAnsId={205}
                />
              </div>
            </li>
          </ul>
          {questResult && (
            <span className="error_msg">Please select an option</span>
          )}
        </div>
        {/* <div className="row">
          <div className="col-lg-8 mb-2">
            <SelectBox
              className="form-select"
              OptionValue={Employmentsts}
              // OptionValue={EmploymentNDY}
              name="employment"
              id="employment"
              onChange={() => clearErrors("employment")}
              clearErrors={clearErrors}
              myRef={validation({ required: "Please select your employement status" })}
              validationMsg={
                validationMsg.employment && validationMsg.employment.message
              }
            ></SelectBox>
          </div>
          <div className="col-lg-4 mb-2 ">
            <button
              type="button"
              className=" btn-comm2 py-lg-3 py-md-3 py-sm-2 py-2 w-100 nextBtn "
              id="nextscroll"
              name="employmentsts"
              onClick={optionSelected}
              data-quest-id={2}
            >
              Next &#8594; 
            </button>
          </div>
        </div> */}
        <div className="col-lg-12 mb-2 mt-2">
          <button
            type="button"
            className=" btn-comm2 py-lg-3 py-md-3 py-sm-2 py-2 w-100 nextBtn "
            id="nextscroll"
            name="employmentsts"
            onClick={handleClick}
            data-quest-id={2}
          >
            Next &#8594;
            {/* <i className="bi bi-arrow-right"></i> */}
          </button>
        </div>
        <p className="text-center bck-marwid">
          <button
            name="stillactive"
            className="btn btn-secondary backbtn1a"
            id=""
            value=""
            type="button"
            onClick={backClick}
          >
            Go Back
          </button>
        </p>
      </div>
    </>
  );
}
export default EmploymentStatus; 
